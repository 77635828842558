import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ajax } from 'usefuljs'
import { selectTaskId } from '../../../../../../../app/store/feedSlice'
import { updatePostSentiment } from '../../../../../../../app/store/livefeedpost'
import { selectToken } from '../../../../../../../app/store/userSlice'
import NegativeFaceIcon from '../../../../../../reusable/icons/NegativeFaceIcon'
import NeutralFaceIcon from '../../../../../../reusable/icons/NeutralFaceIcon'
import PositiveFaceIcon from '../../../../../../reusable/icons/PositiveFaceIcon'
import { ButtonWrapper, PickerRoot, SentimentContainer } from './styled'

interface PickerProp {
    hash: string
}

function Picker({ hash }: PickerProp) {
    const taskId = useSelector(selectTaskId)
    const token = useSelector(selectToken)
    const dispatch = useDispatch()

    const selectSentiment = async (value: number) => {
        try {
            const res: any = await ajax({
                method: "POST",
                headers: { token },
                url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/sentiment/tag`,
                data: [{
                    task_id: taskId,
                    live_sid: `${taskId}_${hash}`,
                    value
                }]
            })

            if (res.result === "success") {
                dispatch(updatePostSentiment({
                    taskHash: hash,
                    sentiment: value
                }))
            } else {
                throw new Error("Failed to update sentiment")
            }

        } catch (e) {
            toast.error(e.message)
        }

    }

    return (
        <PickerRoot>
            <SentimentContainer>
                <ButtonWrapper onClick={() => selectSentiment(1)}>
                    <PositiveFaceIcon height="2rem" width="2rem" />
                    <span>Positive</span>
                </ButtonWrapper>
                <ButtonWrapper onClick={() => selectSentiment(0)}>
                    <NeutralFaceIcon height="2rem" width="2rem" />
                    <span>Neutral</span>
                </ButtonWrapper>
                <ButtonWrapper onClick={() => selectSentiment(-1)}>
                    <NegativeFaceIcon height="2rem" width="2rem" />
                    <span>Negative</span>
                </ButtonWrapper>
            </SentimentContainer>
        </PickerRoot>
    )
}

export default Picker
