import React from 'react'
import styled, { css } from 'styled-components'
import { uuidv4 } from "usefuljs"
import DropdownWrapper from "../effects/DropdownWrapper"
import { AsyncThunk } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"

interface SelectProp {
  value: number
  setValue: AsyncThunk<void, number, {}>
  options: { label: string, value: number }[]
  width: string
  height: string
  margin?: string
}

const SelectRoot = styled.div<{ margin: string }>`
  margin: ${props => props.margin};
  border: 1px solid #F2F2F2;
  border-radius: 5px;
`

const SelectHeader = styled.button<{ width: string, height: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: white;
  border-radius: 3px;
  border: none;
  padding: 10px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const SelectHeaderTitle = styled.p`
  /* margin: 0px 11px; */
`

const SelectHeaderIcon = styled.i<{ show: boolean }>`
  margin-right: 0px;
  transition: all 0.2s ease;
  ${props => props.show && css`
    transform: rotate(-180deg);
  `}
`

const SelectMenu = styled.div<{ width: string }>`
  position: absolute;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: ${props => props.width};
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  transition: 0.3s all ease;
`

const MenuItem = styled.button<{ height: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: ${props => props.height};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #F5F5F5;
  }
  &:focus {
    outline: none;
  }
`

function Select({
  width,
  value,
  height,
  setValue,
  options,
  margin = "0px"
}: SelectProp) {

  const dispatch = useDispatch()

  const SelectHeaderRoot = ({ show }: { show: boolean }) => {

    return (
      <SelectHeader width={width} height={height}>
        <SelectHeaderTitle>{value}</SelectHeaderTitle>
        <SelectHeaderIcon className="fas fa-chevron-down" show={show} />
      </SelectHeader>
    )
  }

  return (
    <SelectRoot margin={margin}>
      <DropdownWrapper
        direction="up"
        Header={SelectHeaderRoot}
        Menu={(
          <SelectMenu width={width} >
            {options.map(option => (
              <MenuItem
                key={uuidv4()}
                value={option.value}
                height={height}
                onClick={(event) => {
                  dispatch(setValue(parseInt(event.currentTarget.value)))
                }}
              >
                {option.label}
              </MenuItem>
            )
            )}
          </SelectMenu>
        )}
      />
    </SelectRoot>
  )
}

export default Select
