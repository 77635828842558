import * as React from "react";

function ArrowUp(props:any) {
  return (
    <svg
      width={30}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.366 4.055c-.487 0-.955-.214-1.3-.594a2.137 2.137 0 01-.539-1.434c0-.537.194-1.053.539-1.433.345-.38.813-.594 1.3-.594h9.195c.487 0 .955.214 1.3.594.345.38.539.896.539 1.433v10.138c0 .538-.194 1.053-.539 1.434-.345.38-.813.593-1.3.593-.488 0-.956-.213-1.3-.593a2.138 2.138 0 01-.54-1.434V6.922l-7.893 8.704c-.345.38-.813.594-1.3.594-.488 0-.956-.214-1.3-.594l-4.217-4.65-7.895 8.705c-.346.37-.81.573-1.293.569a1.758 1.758 0 01-1.284-.594A2.137 2.137 0 010 18.24a2.143 2.143 0 01.516-1.426L9.711 6.677c.345-.38.812-.594 1.3-.594.487 0 .955.213 1.3.594l4.216 4.649 6.595-7.271h-4.756z"
        fill="#1877F2"
      />
    </svg>
  );
}

export default ArrowUp
