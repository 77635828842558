import uf from "usefuljs"
import { EditorType, EditorDetail, EditorView, ESQLayer, UpdateFrequency, EditorState, TaskType, GuidedKeyword } from "../interface"
import { store } from "../../../../../app/store"
import { Thunk } from "."
import ESConvert from "../qrybuilder/esconvert"
import { guided2view } from "../qrybuilder/converter"
import { FeedData } from "../../../../../app/interfaces/feed"
import { calculateIncludes } from "../helper"

export const setEditorType = (editorType: EditorType, dispatch: React.Dispatch<any>) => dispatch({ type: "setEditorType", payload: editorType })

export const nextPage = (dispatch: React.Dispatch<any>) => dispatch({ type: "nextEditorView" })

export const checkAccess = (view: EditorView, state: EditorState): boolean => {
    let hasAccess = false

    switch (view) {
        case EditorView.Type:
            if (state.detail.type !== null) { hasAccess = true };
            break
        case EditorView.Name:
            if (state.detail.name) { hasAccess = true };
            break
        case EditorView.Keywords:
            if (state.detail.keywords) { hasAccess = true };
            break
        case EditorView.Sources:
            if (state.detail.mediums === null || state.detail.mediums.length > 0) { hasAccess = true };
            break
        case EditorView.Settings:
            if (state.detail.unix_start !== 0) { hasAccess = true };
    }
    return hasAccess
}


export const setView = (view: EditorView, thunk: (t: Thunk) => void) => thunk((dispatch: React.Dispatch<any>, state: EditorState) => {
    let hasAccess = checkAccess(view, state)
    if (hasAccess) {
        dispatch({ type: "setEditorView", payload: view })
    }

})

export const saveTask = async (taskId: number, ed: EditorDetail) => {
    try {

        if (!ed.keywords || ed.keywords.guided.length === 0) {
            throw new Error("Keywords cannot be empty.")
        }

        const res: { result: string, error?: string } = await uf.ajax({
            method: "post",
            headers: { token: store.getState().user.token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/task/info/${taskId}/save`,
            data: { ...ed, taskId }
        })

        if (res.result !== "success") {
            throw new Error(res.error)
        }

        window.localStorage.setItem(`${taskId}_update_timestamp`, (new Date()).toString())
        return res.result
    } catch (err) {
        throw new Error(err)
    }
}


export const createTask = async (editorDetail: EditorDetail, dispatch: React.Dispatch<any>) => {
    try {
        if (editorDetail.type === null) { throw new Error("No feed type selected") }
        if (!editorDetail.name?.trim()) { throw new Error("Feed name has not been set yet") }
        if (!editorDetail.keywords) { throw new Error("Keywords has not been set yet") }
        if (editorDetail.type === 1 && calculateIncludes(editorDetail.keywords.guidedView?.NoExOnly ?? []) > 100) { throw new Error("Keywords exceed the limit") }
        if (!editorDetail.lang) { throw new Error("No AI Language picked") }
        if (editorDetail.mediums && !editorDetail.mediums.length) { throw new Error("No medium selected") }
        if (editorDetail.unix_start === null || editorDetail.unix_start === 0) { throw new Error("No start date selected") }
        if (editorDetail.type === 1 && (editorDetail.unix_end === null || editorDetail.unix_end === 0)) { throw new Error("No end date selected") }
        if (editorDetail.frequency === null) { throw new Error("No update frequency selected") }

        dispatch({ type: "setEditorSubmitting", payload: true })

        const res: any = await uf.ajax({
            method: "post",
            headers: { token: store.getState().user.token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/task/create`,
            data: editorDetail
        })

        if (res.result !== "success") { throw new Error(res.error) }

        return res.data

    } catch (err) {
        dispatch({ type: "setEditorSubmitting", payload: false })
        throw err
    }
}


export const setTaskType = (tasktype: TaskType, dispatch: React.Dispatch<any>) => {
    dispatch({ type: "setTaskType", payload: tasktype })
    dispatch({ type: "nextEditorView" })
    if (tasktype === TaskType.adhoc) {
        dispatch({ type: "setTaskFrequency", payload: UpdateFrequency.OnRequest })
    }
}


export const setFirstKeyword = (name: string, thunk: (t: Thunk) => void) => thunk((dispatch: React.Dispatch<any>, state: EditorState) => {
    // update first include
    const currGuided: ESQLayer[] = state.detail.keywords?.guided ?? [{ in: [[]] }]


    if (typeof currGuided[0]?.in?.[0] === "object") {
        currGuided[0].in[0][0] = name
    }


    const ec = new ESConvert(currGuided)
    const qrystring = ec.convert()
    const view: GuidedKeyword = guided2view(currGuided)
    dispatch({ type: "setTaskKeywords", payload: { guided: currGuided, advanced: qrystring, guidedView: view } })
})


export const setTaskName = (name: string, thunk: (t: Thunk) => void) => {
    return thunk((dispatch: React.Dispatch<any>, state: EditorState) => {
        dispatch({ type: "setTaskName", payload: name })
        setFirstKeyword(name, thunk)
    })
}


export const addNickname = (name: string, thunk: (t: Thunk) => void) => thunk((dispatch: React.Dispatch<any>, state: EditorState) => {
    const currGuided: ESQLayer[] = state.detail.keywords?.guided ?? [{ in: [[]] }]
    if (typeof currGuided[0]?.in?.[0] === "object") {
        currGuided[0].in[0].push(name)
    }
    const ec = new ESConvert(currGuided)
    const qrystring = ec.convert()


    dispatch({ type: "setTaskKeywords", payload: { guided: currGuided, advanced: qrystring, guidedView: guided2view(currGuided) } })
})



export const setTaskIndus = (indus_id: number, dispatch: React.Dispatch<any>) => dispatch({ type: "setTaskIndus", payload: indus_id })
export const setTaskKw = (guided: ESQLayer[], advanced: string, thunk: (t: Thunk) => void) => thunk((dispatch: React.Dispatch<any>, state: EditorState) => {
    if (advanced === "") {
        dispatch({ type: "setTaskKeywords", payload: null })
    } else {
        dispatch({ type: "setTaskKeywords", payload: { guided, advanced, guidedView: guided2view(guided) } })
    }

})
export const setTaskLang = (lang: string, dispatch: React.Dispatch<any>) => dispatch({ type: "setTaskLang", payload: lang })
export const setTaskMediums = (mediums: string[], dispatch: React.Dispatch<any>) => dispatch({ type: "setTaskMediums", payload: mediums })


export const setTaskStart = (unix_start: number, dispatch: React.Dispatch<any>) => dispatch({ type: "setTaskUnixStart", payload: unix_start })
export const setTaskEnd = (unix_end: number, dispatch: React.Dispatch<any>) => dispatch({ type: "setTaskUnixEnd", payload: unix_end })

export const setTaskDateRange = (unix_start: number, unix_end: number, dispatch: React.Dispatch<any>) => {
    dispatch({ type: "setTaskUnixStart", payload: unix_start })
    dispatch({ type: "setTaskUnixEnd", payload: unix_end })
}

export const setTaskFrequency = (frequency: UpdateFrequency, dispatch: React.Dispatch<any>) => dispatch({ type: "setTaskFrequency", payload: frequency })

export const fetchPreviewData = (thunk: (t: Thunk) => void) => thunk(async (dispatch: React.Dispatch<any>, state: EditorState) => {
    dispatch({ type: "setEditorPreviewFetching", payload: true })
    try {
        const data: Promise<FeedData[]> = await uf.ajax({
            method: "post",
            headers: { token: store.getState().user.token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/task/searchprev`,
            data: {
                query: state.detail.keywords?.advanced
            }
        }).then((res:any) => res.data).catch(err => { throw err })

        dispatch({ type: "setEditorPreviewData", payload: data })

    } catch (err) { } finally {
        dispatch({ type: "setEditorPreviewFetching", payload: false })
    }
})

export const fetchTaskConfig = (taskId: number, dispatch: React.Dispatch<any>) => {
    try {
        uf.ajax({
            method: "get",
            headers: { token: store.getState().user.token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/task/info/${taskId}`
        }).then((res:any) => {
            if (res.result !== "success") throw new Error(res.error)
            dispatch({ type: "setTaskConfig", payload: res.data })
        }).catch(err => { throw err })
    } catch (err) { throw err }
}

// export const setTaskConfig = (frequency: UpdateFrequency, dispatch: React.Dispatch<any>) => dispatch({ type: "setTaskFrequency", payload: frequency });