import React from "react";
import { FeedData } from "../../../../../app/interfaces/feed";
import styles from "./posts.module.scss";
import Post from "./post";

interface PostsProp {
  data: FeedData[];
}

const Posts = ({ data }: PostsProp) => {
  return (
    <div className={styles.posts}>
      {data.map((feed) => (
        <Post key={feed.hash} data={feed} />
      ))}
    </div>
  );
};

export default Posts;
