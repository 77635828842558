import React, { memo } from 'react'
import styled from 'styled-components'
import Select from '../../../../reusable/inputs/Select'
import { useSelector } from "react-redux"
import { selectPostsPerPage, setPaginationPostsPerPage } from "../../../../../app/store/livefeedpost"

interface PostsPerPageSelectProp {
  margin?: string
}

const PostsPerPageSelectRoot = styled.div<{ margin: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${props => props.margin};
  min-width: fit-content;
`

const LabelText = styled.p<{ margin?: string }>`
  font-size: 1rem;
  color: #868686;
  margin:${props => props.margin ?? "0px"};
`

const PostsPerPageSelect = memo<PostsPerPageSelectProp>(({
  margin = "0px"
}) => {

  const postsPerPage = useSelector(selectPostsPerPage)

  return (
    <PostsPerPageSelectRoot margin={margin}>
      <LabelText margin={"0px 12px 0px 0px"}>Posts per page:</LabelText>
      <Select
        width="65px"
        height="30px"
        value={postsPerPage}
        setValue={setPaginationPostsPerPage}
        options={[{ value: 20, label: "20" }, { value: 30, label: "30" }, { value: 40, label: "40" }]}
      />
    </PostsPerPageSelectRoot>
  )
})

export default PostsPerPageSelect