import styled, { css } from "styled-components";

export const PreviousVersionsMenu = styled.div`
  max-height: 444px;
  width: 386px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 10rem;
  position: absolute;
  left: 37px;
  background-color: white;
  box-shadow: 0px 0px 15px rgba(146, 165, 195, 0.5);
  z-index: 11;
  margin-top: 5px;
  overflow-y: auto;
`;

export const PreviousVerisonsHeader = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const PreviousVersionText = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

export const TimeframeContainer = styled.div`
  padding: 0 15px;
  font-size: 12px;
  height: 33.5px;
  border-top: 1px solid #dadada;
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

export const VersionItemContainer = styled.div`
  padding: 15px;
  border-top: 1px solid #dadada;
  width: 100%;

  &:hover {
    background-color: #fafafa;
  }
`;
