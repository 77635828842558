import styled from 'styled-components'

const legendDotSize = "1rem"

export const SentimentChartRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
`

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
`

export const Legend = styled.div<{ color: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100px;

  div:first-child {
    height: ${legendDotSize};
    width: ${legendDotSize};
    border-radius: 50%;
    background-color: ${props => props.color};
    margin-right: 1rem;
  }

  span {
    font-size: 14px;
    color: #333333;
  }
`