import * as React from "react";
import styled from 'styled-components'

const SVG = styled.svg`
  height: 1.5rem;
  fill: none;
`

const ExternalPath = styled.path`
  fill: #D2D2D2;
  ${SVG}:hover & {
    fill: #FEEEC6;
  }
`

const InternalPath = styled.path`
  fill: #FFF;
  ${SVG}:hover & {
    fill: #FEEEC6;
  }
`

function SvgBookmarkDefault() {
  return (
    <SVG viewBox="0 0 15 23">
      <ExternalPath d="M12.5 0H2.083C.937 0 .01 1.147.01 2.55L0 22.95l7.292-3.826 7.291 3.825V2.55C14.583 1.148 13.646 0 12.5 0z" />
      <InternalPath d="M12.5 19.124l-5.208-2.78-5.208 2.78v-15.3c0-.7.469-1.274 1.042-1.274h8.333c.573 0 1.042.574 1.042 1.275v15.3z" />
    </SVG>
  );
}

export default SvgBookmarkDefault;