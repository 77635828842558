import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom"
import styled from 'styled-components'
import AuthRoute from "../auth/AuthRoute"
import NavigationBar from "./NavigationBar"
import PlanUsage from "./PlanUsage"
// import Profile from "./Profile"

const AccountPageRoot = styled.div`
  display: flex;
  background-color:  #f8f8fa;
`

const TabContainer = styled.div`
  width: 100%;
  padding: 3rem;
`



function Account() {
  let match = useRouteMatch()

  return (
    <AccountPageRoot>
      <NavigationBar />
      <TabContainer>
        <Switch>
          {/* <AuthRoute path={`${match.path}/profile`} component={Profile} /> */}
          <AuthRoute path={`${match.path}/usage`} component={PlanUsage} />
        </Switch>
      </TabContainer>
    </AccountPageRoot>
  )
}

export default Account
