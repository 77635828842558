import * as React from "react"

interface AngleRightEndProp {
  height?: string
}

function AngleRightEnd({height = "1rem"}:AngleRightEndProp) {
  return (
    <svg viewBox="0 0 141.73 141.73" height={height}>
      <defs>
        <style>{".icon4_svg__cls-1{fill:#414b59}"}</style>
      </defs>
      <g id="icon4_svg__\u5716\u5C64_2" data-name="\u5716\u5C64 2">
        <path
          className="icon4_svg__cls-1"
          d="M86.94 68.53a3.05 3.05 0 00-1-2.09L39.45 24.13a3.34 3.34 0 00-4.58 0l-5 4.54a2.68 2.68 0 000 4.18l39.22 35.68-39.21 35.69a2.67 2.67 0 000 4.17l5 4.54a3.34 3.34 0 004.58 0L86 70.62a3 3 0 00.94-2.09zM98 23.22h8.86c1.22 0 2.22.7 2.22 1.57v87.49c0 .86-1 1.56-2.22 1.56H98c-1.22 0-2.21-.7-2.21-1.56V24.79c-.02-.87.97-1.57 2.21-1.57z"
        />
      </g>
    </svg>
  )
}

export default AngleRightEnd