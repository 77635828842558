import React, { useState } from 'react'
import styled, { css } from "styled-components"

interface SelectViewByProp {
  viewByOption: string
  hasEngagement: boolean
  hasGrowth: boolean
  handleSelectViewBy: (event: React.MouseEvent) => void
  options: string[]
  isReadOnly: boolean
}

export const baseWidth = "210px"
export const baseHeight = "24px"
export const baseFontSize = "1rem"

const SelectViewByRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const DropdownContainer = styled.div`
  position: relative;
`
const DropdownHeader = styled.div`
  button {
    font-size: ${baseFontSize};
    height: ${baseHeight};
    color: #363636;
    background-color: white;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 12px;
    cursor: pointer;
    span {
      display: block;
      font-weight: 600;
    }
    &:focus {
      outline: none;
    }
  }
`

const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  margin-left: 4px;
  margin-right: -9px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ToggleIcon = styled.i<{ collapsed: boolean }>`
  margin-left: 0.25rem;
  transition: all 0.2s ease;
  ${props => props.collapsed && css`
    transform: rotate(-180deg);
  `}
`

const DropdownMenu = styled.div`
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 0 0.5rem 0;
  min-width: 12rem;
  position: absolute;
  right: 0.4rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0 0 1px rgba(10, 10, 10, 0.02);
  z-index: 11;
`

const DropdownMenuContent = styled.div`
  overflow: auto;
  width: 100%;
  max-height: 300px;
`

const DropdownOption = styled.div`
    font-size: .875rem;
    width: 220px;
    padding: 6px 16px;
    cursor: pointer;
    &:hover {
        background-color: #f5f5f5;;
    }
`


function SelectViewBy(props: SelectViewByProp) {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)


  const toggleMenu = () => {
    if (!showDropdownMenu) {
      document.addEventListener('click', closeMenu)
      setShowDropdownMenu(true)
    }
  }

  const closeMenu = () => {
    setShowDropdownMenu(false)
    document.removeEventListener('click', closeMenu)
  }

  return (
    <SelectViewByRoot>
      <span>View by:</span>
      <DropdownContainer>
        <DropdownHeader>
          <button onClick={toggleMenu}>
            <span>{props.viewByOption}</span>
            {
              !props.isReadOnly && (
                <IconWrapper>
                  <ToggleIcon className="fas fa-angle-down" collapsed={showDropdownMenu} />
                </IconWrapper>
              )
            }
          </button>
        </DropdownHeader>
        {
          showDropdownMenu && !props.isReadOnly && (
            <DropdownMenu>
              <DropdownMenuContent >
                {props.hasEngagement && (
                  <DropdownOption onClick={props.handleSelectViewBy} >{props.options[0]}</DropdownOption>
                )}
                {props.hasGrowth && (
                  <DropdownOption onClick={props.handleSelectViewBy} >{props.options[1]}</DropdownOption>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      </DropdownContainer>
    </SelectViewByRoot>
  )
}

export default SelectViewBy
