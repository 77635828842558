import { EditorState } from "../interface";
import ESConvert from "../qrybuilder/esconvert";
import { guided2view } from "../qrybuilder/converter";


const EditorReducer: React.Reducer<EditorState,any> = (state:EditorState,action:any):EditorState=>{
    if(!action)return state;
    switch(action.type){
        case "setEditorType":
            return {...state,type:action.payload};
        case "nextEditorView":
            return {...state,view: state.view + 1};
        case "setEditorView":
            return {...state,view: action.payload};
        case "setTaskType":
            return {...state,detail:{...state.detail,type: action.payload }}
        case "setTaskIndus":
            return {...state,detail:{...state.detail,indus_id: action.payload }}
        case "setTaskName":
            return {...state,detail:{...state.detail,name:action.payload}};
        case "setTaskKeywords":
            return {...state,detail:{...state.detail,keywords:action.payload}}
        case "setTaskLang":
            return {...state,detail:{...state.detail,lang:action.payload}};
        case "setTaskMediums":
            return {...state,detail:{...state.detail,mediums: action.payload}};
        case "setTaskUnixStart":
            return {...state,detail:{...state.detail,unix_start: action.payload}};
        case "setTaskUnixEnd":
            return {...state,detail:{...state.detail,unix_end: action.payload}};
        case "setTaskFrequency":
            return {...state,detail:{...state.detail,frequency:action.payload}};
        case "setEditorPreviewData":
            return {...state,preview:{...state.preview,data:action.payload}};
        case "setEditorPreviewFetching":
            return {...state,preview:{...state.preview,fetching:action.payload}};
        case "setEditorSubmitting":
            return {...state,submitting:action.payload}
        case "setTaskConfig":
            return {...state, detail:{
                type: action.payload.task_type,
                name: action.payload.task_name,
                indus_id: action.payload.indus_id,
                keywords: {
                    guided: action.payload.query_layer,
                    advanced: action.payload.query_string ?? (new ESConvert(action.payload.query_layer)).convert(),
                    guidedView: guided2view(action.payload.query_layer)
                },
                lang: action.payload.lang_abbr,
                mediums: action.payload.list_medium,
                unix_start: action.payload.adhoc_from,
                unix_end: action.payload.adhoc_to,
                frequency: action.payload.cron
            }}
        default:
            return state;
    }
}


export { EditorReducer };