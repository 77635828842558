import React from 'react'
import { TopicData } from '../../../../../interface'
import { usePostsStore } from '../../../../../postStore'
import ChartRow from './ChartRow'
import { StatisticsContainer } from './styled'
import SummaryCardsRow from './SummaryCardsRow'

const Statistics = ({topicData}:StatisticsProps) => {
  const frequency = usePostsStore(state => state.frequency)

  return (
    <StatisticsContainer>
      <SummaryCardsRow 
        topicData={topicData} 
        frequency={frequency}
      />
      <ChartRow 
        topicData={topicData} 
        frequency={frequency}
      />
    </StatisticsContainer>
  )
}

export default Statistics


interface StatisticsProps{
  topicData: TopicData
}