import { TrendPeriod } from "../interface"

export const getHighestChance = (period: TrendPeriod): string => {
	return period
		? Object.keys(period).reduce((a: string, b: string) =>
				period[a as keyof TrendPeriod] > period[b as keyof TrendPeriod] ? a : b
		  )
		: "-1"
}

export const mapTrendForecastText = (period: TrendPeriod) => {
	const trendForecastHighestChanceKey = getHighestChance(period)

	switch (trendForecastHighestChanceKey) {
		case "0":
			return `< 1 week \n (${Math.round(period[0] * 100)}% chance)`
		case "1":
			return `1 week \n (${Math.round(period[1] * 100)}% chance)`
		case "2":
			return `2 weeks \n (${Math.round(period[2] * 100)}% chance)`

		default:
			return "Not Available"
	}
}
