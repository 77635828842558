import React, { memo } from 'react'
import styled from 'styled-components'
import Pagination from "./Pagination"
import PostsPerPageSelect from "./PostsPerPageSelect"
import PageIndicator from "./PageIndicator"
import PageInput from "./PageInput"

interface PaginationBarProp {
  count: number
  rightMostItem?: React.ReactNode
  margin?: string
}

const PaginationRoot = styled.div<{ margin: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  height: 46px;
  padding: 5px 32px;
  background-color: #F8F8FA;
  box-shadow: 0px 2px 5px rgba(211, 210, 224, 0.6);
  border-top: 1px solid #F2F2F2;
  @media (max-width: 992px) {
    padding: 5px 1.5rem;
  }
  @media (max-width: 768) {
    padding: 5px 1rem;
  }
`

const PaginationBar = memo<PaginationBarProp>(({
  count,
  rightMostItem,
  margin = "0px"
}) => {

  return (
    <PaginationRoot margin={margin}>
      <PostsPerPageSelect />
      <PageIndicator count={count} />
      <Pagination
        count={count}
      />
      <PageInput
        count={count}
      />
      {rightMostItem}
    </PaginationRoot>
  )
})

export default PaginationBar
