import { Reactions, FeedData } from "./feed";
import { DateRangeFilter } from "./misc";

export interface StatCount {
  total: number;
  previous: number;
}

export interface Stats {
  authorCount: StatCount;
  engagementCount: StatCount;
  postCount: StatCount;
  threadCount: StatCount;
  viewCount: StatCount;
}

export interface PostCountChartPoint {
  point: string;
  postCount: number;
  threadCount: number;
  commentCount: number;
  topThreads: {
    site: string;
    threadLink: string;
    threadTitle: string;
  }[];
}

export interface Author {
  id: string;
  name: string;
  rank: number;
  postCount: number;
  site: string;
  channel: string;
}

export interface TopAuthors {
  byComments: Author[];
  byThreads: Author[];
  byTotal: Author[];
}

export enum DateRange {
  Today = 0,
  Week = 1,
  Month = 2,
}

export interface TopThread {
  title: string;
  url: string;
  site: string;
}

export interface TopEngagementThread extends TopThread {
  medium: string;
  engagementCount: number;
  rank: number;
}

export interface TopGrowthThread extends TopThread {
  roc: number;
  hash: string;
}

export interface Thread extends Reactions {
  site: string;
  eng_count: number;
  thread_link: string;
  thread_title: string;
}

export interface Site {
  site: string;
  rank: number;
  channel: string;
  postCount: number;
}

export interface TopSites {
  byComments: Site[];
  byThreads: Site[];
  byTotal: Site[];
}

export enum displayOvdata {
  AuthorData = 0,
  ThreadData = 1,
  TopicData = 2,
  ChartPointData = 3,
  SentimentPostData = 4,
}

export interface WordCloudItem {
  word: string;
  count: number;
}

export interface TopTrend {
  name: string;
  result: Trend[];
}

export interface Trend {
  doc_freq: number;
  medium: string;
  related_word: string[];
  date: string;
  ai_score: number;
  word: string;
}

export interface Hidden {
  keywords: string[];
  topics: string[];
  sites: string[];
}

export interface Sentiment {
  timestamp: number;
  positive: number;
  neutral: number;
  negative: number;
}

export interface OverviewState {
  task_id: number;
  filter: DateRangeFilter | null;
  daterange: DateRange;
  stats: Stats | null;
  postCountChart: PostCountChartPoint[] | null;
  topEngagementThreads: TopEngagementThread[] | null;
  topGrowthThreads: TopGrowthThread[] | null;
  topSites: TopSites | null;
  topAuthors: TopAuthors | null;
  wordCloud: WordCloudItem[] | null;
  toptrends: TopTrend[] | null;
  sentiments: Sentiment[] | null;
  sentimentPostData: FeedData[];
  sentimentType: null | 0 | 1 | -1;
  authorData: FeedData[];
  threaddata: FeedData[];
  topicdata: FeedData[];
  chartPointData: FeedData[];
  fetchingOvdata: boolean; //fetching authordata / threaddata / topicdata
  displayOvData: displayOvdata | null;
  hide: Hidden;
  compare: {
    task_id: number;
    filter: DateRangeFilter | null;
    daterange: DateRange;
    stats: Stats | null;
    postCountChart: PostCountChartPoint[] | null;
    topEngagementThreads: TopEngagementThread[] | null;
    topGrowthThreads: TopGrowthThread[] | null;
    topSites: TopSites | null;
    topAuthors: TopAuthors | null;
    wordCloud: WordCloudItem[] | null;
    toptrends: TopTrend[] | null;
    sentiments: Sentiment[] | null;
    sentimentPostData: FeedData[];
    sentimentType: null | 0 | 1 | -1;
    authorData: FeedData[];
    threaddata: FeedData[];
    topicdata: FeedData[];
    chartPointData: FeedData[];
    fetchingOvdata: boolean; //fetching authordata / threaddata / topicdata
    displayOvData: displayOvdata | null;
    hide: Hidden;
  };
}
