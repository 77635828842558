import exceljs from "exceljs"
import { SheetData } from "./interface"
import { FeedData } from "../../interfaces/feed"
import { ExportFields } from "./exportfields"
import { arr_rm } from "usefuljs"

class Sheet {

  constructor(private feeds: FeedData[]) { }

  private valueTypeHandler = (data: FeedData[], columns: string[]): SheetData[] => {
    try {

      return ([...data] as SheetData[]).map(feed => {

        if (columns.includes("post_link")) {
          feed.post_link = { text: feed.post_link as string, hyperlink: feed.post_link as string }
        }

        if (columns.includes("thread_link")) {
          feed.thread_link = { text: feed.thread_link as string, hyperlink: feed.thread_link as string }
        }

        return feed
      })

    } catch (err) {
      throw new Error(err)
    }
  }


  private columnStyleHandler = (worksheet: exceljs.Worksheet, columns: string[]): void => {
    try {
      worksheet.getRow(1).eachCell((cell: exceljs.Cell, _): void => {
        cell.font = {
          color: { argb: "ffffff" },
          bold: true
        }
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "29d1d7" },
          bgColor: { argb: "29d1d7" }
        }
      })

      if (columns.includes("thread_link")) {
        // cell row number starts from 1 not 0
        worksheet.getColumn("thread_link").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.font = {
            color: { argb: "3a89ff" },
            underline: true,
          }
        })
      }

      if (columns.includes("post_link")) {
        // cell row number starts from 1 not 0
        worksheet.getColumn("post_link").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.font = {
            color: { argb: "3a89ff" },
            underline: true,
          }
        })
      }

      if (columns.includes("thread_title")) {
        worksheet.getColumn("thread_title").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.font = {
            bold: true
          }
        })
      }

      if (columns.includes("comment_count")) {
        worksheet.getColumn("comment_count").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }
      if (columns.includes("share_count")) {
        worksheet.getColumn("share_count").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }
      if (columns.includes("view_count")) {
        worksheet.getColumn("view_count").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      if (columns.includes("reaction_count")) {
        worksheet.getColumn("reaction_count").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      if (columns.includes("reaction_like")) {
        worksheet.getColumn("reaction_like").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      if (columns.includes("reaction_dislike")) {
        worksheet.getColumn("reaction_dislike").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      if (columns.includes("reaction_angry")) {
        worksheet.getColumn("reaction_angry").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      if (columns.includes("reaction_haha")) {
        worksheet.getColumn("reaction_haha").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      if (columns.includes("reaction_love")) {
        worksheet.getColumn("reaction_love").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      if (columns.includes("reaction_sad")) {
        worksheet.getColumn("reaction_sad").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      if (columns.includes("reaction_wow")) {
        worksheet.getColumn("reaction_wow").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      if (columns.includes("ai_sentiment")) {
        worksheet.getColumn("ai_sentiment").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      if (columns.includes("impact")) {
        worksheet.getColumn("impact").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.alignment = { vertical: "middle", horizontal: "center" }
        })
      }

      // if (columns.includes("virality")) {
      //   worksheet.getColumn("virality").eachCell!((cell: exceljs.Cell, i: number): void => {
      //     if (i === 1) return
      //     cell.alignment = { vertical: "middle", horizontal: "center" }
      //   })
      // }

      if (columns.includes("post_date")) {
        worksheet.getColumn("post_date").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.numFmt = 'm/d/yyyy'
        })
      }

      if (columns.includes("post_time")) {
        worksheet.getColumn("post_time").eachCell!((cell: exceljs.Cell, i: number): void => {
          if (i === 1) return
          cell.numFmt = 'h:mm:ss\\ AM/PM'
        })
      }

    } catch (err) { throw err }
  }

  private columnNameTransform = (worksheet: exceljs.Worksheet): void => {
    try {
      worksheet.getRow(1).eachCell((cell: exceljs.Cell, _): void => {
        cell.value = ExportFields[cell.value as string]
      })
    } catch (err) { throw err }
  }

  private timezoneFixer = (date: Date, hours: number) => {
    try {
      date.setHours(date.getHours() + hours)
      return date
    } catch (err) { throw err }
  }

  private columnWidth = (column: string): number => {
    switch (column) {
      case "channel":
      case "medium":
      case "post_timestamp":
      case "post_date":
      case "post_time":
        return 15
      case "site":
        return 14
      case "thread_link":
        return 18

      case "post_message":
        return 75
      case "thread_title":
        return 55
      case "author_name":
        return 25
      case "hash":
        return 65
      default:
        return 15
    }
  }

  private recTransform = (feed: FeedData) => {

    return {
      ...feed,
      post_date: this.timezoneFixer((new Date(feed.post_timestamp)), 8),
      post_time: this.timezoneFixer((new Date(feed.post_timestamp)), 8)
    }

  }

  formatData = (feeds: FeedData[]): FeedData[] => {
    try {
      return feeds.map(feed => {

        const clone = { ...feed }

        for (const key of Object.keys(feed)) {
          switch (key) {
            case "comment_count":
            case "share_count":
            case "view_count":
            case "reaction_count":
            case "reaction_like":
            case "reaction_dislike":
            case "reaction_angry":
            case "reaction_haha":
            case "reaction_love":
            case "reaction_sad":
            case "reaction_wow":
            case "ai_sentiment":
            case "ai_impact":
              if (clone[key] === null) { clone[key] = 0 }
              break
            default:
              continue
          }
        }

        return clone
      })
    } catch (err) {
      throw new Error(err)
    }
  }

  public xlsx = async (columns: string[]): Promise<Buffer> => {
    try {
      const workbook = new exceljs.Workbook()

      workbook.creator = "Lenx.ai"

      const threads: FeedData[] = this.formatData(this.feeds).filter(feed => !feed.is_comment)
      const comments: FeedData[] = this.formatData(this.feeds).filter(feed => feed.is_comment)

      if (columns.includes("post_timestamp")) {

        columns.splice(columns.indexOf("post_timestamp"), 0, "post_date")
        columns.splice(columns.indexOf("post_timestamp"), 0, "post_time")

        arr_rm(columns, "post_timestamp", true)
      }

      const threadColumns = [...columns].filter(column => column !== "post_link")
      const commentColumns = [...columns].filter(column => column !== "thread_link")

      const threadDisplayColumns: Partial<exceljs.Column>[] = threadColumns.map(column => ({
        header: column,
        key: column,
        width: this.columnWidth(column),
      }))

      const commentDisplayColumns: Partial<exceljs.Column>[] = commentColumns.map(column => ({
        header: column,
        key: column,
        width: this.columnWidth(column),
      }))


      if (threads.length > 0) {
        const worksheetThreads = workbook.addWorksheet()
        worksheetThreads.name = "Threads"
        worksheetThreads.columns = threadDisplayColumns

        const transformedThreads = threads.map(thread => this.recTransform(thread))

        worksheetThreads.addRows(this.valueTypeHandler(transformedThreads, threadColumns))
        this.columnStyleHandler(worksheetThreads, threadColumns)
        this.columnNameTransform(worksheetThreads)
      }

      if (comments.length > 0) {
        const worksheetComments = workbook.addWorksheet()
        worksheetComments.name = "Comments"
        worksheetComments.columns = commentDisplayColumns

        const transformedComments = comments.map(comment => this.recTransform(comment))

        worksheetComments.addRows(this.valueTypeHandler(transformedComments, commentColumns))
        this.columnStyleHandler(worksheetComments, commentColumns)
        this.columnNameTransform(worksheetComments)
      }

      const buffer = await workbook.xlsx.writeBuffer()
      return buffer as Buffer

    } catch (err) {
      throw new Error(err)
    }
  }
}

export default Sheet