import React, { useContext } from 'react'
// import { useSelector } from "react-redux"
import styled from "styled-components"
// import { selectPostsExportedCount } from "../../../../../../app/store/feedSlice"
// import { selectExportTaskQuota } from "../../../../../../app/store/userSlice"
import Button from "../../../../../reusable/buttons/Button"
import CloseWindowContext from "../context/CloseWindowContext"
import { Container, Text } from "./styled"

interface DownloadProp {
  downloadURL: string
}

const Icon = styled.i`
  font-size: 60px;
  color: #8EECEF;
`

const SubTitle = styled.p`
  color: #283044;
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 1rem;
`

const Reminder = styled.p`
  font-size: 12px;
  text-align: center;
  color: #516380;
  margin-bottom: 1rem;
`

const DownloadButton = styled(Button)`
  color: #FFFFFF;
  background-color: #29D1D7;
  border: none;

  &:hover {
    background-color: #67a6a8;
  }

  &:focus {
    border: 1px solid #516380;
  }
`

function Download({ downloadURL }: DownloadProp) {

  // const postsExportedCount = useSelector(selectPostsExportedCount)
  // const exportTaskQuota = useSelector(selectExportTaskQuota)
  const closeWindow = useContext(CloseWindowContext)

  return (
    <Container>
      <Icon className="fas fa-check-circle" />
      <Text>Success!</Text>
      <SubTitle>The Excel file is ready to download</SubTitle>

      <Reminder></Reminder>
      {/* {
        exportTaskQuota !== null && postsExportedCount !== null && ( // if not unlimited?

          postsExportedCount > exportTaskQuota ? (
            <Reminder>You have reached your monthly data export limit.<br />{postsExportedCount} of {exportTaskQuota} records were exported.</Reminder>
          ) : (
            <Reminder>Remaining records for export: {exportTaskQuota - postsExportedCount}</Reminder>
          )
        )
      } */}

      <a href={downloadURL}>
        <DownloadButton
          onClick={() => { closeWindow() }}
        >Download</DownloadButton>
      </a>

    </Container>
  )
}

export default Download
