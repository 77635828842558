import { FeedTask } from "../../interfaces/feed"
import { AccessFromAPI } from "../../interfaces/ResponseFromAPIs"
import { mapStatCount } from "./overviewMapping"

export function mapAccess(accessFromAPI: AccessFromAPI) {
  return {
    task_id: accessFromAPI.task_id,
    account_name: accessFromAPI.account_name,
    task_name: accessFromAPI.task_name,
    readable: accessFromAPI.readable,
    writable: accessFromAPI.writable,
    task_type: accessFromAPI.task_type,
    status: accessFromAPI.status,
    last_edit_user: accessFromAPI.last_edit_user,
    last_edit_time: accessFromAPI.last_edit_time,
    total_threads: accessFromAPI.total_threads,
    stats: accessFromAPI.stat ? {
      authorCount: mapStatCount(accessFromAPI.stat.author_count),
      engagementCount: mapStatCount(accessFromAPI.stat.eng_count),
      postCount: mapStatCount(accessFromAPI.stat.post_count),
      threadCount: mapStatCount(accessFromAPI.stat.thread_count),
      viewCount: mapStatCount(accessFromAPI.stat.view_count),
    } : null,
    line: accessFromAPI.line ? {
      delta: accessFromAPI.line.delta,
      points: accessFromAPI.line.points
    } : null
  } as FeedTask
}

export function mapAccesses(accessesFromAPI: AccessFromAPI[]) {
  return accessesFromAPI.map(access => mapAccess(access))
}