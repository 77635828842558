import { useState } from "react"

function useDropdown(id: string, isStatic: boolean = true) {
	const [showDropdownMenu, setShowDropdownMenu] = useState(false)

	const toggleMenu = () => {
		if (!showDropdownMenu) {
			document.addEventListener("click", closeMenu)
			setShowDropdownMenu(true)
		}
	}

	const closeMenu = (e: any) => {
		if (isStatic) {
			if (!e.target.closest(`#${id}`)) {
				setShowDropdownMenu(false)
				document.removeEventListener("click", closeMenu)
			}
		} else {
			setShowDropdownMenu(false)
			document.removeEventListener("click", closeMenu)
		}
	}

	return { showDropdownMenu, setShowDropdownMenu, toggleMenu, closeMenu }
}

export default useDropdown
