import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectFeedDateRange } from "../../../../../../../../app/store/feedSlice"
import { fetchSentiments, selectOverviewFilter } from "../../../../../../../../app/store/overviewSlice"
import { calculateDefaultInterval, calculateIntervalStep, mapIntervalToDateRangeCode } from "../../../../helper"
import { Interval } from "../../../../interface"

function useInterval() {
  const dispatch = useDispatch()

  const overviewDateRangeFilter = useSelector(selectOverviewFilter)
  const feedDateRange = useSelector(selectFeedDateRange)

  const effectiveDateRange = overviewDateRangeFilter ?? feedDateRange
  const intervalStep = effectiveDateRange ? calculateIntervalStep(effectiveDateRange) : null
  const [interval, setInterval] = useState<Interval | null>(intervalStep ? calculateDefaultInterval(intervalStep) : null)

  useEffect(() => {
    if (intervalStep) {
      setInterval(calculateDefaultInterval(intervalStep))
    }
  }, [intervalStep])

  const chooseInterval = (interval: Interval) => {
    setInterval(interval)
    if (effectiveDateRange) {
      dispatch(fetchSentiments({ ...effectiveDateRange, range: mapIntervalToDateRangeCode(interval) }))
    }
  }

  return {
    interval,
    intervalStep,
    chooseInterval
  }
}

export default useInterval