import styled from 'styled-components'

export const HomeContainer = styled.div`
  width: 100%;
  min-height: 90vh;
`

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`

export const HeaderGradientContainer = styled.div<{url:string}>`
  width: 100%;
  height: 230px;
  background-image: linear-gradient(105.96deg, rgba(41, 209, 215, 0.40) 7.62%, rgba(58, 137, 255, 0.40) 127.23%), url(${props => props.url});
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 0 45px;
  background-size: 100% 230px;
  opacity: 0.30;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  position: relative;

`

export const HeadersContainer = styled.div`
  text-align: center;
  position: relative;
  `

export const MainHeader = styled.p`
  position: absolute;
  width:100%;
  top: -180px;
  right: 0;
  color: #516380;
  font-size: 3rem;
  letter-spacing: 0.6rem;
  `

export const RegionHeader = styled.p`
  position: absolute;
  width: 100%;
  top: -110px;
  right: 0;
  color: #92A5C3;
  font-size: 1.7rem;
  letter-spacing: 0.2rem;
`

export const SelectContainer = styled.div`
  width: 100%;
  padding: 1rem;
`

export const CardsContainer = styled.div`
  width: 100%;
`

