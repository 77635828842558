import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import styled from 'styled-components'
import _capitalize from "lodash.capitalize"
import { resetModel, selectSmartTrainMode, fetchModelsCounts } from "../../../../../../app/store/smartTrainSlice"
import Button from "../../../../../reusable/buttons/Button"
import ResetIcon from "../../../../../reusable/icons/ResetIcon"

interface ResetModelProp {
  setIsResetting: React.Dispatch<React.SetStateAction<boolean>>
}

const ResetModelRoot = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled.div`
  margin-bottom: 0.5rem;
`

const Title = styled.h1`
  color: #ED5252;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
`

const Description = styled.p`
  color: #516380;
  font-size: 18px;
  margin-bottom: 1rem;
`

const TextContainer = styled.div`
  width: 400px;
  margin-bottom: 2rem;
`

const Text = styled.p`
  color: #516380;
  font-size: 16px;
`

const ButtonContainer = styled.div`
   display: flex;
   justify-content: space-evenly;
   width: 400px;
`

function ResetModel(props: ResetModelProp) {
  const dispatch = useDispatch()

  const mode = useSelector(selectSmartTrainMode)

  const reset = () => {
    dispatch(fetchModelsCounts())
    dispatch(resetModel())
    props.setIsResetting(false)
  }

  const cancel = () => {
    props.setIsResetting(false)
  }

  return (
    <ResetModelRoot>
      <IconWrapper>
        <ResetIcon height="114px" width="114px" />
      </IconWrapper>
      <Title>Reset {_capitalize(mode)} Model</Title>
      <Description>Resetting detection model will erase all previously labeled data.<br />We recommend you to do so when:</Description>
      <TextContainer>
        <Text>1) Your definition of {mode} has changed, or</Text>
        <Text>2) Your labeled data is heavily polluted, or</Text>
        <Text>3) You have recently made a change in keywords query</Text>
      </TextContainer>

      <ButtonContainer>
        <Button variant="tertiary" onClick={cancel}>Cancel</Button>
        <Button variant="danger" onClick={reset}>Reset</Button>
      </ButtonContainer>
    </ResetModelRoot>
  )
}

export default ResetModel
