import React from 'react'
import MenuSelectSingle, { Selections } from '../../components/MenuSelectSingle'
// import MenuSelectMultiple from '../../components/MenuSelectMultiple'
import styled from 'styled-components'
import { usePostsStore } from '../../postStore'
import { Frequency, TrendAccess } from '../../interface'
import { useHistory } from 'react-router'

const SelectionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`
const ItemsContainer = styled.div`
  display:flex;
  flex-wrap: nowrap;
`

const SelectRow = ({industries, frequencies, accessRights}: SelectRowProps) => {
  const {
    frequency,
    setFrequency, 
    frequencyLabel, 
    setFrequencyLabel,
    labelId,
    setLabelId,
    labelText,
    setLabelText,
  } = usePostsStore(state => ({
    frequency: state.frequency,
    setFrequency: state.setFrequency,
    frequencyLabel: state.frequencyLabel,
    setFrequencyLabel: state.setFrequencyLabel,
    labelId: state.labelId,
    setLabelId: state.setLabelId,
    labelText: state.labelText,
    setLabelText: state.setLabelText,
  }))

  const history = useHistory()
  
  const setLabelIdAndQS = (labelId: number) => {
    if(!accessRights[labelId.toString()].freq.includes(frequency)){
      const newFrequency = accessRights[labelId.toString()].freq[0]
      setFrequency(newFrequency)
      setLabelId(labelId)
      history.push(`?f=${newFrequency}&label=${labelId}`)
    } else {
      setLabelId(labelId)
      history.push(`?f=${frequency}&label=${labelId}`)
    }
  }

  const setFrequencyAndQS = (frequency: Frequency) => {
    setFrequency(frequency)
    history.push(`?f=${frequency}&label=${labelId}`)
  }

  
  return (
    <SelectionsContainer>
      <ItemsContainer>
        <MenuSelectSingle 
          selections={industries} 
          selected={labelId!}
          selectLabel={labelText}
          selectLabelAction={setLabelText}
          selectValueAction={setLabelIdAndQS}
          id="trend-home-timeframe-filter" 
         />
        <MenuSelectSingle 
          selections={frequencies} 
          selected={frequency}
          selectLabel={frequencyLabel}
          selectLabelAction={setFrequencyLabel}
          selectValueAction={setFrequencyAndQS}
          id="trend-home-timeframe-filter" 
         />
        {/* <MenuSelectMultiple 
          selections={categorySelections} 
          buttonText="Select Category" 
          id="category-filter"
        /> */}
      </ItemsContainer>
      {/* <ItemsContainer>
        <MenuSelectSingle selections={maturitySelections} buttonText="Maturity" id="maturity-filter" />
      </ItemsContainer> */}
    </SelectionsContainer>
  )
}

export default React.memo(SelectRow)

interface SelectRowProps{
  industries: Selections<number>[]
  frequencies: Selections<Frequency>[]
  accessRights: TrendAccess
}

