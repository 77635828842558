import React from 'react'
import styled, { css } from 'styled-components'
import BaseButton from "../../../../../reusable/buttons/BaseButton"
import { IrrelevantPostsManagerTab } from "./interface"

export interface TabSelectorProp {
  tab: IrrelevantPostsManagerTab
  setTab: React.Dispatch<React.SetStateAction<IrrelevantPostsManagerTab>>
}

const Root = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 1.5rem;
  margin-left: 2.5rem;
`

const SelectTabButton = styled(BaseButton) <{ isActive: boolean, isDisabled?: boolean }>`
  color: #868686;
  padding: 0.5rem;
  ${props => props.isActive && css`
    border-bottom: 3px solid #29d1d7;
  `}

  p {
     font-size: 16px;
     ${props => props.isActive && css`
        color: #283044;
     `}
  }

  ${props => props.isDisabled && css`
    cursor: default;
    color: #a1a1a1;
  `}
`

function TabSelector({ tab, setTab }: TabSelectorProp) {

  const handleClick = (tab: IrrelevantPostsManagerTab) => {
    setTab(tab)
  }

  return (
    <Root>
      <SelectTabButton isActive={tab === "all"} onClick={() => handleClick("all")}>
        <p>All</p>
      </SelectTabButton>
      <SelectTabButton isActive={tab === "user"} onClick={() => handleClick("user")}>
        <p>Removed Manually</p>
      </SelectTabButton>
      <SelectTabButton isActive={tab === "ai"} onClick={() => handleClick("ai")}>
        <p>Removed automatically</p>
      </SelectTabButton>
    </Root>
  )
}

export default React.memo(TabSelector)
