import React from 'react'
import styled from 'styled-components'
import { uuidv4 } from "usefuljs"
import Skeleton from "../../../../../reusable/utils/Skeleton"

const FlexContainer = styled.div`
  display: flex;
`

const TrendItemRoot = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0.5rem 0.75rem 1.5rem;
  &:hover {
      background-color: #f8f8fa;
  }
`

function TrendItemSkeleton() {
  return (
    <TrendItemRoot>
      <FlexContainer>
        <Skeleton height="1rem" width="1rem" margin="0px 1rem 0px 0px" />
        <Skeleton height="1rem" width="5rem" />
      </FlexContainer>
      <Skeleton height="1rem" width="1.5rem" />
    </TrendItemRoot>
  )
}

function TrendSkeleton() {
  return (
    <div>
      {Array.from(Array(6)).map(_ => <TrendItemSkeleton key={uuidv4()} />)}
    </div>
  )
}

export default TrendSkeleton
