import React, { useState } from "react"
import ExportOptions from "./ExportOptions"
import styled from 'styled-components'
import { selectExportFeed, clearExportFeeds, selectIsSelecting, setIsSelecting } from "../../../../../app/store/livefeedpost"
import { useSelector, useDispatch } from "react-redux"
// import SelectIcon from "../../../../reusable/icons/SelectIcon"
import {
    selectAccountId, selectExportTaskQuota,
    // selectExportTaskQuota 
} from "../../../../../app/store/userSlice"
import UpgradePlanNotice from "../../../../reusable/notice/UpgradePlanNotice"
import CloseWindowContext from "./context/CloseWindowContext"
import SelectIcon from "../../../../reusable/icons/SelectIcon"
import ExportIcon from "../../../../reusable/icons/ExportIcon"
import IconButton from "../../../../reusable/buttons/IconButton"
import SelectedIcon from "../../../../reusable/icons/SelectedIcon"
// import { selectPostsExportedCount } from "../../../../../app/store/feedSlice"

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    column-gap: 1rem;
    width: 100%;
    max-width: max-content;
`

const StyledIconButton = styled(IconButton)`
    padding: 0.6rem;
    position: relative;

  &:hover {
    background-color: white;
  }
`

const SelectedPostCountLabel = styled.span`
    padding: 2px;
    position: absolute;
    display: block;
    height: 1.2rem;
    min-width: 1.2rem;
    /* width: 1.2rem; */
    font-size: 0.8rem;
    border-radius: 0.6rem;
    color: white;
    background-color: #29d1d7;
    left: 1.3rem;
    top: 1.5rem;
`


const ExportBtn = () => {

    const dispatch = useDispatch()
    const exportFeeds = useSelector(selectExportFeed)
    const isSelecting = useSelector(selectIsSelecting)
    // const totalDbRecordsCount = useSelector(selectTotal)
    const exportTaskQuota = useSelector(selectExportTaskQuota)
    // const postsExportedCount = useSelector(selectPostsExportedCount)

    const [showExportOptions, setShowExportOptions] = useState(false)
    const [showUpgradeNotice, setShowUpgradeNotice] = useState(false)
    const [showExceedLimitWarning, setShowExceedLimitWarning] = useState(false)

    const cancelExportFeeds = () => {
        dispatch(clearExportFeeds())
        dispatch(setIsSelecting(false))
    }

    const enterSelectingMode = () => {
        dispatch(setIsSelecting(true))
    }

    const handleClickExport = () => {

        // if (postsExportedCount !== null && exportTaskQuota !== null && postsExportedCount >= exportTaskQuota) {
        //     setShowExceedLimitWarning(true)
        //     return
        // }

        // if account is normal
        if (exportTaskQuota !== 0) {
            setShowExportOptions(true)
        }

        // else if account is trial
        // setShowUpgradeNotice(true)

    }

    const accountId = useSelector(selectAccountId)

    if (accountId === 22 || accountId === 10 || accountId === 11 || accountId === 12 || accountId === 13) {
        return null
    }

    return (
        <ButtonWrapper>
            <div>
            {/* <Launcher
                messageList={[]}
                onFilesSelected={() => {}}
                onMessageWasSent={() => {}}
                agentProfile={{}}
            /> */}
            </div>
            {isSelecting ? (
                <div data-tip="Cancel">
                    <StyledIconButton onClick={cancelExportFeeds}>
                        <SelectedIcon fill="#516380" height="1.4rem" width="1.4rem" />
                    </StyledIconButton>
                </div>
            ) : (
                <div data-tip="Select post">
                    <StyledIconButton onClick={enterSelectingMode}>
                        <SelectIcon fill="#516380" height="1.4rem" width="1.4rem" />
                    </StyledIconButton>
                </div>

            )}

            <div data-tip={exportTaskQuota !== 0 ? "Export" : "Upgrade your plan to export data"}>
                <StyledIconButton onClick={handleClickExport}>
                    <ExportIcon fill={exportTaskQuota !== 0 ? "#516380" : "#DADADA"} height="1.4rem" width="1.4rem" />
                    {isSelecting && exportFeeds.length > 0 && (
                        <SelectedPostCountLabel>{exportFeeds.length}</SelectedPostCountLabel>
                    )}
                </StyledIconButton>
            </div>

            {showExportOptions && (
                <CloseWindowContext.Provider value={() => { setShowExportOptions(false) }}>
                    <ExportOptions
                        exportType={exportFeeds.length > 0 ? "byFeed" : "byOptions"}
                    />
                </CloseWindowContext.Provider>
            )}

            {showUpgradeNotice && (
                <UpgradePlanNotice
                    onClose={() => setShowUpgradeNotice(false)}
                    noticeType={"trial"}
                />
            )}

            {showExceedLimitWarning && (
                <UpgradePlanNotice
                    onClose={() => setShowExceedLimitWarning(false)}
                    noticeType={"exportLimit"}
                />
            )}
        </ButtonWrapper>
    )
}

export default ExportBtn

