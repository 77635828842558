import React from 'react'
import { NumberOfPostsContainer, NumberOfPostsNumberText, SmallerText } from '../../styled'

const SocialOutreach = ({totalEngagement, totalPosts}: SocialOutreachProps) => {

  const totalSocialOutreach = totalEngagement + totalPosts
  const estimatedNumber = totalSocialOutreach < 1000 ? totalSocialOutreach : `${Math.floor(totalSocialOutreach / 1000)}K+`

  return (
    <NumberOfPostsContainer>
      <NumberOfPostsNumberText>{estimatedNumber}</NumberOfPostsNumberText>
      <SmallerText>Outreach</SmallerText>
    </NumberOfPostsContainer>
  )
}

export default SocialOutreach

interface SocialOutreachProps{
  totalEngagement: number
  totalPosts: number
}