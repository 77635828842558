import React from 'react'
import styled from 'styled-components'
import Progress from "../../reusable/utils/ProgressBar"

interface UsageMeterProp {
  type: string
  quota: number | null
  used: number
}

const UsageMeterRoot = styled.div`
  width: 100%;
  height: 90px;
  border-radius: 4px;
  border: 1px solid #BDBDBD;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
`

const DetailsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    p {
      margin-right: 1rem;
      color: #868686;
    }
  }
`

const Remaining = styled.div`
  display: flex;
  align-items: center;
  color: #C4C4C4;
  font-size: 14px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.2rem;
    width: 1.2rem;
    color: white;
    background-color: #29D1D7;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 500;
    margin-right: 0.3rem;
  }
`

function UsageMeter({ type, used, quota }: UsageMeterProp) {
  if (quota !== null) {
    const target = Math.round(used / quota * 100) / 100

    return (
      <UsageMeterRoot>
        <DetailsRow>
          <div>
            <p>{type}</p>
            <Remaining>
              <div>{quota - used}</div>
              <p>remaining</p>
            </Remaining>
          </div>
          <div>
            <p>{used} / {quota}</p>
          </div>
        </DetailsRow>
        <Progress value={target} height={21} hideLabel={quota === 0} />
      </UsageMeterRoot>
    )
  } else {
    return (
      <UsageMeterRoot>
        <DetailsRow>
          <div>
            <p>{type}</p>
          </div>
          <div>
            <p>{used} / Unlimited</p>
          </div>
        </DetailsRow>
        <Progress value={0} height={21} disabled={true} />
      </UsageMeterRoot>
    )
  }

}

export default UsageMeter
