import React from 'react'
import { IconProp } from "./interface"

function SelectedIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="24" height="24" rx="1" fill={fill} stroke={fill} stroke-width="0.5" />
      <line x1="5.5" y1="12.5" x2="20.5" y2="12.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </svg >
  )
}

export default SelectedIcon



