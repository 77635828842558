import styled from "styled-components"

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  
  h3 {
    font-weight: 600;
  }
`
