import styled from 'styled-components'
import BaseButton from "../../../../../reusable/buttons/BaseButton"

export const StyledDatePickerWrapper = styled.div`
  margin-left: -1px;

  & .DateRangePicker,
  .DateRangePickerInput {
    border-radius: 0px 4px 4px 0px;
    .DateInput {
      width: 120px;
      height: 33px;
      box-sizing: border-box;
      .DateInput_input {
        color: white;
        font-size: 0.9rem;
        font-weight: 400;
        height: 100%;
        text-align: center;
        background-color: #29d1d7;
        cursor: pointer;
      }
      .DateInput_input__focused {
        border-bottom: 1px solid #00c4ce;
      }
    }

    #start_date_id,
    #end_date_id {
      &::placeholder {
          font-size: 0.9rem;
          font-weight: 400;
          color: #FFFFFF;
      }
    }
  }

  .DateRangePickerInput {
    border: 1px solid #dbdbdb;
    background-color: #29d1d7;
    min-width: 250px;
    &:hover {
      border: 1px solid #b5b5b5;
    }
  }

  .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 {
    color: white;
  }
`

export const CustomButton = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  height: 35px;
  width: 120px;
  border: 1px #dbdbdb solid;
  color: #516380;
  background-color: white;
  margin-left: -1px;
  border-radius: 0px 4px 4px 0px;
  &:hover {
    border: 1px #b5b5b5 solid;
    cursor: pointer;
  }
  i {
    display: block;
    margin-right: 10px;
  }
`

export const CalendarInfoLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #898989;
  padding: 0px 0.5rem 0.5rem 1.5rem;
  margin-top: -1rem;

  i {
    color: #BDBDBD;
    margin-right: 0.5rem;
  }
`