
import moment from "moment"
import { LineGraphViewBY } from "."
import { DateRange, PostCountChartPoint } from "../../../../../../app/interfaces/overview"
import { Interval } from "../../interface"

export function generateLiveTimeLabel(time: string, dateRange: DateRange) {
  switch (dateRange) {
    case 0:
      return moment(time).format("HH:mm")
    case 2:
      return moment(time).format("D/M")
    default:
    case 1:
      return moment(time).format("MMM D")
  }
}

export function formatLiveChartPoints(points: PostCountChartPoint[] | null, dateRange: DateRange) {
  return points ? points.map(point => ({ ...point, label: generateLiveTimeLabel(point.point, dateRange) })) : []
}

export function generateAdHocTimeLabel(time: string, interval: Interval) {
  switch (interval) {
    case "hour":
      return moment(time).format("HH:mm")
    case "day":
      return moment(time).format("D/M")
    case "month":
      return moment(time).format("MMM YYYY")
    case "year":
      return moment(time).format("YYYY")
  }
}

export function formatAdHocChartPoints(points: PostCountChartPoint[] | null, interval: Interval | null) {
  if (!interval) return []

  return points ? points.map(point => ({ ...point, label: generateAdHocTimeLabel(point.point, interval) })) : []
}

export function getDataKey(viewBy: LineGraphViewBY) {
  switch (viewBy) {
    case "Threads":
      return "threadCount"
    case "Comments":
      return "commentCount"
    case "Threads and comments":
    default:
      return "postCount"
  }
}
