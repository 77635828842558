import styled from 'styled-components'

export const OverviewRoot = styled.div`
  background-color: white;
`

export const Header = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid #f8f8fa;
  h3 {
    font-weight: 600;
  }
`

export const Body = styled.div`
  height: 17.5rem;
`

export const SentimentIndicatorContainer = styled.div`
  padding: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

export const SentimentIndicatorRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  row-gap: 0.4rem;
  width: 100px;

  p {
    color: #898989;
  }
`

export const PercentageLabel = styled.p`
  color: #516380;
  font-weight: 600;
  font-size: 14px;
  padding: 1rem;
`