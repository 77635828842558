import React from 'react'
import styled from 'styled-components'
import { Frequency } from '../../interface'
import { usePostsStore } from '../../postStore'
import TimeframeSelection from './TimeframeSelection'

function CurrentResultTimeframeSelectRow({selections, frequency, selectLabelAction, selectValueAction, fromFrequency}: CurrentResultTimeframeSelectRowProps){
  const { clickedPostFrequencies } = usePostsStore(state => ({clickedPostFrequencies: state.clickedPostFrequencies}))
  
  const handleSelectTimeframe = (e:React.MouseEvent<HTMLButtonElement>, title: string, value: Frequency) => {
    selectLabelAction(title)
    selectValueAction(value)
  }

  const fromFrequencies = fromFrequency === 'Monthly' ? (['Daily', 'Weekly', 'BiWeekly','Monthly', 'ThreeMonths']):( fromFrequency === 'Latest' ? (['Latest']):(['Daily', 'Weekly', 'BiWeekly', 'Monthly']) )


  return (
    <Container>
      {
        selections.map((selection)=>(
          <TimeframeSelection 
            key={selection.title} 
            selection={selection}
            isActive={selection.value === frequency} 
            isDisabled={clickedPostFrequencies ? !(clickedPostFrequencies?.indexOf(selection.value)>-1) : !(fromFrequencies?.indexOf(selection.value)>-1)}
            onClick={handleSelectTimeframe}
          />
        ))
      }
    </Container>
  )
}

export default CurrentResultTimeframeSelectRow

const Container = styled.div`
  button:first-child{
    border-left: 1px solid #f2f2f2;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  button:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  margin:8px 0;
`


interface CurrentResultTimeframeSelectRowProps {
  selections: Selection[]
  frequency: Frequency
  selectLabelAction: (label: string) => void
  selectValueAction: (value: Frequency) => void
  fromFrequency: string
}

interface Selection {
  title: string
  disabled?: boolean
  value: Frequency
}