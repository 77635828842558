import React from "react"
import { LiveFeedFilter, FeedData, FeedTask, ThreadView } from "../../../app/interfaces/feed"
import { FeedState } from "../interface"


const initFilter: LiveFeedFilter = {
    page: 1,
    postsPerPage: 20,
    unix_start: 0,
    unix_end: -1,
    sites: [],
    task_id: 0,
    limit: 0,
    search: "",
    mediums: [],
    is_comment: 0,
    sortBy: "unix_timestamp",
    disableSmartFilter: true,
    sentiments: ["unassigned", "negative", "neutral", "positive"]
}
const initSites: string[] = []
const initTaskLive: FeedData[] = []
const initAccess: FeedTask[] = []
const initSearch: string = ""
const initMediums: string[] = []
const init_is_comment = 0
export const init_threadview: ThreadView = {
    thread_title: "",
    unix_timestamp: 0,
    thread_link: "",
    message: null,
    comments: [],
    fetching: false
}



export const initState: FeedState = {
    filter: initFilter,
    sites: initSites,
    tasklive: initTaskLive,
    tasklive_max: 0,
    fetching_tasklive: false,
    access: initAccess,
    mediums: initMediums,
    is_comment: init_is_comment,
    search: initSearch,
    threadviewer: init_threadview
}


export type Thunk = (dispatch: React.Dispatch<any>, state: FeedState) => void
export const dispatcher = (dispatch: React.Dispatch<any>, state: FeedState) =>
    (input: Thunk | any) =>
        input instanceof Function ? input(dispatch, state) : dispatch(input)


export interface context {
    feed: FeedState, // state
    setFeed: React.Dispatch<Thunk | any> //dispatch
}

const Ctx = React.createContext({})
export default Ctx