import styled from 'styled-components'

export const SettingMenu = styled.div`
  position: absolute;
  right: 0px;
  z-index: 999;
  background-color: white;
  padding: 0.5rem 0;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
  border-radius: 4px;
`

const TrendItemRoot = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  &:not(:last-child) {
      border-bottom: 1px solid #f8f8fa;
  }
  &:hover {
      background-color: #f8f8fa;
  }

  &:last-child {
    ${SettingMenu} {
      bottom: 20px;
    }
  }
`

export default TrendItemRoot