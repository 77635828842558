import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
  selectTopTrends, selectHiddenTopics
} from "../../../../../../app/store/overviewSlice"
import styled from "styled-components"
import uf from "usefuljs"
import TrendItem from "./TrendItem"
import TopicManager from "./TopicManager/TopicManager"
import EmptyStatePlaceholder from "../../components/EmptyStatePlaceholder"
import HeaderDropdown from "./HeaderDropdown"
import TrendsBody from "./styled/TrendsBody"
import TrendsRoot from "./styled/TrendsRoot"
import HeaderRootContainer from "./styled/HeaderRootContainer"
import HeaderLeftContainer from "./styled/HeaderLeftContainer"
import TrendSkeleton from "./Skeleton"

const HeaderSparkleIcon = styled.img`
  height: 16px;
  margin-right: 0.3rem;
`

const HeaderTitle = styled.h3`
  font-weight: 600;
  margin-right: 0.3rem;
`

const HeaderInfo = styled.i`
  color: #dbdbdb;
  margin-right: 0.3rem;
`

function Trend() {

  const [showDropdown, setShowDropdown] = useState(false)
  const [showManager, setShowManager] = useState(false)

  const trendData = useSelector(selectTopTrends)
  const hiddenTopics = useSelector(selectHiddenTopics)

  const formattedTopics = trendData ? (trendData.length ? trendData[0].result.slice().map(topic => {
    return {
      topicString: [topic.word, ...(topic.related_word ?? [])].reduce(
        (string, word) => `${string}${word ? ` · ${word}` : ""}`
      ),
      score: topic.ai_score,
      commentScore: topic.doc_freq
    }
  }) : []) : null

  const sortedTopics = formattedTopics ? formattedTopics.sort(uf.obj_sort("commentScore", false, true)) : null
  const filteredTopics = sortedTopics ? sortedTopics.filter(topic => !hiddenTopics?.includes(topic.topicString)) : null

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!showDropdown) {
      document.addEventListener('click', closeMenu)
    }
    setShowDropdown(true)
    event.stopPropagation()
  }

  const closeMenu = () => {
    setShowDropdown(false)
    document.removeEventListener('click', closeMenu)
  }

  const openKeywordManager = () => {
    console.log("open manager")
    setShowManager(true)
    setShowDropdown(false)
  }

  return (
    <TrendsRoot>
      <HeaderRootContainer>
        <HeaderLeftContainer>
          <HeaderSparkleIcon src="https://lenx-public-assets.s3.amazonaws.com/SparkleIcon.webp" alt="sparkle icon" />
          <HeaderTitle>Trending Topic</HeaderTitle>
          <HeaderInfo
            data-tip="<p>AI has detected these topics and<br>related words that contribute<br>heavily on comments activity.</p>"
            data-place="top"
            className="fas fa-question-circle"
          />
          {filteredTopics && filteredTopics.length !== 0 && (
            <HeaderDropdown
              handleClick={handleClick}
              openKeywordManager={openKeywordManager}
              showDropdown={showDropdown}
            />
          )}
        </HeaderLeftContainer>
      </HeaderRootContainer>

      <TrendsBody>
        {filteredTopics ? (filteredTopics.length ? filteredTopics.map((topic, index) => (
          <TrendItem
            topic={topic.topicString}
            score={topic.commentScore}
            rank={index + 1}
            key={topic.topicString}
          />
        )) : <EmptyStatePlaceholder />) : <TrendSkeleton />}
      </TrendsBody>

      {
        showManager && <TopicManager topics={sortedTopics ?? []} hidden={hiddenTopics} cancel={() => { setShowManager(false) }} />
      }
    </TrendsRoot>
  )
}

export default Trend
