import * as React from "react"
import { IconProp } from "./interface"

function ToTop({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg viewBox="0 0 141.73 141.73" width={width} height={height}>
      <defs>
        <style>{`.ToTop_svg__cls-2{fill:${fill}}`}</style>
      </defs>
      <g id="ToTop_svg__old_Version">
        <text
          transform="translate(15.55 -72)"
          fontSize={60}
          fill="#fff"
          fontFamily="AdobeMingStd-Light-B5pc-H,Adobe Ming Std"
        >
          {"Updated"}
        </text>
        <path
          className="ToTop_svg__cls-2"
          d="M14.83 1h111.25c8.19 0 14.83 6.08 14.83 13.59s-6.64 13.59-14.83 13.59H14.83C6.64 28.18 0 22.1 0 14.59S6.64 1 14.83 1zM77.87 62.1H63c-2 0-3.71.61-3.71 1.36v75.91c0 .76 1.66 1.36 3.71 1.36h14.87c2 0 3.71-.6 3.71-1.36V63.44c0-.73-1.66-1.34-3.71-1.34z"
        />
        <path
          className="ToTop_svg__cls-2"
          d="M77.7 50.59L120.31 91a6.9 6.9 0 010 10.1l-7.21 6.53a7.32 7.32 0 01-2.4 1.54 8.16 8.16 0 01-5.84 0 7.26 7.26 0 01-2.41-1.54L72.38 79.11l-30.09 28.48a7.22 7.22 0 01-2.4 1.54 8.16 8.16 0 01-5.84 0 7.26 7.26 0 01-2.41-1.54l-7.2-6.53a6.92 6.92 0 010-10.1l42.61-40.37a7.07 7.07 0 012.4-1.54 8.16 8.16 0 015.84 0 7.11 7.11 0 012.41 1.54z"
        />
      </g>
    </svg>
  )
}

export default ToTop