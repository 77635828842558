import React from "react";
import { FeedTask } from "../../../../app/interfaces/feed";
import styles from "./tasklist.module.scss";
import TaskCard from "./taskcard"

interface TaskListProp {
    tasks: FeedTask[]
}

const TaskList = (props:TaskListProp)=>{
    
    const [searchText,setSearchText] = React.useState<string>("");

    const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
    }

    return (
        <div id={styles.tasklist}>
            <div className={styles.left}>
                <h3>All Feeds</h3>
            </div>
            <div className={styles.right}>
                <div className="field">
                    <div className="control has-icons-right">
                        <input className="input" type="text" value={searchText} onChange={handleSearchInput} />
                        <span className="icon is-right">
                            <i className="fas fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.taskListFlexContainer}>
                <div className={styles.taskListTitleContainer}>
                    <div className={styles.taskListTitleItem}>Name</div>
                    <div></div>
                    <div className={styles.taskListTitleItem}>Last Edited</div>
                    <div className={styles.taskListTitleItem}>Edited By</div>
                    <div className={styles.taskListTitleItem}></div>
                </div>
                {
                    searchText === "" ? props.tasks.map(task => <TaskCard key={task.task_id} task={task} /> )
                    : props.tasks.filter(task=>task.task_name.toUpperCase().includes(searchText.toUpperCase())).map(task => <TaskCard key={task.task_id} task={task} /> )
                }
            </div>
        </div>
    )
}

export default TaskList;