import { useState } from "react"
import { useMutation } from "react-query"
import { Frequency } from "../../../interface"
import { ajax } from "usefuljs"
import { store } from "../../../../../app/store"

interface IFeedbackData {
	topic_id: string
	label_id: number
	freq: Frequency
	words: string
	feedback: string[]
}

const UseSaveHook = () => {
	const [isSubmitted, setIsSubmitted] = useState(false)

	const postFeedback = async (feedbackData: IFeedbackData) => {
		const token = store.getState().user.token

		return await ajax({
			method: "post",
			url: `${process.env.REACT_APP_ENDPOINT}/api/trend/topic/feedback`,
			headers: { token },
			data: feedbackData,
		})
	}

	const feedbackMutation = useMutation(postFeedback)

	return {
		isSubmitted,
		setIsSubmitted,
		feedbackMutation,
	}
}

export default UseSaveHook
