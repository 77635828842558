import React from 'react'
import styled from 'styled-components'
import { prettynum } from '../../../../app/tools/string'
import SummaryCard from "../../../reusable/cards/summaryCard/SummaryCard"
import CardIcon from "../../../reusable/cards/summaryCard/SummaryCardIcon"
import { generateTrendTooltip } from '../../helperFn/tooltipFn'
import { Frequency } from '../../interface'
import { usePostsStore } from '../../postStore'

const CardContainer = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 1rem;
  @media (max-width: 1024px){
    flex-wrap: wrap;
    justify-content: space-between;
    >div{
      width: 48%;
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 600px){
    >div{
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`

function StatCards() {

  const { overviewPost, frequency } = usePostsStore(state => ({
    overviewPost: state.overviewPost,
    frequency: state.frequency
  }))

  const comparedTotalPosts = (overviewPost && (Math.floor((Math.abs(overviewPost?.aggregations?.top_stats?.total - overviewPost?.aggregations?.p_stats?.avg_count)/overviewPost?.aggregations?.p_stats?.avg_count * 100)))) || undefined
  const comparedTotalThreads = (overviewPost && (Math.floor((Math.abs(overviewPost?.aggregations?.top_stats?.total_threads - overviewPost?.aggregations?.p_stats?.avg_thread)/overviewPost?.aggregations?.p_stats?.avg_thread * 100)))) || undefined
  const comparedTotalUniqueAuthors = (overviewPost && (Math.floor((Math.abs(overviewPost?.aggregations?.top_stats?.distinct_author - overviewPost?.aggregations?.p_stats?.avg_author)/overviewPost?.aggregations?.p_stats?.avg_author * 100)))) || undefined
  const comparedTotalEngagement = (overviewPost && (Math.floor((Math.abs(overviewPost?.aggregations?.top_stats?.sum_engagement - overviewPost?.aggregations?.p_stats?.avg_eng)/overviewPost?.aggregations?.p_stats?.avg_eng * 100)))) || undefined
  const comparedSpreadedChannels = (overviewPost && (Math.floor((Math.abs(overviewPost?.aggregations?.top_stats?.distinct_channel - overviewPost?.aggregations?.p_stats?.avg_channel)/overviewPost?.aggregations?.p_stats?.avg_channel * 100)))) || undefined

  return (
    <CardContainer>
      <SummaryCard
        icon={<CardIcon className="far fa-share-square" />}
        title={`${(overviewPost && prettynum(overviewPost.aggregations.top_stats.total)) || ""}`}
        subtitle="Total posts"
        percentage={comparedTotalPosts}
        tooltip={
          generateTrendTooltip(
            'post',
            comparedTotalPosts ?? 0,
            frequency as Frequency
          )
        }
      />
      <SummaryCard
        icon={<CardIcon className="far fa-newspaper" />}
        title={`${(overviewPost && prettynum(overviewPost.aggregations.top_stats.total_threads)) || ''}`}
        subtitle="Total threads"
        percentage={comparedTotalThreads}
        tooltip={
          generateTrendTooltip(
            'thread',
            comparedTotalThreads ?? 0,
            frequency as Frequency
          )
        }
      />
      <SummaryCard
        icon={<CardIcon className="fas fa-user-edit" />}
        title={`${(overviewPost && prettynum(overviewPost.aggregations.top_stats.distinct_author)) || ''}`}
        subtitle="Total unique authors"
        percentage={comparedTotalUniqueAuthors}
        tooltip={
          generateTrendTooltip(
            'author',
            comparedTotalUniqueAuthors ?? 0,
            frequency as Frequency
          )
        }
      />
      <SummaryCard
        icon={<CardIcon className="far fa-thumbs-up" />}
        title={`${(overviewPost && prettynum(overviewPost.aggregations.top_stats.sum_engagement)) || ''}`}
        subtitle="Total engagement"
        percentage={comparedTotalEngagement}
        tooltip={
          generateTrendTooltip(
            'engagement',
            comparedTotalEngagement ?? 0,
            frequency as Frequency
          )
        }
      />
      <SummaryCard
        icon={<CardIcon className="fas fa-share-alt" />}
        title={`${(overviewPost && prettynum(overviewPost?.aggregations.top_stats.distinct_channel)) || ''}`}
        subtitle="Spreaded Channels"
        percentage={comparedSpreadedChannels}
        tooltip={
          generateTrendTooltip(
            'channel',
            comparedSpreadedChannels ?? 0,
            frequency as Frequency
          )
        }
      />
    </CardContainer>
  )
}

export default React.memo(StatCards)
