import React from "react";
import ReactWordcloud, { Word, Optional, Options } from "react-wordcloud";

type WordCloudProp = {
  words: Word[];
  color: string;
  onClickWord: (word: string) => void;
};

const WordCloud = (props: WordCloudProp) => {
  const options: Optional<Options> = {
    colors: [
      props.color,
      "#4694F0",
      "#283044",
      "#56CCFF",
      "#1877F2",
      "#75818F",
      "#B1EEF0",
    ],
    padding: 1,
    deterministic: true,
    rotations: 1,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    fontSizes: [12, 50],
  };

  const callbacks = {
    onWordClick: (word: Word) => props.onClickWord(word.text),
  };

  return (
    <>
      <ReactWordcloud
        words={props.words}
        options={options}
        callbacks={callbacks}
      />
    </>
  );
};

export default WordCloud;
