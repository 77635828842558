import * as React from "react"
import { IconProp } from "./interface"

function BinIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg viewBox="0 0 141.73 141.73" height={height} width={width}>
      <path
        d="M83.26 115.32q-3.33 0-3.33-3.3V52.51q0-3.3 3.33-3.3h6.61q3.33 0 3.33 3.3V112q0 3.28-3.33 3.3zm45.42-92.48a3.6 3.6 0 011.68.32 3.81 3.81 0 011.37 1.05 3.7 3.7 0 011.38 3v4.43a4.44 4.44 0 01-.35 1.8 4.51 4.51 0 01-1 1.51 4.33 4.33 0 01-1.42.86 4.12 4.12 0 01-1.63.24h-4.41v92.47a12.65 12.65 0 01-3.87 9.37 12.85 12.85 0 01-9.41 3.85H31.19a12.9 12.9 0 01-9.43-3.85 12.5 12.5 0 01-2.9-4.27 12.72 12.72 0 01-1-5.1V36h-4.4a5.19 5.19 0 01-3.32-1.1A5.14 5.14 0 019 31.63v-4.4a4.19 4.19 0 01.26-1.61 4.06 4.06 0 01.86-1.38 4.52 4.52 0 013.32-1.37h22.73l9.42-15.73a14.72 14.72 0 015-4.41A13.17 13.17 0 0157.22.81H85a12.42 12.42 0 014.43.82 13.59 13.59 0 014.15 2.48 11.72 11.72 0 013 3L106 22.83zm-72-7.95l-5 8h38.77l-5-7.95a1.59 1.59 0 00-.58-.6 1.64 1.64 0 00-.8-.23h-26a1.56 1.56 0 00-.79.21 1.67 1.67 0 00-.6.58zM111 128.56V36.09H31.19v92.47zm-58.77-13.24c-2.21 0-3.31-1.1-3.32-3.3V52.51q0-3.3 3.32-3.3h6.66q3.31 0 3.32 3.3V112q0 3.28-3.32 3.3z"
        fill={fill}
      />
    </svg>
  )
}

export default BinIcon