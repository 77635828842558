import React, { useMemo } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import ReactTooltip from "react-tooltip"
import SvgBookmarkActive from "./SvgBookmarkActive"
import SvgBookmarkDefault from "./SvgBookmarkDefault"
import { selectBookmarks, saveBookmark, deleteBookmark } from "../../../../../../../app/store/feedSlice"

interface BookmarkProp {
    postId: string
}

const BookmarkRoot = styled.span`
    width: 100%;
`

const BookmarkContainer = styled.div`
    width: 15.64px;
    margin-left: 0.2rem;
    margin-top: calc(-1rem - 1px);
    cursor: pointer;
`

const Bookmark = ({ postId }: BookmarkProp) => {
    const dispatch = useDispatch()

    const bookmarks = useSelector(selectBookmarks)
    const bookmark = useMemo(() => bookmarks.find(bookmark => bookmark.postId === postId), [bookmarks, postId])

    return (
        <BookmarkRoot onClick={() => dispatch(bookmark ? deleteBookmark(postId) : saveBookmark(postId))} >
            <ReactTooltip effect="solid" />
            <BookmarkContainer
                data-tip={bookmark ? `Bookmarked by ${bookmark.userDisplayName}` : "Bookmark this post"}
            >
                {bookmark ? <SvgBookmarkActive /> : <SvgBookmarkDefault />}
            </BookmarkContainer>
        </BookmarkRoot>
    )
}

export default Bookmark