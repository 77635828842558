import { ESQLayer } from "../interface";

import { GuidedKeyword } from "../interface";

export const guided2view = (layer:ESQLayer[]) :GuidedKeyword =>{
    const retme:GuidedKeyword = {
        NoExOnly: [],
        ExcludeOnly:[]
    }

    for(let l of layer){
        if(l.ex && !l.in){
            // exclude only
            retme.ExcludeOnly.push(l);
        }else{
            retme.NoExOnly.push(l);
        }
    }

    return retme;
}

export const view2guided = (g:GuidedKeyword):ESQLayer[] => [...g.NoExOnly,...g.ExcludeOnly];

