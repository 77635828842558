import React from 'react'
import { IconProp } from "./interface"

function SelectIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      height={height}
      width={width}
      fill={fill}
    >
      <defs>
        <clipPath id="Select_svg__clip-path">
          <path
            transform="rotate(-4.07 81.394 56.714)"
            fill="none"
            d="M18.73 3.87h125.49v105.9H18.73z"
          />
        </clipPath>
      </defs>
      <g id="Select_svg__updated">
        <g clipPath="url(#Select_svg__clip-path)">
          <path
            d="M139.09 10.11l-8.94-8a6.08 6.08 0 00-8.94.83L64.35 76.68 36.77 52.1a5.17 5.17 0 00-7.61.73L20.51 64a6 6 0 00.75 8l32.53 29 9.12 8.11.48.44a5.15 5.15 0 007.28-.32 11.41 11.41 0 00.92-.93l68.28-88.52a7.22 7.22 0 00-.78-9.67z"
            fill={fill}
          />
        </g>
        <path
       
          d="M5.17 127.23h131.07a5.42 5.42 0 015.42 5.42v4.17a5.42 5.42 0 01-5.42 5.42H5A5.27 5.27 0 01-.25 137v-4.33a5.42 5.42 0 015.42-5.44z"
          fill={fill}
        />
        <path
       
          d="M97.3 94.4h73.34a4.11 4.11 0 014.11 4.11v6.8a4.11 4.11 0 01-4.11 4.11H97.18a4 4 0 01-4-4v-6.91a4.11 4.11 0 014.12-4.11z"
          transform="rotate(-90 133.97 101.91)"
          fill={fill}
        />
        <path
       
          d="M-58 64.23H73a5.42 5.42 0 015.42 5.42v4.17A5.42 5.42 0 0173 79.25H-58.2A5.27 5.27 0 01-63.47 74v-4.35A5.42 5.42 0 01-58 64.23z"
          transform="rotate(-90 7.49 71.74)"
          fill={fill}
        />
        <path
       
          d="M4.11.52h83.27a4.37 4.37 0 014.37 4.37v6.29a4.37 4.37 0 01-4.37 4.37H4A4.24 4.24 0 01-.25 11.3V4.89A4.37 4.37 0 014.11.52z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default SelectIcon
