import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPostCountChart,
  selectDateRange,
  showChartData,
  selectOverviewFilter,
  selectComparePostCountChart,
} from "../../../../../../app/store/overviewSlice";
import styled from "styled-components";
import { useWindowSize } from "react-use";
import GraphHeader from "./GraphHeader";
import LineChartSkeleton from "./Skeleton";
import {
  CartesianGrid,
  Tooltip,
  XAxis,
  ResponsiveContainer,
  YAxis,
  AreaChart,
  Area,
  Legend,
} from "recharts";
import CustomTooltip from "./CustomTooltip";
import EmptyStatePlaceholder from "../../components/EmptyStatePlaceholder";
import {
  selectCompareFeedTaskInfo,
  selectFeedTaskInfo,
  selectFeedType,
} from "../../../../../../app/store/feedSlice";
import {
  formatAdHocChartPoints,
  formatLiveChartPoints,
  getDataKey,
  mergeTwoGraphsData,
} from "./helper";
import { IntervalContext } from "../../context/IntervalContext";
import useInterval from "./hook/useInterval";
import {
  generateDateRangeInterval,
  generateTimeRange,
} from "../../../../../../app/tools/dateRangeConverter";
import capitalize from "lodash.capitalize";

export enum LineGraphViewBY {
  thread = "Threads",
  comment = "Comments",
  post = "Threads and comments",
}

const LineGraphRoot = styled.div`
  border-radius: 3px;
  background-color: white;
  padding: 1rem;
`;

const LineGraphBody = styled.div`
  width: 100%;
`;

const LineGraph = () => {
  const dispatch = useDispatch();

  const feedType = useSelector(selectFeedType);
  const intervalObject = useInterval();

  const dateRange = useSelector(selectDateRange);
  const customDateRange = useSelector(selectOverviewFilter);
  const postCountChartPoints = useSelector(selectPostCountChart);
  const comparePostCountChartPoints = useSelector(selectComparePostCountChart);
  const [viewBy, setViewBy] = useState(LineGraphViewBY.thread);
  const { height } = useWindowSize();

  const compareTask = useSelector(selectCompareFeedTaskInfo);
  const task = useSelector(selectFeedTaskInfo);

  const formatted =
    feedType === 0
      ? formatLiveChartPoints(postCountChartPoints, dateRange)
      : formatAdHocChartPoints(postCountChartPoints, intervalObject.interval);

  const compareFormatted =
    feedType === 0
      ? formatLiveChartPoints(comparePostCountChartPoints, dateRange)
      : formatAdHocChartPoints(
          comparePostCountChartPoints,
          intervalObject.interval
        );

  const mergedFormattedData = mergeTwoGraphsData(
    formatted,
    compareFormatted,
    task?.task_name,
    compareTask?.task_name
  );

  const handleDotClick = (e: any) => {
    if (feedType === 0) {
      const [from, to] = generateTimeRange(
        e.payload.point as string,
        dateRange
      );
      dispatch(
        showChartData({
          start: from,
          end: to,
        })
      );
    }

    if (feedType === 1 && intervalObject.interval) {
      const [from, to] = generateDateRangeInterval(
        e.payload.point as string,
        intervalObject.interval
      );
      dispatch(
        showChartData({
          start: from,
          end: to,
        })
      );
    }
  };

  console.log(
    "Formatted Data**",
    formatted,
    compareFormatted,
    mergedFormattedData
  );

  return (
    <IntervalContext.Provider value={intervalObject}>
      <LineGraphRoot>
        <GraphHeader
          dateRange={dateRange}
          customDateRange={customDateRange}
          viewBy={viewBy}
          setViewBy={setViewBy}
          feedType={feedType!}
        />
        <div style={{ display: "flex" }}>
          <LineGraphBody>
            {/* <div
              style={{
                marginLeft: "66px",
                marginBottom: "20px",
                color: "#29d1d7",
                fontWeight: "bold",
              }}
            >
              {task?.task_name}
            </div> */}
            {postCountChartPoints ? (
              postCountChartPoints.length > 0 ? (
                <ResponsiveContainer width={"100%"} height={height * 0.3}>
                  <AreaChart data={mergedFormattedData}>
                    <CartesianGrid vertical={false} />
                    <YAxis
                      axisLine={{ stroke: "#D3D2E0" }}
                      tick={{ fontSize: "14px" }}
                      tickLine={{ stroke: "#D3D2E0" }}
                      tickSize={12}
                    />
                    <XAxis
                      dataKey="label"
                      axisLine={{ stroke: "#D3D2E0" }}
                      tick={{ fontSize: "14px" }}
                      tickLine={{ stroke: "#D3D2E0" }}
                      tickSize={12}
                    />
                    <Tooltip
                      content={
                        <CustomTooltip
                          viewBy={viewBy}
                          dateRange={dateRange}
                          interval={intervalObject.interval}
                          feedType={feedType}
                        />
                      }
                    />
                    <Legend
                      verticalAlign="top"
                      height={36}
                      align="right"
                      wrapperStyle={{ left: "0px", top: "0px" }}
                      formatter={(value, entry, index) => {
                        if (value === "threadCount") {
                          return task?.task_name;
                        } else if (value === "compareThreadCount") {
                          return compareTask?.task_name;
                        }
                      }}
                    />
                    <Area
                      type="monotone"
                      dot={false}
                      dataKey={getDataKey(viewBy)}
                      stroke="#29d1d7"
                      strokeWidth={3}
                      fill="#29d1d7"
                      fillOpacity={0.3}
                      activeDot={{
                        onClick: handleDotClick,
                        r: 8,
                        strokeWidth: 1.5,
                        cursor: "pointer",
                      }}
                    />
                    <Area
                      type="monotone"
                      dot={false}
                      dataKey={"compareThreadCount"}
                      stroke="#ffd45d"
                      strokeWidth={3}
                      fill="#ffd45d"
                      fillOpacity={0.3}
                      activeDot={{
                        onClick: handleDotClick,
                        r: 8,
                        strokeWidth: 1.5,
                        cursor: "pointer",
                      }}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              ) : (
                <EmptyStatePlaceholder />
              )
            ) : (
              <LineChartSkeleton />
            )}
          </LineGraphBody>
          {/* <LineGraphBody>
            <div
              style={{
                marginLeft: "66px",
                marginBottom: "20px",
                color: "#ffd45d",
                fontWeight: "bold",
              }}
            >
              {compareTask?.task_name}
            </div>
            {comparePostCountChartPoints ? (
              comparePostCountChartPoints.length > 0 ? (
                <ResponsiveContainer width={"100%"} height={height * 0.3}>
                  <AreaChart data={compareFormatted}>
                    <CartesianGrid vertical={false} />
                    <YAxis
                      axisLine={{ stroke: "#D3D2E0" }}
                      tick={{ fontSize: "14px" }}
                      tickLine={{ stroke: "#D3D2E0" }}
                      tickSize={12}
                    />
                    <XAxis
                      dataKey="label"
                      axisLine={{ stroke: "#D3D2E0" }}
                      tick={{ fontSize: "14px" }}
                      tickLine={{ stroke: "#D3D2E0" }}
                      tickSize={12}
                    />
                    <Tooltip
                      content={
                        <CustomTooltip
                          viewBy={viewBy}
                          dateRange={dateRange}
                          interval={intervalObject.interval}
                          feedType={feedType}
                        />
                      }
                    />
                    <Area
                      type="monotone"
                      dot={false}
                      dataKey={getDataKey(viewBy)}
                      stroke="#ffd45d"
                      strokeWidth={3}
                      fill="#ffd45d"
                      fillOpacity={0.3}
                      activeDot={{
                        onClick: handleDotClick,
                        r: 8,
                        strokeWidth: 1.5,
                        cursor: "pointer",
                      }}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              ) : (
                <EmptyStatePlaceholder />
              )
            ) : (
              <LineChartSkeleton />
            )}
          </LineGraphBody> */}
        </div>
      </LineGraphRoot>
    </IntervalContext.Provider>
  );
};

export default LineGraph;
