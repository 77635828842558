import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import { PDFDocument, PageSizes } from "pdf-lib";
import pptxgen from "pptxgenjs";
import uf from "usefuljs";
import logo from '../../../../../assets/icons/lenx_logo.webp'
import {
  selectDateRange,
  setDateRange,
  clearOverviewData,
  setOverviewFilter,
  selectOverviewFilter,
  selectStats,
  SelectAuthors,
  selectTopSites,
  selectTopEngagementThreads,
  selectTopGrowthThreads,
  selectSentiments,
  selectWordCloud,
  selectTopTrends,
  selectPostCountChart,
} from "../../../../../app/store/overviewSlice";
import {
  Author,
  DateRange,
  Site,
  Thread,
  TopAuthors,
  TopEngagementThread,
  TopGrowthThread,
  TopSites,
} from "../../../../../app/interfaces/overview";
import {
  selectFeedTaskInfo,
  selectFeedType,
  selectTaskUnixEnd,
  selectTaskUnixStart,
} from "../../../../../app/store/feedSlice";
import DateRangeFilter from "../../DateRangeFilter";
import BaseButton from "../../../../reusable/buttons/BaseButton";
import styles from "./head.module.scss";
import DateRangeCalendar from "../components/DateRangePicker/DateRangeCalendar";
import ComponentButton from "../../../../reusable/buttons/Button";
import { TaskInfo } from "../../../../../app/interfaces/feed";
import {
  generateStartOfRangeFromDateRangeUnixTimestamp,
  generateEndOfRangeFromDateRangeUnixTimestamp,
  generateStartOfRangeFromIntervalUnixTimestamp,
  convertIntervalStepAndIntervalToDateRange,
  generateEndOfRangeFromIntervalUnixTimestamp,
} from "../../../../../app/tools/dateRangeConverter";
import {
  generateLiveChartPointLabel,
  generateAdHocChartPointLabel,
} from "../gadgets/sentiment/timeline/helper";
import { IntervalContext } from "../context/IntervalContext";
import {
  formatLiveChartPoints,
  formatAdHocChartPoints,
} from "../gadgets/linegraph/helper";
import useInterval from "../gadgets/linegraph/hook/useInterval";
import ExportpptIcon from "../../../../reusable/icons/ExportpptIcon";
import ExportpdfIcon from "../../../../reusable/icons/ExportpdfIcon";
import IconButton from "../../../../reusable/buttons/IconButton";
import moment from "moment";


export const COLORS_CHART = [
  "73D844",
  "898989",
  "ED5252",
  "177e89",
  "3066be",
  "00a9b5",
  "58508d",
  "bc5090",
  "db3a34",
  "ff6361",
  "ffa600",
];
export const COLORS_ACCENT = ["4472C4", "ED7D31", "FFC000", "70AD47"]; // 1,2,4,6

const tableHeaderStyles = {
  valign: "top",
  align: "left",
  fill: { color: "4472c4" },
  color: "FFFFFF",
  bold: true,
};
const tableCellStyles = {
  valign: "center",
  align: "center",
  fill: { color: "cdd4ea" },
  color: "000000",
  fontSize: 9,
  fit: "shrink",
  autoFit: true,
};

const HeadRoot = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
`;

const ButtonsContainer = styled.div`
  justify-self: left;
  display: flex;
`;

const Button = styled(BaseButton)<{ isActive: boolean }>`
  border: 1px solid #dbdbdb;
  padding: 0px 1rem;
  height: 35px;
  font-size: 0.9rem;
  color: #363636;
  background-color: white;

  ${(props) =>
    props.isActive &&
    css`
      color: white;
      background-color: #29d1d7;
    `}

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  &:not(:last-child) {
    border-right-color: transparent;
  }

  &:hover {
    border: 1px solid #b5b5b5;
    z-index: 2;
  }
`;

const StyledIconButton = styled(IconButton)`
  /* padding: 0.6rem; */
  position: relative;

  &:hover {
    background-color: white;
  }
`;

const Head = ({ isAuthorLoaded }: any) => {
  const dispatch = useDispatch();

  const dateRange = useSelector(selectDateRange);
  const feedType = useSelector(selectFeedType);
  const [showCalendar, setShowCalendar] = useState(false);
  const dateRangeFilter = useSelector(selectOverviewFilter);
  const stats = useSelector(selectStats);
  const task: TaskInfo = useSelector(selectFeedTaskInfo);

  const topAuthors: TopAuthors | null = useSelector(SelectAuthors);
  const topSites: TopSites | null = useSelector(selectTopSites);
  const topEngagementThreads = useSelector(selectTopEngagementThreads);
  const topGrowthThreads = useSelector(selectTopGrowthThreads);
  const sentiments = useSelector(selectSentiments);
  const topWords = useSelector(selectWordCloud);
  const trendData = useSelector(selectTopTrends);

  const taskUnixStart = useSelector(selectTaskUnixStart);
  const taskUnixEnd = useSelector(selectTaskUnixEnd);

  const intervalObject = useInterval();

  const customDateRange = useSelector(selectOverviewFilter);
  const postCountChartPoints = useSelector(selectPostCountChart);

  const formatted =
    feedType === 0
      ? formatLiveChartPoints(postCountChartPoints, dateRange)
      : formatAdHocChartPoints(postCountChartPoints, intervalObject.interval);

  const { interval, intervalStep } = useContext(IntervalContext);



 

  const onClickDr = (dateRange: DateRange) => {
    dispatch(clearOverviewData());
    dispatch(setDateRange(dateRange));
    dispatch(
      setOverviewFilter({
        start: null,
        end: null,
      })
    );
    setShowCalendar(false);
  };

  const onTakeScreenshot = async () => {
    document.getElementById("export-button")!.style.visibility = "hidden";
    document.getElementById("task_name")!.style.display = "block";
    if(feedType === 1){
      document.getElementById("date_filter")!.style.display = "none";
      document.getElementById("date_filter_name")!.style.display = "flex";
    }
    html2canvas(document?.querySelector("#capture")!, {
      useCORS: true,
      allowTaint: true,
      logging: true,
    }).then(async (canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);

      console.log("canvas", image);
      const pdfDoc = await PDFDocument.create();
      let data = await pdfDoc.embedPng(image);
      const page = pdfDoc.addPage(PageSizes.A2);
      const { width, height } = page.getSize();

      console.log(
        "PDF data",
        data,
        data.scale(0.1).width,
        data.scale(0.15).height,
        width,
        height
      );

      let imageDims = data.size();
      // Make sure the image is not larger than the page, and scale down to fit if it is
      if (imageDims.width > width || imageDims.height > height) {
        imageDims = data.scaleToFit(width, height);
      }

      page.drawImage(data, {
        x: width / 2 - imageDims.width / 2,
        y: height / 2 - imageDims.height / 2,
        width: imageDims.width,
        height: imageDims.height,
      });

      let b64Chunk = await pdfDoc.saveAsBase64();
      b64Chunk = "data:application/pdf;base64," + b64Chunk;
      const blob = await (await fetch(b64Chunk)).blob();
      downloadFile(blob);
      // downloadImage(image, "overview");
      document.getElementById("export-button")!.style.visibility = "visible";
      document.getElementById("task_name")!.style.display = "none";
      if(feedType === 1){
        document.getElementById("date_filter")!.style.display = "block";
        document.getElementById("date_filter_name")!.style.display = "none";
      }
    });
    // html2canvas(document?.querySelector("#capture")!);
  };

  const onExportIntoPPT = () => {
    let pres = new pptxgen();
    pres.title = `${task.task_name} Overview Report`;
    let masterSlide = pres.addSlide();
    masterSlide.addText(`${task.task_name} Overview Report`, {
      x: 1,
      y: 2,
      w: "80%",
      h: 1,
      fontSize: 36,
      align: "center",
      fill: { color: "D3E3F3" },
      color: "008899",
      valign: "middle",
    });
    let slide = pres.addSlide();

    let objOpts3 = {
      x: 0.5,
      y: 0.7,
      h: 0.3,
      margin: 0,
      fontSize: 18,
      fontFace: "Arial",
      color: "0088CC",
    };
    slide.addText("Overview Daily Summary", objOpts3);

    let arrTabRows1: any = [
      [
        { text: "Total Posts", options: tableHeaderStyles },
        { text: "Total Threads", options: tableHeaderStyles },
        { text: "Total Authors", options: tableHeaderStyles },
      ],
      [
        { text: stats?.postCount?.total?.toString(), options: tableCellStyles },
        {
          text: stats?.threadCount?.total?.toString(),
          options: tableCellStyles,
        },
        {
          text: stats?.authorCount?.total?.toString(),
          options: tableCellStyles,
        },
      ],
    ];
    slide.addTable(arrTabRows1, {
      x: 0.5,
      y: 1.1,
      rowH: [0.5, 0.9],
      colW: [3],
      fill: { color: "F7F7F7" },
      color: "6c6c6c",
      fontSize: 14,
      align: "center",
      border: { pt: 1, color: "ffffff" },
    });

    let arrTabRows2: any = [
      [
        { text: "Total Engagement", options: tableHeaderStyles },
        { text: "Total View Count", options: tableHeaderStyles },
      ],
      [
        {
          text: stats?.engagementCount?.total?.toString(),
          options: tableCellStyles,
        },
        { text: stats?.viewCount?.total?.toString(), options: tableCellStyles },
      ],
    ];

    slide.addTable(arrTabRows2, {
      x: 0.5,
      y: 3.0,
      rowH: [0.5, 0.9],
      colW: [3],
      fill: { color: "F7F7F7" },
      color: "6c6c6c",
      fontSize: 14,
      align: "center",
      border: { pt: 1, color: "ffffff" },
    });

    addThreadCount(pres, objOpts3, "threads", "Threads Count");
    addThreadCount(pres, objOpts3, "comments", "Comments Count");
    addThreadCount(pres, objOpts3, "total", "Threads and Comments Count");

    addSentimentOverviewSlide(pres, objOpts3);

    addSentimentTimeline(pres, objOpts3);
    addSentimentTimelineVolume(pres, objOpts3);

    addTopTrends(pres, objOpts3);

    addEngagementSlide(pres, objOpts3, topEngagementThreads, "engagement");
    addEngagementSlide(pres, objOpts3, topGrowthThreads, "growth");

    addIntelligentWorldCloudSlide(pres, objOpts3);

    addTopAuthors(
      pres,
      objOpts3,
      topAuthors?.byThreads,
      "Top Authors Summary (By Threads)"
    );
    addTopAuthors(
      pres,
      objOpts3,
      topAuthors?.byComments,
      "Top Authors Summary (By Comments)"
    );
    addTopAuthors(
      pres,
      objOpts3,
      topAuthors?.byTotal,
      "Top Authors Summary (Total)"
    );

    addTopSitesByActivity(
      pres,
      objOpts3,
      topSites?.byThreads,
      "Top Sites by Activity (By Threads)"
    );
    addTopSitesByActivity(
      pres,
      objOpts3,
      topSites?.byComments,
      "Top Sites by Activity (By Comments)"
    );
    addTopSitesByActivity(
      pres,
      objOpts3,
      topSites?.byTotal,
      "Top Sites by Activity (Total)"
    );
    pres.writeFile({ fileName: `${task.task_name} Overview Report.pptx` });
  };

  const addTopAuthors = (
    pres: any,
    objOpts3: any,
    authorList: any,
    title: string
  ) => {
    let slide = pres.addSlide();
    slide.addText(title, objOpts3);
    let arrTabRows: any = [
      [
        { text: "Author", options: tableHeaderStyles },
        { text: "Channel", options: tableHeaderStyles },
        { text: "Post", options: tableHeaderStyles },
      ],
    ];
    authorList?.forEach((item: Author) => {
      arrTabRows.push([
        { text: item?.name, options: tableCellStyles },
        {
          text: item?.channel,
          options: tableCellStyles,
        },
        {
          text: item?.postCount,
          options: tableCellStyles,
        },
      ]);
    });
    slide.addTable(arrTabRows, {
      x: 0.5,
      y: 1.1,
      rowH: [0.3],
      colW: [3],
      fill: { color: "F7F7F7" },
      color: "6c6c6c",
      fontSize: 14,
      align: "center",
      border: { pt: 1, color: "ffffff" },
    });

    return pres;
  };

  const addTopSitesByActivity = (
    pres: any,
    objOpts3: any,
    sitesList: any,
    title: string
  ) => {
    if (sitesList) {
      let slide = pres.addSlide();
      slide.addText(title, objOpts3);
      let arrTabRows: any = [
        [
          { text: "Site", options: tableHeaderStyles },
          { text: "Channel", options: tableHeaderStyles },
          { text: "Post Count", options: tableHeaderStyles },
        ],
      ];
      sitesList?.forEach((item: Site) => {
        arrTabRows.push([
          { text: item?.site, options: tableCellStyles },
          {
            text: item?.channel,
            options: tableCellStyles,
          },
          {
            text: item?.postCount,
            options: tableCellStyles,
          },
        ]);
      });
      slide.addTable(arrTabRows, {
        x: 0.5,
        y: 1.1,
        rowH: [0.3],
        colW: [3],
        fill: { color: "F7F7F7" },
        color: "6c6c6c",
        fontSize: 14,
        align: "center",
        border: { pt: 1, color: "ffffff" },
      });
    }
  };

  const addEngagementSlide = (
    pres: any,
    objOpts3: any,
    threadList: any,
    type: string
  ) => {
    if (threadList?.length) {
      let slide = pres.addSlide();
      slide.addText(`Top Threads (Total ${type})`, objOpts3);
      let arrTabRows: any = [
        [
          { text: "Site", options: tableHeaderStyles },
          { text: "Title", options: tableHeaderStyles },
          { text: "Count", options: tableHeaderStyles },
        ],
      ];
      let list = threadList
        ? threadList.filter((thread: any) => thread.title !== "")
        : null;
      let uniqueList: any = [];
      list?.forEach((thread: TopEngagementThread | TopGrowthThread) => {
        const isFound = uniqueList?.find(
          (item: TopEngagementThread | TopGrowthThread) =>
            item?.title === thread?.title
        );

        if (!isFound) {
          uniqueList.push(thread);
        }
      });
      uniqueList?.forEach((item: any, index: number) => {
        if (index % 10 !== 0 || index === 0) {
          console.log("I am getting called!!", index, uniqueList?.length - 1);
          arrTabRows.push([
            { text: item?.site, options: tableCellStyles },
            {
              text: item?.title,
              options: tableCellStyles,
            },
            {
              text: type === "engagement" ? item?.engagementCount : item?.roc,
              options: tableCellStyles,
            },
          ]);
          if (index === uniqueList?.length - 1) {
            slide.addTable(arrTabRows, {
              x: 0.5,
              y: 1.1,
              rowH: [0.3],
              colW: [3],
              fill: { color: "F7F7F7" },
              color: "6c6c6c",
              fontSize: 10,
              align: "center",
              border: { pt: 1, color: "ffffff" },
            });
          }
        } else if (
          (index % 10 === 0 || index === uniqueList?.length - 1) &&
          index !== 0
        ) {
          console.log("I am getting called!!!", index, uniqueList?.length - 1);
          slide.addTable(arrTabRows, {
            x: 0.5,
            y: 1.1,
            rowH: [0.3],
            colW: [3],
            fill: { color: "F7F7F7" },
            color: "6c6c6c",
            fontSize: 10,
            align: "center",
            border: { pt: 1, color: "ffffff" },
          });
          if (index !== uniqueList?.length - 1 && index !== 0) {
            slide = pres.addSlide();
            slide.addText(`Top Threads (Total ${type}) Cont..`, objOpts3);
            arrTabRows = [
              [
                { text: "Site", options: tableHeaderStyles },
                { text: "Title", options: tableHeaderStyles },
                { text: "Count", options: tableHeaderStyles },
              ],
            ];
            arrTabRows.push([
              { text: item?.site, options: tableCellStyles },
              {
                text: item?.title,
                options: tableCellStyles,
              },
              {
                text: type === "engagement" ? item?.engagementCount : item?.roc,
                options: tableCellStyles,
              },
            ]);
          }
        }
      });
    }
  };

  const addSentimentOverviewSlide = (pres: any, objOpts3: any) => {
    if (sentiments?.length) {
      let slide = pres.addSlide();
      slide.addText(`Sentiment Summary`, objOpts3);
      const positive = sentiments.reduce((sum, item) => sum + item.positive, 0);
      const neutral = sentiments.reduce((sum, item) => sum + item.neutral, 0);
      const negative = sentiments.reduce((sum, item) => sum + item.negative, 0);
      const sum = positive + neutral + negative;
      let arrTabRows: any = [
        [
          { text: "", options: tableHeaderStyles },
          { text: `Percentage %`, options: tableHeaderStyles },
        ],
        [
          { text: "Positive", options: tableCellStyles },
          {
            text: `${Math.round((positive / sum) * 100)}%`,
            options: tableCellStyles,
          },
        ],
        [
          { text: "Neutral", options: tableCellStyles },
          {
            text: `${Math.round((neutral / sum) * 100)}%`,
            options: tableCellStyles,
          },
        ],
        [
          { text: "Negative", options: tableCellStyles },
          {
            text: `${Math.round((negative / sum) * 100)}%`,
            options: tableCellStyles,
          },
        ],
      ];
      slide.addTable(arrTabRows, {
        x: 0.5,
        y: 1.1,
        rowH: [0.3],
        colW: [2],
        fill: { color: "F7F7F7" },
        color: "6c6c6c",
        fontSize: 10,
        align: "center",
        border: { pt: 1, color: "ffffff" },
      });
    }
  };

  const addIntelligentWorldCloudSlide = (pres: any, objOpts3: any) => {
    if (topWords?.length) {
      let slide = pres.addSlide();
      slide.addText(`Intelligent Word Cloud`, objOpts3);
      let arrTabRows: any = [
        [
          { text: "Word", options: tableHeaderStyles },
          { text: "Count", options: tableHeaderStyles },
        ],
      ];
      topWords?.forEach((item: any, index: number) => {
        if (index % 15 !== 0 || index === 0) {
          console.log("I am getting called!!", index, topWords?.length - 1);
          arrTabRows.push([
            {
              text: item?.word,
              options: tableCellStyles,
            },
            {
              text: item?.count,
              options: tableCellStyles,
            },
          ]);
          if (index === topWords?.length - 1) {
            slide.addTable(arrTabRows, {
              x: 0.5,
              y: 1.1,
              rowH: [0.3],
              colW: [3],
              fill: { color: "F7F7F7" },
              color: "6c6c6c",
              fontSize: 10,
              align: "center",
              border: { pt: 1, color: "ffffff" },
            });
          }
        } else if (
          (index % 15 === 0 || index === topWords?.length - 1) &&
          index !== 0
        ) {
          slide.addTable(arrTabRows, {
            x: 0.5,
            y: 1.1,
            rowH: [0.3],
            colW: [3],
            fill: { color: "F7F7F7" },
            color: "6c6c6c",
            fontSize: 10,
            align: "center",
            border: { pt: 1, color: "ffffff" },
          });
          if (index !== topWords?.length - 1 && index !== 0) {
            slide = pres.addSlide();
            slide.addText(`Intelligent Word Cloud Cont..`, objOpts3);
            arrTabRows = [
              [
                { text: "Word", options: tableHeaderStyles },
                { text: "Count", options: tableHeaderStyles },
              ],
            ];
            arrTabRows.push([
              {
                text: item?.word,
                options: tableCellStyles,
              },
              {
                text: item?.count,
                options: tableCellStyles,
              },
            ]);
          }
        }
      });
    }
  };

  const addTopTrends = (pres: any, objOpts3: any) => {
    if (trendData?.length) {
      const formattedTopics = trendData
        ? trendData.length
          ? trendData[0].result.slice().map((topic) => {
              return {
                topicString: [topic.word, ...(topic.related_word ?? [])].reduce(
                  (string, word) => `${string}${word ? ` · ${word}` : ""}`
                ),
                score: topic.ai_score,
                commentScore: topic.doc_freq,
              };
            })
          : []
        : null;

      const sortedTopics = formattedTopics
        ? formattedTopics.sort(uf.obj_sort("commentScore", false, true))
        : null;
      let slide = pres.addSlide();
      slide.addText(`Trending Topic`, objOpts3);
      let arrTabRows: any = [
        [
          { text: "Rank", options: tableHeaderStyles },
          { text: "Topic", options: tableHeaderStyles },
          { text: "Score", options: tableHeaderStyles },
        ],
      ];
      sortedTopics?.forEach((item: any, index: number) => {
        if (index % 13 !== 0 || index === 0) {
          console.log("I am getting called!!", index, sortedTopics?.length - 1);
          arrTabRows.push([
            {
              text: index + 1,
              options: tableCellStyles,
            },
            {
              text: item?.topicString,
              options: tableCellStyles,
            },
            {
              text: `${(item?.commentScore * 100).toFixed(2)}%`,
              options: tableCellStyles,
            },
          ]);
          if (index === sortedTopics?.length - 1) {
            slide.addTable(arrTabRows, {
              x: 0.5,
              y: 1.1,
              rowH: [0.3],
              colW: [3],
              fill: { color: "F7F7F7" },
              color: "6c6c6c",
              fontSize: 10,
              align: "center",
              border: { pt: 1, color: "ffffff" },
            });
          }
        } else if (
          (index % 13 === 0 || index === sortedTopics?.length - 1) &&
          index !== 0
        ) {
          slide.addTable(arrTabRows, {
            x: 0.5,
            y: 1.1,
            rowH: [0.3],
            colW: [3],
            fill: { color: "F7F7F7" },
            color: "6c6c6c",
            fontSize: 10,
            align: "center",
            border: { pt: 1, color: "ffffff" },
          });
          if (index !== sortedTopics?.length - 1 && index !== 0) {
            slide = pres.addSlide();
            slide.addText(`Intelligent Word Cloud Cont..`, objOpts3);
            arrTabRows = [
              [
                { text: "Rank", options: tableHeaderStyles },
                { text: "Topic", options: tableHeaderStyles },
                { text: "Score", options: tableHeaderStyles },
              ],
            ];
            arrTabRows.push([
              {
                text: index + 1,
                options: tableCellStyles,
              },
              {
                text: item?.topicString,
                options: tableCellStyles,
              },
              {
                text: `${(item?.commentScore * 100).toFixed(2)}%`,
                options: tableCellStyles,
              },
            ]);
          }
        }
      });
    }
  };

  const addSentimentTimeline = (pres: any, objOpts3: any) => {
    let slide = pres.addSlide();
    const data = sentiments?.map(
      ({ timestamp, positive, neutral, negative }, index) => {
        const sum = positive + neutral + negative;
        const positivePercent = Math.round((positive / sum) * 100) || 0;
        const negativePercent = Math.round((negative / sum) * 100) || 0;
        return {
          name:
            feedType === 0
              ? generateLiveChartPointLabel(timestamp, dateRange)
              : generateAdHocChartPointLabel(timestamp, interval),
          positive: positivePercent,
          neutral: 100 - positivePercent - negativePercent,
          negative: negativePercent,
          start:
            feedType === 0
              ? generateStartOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateStartOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
          end:
            feedType === 0
              ? generateEndOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateEndOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
        };
      }
    );
    let optsChart = {
      x: 0.5,
      y: 0.5,
      w: "90%",
      h: "90%",
      barDir: "col",
      barGrouping: "stacked",
      chartColors: COLORS_CHART,
      invertedColors: ["C0504D"],
      showLegend: true,
      //
      showTitle: true,
      title: "Sentiment Overview (Percentage)",
      titleFontFace: "Helvetica Neue Thin",
      titleFontSize: 24,
      titleColor: COLORS_ACCENT[0],
      titlePos: { x: 1.5, y: 0 },
      lineDataSymbolSize: 10,
      shadow: { type: "none" },
      //titleRotate: 10,
      //
      showCatAxisTitle: true,
      catAxisLabelColor: COLORS_ACCENT[1],
      catAxisTitleColor: COLORS_ACCENT[1],
      catAxisTitle: "Date",
      catAxisTitleFontSize: 14,
      //
      showValAxisTitle: true,
      valAxisLabelColor: COLORS_ACCENT[2],
      valAxisTitleColor: COLORS_ACCENT[2],
      valAxisTitle: "Percentage(%)",
      valAxisTitleFontSize: 14,
    };

    const dataChartBar8Series: any = [
      { name: "Positive", labels: [], values: [] },
      { name: "Neutral", labels: [], values: [] },
      { name: "Negative", labels: [], values: [] },
    ];
    let labels: string[] = [];
    data?.forEach((item: any) => {
      console.log("Item Name", item, dataChartBar8Series);
      labels.push(item?.name);
      dataChartBar8Series[0].values.push(item?.positive);
      dataChartBar8Series[1].values.push(item?.neutral);
      dataChartBar8Series[2].values.push(item?.negative);
    });

    dataChartBar8Series[0].labels = labels;
    dataChartBar8Series[1].labels = labels;
    dataChartBar8Series[2].labels = labels;
    slide.addChart(pres.charts.BAR, dataChartBar8Series, optsChart);
  };

  const addSentimentTimelineVolume = (pres: any, objOpts3: any) => {
    let slide = pres.addSlide();

    const data = sentiments?.map(
      ({ timestamp, positive, neutral, negative }, index) => {
        return {
          name:
            feedType === 0
              ? generateLiveChartPointLabel(timestamp, dateRange)
              : generateAdHocChartPointLabel(timestamp, interval),
          positive,
          neutral,
          negative,
          start:
            feedType === 0
              ? generateStartOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateStartOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
          end:
            feedType === 0
              ? generateEndOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateEndOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
        };
      }
    );

    // FULL SLIDE:
    const OPTS_CHART = {
      x: 0.5,
      y: 0.5,
      w: "95%",
      h: "85%",
      plotArea: { fill: { color: "F2F9FC" } },
      chartColors: COLORS_CHART,
      //
      showLegend: true,
      legendPos: "r",
      //
      showTitle: true,
      lineDataSymbol: "none",
      title: "Sentiment Overview (Volume)",
      titleColor: "0088CC",
      titleFontFace: "Arial",
      titleFontSize: 18,
    };

    const dataChartBar8Series: any = [
      { name: "Positive", labels: [], values: [] },
      { name: "Neutral", labels: [], values: [] },
      { name: "Negative", labels: [], values: [] },
    ];
    let labels: string[] = [];
    data?.forEach((item: any) => {
      console.log("Item Name", item, dataChartBar8Series);
      labels.push(item?.name);
      dataChartBar8Series[0].values.push(item?.positive);
      dataChartBar8Series[1].values.push(item?.neutral);
      dataChartBar8Series[2].values.push(item?.negative);
    });
    dataChartBar8Series[0].labels = labels;
    dataChartBar8Series[1].labels = labels;
    dataChartBar8Series[2].labels = labels;
    slide.addChart(pres.charts.LINE, dataChartBar8Series, OPTS_CHART);
  };

  const addThreadCount = (
    pres: any,
    objOpts3: any,
    type: string,
    title: string
  ) => {
    console.log("Formatted Line Graph", formatted);

    let slide = pres.addSlide();

    // FULL SLIDE:
    const OPTS_CHART = {
      x: 0.5,
      y: 0.5,
      w: "95%",
      h: "85%",
      plotArea: { fill: { color: "F2F9FC" } },
      chartColors: ["29d1d7"],
      dataBorder: { pt: 1, color: "29d1d7" },
      chartColorsOpacity: 25,
      //
      showLegend: true,
      legendPos: "r",
      //
      showTitle: true,
      lineDataSymbol: "none",
      title: title,
      titleColor: "0088CC",
      titleFontFace: "Arial",
      titleFontSize: 18,
    };
    let dataChartBar8Series: any = [];
    if (type === "threads") {
      dataChartBar8Series = [{ name: "Threads", labels: [], values: [] }];
    }
    if (type === "comments") {
      dataChartBar8Series = [{ name: "Comments", labels: [], values: [] }];
    }
    if (type === "total") {
      dataChartBar8Series = [{ name: "Total", labels: [], values: [] }];
    }

    let labels: string[] = [];
    formatted?.forEach((item: any) => {
      console.log("Item Name", item, dataChartBar8Series);
      labels.push(item?.label);
      if (type === "threads") {
        dataChartBar8Series[0].values.push(item?.threadCount);
      }
      if (type === "comments") {
        dataChartBar8Series[0].values.push(item?.commentCount);
      }
      if (type === "total") {
        dataChartBar8Series[0].values.push(item?.postCount);
      }
    });
    dataChartBar8Series[0].labels = labels;

    slide.addChart(pres.charts.AREA, dataChartBar8Series, OPTS_CHART);
  };

  const downloadFile = async (blob: any) => {
    const URL = window.URL.createObjectURL(blob);
    const el = document.createElement("a");
    el.download = `overview_${task?.task_name}.pdf`;
    el.href = URL;
    el.click();
    window.URL.revokeObjectURL(URL);
  };

  // const downloadImage = (blob: any, fileName: any) => {
  //   let fakeLink = window.document.createElement("a");
  //   // fakeLink.style = "display:none;";
  //   fakeLink.download = fileName;

  //   fakeLink.href = blob;

  //   document.body.appendChild(fakeLink);
  //   fakeLink.click();
  //   document.body.removeChild(fakeLink);

  //   fakeLink.remove();
  // };

  return (
    <>
      <div id="task_name" className={styles["task-name-container"]}>
        <div className={styles["task-version-logo-container"]}>
          <img className={styles.img} src={logo} alt="logo" />
          {/* <div className={styles["app-version-container"]}>(<b>{VERSION_NUMBER}</b>)</div> */}
        </div>
        <div className={styles["task-name"]}>{task?.task_name}</div>
        </div>
      <HeadRoot>
      {feedType === 0 && (
        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ flex: "1 1 auto" }}>
            <ButtonsContainer >
              <Button
                isActive={
                  dateRange === DateRange.Today && !dateRangeFilter?.start
                }
                onClick={() => onClickDr(DateRange.Today)}
              >
                <span>Last 24 hours</span>
              </Button>
              <Button
                isActive={
                  dateRange === DateRange.Week && !dateRangeFilter?.start
                }
                onClick={() => onClickDr(DateRange.Week)}
              >
                <span>Last 7 days</span>
              </Button>
              <Button
                isActive={
                  dateRange === DateRange.Month && !dateRangeFilter?.start
                }
                onClick={() => onClickDr(DateRange.Month)}
              >
                <span>Last 30 days</span>
              </Button>
              <DateRangeCalendar
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
              />
            </ButtonsContainer>
          </div>
          {/* <div id="export-button">
           
              <StyledIconButton onClick={isAuthorLoaded && onExportIntoPPT}>
                <ExportpptIcon height="2.5rem" width="2.5rem" fill={isAuthorLoaded ? "#516380": "#eee" }/>
              </StyledIconButton>
        
          </div> */}
          <div id="export-button">
            {/* <ComponentButton
              onClick={onTakeScreenshot}
              disabled={!isAuthorLoaded}
            >
              Export as PDF
            </ComponentButton> */}
            {/* <div data-tip={"Export as PDF"}> */}
              <StyledIconButton onClick={isAuthorLoaded && onTakeScreenshot}>
                <ExportpdfIcon height="2.3rem" width="2.3rem" fill={isAuthorLoaded ? "#516380": "#eee" } />
              </StyledIconButton>
            {/* </div> */}
          </div>
        </div>
      )}
      {feedType === 1 && (
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1 1 auto" }} id="date_filter">
            <DateRangeFilter type="overview" />
          </div>
          
          
            <div className={styles["date-filter-text-container"]} id="date_filter_name">
              <div className={styles["date-filter-text"]}>{moment(taskUnixStart).format("MMM DD, YYYY")}</div>
              <div className={styles["date-filter-separator"]}>-</div>
              <div className={styles["date-filter-text"]}>{moment(taskUnixEnd).format("MMM DD, YYYY")}</div>
            </div>
         
          
          {/* <div id="export-button">
            
              <StyledIconButton onClick={isAuthorLoaded && onExportIntoPPT}>
                <ExportpptIcon height="2.5rem" width="2.5rem" fill={isAuthorLoaded ? "#516380": "#eee" } />
              </StyledIconButton>
          
          </div> */}
          <div id="export-button" style={{ marginLeft: "10px" }}>
            {/* <ComponentButton
              onClick={onTakeScreenshot}
              disabled={!isAuthorLoaded}
            >
              Export as PDF
            </ComponentButton> */}

            {/* <div data-tip={"Export as PDF"}> */}
              <StyledIconButton onClick={isAuthorLoaded && onTakeScreenshot}>
                <ExportpdfIcon height="2.3rem" width="2.3rem" fill={isAuthorLoaded ? "#516380": "#eee" } />
              </StyledIconButton>
            {/* </div> */}
          </div>
        </div>
      )}
      <div></div>
    </HeadRoot>
    </>
    
  );
};

export default Head;
