import React, { useContext, useState } from "react";
import {
  useSelector,
  // useDispatch
} from "react-redux";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import uf from "usefuljs";
import { store } from "../../../../../../app/store";
import {
  selectFeedTaskInfo,
  // selectPostsExportedCount,
  selectTaskSetting,
  // setPostsExportedCount
} from "../../../../../../app/store/feedSlice";
import { selectExportFeed } from "../../../../../../app/store/livefeedpost";
import {
  selectAccountId,
  // selectExportTaskQuota
} from "../../../../../../app/store/userSlice";
import Sheet from "../../../../../../app/tools/excel/sheet";
import { formatDate } from "../../../../../../app/tools/task";
import Button from "../../../../../reusable/buttons/Button";
import AbortContext from "../context/AbortContext";
import CloseWindowContext from "../context/CloseWindowContext";
import { ExportType } from "../interface";
import MultiSelectDropdown from "../MultiSelectDropdown";
import { options } from "./ExportColumnsOptions";
import { setDateMaxLimit, setDateMinLimit } from "./helper";
import {
  BottomContainer,
  ButtonsContainer,
  ContentGridContainer,
  DataOptionInputWrapper,
  DateRangePickersContainer,
  ItemTitle,
  // Reminder
} from "./styled";

interface ExportFormProp {
  exportType: ExportType;
  setIsExporting: React.Dispatch<React.SetStateAction<boolean>>;
  setCanDownload: React.Dispatch<React.SetStateAction<boolean>>;
  setDownloadURL: React.Dispatch<React.SetStateAction<string>>;
}

function ExportForm({
  exportType,
  setIsExporting,
  setCanDownload,
  setDownloadURL,
}: ExportFormProp) {
  // const dispatch = useDispatch()

  const task = useSelector(selectFeedTaskInfo);
  const settings = useSelector(selectTaskSetting);
  const accountId = useSelector(selectAccountId);
  const exportFeeds = useSelector(selectExportFeed);

  // const postsExportedCount = useSelector(selectPostsExportedCount)
  // const exportTaskQuota = useSelector(selectExportTaskQuota)

  const { abortController } = useContext(AbortContext);
  const closeWindow = useContext(CloseWindowContext);

  const [includeComments, setIncludeComments] = useState(true);
  const [includeThreads, setIncludeThreads] = useState(true);
  const [startDate, setStartDate] = useState(
    settings.unix_start
      ? formatDate(new Date(settings.unix_start))
      : formatDate(new Date())
  );
  const [endDate, setEndDate] = useState(
    settings.unix_end
      ? formatDate(new Date(settings.unix_end))
      : formatDate(new Date())
  );
  const [columnOptions, setColumnOptions] = useState(
    options.map((option) => {
      return { ...option, checked: true };
    })
  );

  const handleExcludeCommentsClick = () => {
    if (includeComments && !includeThreads) {
      setIncludeThreads(true);
    }
    setIncludeComments(!includeComments);
  };

  const handleExcludeThreadsClick = () => {
    if (includeThreads && !includeComments) {
      setIncludeComments(true);
    }
    setIncludeThreads(!includeThreads);
  };

  const exportDataByOptions = async () => {
    try {
      const token = store.getState().user.token;
      const pickedColumnOptions = columnOptions.filter(
        (option) => option.checked
      );

      // if (postsExportedCount && exportTaskQuota && postsExportedCount > exportTaskQuota) {
      //   throw new Error("Reached monthly data export limit")
      // }

      if (!startDate || !endDate) {
        throw new Error("Date range cannot be empty");
      }

      if (pickedColumnOptions.length === 0) {
        throw new Error("Columns cannot be empty");
      }

      // if (accountId >= 7 && accountId <= 22) {
      //   throw new Error("Limited permission to export data")
      // }

      setIsExporting(true);

      const res: any = await uf.ajax({
        method: "post",
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/export/posts`,
        signal: abortController.signal,
        data: {
          task_id: task.task_id,
          columns: pickedColumnOptions
            .map((option) => {
              if (option.label === "Thread Link") {
                return ["thread_link", "post_link"];
              }

              return option.value;
            })
            .flat(),
          // new Date(startDate/endDate) give the time at 08:00
          unix_start: new Date(startDate).getTime() - 1000 * 60 * 60 * 8,
          unix_end: new Date(endDate).getTime() + 1000 * 60 * 60 * 16 - 1,
          comments: includeComments,
          thread: includeThreads,
        },
      });

      if (res.result === "success") {
        // if (res.file_rows && postsExportedCount !== 0) {
        //   dispatch(setPostsExportedCount(postsExportedCount + res.file_rows))
        // }

        setCanDownload(true);
        setDownloadURL(res.data);

        return;
      }

      throw new Error("no post to export");
    } catch (error) {
      if (error.name === "AbortError") {
        toast.error("Request cancelled");
      } else {
        toast.error(error.message);
      }

      setCanDownload(false);
      setIsExporting(false);
    }
  };

  const exportDataByFeeds = async () => {
    try {
      const pickedColumnOptions = columnOptions.filter(
        (option) => option.checked
      );

      if (pickedColumnOptions.length === 0) {
        throw new Error("Columns cannot be empty");
      }

      // if (accountId >= 7 && accountId <= 22) {
      //   throw new Error("Limited permission to export data");
      // }

      const sheet = new Sheet(exportFeeds);
      const buffer = await sheet.xlsx(
        pickedColumnOptions
          .map((option) => {
            if (option.label === "Thread Link") {
              return ["thread_link", "post_link"];
            }

            return option.value;
          })
          .flat()
      );
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${task.task_name}-${Date.now()}.xlsx`);
      closeWindow();
    } catch (err) {
      closeWindow();
      toast.error(err.message);
    }
  };

  return (
    <ContentGridContainer isLarge={exportType === "byOptions"}>
      {exportType === "byOptions" && (
        <>
          <ItemTitle>Options</ItemTitle>
          <div>
            <DataOptionInputWrapper>
              <input
                type="checkbox"
                checked={!includeComments}
                onChange={handleExcludeCommentsClick}
              />
              <span>Exclude comments posts</span>
            </DataOptionInputWrapper>
            <DataOptionInputWrapper>
              <input
                type="checkbox"
                checked={!includeThreads}
                onChange={handleExcludeThreadsClick}
              />
              <span>Exclude thread posts</span>
            </DataOptionInputWrapper>
          </div>

          <ItemTitle>Date Range</ItemTitle>
          <DateRangePickersContainer>
            <div>
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                min={setDateMinLimit(settings.unix_start)}
                max={setDateMaxLimit(settings.unix_end)}
              />
              <span>to</span>
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                min={startDate}
                max={setDateMaxLimit(settings.unix_end)}
              />
            </div>
          </DateRangePickersContainer>
        </>
      )}

      <ItemTitle>Customize Columns</ItemTitle>
      <MultiSelectDropdown
        options={columnOptions}
        setOptions={setColumnOptions}
      />
      <BottomContainer>
        <div>
          {/* {postsExportedCount !== null && exportTaskQuota !== null && postsExportedCount > exportTaskQuota && (
            <Reminder>You have reached your monthly data export limit of {exportTaskQuota}</Reminder>
          )} */}
        </div>
        <ButtonsContainer>
          <Button variant="tertiary" onClick={closeWindow}>
            Cancel
          </Button>
          <Button
            onClick={
              exportType === "byOptions"
                ? exportDataByOptions
                : exportDataByFeeds
            }
          >
            Export
          </Button>
        </ButtonsContainer>
      </BottomContainer>
    </ContentGridContainer>
  );
}

export default ExportForm;
