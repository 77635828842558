import React, { memo } from 'react'
import { LineChart, Line } from 'recharts'

interface MiniChartProp {
  data: {
    from: number
    to: number
    count: number
  }[]
}

const MiniChart = memo<MiniChartProp>(({ data }) => {

  const chartData = data.map(item => {
    return {
      time: item.from,
      count: item.count
    }
  }).sort((a, b) => a.time - b.time)

  return (
    <LineChart width={100} height={60} data={chartData}>
      <Line
        type="monotone"
        dataKey="count"
        stroke="#29d1d7"
        dot={false}
        strokeWidth={2}
      />
    </LineChart>
  )
})

export default MiniChart
