import styled from 'styled-components'

export const StyledDateRangeFilter = styled.div`
  & .DateRangePicker,
  .DateRangePickerInput {
    border-radius: 4px;
    
    .DateInput {
      width: 120px;
      height: 33px;
      box-sizing: border-box;
      .DateInput_input {
        color: #29D1D7;
        font-size: 0.9rem;
        font-weight: 600;
        height: 100%;
        text-align: center;
        background-color: #DBF7F8;
        cursor: pointer;
      }
      .DateInput_input__focused {
        border-bottom: 1px solid #DBF7F8;
      }
    }

    #filter_start_date_id,
    #filter_end_date_id {
      &::placeholder {
          font-size: 0.9rem;
          font-weight: 600;
          color: #29D1D7;
      }
    }
  }

  .DateRangePickerInput {
    border: 1px solid #F2F2F2;
    background-color: #DBF7F8;
    min-width: 250px;
  }

  .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1 {
    color: #29D1D7;
    font-weight: 600;
  }
`