import React from 'react'
import styled from 'styled-components'
import _capitalize from "lodash.capitalize"
import { LegendProps } from "recharts"
import { uuidv4 } from "usefuljs"

const LegendContainer = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
`

const LegendLabel = styled.div<{backgroundColor: string}>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
`

const LegendText = styled.p`
  font-size: 10px;
  color: #516380;
`

function CustomLegend(props: LegendProps) {
  const { payload } = props

  return (
    <LegendContainer>
      {payload?.map(legend=>{
        return (
          <LegendItem key={uuidv4()}>
            <LegendLabel backgroundColor={legend.color || "red"} />
            <LegendText>{_capitalize(legend.value)}</LegendText>
          </LegendItem>
        )
      })}
    </LegendContainer>
  )
}

export default CustomLegend
