import React from 'react'
import styled, { css } from 'styled-components'
import pulse from "../../animation/pulse"
import wave from "../../animation/wave"

interface SkeletonProp {
  width?: string
  height?: string
  variant?: "default" | "circle" | "rect"
  animation?: "wave" | "pulse" | "none"
  maxWidth?: string
  minWidth?: string
  margin?: string
}

const StyledSkeleton = styled.div<SkeletonProp>`
  background-color: rgba(0, 0, 0, 0.05);
  width: ${props => props.width};
  height: ${props => props.height};

  ${props => props.animation === "wave" && css`
    position: relative;
    overflow: hidden;
    &::after {
      animation: ${wave} 1.25s linear 0.5s infinite;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: translateX(-100%);
      background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
    }
  `}

  ${props => props.animation === "pulse" && css`
    animation: ${pulse} 1.5s ease-in-out 0.5s infinite;
  `}

  ${props => props.variant === "circle" ? css`
    border-radius: 50%;
  ` : css`
    border-radius: 4px;
  `}

  ${props => props.maxWidth && css`
    max-width: ${props.maxWidth};
  `}

  ${props => props.minWidth && css`
    min-width: ${props.minWidth};
  `}

  ${props => props.margin && css`
    margin: ${props.margin};
  `}
`

function Skeleton({
  width = "100%",
  height = "100%",
  variant = "default",
  animation = "wave",
  maxWidth = "",
  minWidth = "",
  margin = ""
}: SkeletonProp) {
  return (
    <>
      <StyledSkeleton
        width={width}
        height={height}
        variant={variant}
        animation={animation}
        maxWidth={maxWidth}
        minWidth={minWidth}
        margin={margin}
      />
    </>
  )
}

export default Skeleton