import React from "react";
import Head from "./head";
import Content from "./content";
import ReactionsView from "./reactions";
import { FeedData, Reactions } from "../../../../../../../app/interfaces/feed";
import { FeedDataHead } from "./head/interface";
import { FeedDataContent } from "./content/interface";
import styles from "./post.module.scss";

type prop = {
    data: FeedData
}

const Post = (props: prop) => {

    const headdata:FeedDataHead = React.useMemo(() => ({
            author_image: props.data.author_image,
            author_name: props.data.author_name,
            author_link: props.data.author_link,
            unix_timestamp: props.data.unix_timestamp,
            site: props.data.site,
            channel: props.data.channel

    }), [props.data]);

    const contentdata:FeedDataContent = React.useMemo(() => ({
            post_link: props.data.post_link,
            post_message: props.data.post_message,
            thread_link: props.data.thread_link,
            thread_title: props.data.thread_title,
            is_comment: props.data.is_comment,
            unix_timestamp: props.data.unix_timestamp
        

    }), [props.data]);


    const reactionsdata:Reactions = React.useMemo(() => ({
            reaction_angry: props.data.reaction_angry,
            reaction_count: props.data.reaction_count,
            reaction_dislike: props.data.reaction_dislike,
            reaction_haha: props.data.reaction_haha,
            reaction_like: props.data.reaction_like,
            reaction_love: props.data.reaction_love,
            reaction_sad: props.data.reaction_sad,
            reaction_wow: props.data.reaction_wow,
            comment_count: props.data.comment_count,
            share_count:props.data.share_count,
            view_count: props.data.view_count
    }), [props.data]);


    return (
        <div className={styles.post}>
            <Head head={headdata} />
            <Content content={contentdata} />
            <ReactionsView reactions={reactionsdata} />
        </div>
    )
}

export default Post;