import styled from 'styled-components'

const SettingButton = styled.button`
  display: block;
  width: 1.3rem;
  height: 1.3rem;
  color: #dbdbdb;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  &:focus {
      outline: none;
  }
  &:hover {
      background-color: #f8f8fa;
  }
`

export default SettingButton