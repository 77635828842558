import React from "react";
import styles from "./switch.module.scss";


type prop = {
    checked:boolean,
    handleChange: (value:boolean)=>void
}

const Switch = (props:prop)=>{
    const [isChecked,setIsChecked] = React.useState(props.checked);
    React.useEffect(()=>{
        setIsChecked(props.checked);
    },[props.checked]);

    return (
        <label className={styles.switch}>
            <input checked={isChecked} onChange={e => props.handleChange(e.target.checked)} type="checkbox" />
            <span className={styles.slider}></span>
        </label>
    )
}

export default Switch;