import React from "react"
import { ESQLayer } from "../../../interface"
import Group from "../group"
import uf from "usefuljs"
import styles from "./ingroup.module.scss"

type prop = {
    data: ESQLayer[],
    onChange: (data: ESQLayer[]) => void
    isLimited: boolean
}

const Ingroup = (props: prop) => {

    const onChangeGroup = React.useCallback((layer: ESQLayer, index: number) => {
        const currdata = props.data
        currdata[index] = layer
        props.onChange(currdata)
    }, [props])


    const onDeleteGroup = React.useCallback((index: number) => {
        const currdata = props.data
        uf.arr_rmi(currdata, index, true)
        props.onChange(currdata)
    }, [props])


    return (
        <div className={styles.ingroup}>

            {
                props.data.length ? props.data.map((d, i) =>
                    <Group
                        index={i}
                        onChange={onChangeGroup}
                        key={`${i}_${d?.ex?.length ?? 0}`}
                        layer={d}
                        onDelete={onDeleteGroup}
                        excludeOnly={false}
                        isLimited={props.isLimited}
                    />
                ).reduce((p, c) => <>{p} <span className={styles.sep}>OR</span> {c}</>) : null
            }

        </div>
    )
}
export default Ingroup