import { createContext } from "react"

let abortController = new AbortController()

const abort = () => {
  abortController.abort()
  abortController = new AbortController()
}

const AbortContext = createContext({ abortController, abort })

export default AbortContext