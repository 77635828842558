import React from 'react'
import Skeleton from "../../../../../../reusable/utils/Skeleton"
import { Body, Header, IconContainer, OverviewRoot, SentimentIndicatorContainer, SentimentIndicatorRoot } from "./styled"

function OverviewSkeleton() {
  return (
    <OverviewRoot>
      <Header>
        <h3>Sentiment Overview</h3>
      </Header>
      <Body>
        <SentimentIndicatorContainer>
          <SentimentIndicatorSkeleton />
          <SentimentIndicatorSkeleton />
          <SentimentIndicatorSkeleton />
        </SentimentIndicatorContainer>
      </Body>
    </OverviewRoot>
  )
}

function SentimentIndicatorSkeleton() {
  return (
    <SentimentIndicatorRoot>
      <IconContainer>
        <Skeleton variant="circle" height="1.5rem" width="1.5rem" />
        <Skeleton variant="default" height="1rem" width="4rem" />
        {/* <p>{type.charAt(0).toUpperCase() + type.substring(1)}</p> */}

      </IconContainer>
      {/* <Progress
        value={value}
        hideLabel={true}
        loadingColor={type === "positive" ? "#73D844" : type === "negative" ? "#ED5252" : "#898989"}
      /> */}
      <Skeleton variant="default" height="1rem" width="100%" />
      <Skeleton variant="default" height="1rem" width="2rem" margin="1rem" />
      {/* <PercentageLabel>{Math.round(value * 100)}%</PercentageLabel> */}
    </SentimentIndicatorRoot>
  )
}

export default OverviewSkeleton
