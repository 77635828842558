import React from 'react'
import styled from 'styled-components'
import { siteicon } from '../../../../../app/tools/display'
import { SiteForTopThreads } from '../../../interface'

interface SiteOptionProp extends SiteForTopThreads {}

const OptionRoot = styled.div<{ isActive: boolean }>`
  position: relative;
  filter: ${props => props.isActive ? "opacity(1)" : "opacity(0.65)"};
  cursor: pointer;

  >img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }

  >span {
    position: absolute;
    bottom: 0px;
    right: -5px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 21px;
    width: 21px;
    border-radius: 50%;
    
    color: white;
    background-color: #29D1D7;

    font-size: 10px;
  }
`

function SiteOption({
  name,
  count,
  isActive
}: SiteOptionProp) {
  return (
    <OptionRoot isActive={isActive}>
      <img src={siteicon(name)} alt={`${name} logo`} />
      <span>{count}</span>
    </OptionRoot>
  )
}

export default SiteOption
