import React from 'react'
import { Maturity } from '../../../../trendOverview/models'
import styled from 'styled-components'

const MaturityTag = ({maturity, color}: MaturityTagProps) => {
  return (
    <TagContainer color={color}>
      {maturity === 'emerging' && <i className="fas fa-bomb" />}
      <span>{maturity}</span>
    </TagContainer>
  )
}

export default MaturityTag

interface MaturityTagProps {
  maturity: Maturity
  color: string
}

const TagContainer = styled.div<{color: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
  margin-left: 1rem;
  background-color: ${props => props.color};
  border-radius: 3px;
  height: 25px;

  > i {
    color: white;
    font-size: 0.85rem;
    margin-right: 8px;
  }

  > span {
    text-transform: capitalize;
    color: white;
    font-size: 0.8rem;
  }
`