import React from 'react'
import { useDispatch } from "react-redux"
import { setShowIrrelevantManager } from "../../../../../../app/store/smartTrainSlice"
import { MenuItem, MenuList } from "./styled"

function ActionMenuContent() {
  const dispatch = useDispatch()

  return (
    <MenuList>
      {/* <MenuItem>
        Show/Hide columns
      </MenuItem> */}
      <MenuItem onClick={() => dispatch(setShowIrrelevantManager(true))}>
        Manage irrelevant posts
      </MenuItem>
      {/* <MenuItem>
        Manage tags
      </MenuItem>
      <MenuItem>
        Clear bookmarks
      </MenuItem> */}
    </MenuList>
  )
}

export default ActionMenuContent
