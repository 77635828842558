import React, { useState } from 'react'
import styled from 'styled-components'
import { shorttxt } from '../../../../app/tools/string'

interface AuthorRowProp {
    authorName: string
    authorAvatar: string
    count: number
    site: string
}

const RowRoot = styled.li`
    min-height: 50px;
    border-bottom: 1px solid #f2F5FA;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.5rem;
    
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &:hover {
        background-color: #f5f5f5;
    }

    >div:first-child {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 0.5rem;

        >img {
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
        }

        >i {
            font-size: 2rem;
            color: #29D1D7;
        }

        >span {
            color: #7B7B7B;
            font-weight: 600;
        }
    }

    >div:last-child {
        >span {
            color: #7B7B7B;
        }
    }
`

function AuthorRow({
    authorName,
    authorAvatar,
    site,
    count,
}: AuthorRowProp) {
    const [avatarError, setAvatorError] = useState(false)
    return (
        <RowRoot>
            <div style={{flex:'4 0 0'}}>
                {avatarError ? (
                    <i className="fas fa-user-circle" />):(
                    <img src={authorAvatar ?? ""} alt={authorAvatar ? `avatar of ${authorName}`:''} onError={()=>setAvatorError(true)} />
                )}
                <span>{shorttxt(authorName,14)}</span>
            </div>
            <div style={{flex:'3 0 0'}}>
                <span>{site}</span>
            </div>
            <div style={{flex:'2 0 0', textAlign:'right'}}>
                <span>{`${count} posts`}</span>
            </div>
        </RowRoot>
    )
}

export default AuthorRow
