import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux"
import { selectIsHighlightingKeyword, setIsHighlightingKeyword } from "../../../../../../app/store/livefeedpost"
import Switch from "../../../../../reusable/inputs/Switch"

const SwitchRoot = styled.div`
   display: flex;
   align-items: center;
   column-gap: 0.5rem;
`

const SwitchLabel = styled.span`
  font-size: 16px;
  color: #516380;
`

function HighlightKeywordSwitch() {
  const dispatch = useDispatch()
  const isHighlightingKeyword = useSelector(selectIsHighlightingKeyword)

  return (
    <SwitchRoot>
      <SwitchLabel>Highlight keywords</SwitchLabel>
      <Switch checked={isHighlightingKeyword} handleClick={() => dispatch(setIsHighlightingKeyword(!isHighlightingKeyword))} />
    </SwitchRoot>
  )
}

export default HighlightKeywordSwitch
