import React from 'react'
import DropdownContainer from "./styled/DropdownContainer"
import DropdownItem from "./styled/DropdownItem"
import DropdownItemContent from "./styled/DropdownItemContent"
import DropdownMenu from "./styled/DropdownMenu"
import Icon from "./styled/Icon"
import SettingButton from "../styled/SettingButton"

interface HeaderDropdownProp {
  handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  showDropdown: boolean
  openKeywordManager: () => void
}

function HeaderDropdown({ handleClick, openKeywordManager, showDropdown }: HeaderDropdownProp) {

  return (
    <DropdownContainer>
      <SettingButton onClick={handleClick}>
        <i className="fas fa-ellipsis-v" />
      </SettingButton>
      {showDropdown && (
        <DropdownMenu>
          <DropdownItem onClick={openKeywordManager}>
            <DropdownItemContent>
              <Icon src="https://d18dyb0v4b4cx9.cloudfront.net/edit_hidden_list.svg" alt="edit hidden words" />
              <p>Manage hidden topics</p>
            </DropdownItemContent>
          </DropdownItem>
        </DropdownMenu>
      )}
    </DropdownContainer>
  )
  
}

export default HeaderDropdown
