import React from 'react'
import Skeleton from "../../utils/Skeleton"
import { CardRoot, FirstRow, SecondRow } from "./SummaryCard"

interface SummaryCardSkeletonProp {
  icon: JSX.Element
  appearance?: "dark" | "light"
}

function SummaryCardSkeleton({ 
  icon,
  appearance = "light"
 }: SummaryCardSkeletonProp) {
  return (
    <CardRoot color={appearance}>
      <FirstRow>
        {icon}
        <Skeleton height="1rem" width="60px" margin="0px 1rem 0px 0px" />
        <Skeleton height="1rem" width="30px" margin="0px 1rem 0px 0px" />
      </FirstRow>
      <SecondRow>
        <Skeleton height="1rem" />
      </SecondRow>
    </CardRoot>
  )
}

export default SummaryCardSkeleton
