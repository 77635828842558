import React from 'react'
import moment from "moment"
import styled from 'styled-components'

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  position: relative;
`

const Select = styled.select`
  color: #19181A;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
  height: 33px;
  width: 80px;
  border-radius: 4px;
  text-align-last: center;
  position: relative;
  bottom: 4px;

  appearance:none;

  cursor: pointer;

  &:hover {
    color: #29D1D7;
    background-color: #F8F7FA;
  }

  &:focus {
    outline: none;
  }
`

const MonthYearSelect = ({ month, onMonthSelect, onYearSelect }: {
  month: moment.Moment
  onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void
  onYearSelect: (currentMonth: moment.Moment, newYearVal: string) => void
  isVisible: boolean
}) => {

  const currentYear = moment().year()
  const years = [currentYear - 4, currentYear - 3, currentYear - 2, currentYear - 1, currentYear]

  return (
    <SelectContainer>
      <div>
        <Select
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </Select>
      </div>
      <div>
        <Select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {
            years.filter(year => year >= 2020).map(year => <option key={year} value={year}>{year}</option>)
          }
        </Select>
      </div>
    </SelectContainer>
  )
}





export default MonthYearSelect