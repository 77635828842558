import React, { useState } from "react";
import WC from "../../../../../reusable/wordcloud";
import { useSelector, useDispatch } from "react-redux";
import {
  selectWordCloud,
  showTopicData,
  selectHiddenKeywords,
} from "../../../../../../app/store/overviewSlice";
import { Word } from "react-wordcloud";
import styles from "./wordcloud.module.scss";
import { selectTaskId } from "../../../../../../app/store/feedSlice";
import KeywordManager from "./KeywordManager/KeywordManager";
import EmptyStatePlaceholder from "../../components/EmptyStatePlaceholder";
import WordCloudSkeleton from "./Skeleton";
import { GadgetBody, GadgetHeader, GadgetRoot } from "../../styled";

const WordCloud = () => {
  const dispatch = useDispatch();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showManager, setShowManager] = useState(false);

  const topWords = useSelector(selectWordCloud);
  const taskId = useSelector(selectTaskId);
  const hiddenKeywords = useSelector(selectHiddenKeywords);

  const wcdata: Word[] | null = topWords
    ? topWords
        .filter((item) => !hiddenKeywords?.includes(item.word))
        .map((t) => ({ text: t.word, value: t.count }))
    : null;

  const onClickWord = (word: string) => {
    dispatch(
      showTopicData({
        topics: [word],
        taskId,
      })
    );
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!showDropdown) {
      document.addEventListener("click", closeMenu);
    }
    setShowDropdown(true);
    event.stopPropagation();
  };

  const closeMenu = () => {
    setShowDropdown(false);
    document.removeEventListener("click", closeMenu);
  };

  const openKeywordManager = () => {
    setShowManager(true);
    setShowDropdown(false);
  };

  return (
    <GadgetRoot>
      <GadgetHeader>
        <h3>Intelligent Word Cloud</h3>
        {topWords?.length !== 0 && (
          <div className={styles.dropdownContainer}>
            <button onClick={handleClick}>
              <i className="fas fa-ellipsis-v" />
            </button>
            {showDropdown && (
              <div className={styles.dropdownMenu}>
                <div
                  className={styles.dropdownItem}
                  onClick={openKeywordManager}
                >
                  <div className={styles.dropdownItemContent}>
                    <img
                      className={styles.dropdownItemContentIcon}
                      src="https://d18dyb0v4b4cx9.cloudfront.net/edit_hidden_list.svg"
                      alt="edit hidden words"
                    />
                    <p>Manage hidden keywords</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </GadgetHeader>
      <GadgetBody>
        {wcdata ? (
          wcdata.length ? (
            <WC words={wcdata} onClickWord={onClickWord} color="#29d1d7" />
          ) : (
            <EmptyStatePlaceholder />
          )
        ) : (
          <WordCloudSkeleton />
        )}
      </GadgetBody>
      {showManager && (
        <KeywordManager
          words={topWords ?? []}
          hidden={hiddenKeywords}
          cancel={() => {
            setShowManager(false);
          }}
        />
      )}
    </GadgetRoot>
  );
};

export default WordCloud;
