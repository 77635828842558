import React from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import Skeleton from '../../../../../../../reusable/utils/Skeleton'
import { Frequency, TopicData } from '../../../../../../interface'
import { ChartContainer, ChartTitle } from '../styled'
import { calculateTotalThread } from './helperFn'

const ChartRow = ({topicData, frequency}: ChartRow) => {
  const dataForChart = topicData.detailData && calculateTotalThread(topicData.detailData!.results[frequency]?.aggregations.medium_count_line, frequency)
  
  return (
    <ChartContainer>
      <ChartTitle>Threads over time</ChartTitle>
      {dataForChart && dataForChart?.length > 0 ? (
      <ResponsiveContainer width="100%" height={290}>
        <LineChart data={dataForChart}>
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
          />
          <XAxis
            dataKey="dateTime"
            axisLine={{ stroke: '#D3D2E0' }}
            tick={{ fontSize: '10px' }}
            tickLine={{ stroke: '#D3D2E0' }}
            tickSize={12}
          />
          <YAxis
            axisLine={{ stroke: '#D3D2E0' }}
            tick={{ fontSize: '14px' }}
            tickLine={{ stroke: '#D3D2E0' }}
            tickSize={12}
          />
          <Line
            type="monotone"
            dataKey="total"
            stroke="#1877F2"
            strokeWidth={3}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>

      ):(
        <Skeleton height="290px" />
      )}
    </ChartContainer>
  )
}

export default ChartRow

export interface ChartRow{
  topicData: TopicData
  frequency: Frequency
}