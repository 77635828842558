import styled, {css} from 'styled-components'
import { OptionsContainer } from "./options/styled"

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;
    position: relative;

    &:hover {
      ${OptionsContainer} {
        display:flex;
      }
    }
`

export const MessageCard = styled.div<{ checked: boolean,feedType: any}>`
    display: grid;
    grid-template-rows: min-content auto;
     grid-template-columns: 1fr 12fr 4fr;
    /* column-gap: 1rem; */
    width: 100%;
    ${props => props.checked ? css`
        border: 1px solid #29d1d7;
    ` : css`
        border: 1px solid #e6e6e6d2;
    `};
    /* ${props => !(props.feedType === 0)  ? css `grid-template-columns: 1fr 12fr 4fr 4fr;` : css `grid-template-columns: 1fr 12fr 4fr;`} */
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    border-radius: 5px;
    background-color: white;
    transition: all 0.2s ease;
    &:hover {
        box-shadow: 0 2px 5px 1px #d3d2e063;
    }
`

export const Checkbox = styled.input<{ selecting: boolean }>`
    display: ${props => props.selecting ? "block" : "none"};
    margin-left: -1rem;
    margin-right: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;    
`