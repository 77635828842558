import React, { memo } from "react"
import { EditorViewNames, EditorView } from "../interface"
import Ctx from "../ctx"
import { setView, checkAccess } from "../ctx/action"
import styles from "./breadcrumb.module.scss"
import { Context } from "../ctx"

const Breadcrumb = memo(() => {

    const breadcrumbItems = () =>
        Object.entries(EditorViewNames).map(([_, viewName], index) => {
            return <BreadcrumbItem viewName={viewName} index={index} key={viewName} />
        })

    return <div className={styles.breadcrumb}>{breadcrumbItems()}</div>
})

interface BreadcrumbItemProp {
    index: number
    viewName: string
}

const BreadcrumbItem = memo<BreadcrumbItemProp>(({ index, viewName }) => {
    const ctx = React.useContext(Ctx) as Context

    const currentView: EditorView = ctx.editor.view

    const changeView = (editorView: EditorView) =>
        setView(editorView, ctx.setEditor)

    const isClickable = (editorView: EditorView) =>
        checkAccess(editorView, ctx.editor)

    const classNameGenerator = (index: number) => {
        let result = ""
        if (index === currentView) {
            result += styles.active
        }

        if (isClickable(index)) {
            result += ` ${styles.isClickable}`
        }
        return result
    }

    return (
        <span
            className={classNameGenerator(index)}
            key={index}
            onClick={() => {
                changeView(index)
            }}
        >
            <span>{viewName}</span>
        </span>
    )
})  

export default Breadcrumb
