import styled, { css } from "styled-components";

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  padding: 1.5rem 3rem 3rem 3rem;
`;

export const TooltipIcon = styled.i`
  font-size: 0.9rem;
  color: #bdbdbd;
  margin-left: 5px;
  vertical-align: middle;
`;

export const CardsHeadRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-basis: 1 0 100%;
  width: 100%;
  margin-bottom: 0.6rem;
  max-width: 88vw;
`;

export const CardHeadTitle = styled.div<{
  basis?: string;
  align?: string;
  relative?: boolean;
}>`
  text-align: ${(props) => props.align ?? "center"};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #bdbdbd;
  font-size: 0.8rem;
  padding: 0 0.5rem;

  ${(props) =>
    props.basis &&
    css`
      flex: ${props.basis};
    `}

  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `}

  >i {
    font-size: 0.65rem;
    margin-bottom: 5px;
  }
`;

export const CardBodyContainer = styled.div<isActive>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-basis: 1 0 100%;
  width: 100%;
  max-width: 88vw;
  min-height: 22vh;

  background-color: #fff;

  border: 1px solid #e5e5e5;
  border-radius: 10px;

  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.isActive &&
    css`
      transform: scale(1.02);
      box-shadow: 0px 0px 15px rgba(211, 210, 224, 0.4);
    `};

  :hover {
    box-shadow: 0px 0px 15px rgba(211, 210, 224, 0.4);
  }
`;

export const CardRow = styled.div<{ justify?: string }>`
  display: flex;
  width: 100%;

  ${(props) =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
`;

export const CardBodyContent = styled.div<{
  basis?: string;
  flexDirection?: string;
  padding?: string;
}>`
  display: flex;
  justify-content: ${(props) =>
    props.flexDirection ? "flex-start" : "center"};

  padding: ${(props) => props.padding || "1.5em 0.5em"};
  text-align: left;
  font-weight: 400;

  align-items: ${(props) => (props.flexDirection ? "flex-start" : "center")};

  ${(props) =>
    props.basis &&
    css`
      flex: ${props.basis};
    `}

  ${(props) =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `}
`;

export const CardSentimentLoading = styled.div`
  text-align: center;

  > p {
    font-size: 0.8rem;
    color: #bdbdbd;
  }
`;

//Card body content text
export const LastUpdatedText = styled.span`
  color: #516380;
  position: absolute;
  left: 1rem;
  text-overflow: clip;
  white-space: nowrap;
`;

export const LastUpdatedValue = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const ItemNumber = styled.p`
  width: 100%;
  color: #92a5c3;
  font-size: 1.3rem;
  text-align: center;
`;

export const TitleText = styled.div`
  color: #333333;
  font-size: 1.4rem;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const DetailsLink = styled.span`
  text-decoration: underline;
  font-size: 0.8rem;
  color: #00acee;
  margin-left: 2rem;
  vertical-align: 25%;
  :hover {
    color: #516380;
  }
`;

export const SummaryText = styled.p`
  color: #516380;
  font-size: 0.9rem;
`;

// Trend index
export const TrendIndexContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

export const TrendIndexText = styled.span`
  color: #29d1d7;
  font-weight: 600;
  padding-left: 0.5rem;
`;

// Sentiment scale
export const SentimentScaleContainer = styled(TrendIndexContainer)``;

// Number of posts
export const NumberOfPostsContainer = styled.div`
  width: 100%;
`;

export const NumberOfPostsNumberText = styled.p`
  font-size: 1.3rem;
  color: #516380;
  text-align: center;
`;
export const SmallerText = styled.p`
  font-size: 0.7rem;
  color: #97a1b3;
  text-align: center;
`;

interface isActive {
  isActive: boolean;
}
