import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import styled from 'styled-components'
import { selectRelevancy, selectSentiment, selectIsLoading, fetchModelsCounts, fetchModelStatus } from "../../../../../../app/store/smartTrainSlice"
import ModelCard from "./ModelCard"
import ResetModel from "./ResetModel"

const SelectorRoot = styled.div`
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`

function ModelSelector() {
  const dispatch = useDispatch()

  const sentiment = useSelector(selectSentiment)
  const relevancy = useSelector(selectRelevancy)
  const isLoading = useSelector(selectIsLoading)

  const [isResetting, setIsResetting] = useState(false)

  useEffect(() => {
    dispatch(fetchModelsCounts())
    dispatch(fetchModelStatus())
  }, [dispatch])

  return (
    <>
      {
        isResetting ? <ResetModel setIsResetting={setIsResetting} /> : (
          < SelectorRoot >
            <ModelCard
              type="relevancy"
              isTraining={relevancy.isTraining}
              isLoading={isLoading}
              shouldCreateModel={relevancy.shouldCreateModel ?? false}
              setIsResetting={setIsResetting}
            />
            <ModelCard
              type="sentiment"
              isTraining={sentiment.isTraining}
              isLoading={isLoading}
              shouldCreateModel={sentiment.shouldCreateModel ?? false}
              setIsResetting={setIsResetting}
            />
          </SelectorRoot >
        )
      }
    </>
  )
}

export default ModelSelector
