import React from "react";
import Loading from "./utils/loading/loading1";
import Loading2 from "./utils/loading/loading2";
import Loading3 from "./utils/loading/loading3";
import Loading4 from "./utils/loading/loading4";
import Loading5 from "./utils/loading/loading5";
import { useSelector } from "react-redux";
import { selectUserConfig } from "../../app/store/userSlice";

const Loader = ()=>{
    const userconfig = useSelector(selectUserConfig);

    const SelectedLoader = React.useMemo(()=>{
        switch(userconfig.loader){
            case 1:
                return Loading;
            case 2:
                return Loading2;
            case 3:
                return Loading3;
            case 5:
                return Loading5;
            case 4:
            case 0:
            default:
                return Loading4;
        }
    },[userconfig]);

    return (
        <React.Fragment>
            {SelectedLoader && <SelectedLoader />}
            
        </React.Fragment>
    );

}


export default Loader;