import {
	FinalDataThread,
	Points,
} from "../home/Cards/CardBody/ExpandedCard/Statistics/ChartRow/helperFn"
import { AuthorsForTopAuthors, ChannelsForTopChannels } from "../interface"

export const sortByTime = (a: FinalDataThread, b: FinalDataThread) => {
	if (a.dateTime < b.dateTime) {
		return -1
	}
	if (a.dateTime > b.dateTime) {
		return 1
	}
	return 0
}

export const sortByTimestamp = (a: Points, b: Points) => {
	if (a.unix_timestamp < b.unix_timestamp) {
		return -1
	}
	if (a.unix_timestamp > b.unix_timestamp) {
		return 1
	}
	return 0
}

export const sortChannelRanking = (
	a: ChannelsForTopChannels,
	b: ChannelsForTopChannels
) => {
	if (a.count < b.count) {
		return 1
	}
	if (a.count > b.count) {
		return -1
	}
	return 0
}

export const sortAuthorByNumberOfPosts = (
	a: AuthorsForTopAuthors,
	b: AuthorsForTopAuthors
) => {
	if (a.count < b.count) {
		return 1
	}
	if (a.count > b.count) {
		return -1
	}
	return 0
}
