import React from 'react'
import useDropdown from "../../../../hooks/useDropdown"
import { DropdownMenu, DropdownMenuContent, SelectHead, SelectRoot } from "../styled"
import SelectItem from "./SelectItem"

interface MultiSelectProp<T> {
  id: string
  options: { label: string, value: T }[] | undefined
  values: T[]
  setValues: (value: T) => void
  width: number
  label?: string
}

function MultiSelect<T>({
  id,
  options,
  values, 
  setValues,
  width,
  label,
}: MultiSelectProp<T>) {
  const { showDropdownMenu, toggleMenu } = useDropdown(id, true)

  return (
    <SelectRoot onClick={toggleMenu}>
      <span>{label}</span>
      <SelectHead width={width}>
        <span>All Medium</span>
        <i className={`fas fa-angle-${showDropdownMenu ? "up" : "down"}`} aria-hidden="true" />
      </SelectHead>
      {
        showDropdownMenu && (
          <DropdownMenu width={width}>
            <DropdownMenuContent id={id}>
              {
                options?.map(option => (
                  <div key={option.label}>
                    <SelectItem 
                      isActive={values.includes(option.value)} 
                      label={option.label} 
                      onClick={() => setValues(option.value)}
                      
                    />
                  </div>
                ))
              }
            </DropdownMenuContent>
          </DropdownMenu>)
      }
    </SelectRoot>
  )
}

export default MultiSelect
