import * as React from "react";

function SvgSentimentScaleHappy(props:any) {
  return (
    <svg
      width={69}
      height={45}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        opacity={0.4}
        x={5}
        y={17.001}
        width={59}
        height={7}
        rx={3.5}
        fill="url(#Sentiment_ScaleHappy_svg__paint0_linear)"
      />
      <g filter="url(#Sentiment_ScaleHappy_svg__filter0_d)">
        <circle cx={46.201} cy={20.201} r={12.201} fill="#FFD366" />
        <circle cx={46.201} cy={20.201} r={11.701} stroke="#FFCC4D" />
      </g>
      <path
        d="M40.422 23.09c.32.964 2.52 3.533 5.78 3.533 3.259 0 5.457-2.569 5.773-3.532"
        stroke="#516380"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={42.026} cy={17.954} r={1.605} fill="#516380" />
      <path
        d="M51.338 17.954a1.605 1.605 0 11-3.211 0 1.605 1.605 0 013.21 0z"
        fill="#516380"
      />
      <defs>
        <linearGradient
          id="Sentiment_ScaleHappy_svg__paint0_linear"
          x1={5}
          y1={20.501}
          x2={64}
          y2={20.501}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F75E5E" />
          <stop offset={1} stopColor="#5CE191" />
        </linearGradient>
        <filter
          id="Sentiment_ScaleHappy_svg__filter0_d"
          x={24}
          y={0}
          width={44.401}
          height={44.401}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgSentimentScaleHappy;
