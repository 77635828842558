import React from "react"
import { useLocation } from "react-router-dom"
import styles from "./tnav.module.scss"
import styled from "styled-components"




const Tnav = () => {
    const location = useLocation()
    const page = location.pathname.split("/")[1]

    return (
        <div id={styles.tnav}>
            <span className={styles["tnav-title"]}>
                {
                    page === "feed" && <b>Feed</b>
                }
                {
                    page === "trend" && (
                    <>
                        <b>Trend Explorer</b>
                        <BetaBadge>BETA</BetaBadge>
                    </>
                )}
                {
                    page === "account" && <b>Account</b>
                }
            </span>
        </div>
    )
}

export default Tnav

const BetaBadge = styled.span`
    height: 15px;
    width: 35px;
    background-color: #D5FEFF;
    color:#29D1D7 !important;
    border-radius: 3px;
    font-size: 0.7rem;
    letter-spacing: 1px;
    padding: 2px 8px !important;
`