import * as React from "react"

function SvgSentimentScaleSad() {
  return (
    <svg
      width="64px"
      height="45px"
      viewBox="0 0 64 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity={0.4}
        x={5}
        y={17}
        width={59}
        height={7}
        rx={3.5}
        fill="url(#SentimentScaleSad_svg__paint0_linear)"
      />
      <g filter="url(#SentimentScaleSad_svg__filter0_d)">
        <circle cx={23.201} cy={20.201} r={12.201} fill="#FFB966" />
        <circle
          cx={23.201}
          cy={20.201}
          r={11.701}
          stroke="#ED9C52"
          strokeOpacity={0.5}
        />
      </g>
      <path
        d="M28.98 25.339c-.322-.7-2.521-2.569-5.783-2.569-3.26 0-5.46 1.868-5.776 2.569"
        stroke="#516380"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={19.026} cy={17.954} r={1.605} fill="#516380" />
      <path
        d="M28.338 17.954a1.605 1.605 0 11-3.211 0 1.605 1.605 0 013.21 0z"
        fill="#516380"
      />
      <defs>
        <linearGradient
          id="SentimentScaleSad_svg__paint0_linear"
          x1={5}
          y1={20.5}
          x2={64}
          y2={20.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F75E5E" />
          <stop offset={1} stopColor="#5CE191" />
        </linearGradient>
        <filter
          id="SentimentScaleSad_svg__filter0_d"
          x={1}
          y={0}
          width={44.401}
          height={44.401}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
export default SvgSentimentScaleSad
