import React, { useContext } from 'react'
import styled from "styled-components"
import Button from "../../../../../reusable/buttons/Button"
import Loader from "../../../../../reusable/loader"
import AbortContext from "../context/AbortContext"
import CloseWindowContext from "../context/CloseWindowContext"
import { Container, Text } from "./styled"

const LoaderWrapper = styled.div`
  margin-top: 2.5rem;
  height: 2rem;
  width: 4rem;
`

const ExportingText = styled(Text)`
  margin-bottom: 40px;
`

const CancelButton = styled(Button)`
  color: #4c4c4c;
  background-color: transparent;
  border: #CCCCDB solid 1px;
`

function Exporting() {

  const { abort } = useContext(AbortContext)
  const closeWindow = useContext(CloseWindowContext)

  return (
    <Container>
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
      <ExportingText>Exporting data...</ExportingText>
      <CancelButton onClick={() => {
        abort()
        closeWindow()
      }}>Cancel</CancelButton>
    </Container>
  )
}

export default Exporting
