import styled from 'styled-components'

export const ContentGridContainer = styled.div<{ isLarge: boolean }>`
  background-color: white;
  height: ${props => props.isLarge ? "250px" : "100px"};
  border-radius: 3px;
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr 2fr;
`

export const DataOptionInputWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #7E7E7E;
    >input{
      border: solid #BCBCBC 1px;
      margin-right: 0.5rem;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
`

export const DateRangePickersContainer = styled.div`
   >div>input {
    height: 30px;
    width: 170px;
    padding: 0.05rem 0.5rem;
    color: #232830;
    border: solid #BCBCBC 1px;
    border-radius: 3px;
  }
  >div>span {
    margin: auto 6px;
  }
`

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 1rem;
  grid-column: 1 / span 2;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  column-gap: 1rem;
`

export const ItemTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color:  #4d5280;
`

export const Reminder = styled.p`
  font-size: 12px;
  color: #516380;
`