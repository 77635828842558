import React, { useState } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import ModalMain from "../../reusable/utils/Modal/ModalMain"
import ModalRoot from "../../reusable/utils/Modal/ModalRoot"
import SmartFeedIcon from "../../reusable/icons/SmartFeedIcon"
import Button from "../../reusable/buttons/Button"
import { useDispatch, useSelector } from "react-redux"
import { selectDisplaySmartTrainPrompt, selectModelsAvailable, setDisplaySmartTrainPrompt, setShowSmartTrainManager, unsubscribeSmartTrainPrompt } from "../../../app/store/smartTrainSlice"
import { selectSubscriptedToSmartTrain } from "../../../app/store/userSlice"


const SmartFeedPromptContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const GrayscaleWrapper = styled.div`
  filter: grayscale(1);
`

const Title = styled.h3`
  color: #283044;
  font-size: 20px;
  font-weight: 600;
  margin-top: 0.5rem;
`

const Label = styled.p`
  color: #283044;
  font-size: 14px;
  margin-top: 1rem;
  text-align: center;

  span {
    color: #29D1D7;
    font-weight: bold;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 3rem;
  margin-top: 3rem;
`

function SmartFeedPrompt() {
  const dispatch = useDispatch()

  const show = useSelector(selectDisplaySmartTrainPrompt)
  const subscribedToSmartFeed = useSelector(selectSubscriptedToSmartTrain)
  const modelsAvailable = useSelector(selectModelsAvailable)
  const [stage, setStage] = useState<0 | 1>(0)

  const props = useSpring({ opacity: show ? 1 : 0, from: { opacity: 0 }, config: { tension: 350 } })

  const setUpSmartTrain = () => {
    dispatch(unsubscribeSmartTrainPrompt())
    dispatch(setDisplaySmartTrainPrompt(false))
    dispatch(setShowSmartTrainManager(true))
  }

  const setUpLater = () => {
    setStage(1)
  }

  const unsubscribePrompt = () => {
    dispatch(setDisplaySmartTrainPrompt(false))
    dispatch(unsubscribeSmartTrainPrompt())
  }

  return (
    <div>
      {subscribedToSmartFeed && show && !modelsAvailable.length && (
        <animated.div style={props}>
          <ModalRoot show={true}>
            <ModalMain width={620} height={420}>
              {
                stage === 0 ? (
                  <SmartFeedPromptContainer>
                    <SmartFeedIcon height="5rem" width="5rem" />
                    <Title>Setup Smart Feed</Title>
                    <Label>Smart feed helps you detect <span>irrelevant</span> posts and <span>sentiment</span><br />set up your detection models by giving us some examples.</Label>
                    <ButtonContainer>
                      <Button size="small" variant="tertiary" onClick={setUpLater}>MAYBE LATER</Button>
                      <Button size="small" onClick={setUpSmartTrain}>SET UP NOW</Button>
                    </ButtonContainer>
                  </SmartFeedPromptContainer>
                ) : (
                  <SmartFeedPromptContainer>
                    <GrayscaleWrapper>
                      <SmartFeedIcon height="5rem" width="5rem" />
                    </GrayscaleWrapper>
                    <Title>Smart Feed not Activated</Title>
                    <Label>Smart feed feature will only be activated after you<br />set up examples</Label>
                    <ButtonContainer>
                      <Button size="small" variant="tertiary" onClick={unsubscribePrompt}>GOT IT!</Button>
                      <Button size="small" onClick={setUpSmartTrain}>SET UP NOW</Button>
                    </ButtonContainer>
                  </SmartFeedPromptContainer>
                )
              }
            </ModalMain>
          </ModalRoot>
        </animated.div>
      )}
    </div>
  )
}

export default SmartFeedPrompt
