import styled from 'styled-components'

export const ContextSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`


//SummaryRow
export const SummaryRowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  padding: 1rem 2rem 0 2rem;
  margin-bottom: 1rem;

  @media (max-width: 1024px){
    flex-wrap: wrap;
  }
`
export const RelatedTagsContainer = styled.div`
  width: 50%;

  @media (max-width: 1024px){
    width: 100%;
  }
`
export const AISummaryContainer = styled.div`
  width: 50%;

  @media (max-width: 1024px){
    width: 100%;
  }
`
export const SectionHeading = styled.p`
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #516380;
  margin-bottom: 0.8rem; 
`
export const TagsContainer = styled.div`
  margin-bottom: 1.5rem;
`

export const TopicSummary = styled.p`
  color: #516380;
  text-align: justify;
  font-size: 0.9rem;
`

//RelatedPostCarousel

export const CarouselContainer = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
`
export const CarouselWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`
export const CarouselContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`
export const CarouselContent = styled.div`
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar{
    display: none;
  }
  > * {
    width: calc(50% - 2rem);
    flex-shrink: 0;
    flex-grow: 1;
  }
`
const NavigationButton = styled.button`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: white;
  border: 0px;
  box-shadow: 0px 0px 15px rgba(211, 210, 224, 0.4);

  cursor: pointer;
`
export const LeftButton = styled(NavigationButton)`
  left: -5px;
`
export const RightButton = styled(NavigationButton)`
  right: -5px;
`
export const CarouselTitle = styled.h3`
  font-weight: 600;
  margin: 1rem 1rem 0 1rem;
  color: rgba(40, 48, 68, 0.97);
`
//RelatedPostPreview
export const RelatedPostPreviewContainer = styled.div`
  background-color: #F8F8FA;
  height: 250px;

  width: 48%;
  max-width: 45vw;
  margin: 0.8rem 1%;

  padding: 1rem;
`

export const PreviewLink = styled.a`
  color: #283044;
  :hover{
    color:#00ACEE
  }
`

export const PreviewTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
`

export const PreviewPostDataContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
`

export const PreviewPostDataSiteAndDate = styled.p`
  font-size: 0.8rem;
  color: #868686;
`

