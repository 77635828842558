import * as React from "react"

function SvgBookmarkActive() {
  return (
    <svg height="1.5rem" viewBox="0 0 15 23" fill="none" >
      <path
        d="M12.5 0H2.083C.937 0 .01 1.147.01 2.55L0 22.95l7.292-3.826 7.291 3.825V2.55C14.583 1.148 13.646 0 12.5 0z"
        fill="#FFCC4D"
      />
    </svg>
  )
}

export default SvgBookmarkActive