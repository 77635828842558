import uf from "usefuljs";
import { store } from "../../../app/store";
import React from "react";
import { LiveFeedFilter, ThreadViewInfo, FeedData } from "../../../app/interfaces/feed";
import { init_threadview } from ".";

export const setAccess = async (dispatch: React.Dispatch<any>) => {
    try {
        const liveaccess = await uf.ajax({
            method: "get",
            headers: { token: store.getState().user.token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/users/liveaccess`
        }).then((res: any) => {
            if(res.result !== "success")throw new Error(res.error);
            return res.data
        }).catch(err => { throw err });
        dispatch({ type: "setAccess", payload: liveaccess })
    } catch (err) { 
        dispatch({ type: "setAccess", payload: [] })
    }
}


const _date_conv = (x:number):number =>{
    switch(x){
        case -1:
            return Date.now();
        case -2:
            const now = new Date();
            now.setDate(now.getDate() - 1);
            return now.getTime();
        case -3: {
            const now = new Date();
            now.setDate(now.getDate() - 7);
            return now.getTime();
        }
        case -4: {
            const now = new Date();
            now.setDate(now.getDate() - 31);
            return now.getTime();
        }
        default:
            return x;
    }
}


export const setTaskFeedLiveData = async (filter:LiveFeedFilter,dispatch: React.Dispatch<any>,ac?:AbortController) => {
    let aborted:boolean = false;
    try {
        if(filter.task_id === 0)return;
        dispatch({type:"setFetchLiveFeedState",payload:true});
        await uf.ajax({
            method: "post",
            headers: { token: store.getState().user.token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/task/feed`,
            signal:ac?.signal,
            data:{
                ...filter,
                unix_start: _date_conv(filter.unix_start),
                unix_end: _date_conv(filter.unix_end),
                sites: filter.sites.length ? `('${filter.sites.join("','")}')` : "",
                mediums: filter.mediums.length ? `('${filter.sites.join("','")}')` : "",
                is_comment: filter.is_comment,
                search:filter.search
            }
        }).then((res:any) =>{
            if(res.result !== "success")throw new Error(res.error);
            dispatch({type:"setTaskFeedLiveData", payload: res.data });
            dispatch({type:"setTaskFeedLiveDataMax",payload: res.results })
        }).catch(err =>{throw err});
    } catch (err) {
        dispatch({type:"setTaskFeedLiveData", payload: [] });
        if(err.name === "AbortError"){
            // aborted 
            aborted = true;
        }
    }finally{
        if(!aborted)dispatch({type:"setFetchLiveFeedState",payload:false});
    }
}


export const setSites = async(dispatch:React.Dispatch<any>) =>{
    try{
        await uf.ajax({
            headers: { token: store.getState().user.token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/task/list/sites`,
        }).then((res:any) =>{
            if(res.result !== "success")throw new Error(res.error);
            dispatch({type:"setSites", payload:res.data })
        })
    }catch(err){console.error(err)}
}


export const truncateFeedLiveData = (dispatch:React.Dispatch<any>)=> dispatch({type:"truncateTaskFeedLiveData"});


export const setFilter = (payload:LiveFeedFilter,dispatch:React.Dispatch<any>)=>dispatch({ type:"setFilter",payload });
export const setSearch = (payload:string,dispatch:React.Dispatch<any>)=>dispatch({ type:"setSearch",payload });
export const resetSearch =  (dispatch:React.Dispatch<any>)=>dispatch({ type:"setSearch",payload:"" });


export const showThread = async(payload:ThreadViewInfo,dispatch:React.Dispatch<any>)=>{
    try{
        dispatch({ type:"setThreadViewerInfo",payload:{...payload, fetching:true } });
        const data:FeedData[] = await uf.ajax({
            method: "post",
            headers: { token: store.getState().user.token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/task/thread`,
            data:{
                thread_link: payload.thread_link
            }
        }).then((res:any) => res?.data ?? []).catch(err=>{throw err});

        const comments:FeedData[] = [];
        let message:FeedData | null = null;
        
        for(let d of data){
            if(d.is_comment){
                comments.push(d);
            }else{
                message = d;
            }
        }

        dispatch({ type:"setThreadViewerInfo",payload:{ comments , message  } });

    }catch(err){console.error(err);throw err}finally{
        dispatch({ type:"setThreadViewerInfo",payload:{ fetching:false } });
    }
}

export const closeThreadViewer = (dispatch:React.Dispatch<any>)=> dispatch({type:"setThreadViewerInfo",payload:init_threadview})