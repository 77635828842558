import { Frequency, StatType } from "../interface"

export const generateTrendTooltip = (
	type: StatType,
	percentChanged: number,
	frequency: Frequency
) => {
	switch (type) {
		case "post":
			return `${
				percentChanged > 0
					? `Volume ${convertDateRangeToTextTrends(frequency)} 
              is <b>${percentChanged}%</b> higher<br/>
              than average volume in ${convertDateRangeToUnitTrends(
								frequency
							)}<br><br>`
					: percentChanged === 0
					? `No percentage change in the ${convertDateRangeToUnitTrends(
							frequency
					  )}<br><br>`
					: `Volume ${convertDateRangeToTextTrends(frequency)} 
              is <b>${percentChanged}%</b> lower<br/>
              than average volume in ${convertDateRangeToUnitTrends(
								frequency
							)}<br><br>`
			}<i>Note: Posts include both threads<br>and comments</i>`

		case "thread":
			return `${
				percentChanged > 0
					? `Thread count ${convertDateRangeToTextTrends(frequency)} 
              is <b>${percentChanged}%</b> higher<br/>
              than average volume in ${convertDateRangeToUnitTrends(
								frequency
							)}<br><br>`
					: percentChanged === 0
					? `No percentage change in the ${convertDateRangeToUnitTrends(
							frequency
					  )}<br><br>`
					: `Thread count ${convertDateRangeToTextTrends(frequency)} 
              is <b>${percentChanged}%</b> lower<br/>
              than average volume in ${convertDateRangeToUnitTrends(
								frequency
							)}<br><br>`
			}<i>Note: Threads are initial posts<br>that trigger comments or replies</i>`

		case "author":
			return `${
				percentChanged > 0
					? `Unique author count ${convertDateRangeToTextTrends(frequency)} 
              is <b>${percentChanged}%</b> higher<br/>
              than average`
					: percentChanged === 0
					? `No percentage change in the ${convertDateRangeToUnitTrends(
							frequency
					  )}`
					: `Unique author count ${convertDateRangeToTextTrends(frequency)} 
              is <b>${percentChanged}%</b> lower<br/>
              than average`
			}`

		case "engagement":
			return `${
				percentChanged > 0
					? `Engagement count ${convertDateRangeToTextTrends(frequency)} 
        is <b>${percentChanged}%</b> higher<br/>
        than average volume in a ${convertDateRangeToUnitTrends(
					frequency
				)}<br></br>`
					: percentChanged === 0
					? `No percentage change in the ${convertDateRangeToUnitTrends(
							frequency
					  )}<br></br>`
					: `Engagement count ${convertDateRangeToTextTrends(frequency)} 
        is <b>${percentChanged}%</b> lower<br/>
        than average volume in a ${convertDateRangeToUnitTrends(
					frequency
				)}<br></br>`
			}<i>Note: Engagement includes shares,<br>comments, and reactions</i>`

		case "channel":
			return `${
				percentChanged > 0
					? `Number of spreaded channel ${convertDateRangeToTextTrends(
							frequency
					  )} 
        is <b>${percentChanged}%</b> higher<br/>
        than average volume in a ${convertDateRangeToUnitTrends(frequency)}`
					: percentChanged === 0
					? `No percentage change in the ${convertDateRangeToUnitTrends(
							frequency
					  )}`
					: `Number of spreaded channel ${convertDateRangeToTextTrends(
							frequency
					  )} 
        is <b>${percentChanged}%</b> lower<br/>
        than average volume in a ${convertDateRangeToUnitTrends(
					frequency
				)}<br></br>`
			}`
	}
}

export const convertDateRangeToTextTrends = (frequency: Frequency) => {
	switch (frequency) {
		case "Latest":
			return "today"
		case "Daily":
			return "yesterday"
		case "Weekly":
			return "this week"
		case "BiWeekly":
			return "these two weeks"
		case "Monthly":
			return "this month"
		default:
			return ""
	}
}

export const convertDateRangeToUnitTrends = (frequency: Frequency) => {
	switch (frequency) {
		case "Latest":
			return "the past 24 hours"
		case "Daily":
			return "a day"
		case "Weekly":
			return "a week"
		case "BiWeekly":
			return "two weeks"
		case "Monthly":
			return "a month"
		default:
			return ""
	}
}

export type TrendTooltip = "trendIndex" | "sentiment"
