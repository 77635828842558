import React, { useState } from 'react'
import { Medium } from "./HiddenSiteFilter"
import styled, { css } from 'styled-components'

interface HiddenSiteFilterItemProp {
  medium: Medium
  index: number
  handleMediumClick: (mediumIndex: number) => void
  handleSiteClick: (medium: string, site: string) => void
}

export const baseWidth = "210px"
export const baseHeight = "35px"
export const baseFontSize = "0.9rem"

export const HiddenSiteFilterItemRoot = styled.div`
  width: 100%;
  font-size: ${baseFontSize};
`

export const HiddenSiteFilterItemContent = styled.div`
  min-height: ${baseHeight};
  width: 100%;
  padding: 0.4rem 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  div {
    display: flex;
    align-items: center;
  }
`

const CheckBox = styled.input`
  cursor: pointer;
  margin-right: 10px;
  width: ${baseFontSize};
  height: ${baseFontSize};
`

const SubFilterMenu = styled.div`
  width: 100%;
  background-color: #f5f5f5;
`

const SubFilterItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${baseHeight};
  padding: 0.4rem 1.5rem;
  cursor: pointer;
`

const ToggleIcon = styled.i<{ collapsed: boolean }>`
  transition: all 0.2s ease;
  ${props => props.collapsed && css`
    transform: rotate(-180deg);
  `}
`

function HiddenSiteFilterItem({ medium, index, handleMediumClick, handleSiteClick }: HiddenSiteFilterItemProp) {

  const [collapsed, setCollapsed] = useState(false)

  return (
    <HiddenSiteFilterItemRoot>
      <HiddenSiteFilterItemContent onClick={() => { handleMediumClick(index) }} >
        <div>
          <CheckBox type="checkbox" checked={medium.checked} key={medium.name} readOnly />
          <p>{medium.name}</p>
        </div>
        <div onClick={(e) => {
          e.stopPropagation()
          setCollapsed(!collapsed)
        }}>
          <ToggleIcon className="fas fa-chevron-down" collapsed={collapsed} />
        </div>
      </HiddenSiteFilterItemContent>
      {collapsed && (
        <SubFilterMenu>
          {medium.sites.map(site => (
            <SubFilterItem
              key={site.name}
              onClick={(e) => {
                e.stopPropagation()
                handleSiteClick(medium.name, site.name)
              }}>
              <CheckBox type="checkbox" checked={site.checked} key={medium.name} readOnly />
              <p>{site.name}</p>
            </SubFilterItem>

          ))}
        </SubFilterMenu>
      )}
    </HiddenSiteFilterItemRoot>
  )
}

export default HiddenSiteFilterItem