import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const OpenMenuButton = styled.button`
  height: 38px;
  min-width: 140px;
  background-color: #ffffff00;
  border: none;
  border-radius: 4px;
  
  padding: 0 1rem 0 0.5rem;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  cursor: pointer;
  
  &:disabled{
    background-color: #F8F8FA;
    cursor: auto;
    span{
      color: #D3D2E0;
    }
  }
  `

export const ButtonSpan = styled.span`
  text-decoration: underline;
  font-weight: 600;
  color: #516380;
  margin-right: 1rem;
  font-size: 0.9rem;
`

export const MenuSelectionButton = styled.button`
  background-color:#fff;
  border: none;
  padding: 8px 15px;
  width: 100%;
  text-align: left;
  
  font-size: 1rem;
  color: #283044;

  cursor: pointer;

  &:disabled{
    color: #D3D2E0;
    cursor: auto;
  }
`

export const MenuListContainer = styled.div`
  position: absolute;
  top: 38px;

  min-width: 180px;
  z-index: 10;

  border: 1px solid #DADADA;
  border-radius: 4px;
  background-color:#fff;

  padding: 5px 0;
  margin-right:5px;

  box-shadow: 0px 0px 15px rgba(211, 210, 224, 0.4);
`