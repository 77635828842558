import * as React from "react";

function EmptyDashboardIcon(props:any) {
  return (
    <svg
      width={183}
      height={126}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={19} width={130} height={82} rx={3} fill="#fff" />
      <path d="M19 3a3 3 0 013-3h124a3 3 0 013 3v8H19V3z" fill="#E2E1EB" />
      <circle cx={27} cy={6} r={2} fill="#fff" />
      <circle cx={35} cy={6} r={2} fill="#fff" />
      <circle cx={43} cy={6} r={2} fill="#fff" />
      <rect x={30} y={20} width={33} height={18} rx={2} fill="#F2F5FA" />
      <rect x={98} y={50} width={33} height={18} rx={2} fill="#F2F5FA" />
      <rect x={70} y={21} width={61} height={2} rx={1} fill="#F2F5FA" />
      <rect x={30} y={50} width={61} height={2} rx={1} fill="#F2F5FA" />
      <rect x={70} y={31} width={61} height={2} rx={1} fill="#F2F5FA" />
      <rect x={30} y={60} width={52} height={2} rx={1} fill="#F2F5FA" />
      <rect x={70} y={26} width={61} height={2} rx={1} fill="#F2F5FA" />
      <rect x={30} y={55} width={57} height={2} rx={1} fill="#F2F5FA" />
      <rect x={70} y={36} width={46} height={2} rx={1} fill="#F2F5FA" />
      <rect x={30} y={65} width={46} height={2} rx={1} fill="#F2F5FA" />
      <path
        d="M86.5 82.5c-3.833 7.167-9.2 22.9 0 28.5 11.5 7 42-15 56.5-28.5S176 48 174 32c-1.6-12.8-17.333-7.333-25-3"
        stroke="#D3D2E0"
        strokeOpacity={0.6}
        strokeWidth={3}
      />
      <g filter="url(#Empty_Dashboard_svg__filter0_d)">
        <path
          d="M106.264 99.827c.519-1.644.705-4.56.705-5.827.678 4.194.452 5.658 1.977 7.178 1.22 1.216 3.699.957 5.054 1.295-1.384.084-3.774 1.041-5.054 2.955-1.092 1.633-.621 5.489-1.073 7.572-.282-3.321-1.073-6.08-1.977-7.572-.722-1.193-2.936-1.313-3.896-1.407 2.129-.909 3.614-2.14 4.264-4.194z"
          fill="#FEEEC6"
        />
        <path
          d="M106.264 99.827c.519-1.644.705-4.56.705-5.827.678 4.194.452 5.658 1.977 7.178 1.22 1.216 3.699.957 5.054 1.295-1.384.084-3.774 1.041-5.054 2.955-1.092 1.633-.621 5.489-1.073 7.572-.282-3.321-1.073-6.08-1.977-7.572-.722-1.193-2.936-1.313-3.896-1.407 2.129-.909 3.614-2.14 4.264-4.194z"
          stroke="#D2D2D2"
        />
      </g>
      <g filter="url(#Empty_Dashboard_svg__filter1_d)">
        <path
          d="M150.685 17.973c.692-2.25.941-6.24.941-7.973.903 5.74.602 7.742 2.635 9.822 1.627 1.664 4.932 1.31 6.739 1.772-1.845.116-5.032 1.425-6.739 4.044-1.456 2.235-.828 7.512-1.43 10.362-.377-4.545-1.431-8.32-2.636-10.361-.963-1.634-3.915-1.798-5.195-1.926 2.839-1.244 4.819-2.928 5.685-5.74z"
          fill="#FEEEC6"
        />
        <path
          d="M150.685 17.973c.692-2.25.941-6.24.941-7.973.903 5.74.602 7.742 2.635 9.822 1.627 1.664 4.932 1.31 6.739 1.772-1.845.116-5.032 1.425-6.739 4.044-1.456 2.235-.828 7.512-1.43 10.362-.377-4.545-1.431-8.32-2.636-10.361-.963-1.634-3.915-1.798-5.195-1.926 2.839-1.244 4.819-2.928 5.685-5.74z"
          stroke="#D2D2D2"
        />
      </g>
      <g filter="url(#Empty_Dashboard_svg__filter2_d)">
        <path
          d="M164.908 70.827c.476-1.644.647-4.56.647-5.827.621 4.194.414 5.658 1.812 7.178 1.118 1.216 3.391.957 4.633 1.295-1.268.084-3.46 1.041-4.633 2.955-1.001 1.633-.569 5.489-.983 7.572-.259-3.322-.984-6.08-1.812-7.572-.663-1.193-2.692-1.313-3.572-1.407 1.952-.909 3.313-2.14 3.908-4.194z"
          fill="#FEEEC6"
        />
        <path
          d="M164.908 70.827c.476-1.644.647-4.56.647-5.827.621 4.194.414 5.658 1.812 7.178 1.118 1.216 3.391.957 4.633 1.295-1.268.084-3.46 1.041-4.633 2.955-1.001 1.633-.569 5.489-.983 7.572-.259-3.322-.984-6.08-1.812-7.572-.663-1.193-2.692-1.313-3.572-1.407 1.952-.909 3.313-2.14 3.908-4.194z"
          stroke="#D2D2D2"
        />
      </g>
      <g filter="url(#Empty_Dashboard_svg__filter3_d)">
        <path
          d="M16.685 72.667c.692-2.163.94-6 .94-7.667.904 5.519.603 7.444 2.636 9.444 1.627 1.6 4.932 1.26 6.739 1.704-1.845.111-5.032 1.37-6.739 3.889-1.456 2.148-.828 7.222-1.43 9.963-.377-4.37-1.431-8-2.636-9.963-.963-1.57-3.915-1.728-5.195-1.852 2.839-1.195 4.819-2.815 5.685-5.518z"
          fill="#FEEEC6"
        />
        <path
          d="M16.685 72.667c.692-2.163.94-6 .94-7.667.904 5.519.603 7.444 2.636 9.444 1.627 1.6 4.932 1.26 6.739 1.704-1.845.111-5.032 1.37-6.739 3.889-1.456 2.148-.828 7.222-1.43 9.963-.377-4.37-1.431-8-2.636-9.963-.963-1.57-3.915-1.728-5.195-1.852 2.839-1.195 4.819-2.815 5.685-5.518z"
          stroke="#D2D2D2"
        />
      </g>
      <defs>
        <filter
          id="Empty_Dashboard_svg__filter0_d"
          x={91.804}
          y={85.92}
          width={32.317}
          height={39.186}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="Empty_Dashboard_svg__filter1_d"
          x={133.052}
          y={1.922}
          width={38.072}
          height={46.181}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="Empty_Dashboard_svg__filter2_d"
          x={149.157}
          y={56.927}
          width={32.974}
          height={39.171}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="Empty_Dashboard_svg__filter3_d"
          x={0.806}
          y={56.919}
          width={36.313}
          height={45.188}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default EmptyDashboardIcon;
