import React from 'react'
import styled, { css } from 'styled-components'

interface ButtonProp extends BasicButtonProp {
  children: JSX.Element | string
  icon?: JSX.Element
  onClick?: () => void
}

interface BasicButtonProp {
  size?: "large" | "medium" | "small"
  variant?: "primary" | "secondary" | "tertiary" | "danger"
  disabled?: boolean
  type?: "button" | "submit" | "reset" | undefined
}

const BasicButton = styled.button<BasicButtonProp>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  transition: all 0.2s ease;
  &:hover {
    background-color: #67A6A8;
  }
  &:focus {
    border: 1px solid #516380;
    outline: none;
  }
  &:active {
    color: #29d1d7;
    background-color: #E3FEFF;
  }

  ${props => {
    switch (props.variant) {
      default:
      case "primary":
        return css`
          color: #FFFFFF;
          background-color: #29d1d7;
          &:hover {
            background-color: #67A6A8;
          }
          &:focus {
            border: 1px solid #516380;
            outline: none;
          }
          &:active {
            color: #29d1d7;
            background-color: #E3FEFF;
          }
        `
      case "secondary":
        return css`
          color: #29d1d7;
          border: 1px solid #29d1d7;
          background-color: #ffffff;
          &:hover {
            color: #ffffff;
            border: 1px solid #67a6a8;
            background-color: #67a6a8;
          }
          &:focus {
            border: 1px solid #516380;
            outline: none;
          }
          &:active {
            color: #29d1d7;
            border: 1px solid #E3FEFF;
            background-color: #E3FEFF;
          }
        `
      case "tertiary":
        return css`
          color: #898989;
          border: 1px solid #898989;
          background-color: #ffffff;
          &:hover {
            /* color: #ffffff;
            border: 1px solid #67a6a8;
            background-color: #67a6a8; */
            background-color: #eeeeee;
          }
          &:focus {
            border: 1px solid black;
            outline: none;
          }
          &:active {
            color: #29d1d7;
            border: 1px solid #E3FEFF;
            background-color: #E3FEFF;
          }
        `
      case "danger":
        return css`
          color: #ffffff;
          background-color: #ed5252;
          &:hover {
            background-color:#b30000;
          }
          &:focus {
            border: 1px solid #516380;
            outline: none;
          }
          &:active {
            color: #ed5252;
            background-color: #ffd9df;
          }
        `
    }
  }}

  ${props => {
    switch (props.size) {
      case "small":
        return css`
          height: 28px;
          font-size: 14px;
          padding: 4px 12px;
          column-gap: 6px;
          &:focus {
            padding: 3px 11px;
          }
        `
      case "medium":
        return css`
          height: 36px;
          font-size: 16px;
          padding: 10px 14px;
          column-gap: 8px;
          &:focus {
            padding: 9px 13px;
          }
        `
      case "large":
        return css`
          height: 44px;
          font-size: 24px;
          padding: 10px 16px;
          column-gap: 10px;
          &:focus {
            padding: 9px 15px;
          }
        `
    }
  }};

  ${props => props.disabled && css`
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.12);
    border: none;
    color: rgba(0, 0, 0, 0.26);
    &:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  `}
`

export default function Button({
  size = "medium",
  variant = "primary",
  disabled = false,
  children,
  icon,
  onClick,
  type = "button"
}: ButtonProp) {
  return (
    <BasicButton
      size={size}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      type={type}
    >
      {icon ?? null}
      {children}
    </BasicButton>
  )
}