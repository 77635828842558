import _capitalize from "lodash.capitalize"
import React from 'react'
import { TooltipProps } from "recharts"
import styled from "styled-components"
import { uuidv4 } from "usefuljs"

interface CustomTooltipProp extends TooltipProps {
  type: "percentage" | "volume"
}

const TooltipContainer = styled.div`
  height: fit-content;
  width: 180px;
  color: white;
  background-color: rgba(40, 48, 68, 0.8);
  border: 1px solid grey;
  padding: 0.5rem 1rem;
  border-radius: 4px;
`

const TimeRangeContainer = styled.p`
  font-size: 14px;
`

const ContentContainer = styled.div`
  
`

const TooltipItem = styled.div<{ fill: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  div:first-child {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: ${props => props.fill};
    margin-right: 12px;
  }
  p:nth-child(2) {
    margin-right: 8px;
  }
`

function CustomTooltip(props: CustomTooltipProp) {

  const data = props.payload !== undefined ? props.payload?.map(item => {
    return {
      fill: item.fill,
      name: item.name,
      value: item.value
    }
  }) : []

  return (
    <TooltipContainer>
      <TimeRangeContainer>
        {props.label}
      </TimeRangeContainer>
      <ContentContainer>
        {data?.map(item => (
          <TooltipItem fill={item.fill ?? "white"} key={uuidv4()}>
            <div></div>
            <p>{_capitalize(item.name)}</p>
            <p>{item.value}{props.type === "percentage" && "%"}</p>
          </TooltipItem>
        ))}
      </ContentContainer>
    </TooltipContainer>
  )
}

export default CustomTooltip
