import React, { useContext } from 'react'
import styled, { css } from "styled-components"
import BaseButton from "../../../../reusable/buttons/BaseButton"
import { IntervalContext } from "../context/IntervalContext"
interface ButtonProp {
  isActive: boolean
}

const SwitchRoot = styled.div`
  border-radius: 4px;
  border: 1px solid #F8F8FA;
  min-width: fit-content;
`

const Button = styled(BaseButton) <ButtonProp>`
  font-size: 14px;
  font-weight: 500;
  height: 28px;
  width: 56px;

  ${props => props.isActive ? css`
    color: #29D1D7;
    background-color: #DBF7F8;
  ` : css`
    color: #516380;
    background-color: #F8F8FA;
  `}
  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }
  &:nth-child(2) {
    border-radius: 0px 4px 4px 0px;
  }
`

const SingleButton = styled(BaseButton) <ButtonProp>`
  font-size: 14px;
  font-weight: 500;
  height: 28px;
  width: 56px;
  border-radius: 4px;

  ${props => props.isActive ? css`
    color: #29D1D7;
    background-color: #DBF7F8;
  ` : css`
    color: #516380;
    background-color: #F8F8FA;
  `}
 
`

function IntervalSwitch() {

  const {interval, intervalStep, chooseInterval} = useContext(IntervalContext)

  return (
    <SwitchRoot>
      {intervalStep === "hourOnly" && <SingleButton isActive={interval === "hour"}>Hour</SingleButton>}
      {intervalStep === "hourDay" && (
        <>
          <Button
            isActive={interval === "hour"}
            onClick={() => chooseInterval("hour")}
          >
            Hour
          </Button>
          <Button
            isActive={interval === "day"}
            onClick={() => chooseInterval("day")}
          >
            Day
          </Button>
        </>
      )}
      {intervalStep === "dayOnly" && <SingleButton isActive={interval === "day"}>Day</SingleButton>}
      {
        intervalStep === "dayMonth" && (
          <>
            <Button
              isActive={interval === "day"}
              onClick={() => chooseInterval("day")}
            >
              Day
            </Button>
            <Button
              isActive={interval === "month"}
              onClick={() => chooseInterval("month")}
            >
              Month
            </Button>
          </>
        )
      }
      {
        intervalStep === "monthYear" && (
          <>
            <Button
              isActive={interval === "month"}
              onClick={() => chooseInterval("month")}
            >
              Month
            </Button>
            <Button
              isActive={interval === "year"}
              onClick={() => chooseInterval("year")}
            >
              Year
            </Button>
          </>
        )
      }

    </SwitchRoot>
  )
}

export default IntervalSwitch
