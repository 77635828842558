import React from "react";
import moment from "moment";
import { TheadViewerHead } from "./interface";
import { closeThreadViewer } from "../../../ctx/action";
import styles from "./head.module.scss";
import Ctx from "../../../ctx";

type prop = {
    head: TheadViewerHead
}

const Head = (props:prop)=>{

    const ctx:any = React.useContext(Ctx);

    const timetext = React.useMemo(()=> moment(props.head.unix_timestamp).format("DD MMMM YYYY [at] kk:mm"),[props.head.unix_timestamp]);

    const clickClose = React.useCallback(()=> closeThreadViewer(ctx.setFeed) ,[ctx.setFeed])

    return (
        <div id={styles.head}>
            <span id={styles.close} onClick={clickClose}>
                <i className="fas fa-times"></i>
            </span>
            <h2 id={styles.title}>Viewing Post Details</h2>
            <p id={styles.timestamp}>Last updated: {timetext}</p>
            <a id={styles.link} href={props.head.thread_link} target="_blank" rel="noopener noreferrer"><i className="fas fa-link"></i> <span>Visit post link</span></a>
        </div>
    )
}
export default Head;