import React from "react";
import styles from "./loading_msg.module.scss";

const LoadingMsg = () =>
    <div className={styles.loading_msg}>


        <div className={styles.wrapper}>
            <div className={styles["wrapper-cell"]}>
                <div className={styles.image}></div>
                <div className={styles["author-lines"]}>
                    <div className={styles["author-line"]}></div>
                    <div className={styles["author-line"]}></div>
                </div>
                <div className={styles.text}>
                    <div className={styles["text-line"]}> </div>
                    <div className={styles["text-line"]}></div>
                    <div className={styles["text-line"]}></div>
                    <div className={styles["text-line"]}></div>
                </div>
            </div>
        </div>

        <div className={styles.wrapper}>
            <div className={styles["wrapper-cell"]}>
                <div className={styles.image}></div>
                <div className={styles["author-lines"]}>
                    <div className={styles["author-line"]}></div>
                    <div className={styles["author-line"]}></div>
                </div>
                <div className={styles.text}>
                    <div className={styles["text-line"]}> </div>
                    <div className={styles["text-line"]}></div>
                    <div className={styles["text-line"]}></div>
                    <div className={styles["text-line"]}></div>
                </div>
            </div>
        </div>


        <div className={styles.wrapper}>
            <div className={styles["wrapper-cell"]}>
                <div className={styles.image}></div>
                <div className={styles["author-lines"]}>
                    <div className={styles["author-line"]}></div>
                    <div className={styles["author-line"]}></div>
                </div>
                <div className={styles.text}>
                    <div className={styles["text-line"]}> </div>
                    <div className={styles["text-line"]}></div>
                    <div className={styles["text-line"]}></div>
                    <div className={styles["text-line"]}></div>
                </div>
            </div>
        </div>

        <div className={styles.wrapper}>
            <div className={styles["wrapper-cell"]}>
                <div className={styles.image}></div>
                <div className={styles["author-lines"]}>
                    <div className={styles["author-line"]}></div>
                    <div className={styles["author-line"]}></div>
                </div>
                <div className={styles.text}>
                    <div className={styles["text-line"]}> </div>
                    <div className={styles["text-line"]}></div>
                    <div className={styles["text-line"]}></div>
                    <div className={styles["text-line"]}></div>
                </div>
            </div>
        </div>
    </div>

export default LoadingMsg;