import {
	Author,
	PostCountChartPoint,
	Sentiment,
	Site,
	StatCount,
	Stats,
	TopAuthors,
	TopEngagementThread,
	TopGrowthThread,
	TopSites,
	TopTrend,
} from "../../interfaces/overview"
import uf from "usefuljs"
import {
	AuthorDataFromAPI,
	AuthorFromAPI,
	HiddenFromAPI,
	PostCountChartFromAPI,
	SentimentsFromAPI,
	SiteFromAPI,
	StatCountFromAPI,
	StatsFromAPI,
	TopAuthorsFromAPI,
	TopEngagementThreadsFromAPI,
	TopGrowthThreadsFromAPI,
	TopSitesFromAPI,
	TopTrendsFromAPI,
	WordCloudFromAPI,
} from "../../interfaces/ResponseFromAPIs"
import { FeedData } from "../../interfaces/feed"

export function mapTopSites(topSitesFromAPI: TopSitesFromAPI) {
	return {
		byComments: mapSites(topSitesFromAPI.comments),
		byThreads: mapSites(topSitesFromAPI.threads),
		byTotal: mapSites(topSitesFromAPI.total),
	} as TopSites
}

export function mapSites(sitesFromAPI: SiteFromAPI[]) {
	return sitesFromAPI.map((site) => ({
		site: site.site,
		rank: site.rank,
		channel: site.channel,
		postCount: site.post_count,
	})) as Site[]
}

export function mapTopAuthors(topAuthorsFromAPI: TopAuthorsFromAPI) {
	return {
		byComments: mapAuthors(topAuthorsFromAPI.comments),
		byThreads: mapAuthors(topAuthorsFromAPI.threads),
		byTotal: mapAuthors(topAuthorsFromAPI.total),
	} as TopAuthors
}

export const mapAuthors = (authorsFromAPI: AuthorFromAPI[]) => {

	return authorsFromAPI.map((author) => ({
		id: author.author_id,
		name: author.author_name,
		rank: author.rank,
		postCount: author.post_count,
		site: author.site,
		channel: author.channel,
	})) as Author[]
}

export async function sequentialApiCalls(authorsFromAPI: AuthorFromAPI[], userToken?: string, feedTaskId?: number) {
    const results = [];

    for (const item of authorsFromAPI) {
		try {

		const response: AuthorDataFromAPI = await uf.ajax({
			method: "get",
			headers: { token: userToken },
			url: `${process.env.REACT_APP_ENDPOINT}/api/task/author?task_id=${feedTaskId}&author_id=${item?.author_id}`,
		})

		if (response.result !== "success")
			throw new Error("failed to fetch author data")
		item.post_count = findDistinctObjects(response.data)
			results.push(item);
		// return response.data as FeedData[]
	} catch (err) {
		console.error(err.message)
		return []
	}
       
       
    }

    return results;
}

function findDistinctObjects(feedDataList: FeedData[]) {
	const distinctValues = new Set();
	const result:FeedData[] = [];
  
	feedDataList?.forEach(feedData => {
	  const propValue = feedData["post_message"];
  
	  if (!distinctValues.has(propValue)) {
		distinctValues.add(propValue);
		result.push(feedData);
	  }
	});
	return result?.length;
  }


export function mapStats(statsFromAPI: StatsFromAPI) {
	return {
		authorCount: mapStatCount(statsFromAPI.data.author_count),
		engagementCount: mapStatCount(statsFromAPI.data.eng_count),
		postCount: mapStatCount(statsFromAPI.data.post_count),
		threadCount: mapStatCount(statsFromAPI.data.thread_count),
		viewCount: mapStatCount(statsFromAPI.data.view_count),
	} as Stats
}

export function mapStatCount(statCount: StatCountFromAPI) {
	return {
		total: statCount.total,
		previous: statCount.prev_avg,
	} as StatCount
}

export function mapPostCountChart(
	postCountChartFromAPI: PostCountChartFromAPI
) {
	return postCountChartFromAPI.data.map(
		(point) =>
			({
				point: new Date(parseInt(point.point)).toISOString(),
				postCount: point.post_count,
				threadCount: point.thread_count,
				commentCount: point.comment_count,
				topThreads: point.top_threads.map((thread) => ({
					site: thread.site,
					threadTitle: thread.thread_title,
					threadLink: thread.thread_link,
				})),
			} as PostCountChartPoint)
	)
}

export function mapWordCloud(wordCloudFromAPI: WordCloudFromAPI) {
	return Object.keys(wordCloudFromAPI.data).map((key) => ({
		word: key,
		count: wordCloudFromAPI.data[key] as number,
	}))
}

export function mapTopEngagementThreads(
	topEngagementThreadsFromAPI: TopEngagementThreadsFromAPI
) {
	return topEngagementThreadsFromAPI.data.map(
		(thread) =>
			({
				title: thread.thread_title,
				url: thread.thread_link,
				medium: thread.medium,
				site: thread.site,
				engagementCount: thread.engagement_count,
				rank: thread.rank,
			} as TopEngagementThread)
	)
}

export function mapTopGrowthThreads(
	topGrowthThreadsFromAPI: TopGrowthThreadsFromAPI
) {
	return topGrowthThreadsFromAPI.data.map(
		(thread) =>
			({
				title: thread.thread_title,
				url: thread.thread_link,
				site: thread.site,
				roc: thread.roc,
				hash: thread.hash,
			} as TopGrowthThread)
	)
}

export function mapTopTrends(topTrends: TopTrendsFromAPI) {
	if (topTrends.data && topTrends.data.length > 0) {
		return [
			{
				name: topTrends.data[0].term[0]?.name,
				result: topTrends.data[0].term[0]?.result?.map((trend) => ({
					doc_freq: trend.doc_freq,
					medium: trend.medium,
					related_word: trend.related_word,
					date: trend.run_date,
					ai_score: trend.ai_score,
					word: trend.word,
				})),
			},
		] as TopTrend[]
	}

	throw new Error("no data")
}

export function mapHidden(hidden: HiddenFromAPI) {
	return hidden.data.hide
		? hidden.data.hide
		: {
				keywords: [],
				topics: [],
				sites: [],
		  }
}

export function mapSentimentsFromAPI(sentimentsFromAPI: SentimentsFromAPI) {
	if (
		sentimentsFromAPI.data.every(
			(item) => item.negative === 0 && item.neutral === 0 && item.positive === 0
		)
	)
		return [] as Sentiment[]
	return sentimentsFromAPI.data as Sentiment[]
}
