import React, { useContext } from 'react'
import { useDispatch, useSelector } from "react-redux"
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip,
} from 'recharts'
import { selectFeedType } from "../../../../../../../../app/store/feedSlice"
import { fetchSentimentTimelinePostData, selectDateRange, selectSentiments } from "../../../../../../../../app/store/overviewSlice"
import { convertIntervalStepAndIntervalToDateRange, generateStartOfRangeFromIntervalUnixTimestamp, generateEndOfRangeFromIntervalUnixTimestamp, generateStartOfRangeFromDateRangeUnixTimestamp, generateEndOfRangeFromDateRangeUnixTimestamp } from '../../../../../../../../app/tools/dateRangeConverter'
import EmptyStatePlaceholder from "../../../../components/EmptyStatePlaceholder"
import { IntervalContext } from "../../../../context/IntervalContext"
import CustomLegend from "../CustomLegend"
import CustomTooltip from "../CustomTooltip"
import { generateAdHocChartPointLabel, generateLiveChartPointLabel } from "../helper"
import PercentageSkeleton from "./PercentageSkeleton"


function PercentageChart() {
  const dispatch = useDispatch()

  const sentiments = useSelector(selectSentiments)
  const dateRange = useSelector(selectDateRange)
  const feedType = useSelector(selectFeedType)

  const { interval, intervalStep } = useContext(IntervalContext)

  const handleClickBar = (sentiment:0 | 1 | -1 | null) => {
    return (data:any) => {
      dispatch(fetchSentimentTimelinePostData({
        type: sentiment,
        start: data.start,
        end: data.end
      }))
    }
  }

  const handleMouseEnter = () => {
    return (_data:any, _i:any, e:any) => {
      e.target.style.stroke = 'rgb(255,255,255)'
    }
  }

  const handleMouseLeave = () => {
    return (_data:any, _i:any, e:any) => {
      e.target.style.stroke = 'rgb(255,255,255,0)'
    }
  }


  if (sentiments) {
    const data = sentiments.map(({ timestamp, positive, neutral, negative },index) => {
      const sum = positive + neutral + negative
      const positivePercent = Math.round(positive / sum * 100) || 0
      const negativePercent = Math.round(negative / sum * 100) || 0
      return {
        name: feedType === 0 ? generateLiveChartPointLabel(timestamp, dateRange) : generateAdHocChartPointLabel(timestamp, interval),
        positive: positivePercent,
        neutral: 100 - positivePercent - negativePercent,
        negative: negativePercent,
        start: feedType === 0 ? generateStartOfRangeFromDateRangeUnixTimestamp(timestamp, dateRange): generateStartOfRangeFromIntervalUnixTimestamp(timestamp, convertIntervalStepAndIntervalToDateRange(intervalStep!, interval!)),
        end: feedType === 0 ? generateEndOfRangeFromDateRangeUnixTimestamp(timestamp, dateRange): generateEndOfRangeFromIntervalUnixTimestamp(timestamp, convertIntervalStepAndIntervalToDateRange(intervalStep!, interval!)),
      }
    })


    return (
      <ResponsiveContainer width="100%" height="100%">
        {sentiments.length ? (
          <BarChart
            data={data}
            margin={{
              top: 20, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              tickSize={0}
              tickMargin={10}
              tick={{ fontSize: '12px', fill: "#BDBDBD" }}
              axisLine={{ stroke: '#D3D2E0' }}
            />
            <YAxis
              unit="%"
              tickSize={0}
              tickMargin={10}
              tick={{ fontSize: '12px', fill: "#BDBDBD" }}
              axisLine={{ stroke: '#D3D2E0' }}
            />
            <Legend
              iconType="circle"
              iconSize={16}
              content={<CustomLegend />}
            />
            <Tooltip content={<CustomTooltip type="percentage" />} />
            <Bar 
              dataKey="positive" 
              stackId="a" 
              fill="#73D844" 
              barSize={16} 
              onMouseEnter={handleMouseEnter()} 
              onMouseLeave={handleMouseLeave()} 
              onClick={handleClickBar(1)} 
              style={{cursor: 'pointer'}} 
            />
            <Bar 
              dataKey="neutral" 
              stackId="a" 
              fill="#898989" 
              barSize={16} 
              onMouseEnter={handleMouseEnter()} 
              onMouseLeave={handleMouseLeave()} 
              onClick={handleClickBar(0)} 
              style={{cursor: 'pointer'}} 
            />
            <Bar 
              dataKey="negative" 
              stackId="a" 
              fill="#ED5252" 
              barSize={16} 
              onMouseEnter={handleMouseEnter()} 
              onMouseLeave={handleMouseLeave()} 
              onClick={handleClickBar(-1)} 
              style={{cursor: 'pointer'}} 
            />
          </BarChart>
        ) : <EmptyStatePlaceholder />
        }

      </ResponsiveContainer>
    )
  }

  return <PercentageSkeleton />
}

export default PercentageChart
