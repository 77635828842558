import React, { useEffect, useRef, memo, useContext } from "react";
import { useRouteMatch, match } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Ctx from "../../ctx";
import LoadingMsg from "../../../reusable/utils/loading/loading_msg";
import MessageScroller from "./messageScroller";
import Minibar from "./minibar";
import FilterBar from "./filterbar";
import {
  setTaskId,
  fetchData,
  selectFetching,
  selectTotal,
  selectDisplayData,
  selectPostsPerPage,
  clearPosts,
  setFilterUnixStart,
  setFilterUnixEnd,
} from "../../../../app/store/livefeedpost";
import EmptyPostData from "./EmptyPostData";
import { closeThreadViewer } from "../../ctx/action";
import ScrollToTop from "../../../reusable/effects/ScrollTo";
import PaginationBar from "./pagination/PaginationBar";
import ToTopButton from "./ToTopButton";
import {
  selectFeedType,
  selectTaskUnixEnd,
  selectTaskUnixStart,
} from "../../../../app/store/feedSlice";
import { useScroll } from "react-use";
import IrrelevantPostsManager from "./irrelevantPostsManager";
import { FeedData, UniqueFeedData } from "../../../../app/interfaces/feed";

const TaskPostsRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const ContentContainer = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  padding: 2rem 2rem 0rem 2rem;
  width: 100%;

  @media (max-width: 992px) {
    padding: 1.5rem 1.5rem 0rem 1.5rem;
  }

  @media (max-width: 768px) {
    padding: 1rem 1rem 0rem 1rem;
  }
`;

const TaskPosts = memo(() => {
  const dispatch = useDispatch();

  const postsPerPage = useSelector(selectPostsPerPage);

  const feedType = useSelector(selectFeedType);

  const matches: match<any> = useRouteMatch();
  const taskId = parseInt(matches.params.taskid);

  // live feed task data
  const liveFeedData = useSelector(selectDisplayData);

  const totalDbRecordsCount = useSelector(selectTotal);

  const isFetchingData = useSelector(selectFetching);

  const taskUnixStart = useSelector(selectTaskUnixStart);
  const taskUnixEnd = useSelector(selectTaskUnixEnd);


  useEffect(() => {
    dispatch(setTaskId(taskId));

    if (feedType === 0) {
      dispatch(fetchData());
    }

    if (feedType === 1 && taskUnixStart && taskUnixEnd) {
      console.log("I am getting called");
      dispatch(setFilterUnixStart(taskUnixStart));
      dispatch(setFilterUnixEnd(taskUnixEnd));
    }
  }, [taskId, dispatch, feedType, taskUnixStart, taskUnixEnd]);

  const scrollRef = useRef(null);
  const { y } = useScroll(scrollRef);

  // hide thread viewer on unmount of posts
  const ctx: any = useContext(Ctx);

  useEffect(() => {
    return () => {
      closeThreadViewer(ctx.setFeed);
      dispatch(clearPosts());
    };
  }, [ctx.setFeed, dispatch]);

  const getUniquePosts = () => {
    const uniqueIds: string[] = [];
    const postData: UniqueFeedData[] = [];
    liveFeedData?.forEach((liveFeed) => {
      const unix_timestamp = new Date(liveFeed?.unix_timestamp);
      const normalDate = `${unix_timestamp.getDate()}/${unix_timestamp.getMonth()}/${unix_timestamp.getFullYear()}`;
      const uniqueId = `${liveFeed?.author_name}:${normalDate}:${liveFeed?.post_message}`;
      postData.push({
        ...liveFeed,
        uniqueId,
      });
    });
    const unique = postData.filter((element: UniqueFeedData) => {
      if (element?.medium === "Facebook") {
        const isDuplicate = uniqueIds.includes(element.uniqueId);

        if (!isDuplicate && element?.medium === "Facebook") {
          uniqueIds.push(element.uniqueId);

          return true;
        }

        return false;
      } else {
        return true;
      }
    });
    return unique;
  };
  return (
    <TaskPostsRoot>
      <ContentContainer ref={scrollRef}>
        <FilterBar />
        <Minibar noOfRecords={totalDbRecordsCount}/>
        {isFetchingData ? (
          <LoadingMsg />
        ) : liveFeedData.length === 0 ? (
          <EmptyPostData />
        ) : (
              <MessageScroller
                data={getUniquePosts()}
                scrollElement={scrollRef.current!}
              />
            )}
      </ContentContainer>

      {totalDbRecordsCount > 0 && (
        <PaginationBar
          count={Math.ceil(totalDbRecordsCount / postsPerPage) ?? 0}
          rightMostItem={
            <ScrollToTop
              element={scrollRef.current!}
              behavior="smooth"
              yAxis={y}
              threshold={400}
            >
              <ToTopButton isActive={y > 400} />
            </ScrollToTop>
          }
        />
      )}
      <IrrelevantPostsManager />
    </TaskPostsRoot>
  );
});

export default TaskPosts;
