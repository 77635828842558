import React, {useState} from 'react'
import styles from "./SortByFilter.module.scss"
import { useDispatch, useSelector } from "react-redux";
import { SortBy } from "../../../../../app/interfaces/feed";
import { setSortByFilter, selectSortBy } from "../../../../../app/store/livefeedpost";

function SortByFilter() {
  const dispatch = useDispatch()

  const sortBy = useSelector(selectSortBy)
  
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const options:SortBy[] = ["unix_timestamp", "reaction_count", "comment_count", "share_count", "view_count"]

  const generateLabel = (sortBy: SortBy) => {
    switch (sortBy) {
      case "unix_timestamp":
        return "Date"
      case "reaction_count":
        return "Reaction count"
      case "comment_count":
        return "Comment count"
      case "share_count":
        return "Share count"
      case "view_count":
        return "View count"
      default:
        return "Sort by"
    }
  }

  const toggleMenu = () => {        
    if (!showDropdownMenu){
        document.addEventListener('click', closeMenu)    
    }
    setShowDropdownMenu(true)
}

  const closeMenu = () => {
      setShowDropdownMenu(false)
      document.removeEventListener('click', closeMenu)
  }

  const handleSortByClick = (option: SortBy) => {
    dispatch(setSortByFilter(option))
  }
  return (
    <div className={styles.container}>
      <div className={styles.dropdownHeader}>
        <button onClick={toggleMenu}>
          <span>Sort by {generateLabel(sortBy)}</span>
          <span><i className="fas fa-sort-amount-down" /></span>
        </button>
      </div>
      {
        showDropdownMenu && (
          <div className={styles.dropdownMenu}>
            {options.map(option=>{
              return <div className={styles.dropdownItem} onClick={()=>{handleSortByClick(option)}} key={option}>{generateLabel(option)}</div>
            })}
          </div>)  
      }
    </div>
  )
}

export default SortByFilter
