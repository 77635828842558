import React from "react";
import { VersionItemContainer } from "./styled";
import moment from "moment";

// import scss
import styles from "./versions.module.scss";

const VersionItem = ({
  isCurrentversion,
  isActive,
  version,
  onClick,
}: VersionItemProps) => {
  const getActiveClassname = () => {
    let className = `${styles["version-item-title"]}`;
    if (isActive) {
      className = `${className} ${styles["version-item-active"]}`;
    }
    return className;
  };
  const date = moment(version?.gen_at).format("MMM D");
  const time = moment(version?.gen_at).format("LT");

  const analyticalfrom = moment(version?.data_from).format("MMM D");
  const analyticalTo = moment(version?.data_to).format("MMM D");
  return (
    <VersionItemContainer onClick={onClick}>
      <div className={getActiveClassname()}>
        {date}, {time}
      </div>
      {isCurrentversion && (
        <div className={styles["version-item-current-version-text"]}>
          Current Version
        </div>
      )}

      <div className={styles["version-item-analytical-period"]}>
        (Analytical Period: {analyticalfrom} - {analyticalTo})
      </div>
    </VersionItemContainer>
  );
};

interface VersionItemProps {
  isCurrentversion?: boolean;
  isActive?: boolean;
  version: any;
  onClick: any;
}

export default VersionItem;
