import styled, { css } from 'styled-components'
import BaseButton from '../../../../../reusable/buttons/BaseButton'

export const ExpandedCardContainer = styled.div`
  border-top: 1px solid #DADADA;
  width: 97%;
  min-height: 12px;
`

export const TabRow = styled.div`
  padding: 0 3rem;
`
export const TabButton = styled(BaseButton)<isActive>`
  height: 31px;
  color: #516380;
  margin-right: 2rem;
  font-size: 1rem;

  ${props => props.isActive && css`
    border-top: 3px solid #29D1D7;
    color: #29D1D7;
  `};
`

interface isActive {
  isActive: boolean
}