import React from "react"
import styled from 'styled-components'
import Ctx, { Context } from "../ctx"
import { setTaskKw } from "../ctx/action"
import Guided from "./guided"
import styles from "./qrybuilder.module.scss"
import { KeywordSearch, ESQLayer } from "../interface"
import ESConvert from "./esconvert"
import Preview from "./preview"
import Examples from "./examples"
import { calculateIncludes } from "../helper"
import { useSelector } from "react-redux"
import { selectKeywordLimit, selectKeywordPerTask } from "../../../../../app/store/userSlice"

const KeywordsCount = styled.p`
    font-size: 16px;
    color: #868686;
    text-align: right;
`

const Qrybuilder = () => {


    const ctx = React.useContext(Ctx) as Context

    const qrydata: KeywordSearch = React.useMemo(() => ctx.editor.detail.keywords!, [ctx.editor.detail.keywords])

    const onChangeGuide = React.useCallback((qry: ESQLayer[]) => {
        const ec = new ESConvert(qry)
        const qrystring = ec.convert()
        setTaskKw(qry, qrystring, ctx.setEditor)
    }, [ctx.setEditor])

    const [showEx, setShowEx] = React.useState(false)

    // const taskType = ctx.editor.detail.type
    const numberOfIncludes = calculateIncludes(qrydata?.guidedView?.NoExOnly ?? [])
    const keywordPerTask = useSelector(selectKeywordPerTask)
    const globalKeywordLimit = useSelector(selectKeywordLimit)
    const isLimitingInput = (keywordPerTask !== null &&  numberOfIncludes >= keywordPerTask) || (globalKeywordLimit !== null && numberOfIncludes >= globalKeywordLimit)
    return (
        <div className={styles.qrybuilder}>

            <div className={styles.recTxt}>
                <p>
                    We recommend you add alternative combinations to collect data about your topic.
                </p>
            </div>


            <div className={styles.qryHead}>
                <p>We collect posts with the following combinations</p>
            </div>

            <div className={styles.qryview}>
                <Guided
                    onChange={onChangeGuide}
                    qry={qrydata?.guided ?? []}
                    view={qrydata?.guidedView ?? { ExcludeOnly: [], NoExOnly: [] }}
                    isLimited={isLimitingInput}
                />
            </div>

            <div className={styles.qryExample}>
                <button onClick={() => setShowEx(true)}>See examples</button>
                {
                    numberOfIncludes > 0 && keywordPerTask !== null && globalKeywordLimit === null && (
                        <KeywordsCount>{keywordPerTask - numberOfIncludes} keyword(s) left</KeywordsCount>
                    )
                }
                 {
                    numberOfIncludes > 0 && globalKeywordLimit !== null && (
                        <KeywordsCount>{globalKeywordLimit - numberOfIncludes} keyword(s) left</KeywordsCount>
                    )
                }
                {globalKeywordLimit === null && keywordPerTask === null && <KeywordsCount>{"∞"} keyword(s) left</KeywordsCount>}

            </div>

            <Preview />

            <Examples active={showEx} onClickClose={() => setShowEx(false)} />
        </div>
    )
}

export default Qrybuilder