import React from "react"
import { FeedData } from "../../../../../app/interfaces/feed"
import { siteicon } from "../../../../../app/tools/display"
import moment from "moment"
import { shorttxt } from "../../../../../app/tools/string"
import styles from "./post.module.scss"

interface PostProp {
    data: FeedData
}

const Post = (props: PostProp) => {
    const TXT_LIMIT = 50
    const [expand, setExpand] = React.useState(false)
    const txt = React.useMemo(() => expand ? props.data.post_message : shorttxt(props.data.post_message ?? "", TXT_LIMIT), [props.data, expand])
    const displayShowMore = React.useMemo(() => (props.data.post_message ?? "").length > TXT_LIMIT, [props.data])

    return (
        <div className={styles.post}>
            <div className={styles.head}>
                <span className={styles.site}><img src={siteicon(props.data.site ?? "")} alt={props.data.site ?? ""} /></span>
                <span className={styles.authorName}>{props.data.author_name}</span>
                <span className={styles.date}>{moment(props.data.unix_timestamp).format("DD MMMM YYYY [at] HH:mm")}</span>
            </div>
            <div className={styles.thread}>
                {props.data.is_comment && <p>Replying to :</p>}
                <a href={props.data.thread_link ?? ""} target="_blank" rel="noopener noreferrer">{shorttxt(props.data.thread_title ?? "")}</a>
            </div>

            <a href={props.data.post_link ?? ""} target="_blank" rel="noopener noreferrer" className={styles.content}>
                <p>{txt}</p>
            </a>
            {displayShowMore && <div className={styles.showMore} onClick={() => setExpand(!expand)}>{expand ? "Show less ..." : "Show more ..."}</div>}

        </div>
    )
}

export default Post