import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import styled from 'styled-components'
import {
  selectCurrent,
  selectShowHistoryTab,
  setShowHistoryTab,
  selectSmartTrainMode,
  setIsConfirming
} from "../../../../../../app/store/smartTrainSlice"
import Button from "../../../../../reusable/buttons/Button"
import { calculateRelevancyProgress, calculateSentimentProgress } from "./calculateProgress"

const ActionMenuRoot = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
`

function ActionMenu() {
  const dispatch = useDispatch()

  const showHistoryTab = useSelector(selectShowHistoryTab)

  const mode = useSelector(selectSmartTrainMode)
  const current = useSelector(selectCurrent)
  const isCreatingModel = current.shouldCreateModel
  const hasHistory = current.tagHistory.length > 0
  const tagHistoryProgress = mode === "relevancy" ? calculateRelevancyProgress(current.tagHistory) : calculateSentimentProgress(current.tagHistory)

  const trainModel = () => {
    dispatch(setShowHistoryTab(false))
    dispatch(setIsConfirming(true))
  }

  return (
    <ActionMenuRoot>
      <Button
        size="small"
        variant="tertiary"
        onClick={() => dispatch(setShowHistoryTab(!showHistoryTab))}
      >
        <p>{showHistoryTab ? "Hide" : "Show"} history</p>
      </Button>
      <Button
        size="small"
        disabled={isCreatingModel ? tagHistoryProgress < 1 : !hasHistory}
        onClick={trainModel}
      >
        {isCreatingModel ? "Activate" : "Confirm session"}
      </Button>
    </ActionMenuRoot>
  )
}

export default ActionMenu
