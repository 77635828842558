import React, { useState } from "react";
import { Medium } from "./MediumFilter";
import styled, { css } from "styled-components";
import newsDisplayData from "../../news_zh-hk_data.json";
import styles from "./MediumFilterItem.module.scss";

interface MediumFilterItemProp {
  medium: Medium;
  index: number;
  handleMediumClick: (mediumIndex: number) => void;
  handleSiteClick: (medium: string, site: string) => void;
}

export const baseWidth = "200px";
export const baseHeight = "35px";
export const baseFontSize = "0.9rem";

interface NewsDisplayData {
  [key: string]: string;
}

const newDataObj: NewsDisplayData = newsDisplayData;



const MediumFilterItemRoot = styled.div`
  width: 100%;
  font-size: ${baseFontSize};
`;

const MediumFilterItemContent = styled.div`
  min-height: ${baseHeight};
  width: 100%;
  padding: 0.4rem 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const CheckBox = styled.input`
  cursor: pointer;
  margin-right: 10px;
  width: ${baseFontSize};
  height: ${baseFontSize};
`;

const SubFilterMenu = styled.div`
  width: 100%;
  background-color: #f5f5f5;
`;

const SubFilterItem = styled.div`
  width: 100%;
  min-height: ${baseHeight};
  width: 100%;
  padding: 0.4rem 1.5rem;
  cursor: pointer;
`;

const ToggleIcon = styled.i<{ collapsed: boolean }>`
  transition: all 0.2s ease;
  ${(props) =>
    props.collapsed &&
    css`
      transform: rotate(-180deg);
    `}
`;

function MediumFilterItem({
  medium,
  index,
  handleMediumClick,
  handleSiteClick,
}: MediumFilterItemProp) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <MediumFilterItemRoot>
      <MediumFilterItemContent
        onClick={() => {
          handleMediumClick(index);
        }}
      >
        <div>
          <CheckBox
            type="checkbox"
            checked={medium.checked}
            key={medium.label}
            readOnly
          />
          <span>{medium.label}</span>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setCollapsed(!collapsed);
          }}
        >
          <ToggleIcon className="fas fa-chevron-down" collapsed={collapsed} />
        </div>
      </MediumFilterItemContent>
      {collapsed && (
        <SubFilterMenu>
          {medium.sites.map((site) => (
            <SubFilterItem
              key={site.value}
              onClick={(e) => {
                e.stopPropagation();
                handleSiteClick(medium.value, site.value);
              }}
            >
              <CheckBox
                type="checkbox"
                checked={site.checked}
                key={medium.label}
                readOnly
              />
              <span>{site.label}</span>{" "}
              {medium.label === "News" && newDataObj[site.label] && (
                <span className={styles?.["site-display-name"]}>
                  ({newDataObj[site.label]})
                </span>
              )}
            </SubFilterItem>
          ))}
        </SubFilterMenu>
      )}
    </MediumFilterItemRoot>
  );
}

export default MediumFilterItem;
