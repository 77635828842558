import styled, { css } from "styled-components"

const gap = "1rem"

export const OverviewLayoutContainer = styled.main`
	width: 100%;
	max-width: 95vw;
	display: flex;
	flex-direction: column;
	row-gap: ${gap};
	padding: 3rem 5rem;
`

export const RowContainer = styled.div`
	display: flex;
	gap: ${gap};
	flex-wrap: nowrap;
	width: 100%;
	align-items: center;

	@media (max-width: 1024px) {
		flex-wrap: wrap;
	}
`

export const LayoutGrid = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	gap: ${gap};

	> div:first-child {
		display: flex;
		flex-direction: column;
		flex: 1 0 0;
		row-gap: ${gap};
	}

	> div:nth-child(2) {
		flex: 1 0 0;
	}
	@media (max-width: 1024px) {
		flex-wrap: wrap;
	}
`

export const SectionRoot = styled.section`
	width: 100%;
	background-color: #ffffff;
	border-radius: 0.5rem;
	height: 100%;
`

export const SectionHeader = styled.header<{ borderBottom?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-content: center;
	padding: 1rem 1.5rem 0.5rem 1.5rem;
	${(props) =>
		props.borderBottom &&
		css`
			border-bottom: 1px solid #f2f5fa;
		`}
	> h3 {
		color: #000000;
		font-weight: 600;
	}

	> div {
		display: flex;
		justify-content: flex-end;
		column-gap: 1rem;
	}
`

export const SectionBody = styled.main<{ expanded?: boolean }>`
	width: 100%;
	padding: ${(props) =>
		props.expanded ? "0rem" : "0.5rem 1.5rem 1rem 1.5rem"};
	/* padding: ${(props) =>
		props.expanded ? "0.5rem 0rem 1rem 0rem" : "0.5rem 1.5rem 1rem 1.5rem"}; */

	p {
		color: #516380;
		font-size: 14px;
	}
`

export const TopicSummaryRoot = styled(SectionRoot)`
	flex: 1 0 0;
	min-height: 14rem;

	@media (max-width: 1024px) {
		width: 100%;
	}
`

export const PostCountChartRoot = styled(SectionRoot)`
	flex: 2 0 0;
`

export const SentimentChartRoot = styled(SectionRoot)`
	flex: 1 0 0;
`
