import { formatDate } from "../../../../../../app/tools/task"

export const setDateMinLimit = (unix: number | null) => {
  if (unix) {
    return formatDate(new Date(unix))
  } else {
    return undefined
  }
}

export const setDateMaxLimit = (unix: number | null) => {
  if (unix) {
    return formatDate(new Date(unix))
  } else {
    return formatDate(new Date())
  }
}