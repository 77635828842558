import React from 'react'
import styled from 'styled-components'
import { usePostsStore } from '../../../postStore'
import { FeedbackRowContainer, FeedbackButton } from './styled'
import UseSaveHook from '../UseSaveHook'

const FeedbackRow = ({topicId}: FeedbackRowProps) => {
  const {
    isLoadingDetailed,
    toggleOpen,
    labelId,
    frequency,
    allTopicData
  } = usePostsStore(state => ({
    isLoadingDetailed: state.isLoadingDetailed,
    toggleOpen: state.toggleOpen,
    labelId: state.labelId,
    frequency: state.frequency,
    allTopicData: state.allTopicData
  }))

  const {isSubmitted, setIsSubmitted, feedbackMutation} = UseSaveHook()

  const clickedTopicData = allTopicData?.find((item) => item.briefData.topic_id === topicId)
  const word = `${clickedTopicData?.briefData.data.word} ` || ""
  const words = clickedTopicData?.briefData.data.related_word.join(" ") || ""

  const submitPositiveFeedback = () => {
    feedbackMutation.mutate({
      topic_id: topicId,
      label_id: labelId || 1,
      freq: frequency,
      words: word + words,
      feedback: ["positive"]
    })
    setIsSubmitted(true)
    setTimeout(() => {
      setIsSubmitted(false)
    }, 1500)
  }


  return (
    <FeedbackRowContainer>
      {
        !isLoadingDetailed && (
          <>
            {
              isSubmitted && (<ThankYouText>Thank you for your feedback!</ThankYouText>)
            }
            <p>Was it helpful?</p>
            <FeedbackButton 
              variant="tertiary"
              size="small"
              icon={<i className="far fa-thumbs-up" />}
              onClick={submitPositiveFeedback}
            >
              <span>Yes</span>
            </FeedbackButton>
            <FeedbackButton 
              variant="tertiary"
              size="small"
              icon={<i className="far fa-thumbs-down" />}
              onClick={toggleOpen}
            >
              No
            </FeedbackButton>
          </>
        )
      }
    </FeedbackRowContainer>
  )
}

export default FeedbackRow

interface FeedbackRowProps {
  topicId: string
}

const ThankYouText = styled.p`
  color:#29D1D7;
  font-style: italic;
  margin-right: 1rem;
`