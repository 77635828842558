import React from "react"
import Header from "./header"
import { useDispatch, useSelector } from "react-redux"
import {
    setDisplayOvdata,
    selectDisplayOvdata,
    selectOvdata,
    selectFetchingOVdata,
    clearModalData,
    setSentimentType,
} from "../../../../../app/store/overviewSlice"
import Loader from "../../../../reusable/loader"
import Posts from "./posts"
import styles from "./ovdata.module.scss"
import EmptyStatePlaceholder from "../components/EmptyStatePlaceholder"
import { FeedData } from "../../../../../app/interfaces/feed"

// Overview post data displayer
const OverviewData = () => {
    const dispatch = useDispatch()
    const displayData = useSelector(selectDisplayOvdata)
    const fetching = useSelector(selectFetchingOVdata)
    const data = useSelector(selectOvdata)

 
    // const findDistinctObjects = () => {
    //     const distinctValues = new Set(data?.map(item => item["author_name"]));
    //     console.log("distinct values",distinctValues)
    //     return [...distinctValues];
    //   }
   
    function findDistinctObjects() {
        const distinctValues = new Set();
        const result:FeedData[] = [];
      
        data?.forEach(item => {
          const propValue = item["post_message"];
      
          if (!distinctValues.has(propValue)) {
            distinctValues.add(propValue);
            result.push(item);
          }
        });
        return result;
      }




    return (
        <div>
            <div
                className={"modal" + (displayData !== null ? " is-active" : "")}
            >
                <div className="modal-background"></div>
                <div className="modal-card">
                    <Header
                        onClose={() => {
                            dispatch(clearModalData())
                            dispatch(setDisplayOvdata(null))
                            dispatch(setSentimentType(null))
                        }}
                    />
                    <section className={`modal-card-body ${styles.modal}`}>
                        {fetching || !data ? <Loader /> : <Posts data={findDistinctObjects()} />}
                        {!fetching && data && data.length === 0 && <EmptyStatePlaceholder />}
                    </section>
                    {/* <footer className="modal-card-foot"></footer> */}
                </div>
            </div>
        </div>
    )
}

export default OverviewData
