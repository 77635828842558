import styled from "styled-components"

export const Container = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Text = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
`