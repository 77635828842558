export const parseQueryString = (qs: string) => {
	const splitByAmpersand = qs.split("&")
	let temp: { [key: string]: string } = {}
	for (let i = 0; i < splitByAmpersand.length; i++) {
		if (i === 0) {
			temp[splitByAmpersand[i].split("=")[0].slice(1)] =
				splitByAmpersand[i].split("=")[1]
		} else {
			temp[splitByAmpersand[i].split("=")[0]] =
				splitByAmpersand[i].split("=")[1]
		}
	}
	return temp
}
