import * as React from "react";

function SvgSentimentScaleAngry(props:any) {
  return (
    <svg
      width={74}
      height={45}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        opacity={0.4}
        x={15}
        y={17}
        width={59}
        height={7}
        rx={3.5}
        fill="url(#Sentiment_ScaleAngry_svg__paint0_linear)"
      />
      <g filter="url(#Sentiment_ScaleAngry_svg__filter0_d)">
        <circle
          cx={22.5}
          cy={20.5}
          r={12.5}
          fill="url(#Sentiment_ScaleAngry_svg__paint1_linear)"
        />
        <circle cx={22.5} cy={20.5} r={12} stroke="#F4A77C" />
      </g>
      <path
        d="M25.13 26.42c-.164-.358-1.29-1.315-2.961-1.315-1.67 0-2.798.957-2.96 1.316"
        stroke="#516380"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.527 19.512a1.388 1.388 0 00-.264.81c0 .842.774 1.524 1.728 1.524.881 0 1.609-.581 1.715-1.333l-3.18-1.001zM25.129 20.659c.07.27.224.526.462.737.675.595 1.77.595 2.445 0 .623-.55.67-1.414.144-2.012L25.13 20.66z"
        fill="#516380"
      />
      <defs>
        <linearGradient
          id="Sentiment_ScaleAngry_svg__paint0_linear"
          x1={15}
          y1={20.5}
          x2={74}
          y2={20.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F75E5E" />
          <stop offset={1} stopColor="#5CE191" />
        </linearGradient>
        <linearGradient
          id="Sentiment_ScaleAngry_svg__paint1_linear"
          x1={22.5}
          y1={8}
          x2={22.5}
          y2={33}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7A7A" />
          <stop offset={0.719} stopColor="#FFB244" />
          <stop offset={1} stopColor="#FFD366" />
        </linearGradient>
        <filter
          id="Sentiment_ScaleAngry_svg__filter0_d"
          x={0}
          y={0}
          width={45}
          height={45}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgSentimentScaleAngry;
