import * as React from "react"
import { IconProp } from "./interface"

function FilterIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg viewBox="0 0 141.73 141.73" height={height} width={width}>
      <path
        d="M135.26.24a6.08 6.08 0 016.1 4.14 6.12 6.12 0 01-1.36 7.18l-51.29 51.1V135a5.88 5.88 0 01-3.6 5.8 6.3 6.3 0 01-6.93-.28L56 125.09a6.81 6.81 0 01-2.77-5.53v-56.9L2 11.56A6.13 6.13 0 01.59 4.38 6.07 6.07 0 016.68.24z"
        fill={fill}
        data-name="\u5716\u5C64 2"
      />
    </svg>
  )
}

export default FilterIcon