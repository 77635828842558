import React from 'react'
import { IconProp } from "./interface"

function NegativeFaceIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      id="Negative_face_(1)_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24.4 24.4"
      xmlSpace="preserve"
      height={height}
      width={width}
    >
      <style>{".Negative_face_(1)_svg__st3{fill:#516380}"}</style>
      <circle cx={12.2} cy={12.2} r={12.2} fill="#ffb966" />
      <circle
        cx={12.2}
        cy={12.2}
        r={11.7}
        fill="none"
        stroke="#ed9c52"
        strokeOpacity={0.5}
      />
      <path
        d="M18 17.3c-.3-.7-2.5-2.6-5.8-2.6s-5.5 1.9-5.8 2.6"
        fill="none"
        stroke="#516380"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle className="Negative_face_(1)_svg__st3" cx={8} cy={10} r={1.6} />
      <path
        className="Negative_face_(1)_svg__st3"
        d="M17.3 10c0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6.9-.1 1.6.7 1.6 1.6z"
      />
    </svg>
  )
}

export default NegativeFaceIcon
