import React from 'react'
import { TooltipProps } from "recharts"
import { siteicon } from "../../../../../../../app/tools/display"
import { LineGraphViewBY } from "../index"
import { uuidv4 } from "usefuljs"
import { DateRange } from "../../../../../../../app/interfaces/overview"
import { TaskType } from "../../../../editor/interface"
import { deduplicateThreads, generateAdHocTimeRangeLabel, generateLiveTimeRangeLabel } from "./helper"
import { ContentContainer, ThreadItem, ThreadsContainer, TimeRangeContainer, TooltipContainer, TooltipSummary } from "./styled"
import { Interval } from "../../../interface"

interface CustomTooltipProp extends TooltipProps {
  viewBy: LineGraphViewBY
  dateRange: DateRange
  interval: Interval | null
  feedType: TaskType | null
}

function CustomTooltip(props: CustomTooltipProp) {

  const timestamp = props.payload !== undefined ? props.payload[0]?.payload?.point : ""

  const commentCount = props.payload !== undefined ? props.payload[0]?.payload?.commentCount : ""
  const threadCount = props.payload !== undefined ? props.payload[0]?.payload?.threadCount : ""
  const postCount = props.payload !== undefined ? props.payload[0]?.payload?.postCount : ""

  const topThreads: {
    site: string
    threadLink: string
    threadTitle: string
  }[] = props.payload !== undefined ? deduplicateThreads(props.payload[0]?.payload?.topThreads) : []

  function generateSummary() {
    switch (props.viewBy) {
      case "Threads":
        return <p>There are <b>{threadCount}</b> threads with {commentCount} comments.</p>
      case "Comments":
        return <p>There are <b>{commentCount}</b> comments from {threadCount} threads.</p>
      case "Threads and comments":
        return <p>There are <b>{postCount}</b> posts with {threadCount} threads and {commentCount} comments.</p>
    }
  }

  return (
    <TooltipContainer>
      <TimeRangeContainer>
        {props.feedType === 0 ? generateLiveTimeRangeLabel(timestamp, props.dateRange) : generateAdHocTimeRangeLabel(timestamp, props.interval)}
      </TimeRangeContainer>
      <ContentContainer>
        <TooltipSummary>
          {generateSummary()}
        </TooltipSummary>
        {commentCount !== 0 && (
          <ThreadsContainer>
            <p>Top {topThreads?.length ?? 0} threads with most comments:</p>
            {topThreads?.map(thread => (
              <ThreadItem key={uuidv4()}>
                <img src={siteicon(thread.site, "News")} alt={`${thread.site} logo`} />
                <p>{thread.threadTitle}</p>
              </ThreadItem>
            ))}
          </ThreadsContainer>
        )}
      </ContentContainer>
    </TooltipContainer>
  )
}

export default CustomTooltip
