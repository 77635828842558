import React from 'react'
import { SectionBody, SectionHeader, SectionRoot } from '../styled'
import BlingSvg from '../../../reusable/icons/Bling'
import TopicList from './TopicList'
import { usePostsStore } from '../../postStore'


function RelatedTopics() {
  const {overviewPost} = usePostsStore(state => ({
    overviewPost : state.overviewPost,
  }))

    return (
        <SectionRoot>
            <SectionHeader borderBottom={true}>
                <h3><BlingSvg />{` `}Related Topics</h3>
            </SectionHeader>
            <SectionBody expanded={true}>
                <TopicList topics={overviewPost?.sub_topics || []} />
            </SectionBody>
        </SectionRoot>
    )
}

export default React.memo(RelatedTopics)
