import React from 'react'
import SentimentScaleAngry from '../../../../../reusable/icons/SentimentScaleAngry'
import SentimentScaleExcited from '../../../../../reusable/icons/SentimentScaleExcited'
import SentimentScaleHappy from '../../../../../reusable/icons/SentimentScaleHappy'
import SentimentScaleNeutral from '../../../../../reusable/icons/SentimentScaleNeutral'
import SentimentScaleSad from '../../../../../reusable/icons/SentimentScaleSad'
import { AiResults } from '../../../../interface'
import { SentimentScaleContainer } from '../../styled'

const SentimentScale = ({sentiment}: SentimentScaleProps) => {

  const totalSentiment = sentiment ? Object.values(sentiment.sentiment).reduce((a,b)=>a+b,0) : {}
  const positiveSentimentPercentage = (sentiment && sentiment.sentiment[1] / totalSentiment * 100) || 0
  const negativeSentimentPercentage = (sentiment && sentiment.sentiment[-1] / totalSentiment * 100) || 0
  
  const sentimentScore = negativeSentimentPercentage >= 50 ? -2:(negativeSentimentPercentage >= 35 ? -1: (positiveSentimentPercentage >= 50 ? 2: (positiveSentimentPercentage >=35 ? 1 : 0)))

  return (
    <>
      {sentimentScore === -2 && (
        <SentimentScaleContainer>
          <SentimentScaleAngry />
        </SentimentScaleContainer>
       )}
      {sentimentScore === -1 && (
        <SentimentScaleContainer>
          <SentimentScaleSad />
        </SentimentScaleContainer>
      )}
      {sentimentScore === 0 && (
        <SentimentScaleContainer>
          <SentimentScaleNeutral />
        </SentimentScaleContainer>
      )}
      {sentimentScore === 1 && (
        <SentimentScaleContainer>
          <SentimentScaleHappy />
        </SentimentScaleContainer>
      )}
      {sentimentScore === 2 && (
        <SentimentScaleContainer>
          <SentimentScaleExcited />
        </SentimentScaleContainer>
      )}
    </>
  )
}

export default SentimentScale

interface SentimentScaleProps{
  sentiment: AiResults
}