import React from 'react'
import { IconProp } from "./interface"

function SmartFeedIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.506 7.873L15.073 1.07a3 3 0 00-3.278 0L1.362 7.873A3 3 0 000 10.386v10.228a3 3 0 001.36 2.513l10.434 6.804a3 3 0 003.278 0l10.433-6.804a3 3 0 001.361-2.513V10.386a3 3 0 00-1.361-2.513z"
        fill="#F7CE64"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.203 9.3h13.23V.58c-.57 0-1.14.162-1.639.487L1.361 7.873A3 3 0 00.203 9.299z"
        fill="#FFEAB1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.434.581V9.3h13.23a3.005 3.005 0 00-.445-.776L14.799.91A2.997 2.997 0 0013.434.58z"
        fill="#FFCC4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.203 21.7H6.2L.121 9.542c-.08.27-.121.554-.121.844v10.228c0 .377.07.744.203 1.086z"
        fill="#F8BE2F"
      />
      <path d="M13.433 9.3l-7.75 12.4L0 9.3h13.433z" fill="#FFCC4D" />
      <path d="M5.167 21.7l8.267-12.4 7.233 12.4h-15.5z" fill="#E8B027" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.663 9.3h-13.23L20.4 21.7l6.345-12.164a2.977 2.977 0 00-.08-.236z"
        fill="#F8BE2F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.528 9.002l-5.861 12.7h5.996c.133-.342.204-.71.204-1.087V10.387c0-.49-.12-.964-.339-1.385z"
        fill="#FFCC4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.663 21.7h-5.996l-6.751 8.68c.304-.05.602-.147.883-.29l11.42-7.613c.186-.236.336-.497.444-.777z"
        fill="#D89E10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.516 30.216l6.15-8.516H5.685l7.75 8.718a3 3 0 001.082-.202z"
        fill="#E1A40B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.433 30.419L5.683 21.7H.203c.1.258.236.5.402.722l12.39 7.965c.145.02.291.032.438.032z"
        fill="#D89E10"
      />
      <path
        d="M11.085 20.98c0 .181.03.363.147.544l.499.755c.176.272.47.453.792.453h1.79a.958.958 0 00.794-.453l.499-.755c.088-.181.146-.363.146-.544v-1.178h-4.667v1.178zm-2.818-8.43c0 1.36.47 2.568 1.262 3.505.499.574 1.233 1.783 1.526 2.78v.03h4.727v-.03c.293-.997 1.027-2.206 1.526-2.78A5.39 5.39 0 0018.6 12.55c0-2.93-2.349-5.318-5.196-5.318-2.994.03-5.137 2.508-5.137 5.318zm5.166-2.417c-1.32 0-2.348 1.088-2.348 2.417a.49.49 0 01-.47.484.47.47 0 01-.47-.484c0-1.843 1.468-3.384 3.288-3.384.235 0 .47.242.47.484a.49.49 0 01-.47.483z"
        fill="#fff"
      />
    </svg>
  )
}

export default SmartFeedIcon
