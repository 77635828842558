import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import CrossIcon from "../../../../../reusable/icons/CrossIcon"
import SkipIcon from "../../../../../reusable/icons/SkipIcon"
import TickIcon from "../../../../../reusable/icons/TickIcon"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrent, selectSmartTrainMode, setCurrentDisplayPost, setIsEditingHistory } from "../../../../../../app/store/smartTrainSlice"
import { uuidv4 } from "usefuljs"
import PositiveFaceIcon from "../../../../../reusable/icons/PositiveFaceIcon"
import NeutralFaceIcon from "../../../../../reusable/icons/NeutralFaceIcon"
import NegativeFaceIcon from "../../../../../reusable/icons/NegativeFaceIcon"
import { ModelType } from "../../../../../../app/interfaces/smartTrain"

const HistoryRoot = styled.div`
  width: 320px;
  padding: 1rem;
  padding-right: 0px;
  border-left: 1px solid #E5E5E5;
`

const Label = styled.h3`
  color: #516380;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1rem;
`

const HistoryList = styled.ul`
  height: 460px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #C4C4C4;
  }
`

const HistoryItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  background-color: white;
  cursor: pointer;
  padding-right: 16px;

  p {
    width: 250px;
    color: #516380;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

function generateRelevancyStatusLabel(status: number) {
  switch (status) {
    case 0:
      return <TickIcon fill="#29D1D7" />
    case 1:
      return <CrossIcon fill="#FF6E86" variant="fat" />
    case -10:
    default:
      return <SkipIcon fill="#000000" height="15px" width="15px" />
  }
}

function generateSentimentStatusLabel(status: number) {
  switch (status) {
    case 1:
      return <PositiveFaceIcon />
    case 0:
      return <NeutralFaceIcon />
    case -1:
      return <NegativeFaceIcon />
    case -10:
    default:
      return <SkipIcon fill="#000000" height="15px" width="15px" />
  }
}

function generateStatusLabel(mode: ModelType, tag: number) {
  switch (mode) {
    case "relevancy":
      return generateRelevancyStatusLabel(tag)
    case "sentiment":
      return generateSentimentStatusLabel(tag)
  }
}

function History() {
  const dispatch = useDispatch()
  const props = useSpring({ opacity: 1, from: { opacity: 0 } })

  const mode = useSelector(selectSmartTrainMode)
  const current = useSelector(selectCurrent)
  const tagHistory = current.tagHistory

  return (
    <animated.div style={props}>
      <HistoryRoot>
        <Label>History</Label>
        <HistoryList>
          {tagHistory.map(record => (
            <HistoryItem key={uuidv4()} onClick={() => {
              dispatch(setCurrentDisplayPost(record.data))
              dispatch(setIsEditingHistory(true))
            }}>
              <p>{record.data.post_message}</p>
              {generateStatusLabel(mode, record.tag)}
            </HistoryItem>
          ))}
        </HistoryList>
      </HistoryRoot>
    </animated.div>

  )
}

export default History
