import React, { memo } from "react"
import styles from "./filterbar.module.scss"
import styled from 'styled-components'
import ExportBtn from "../export"
import SortByFilter from "./SortByFilter"
import PostTypeFilter from "./PostTypeFilter"
import MediumFilter from "./MediumFilter/MediumFilter"
import TimeRangeFilters from './TimeRangeFilters'
import MoreActions from "./MoreActions"
import SmartTrainButton from "./SmartTrainButton"
import SmartFilterSwitch from "./SmartFilterSwitch"
import { selectHasModel } from "../../../../../app/store/smartTrainSlice"
import { useSelector } from "react-redux"
import DateRangeFilter from "../../DateRangeFilter"
import { selectFeedType } from "../../../../../app/store/feedSlice"
import HighlightKeywordSwitch from "./HighlightKeywordSwitch"

const FiltersWrapper = styled.div`
    display: flex;
`

const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items:center;
    width: 100%;
    max-width: max-content;
    column-gap: 1rem;
`

const FilterBarRoot = styled.div`
    margin-bottom: 1.4rem;
    column-gap: 0.5rem;
`

const SwitchContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    column-gap: 1rem;
`

const FilterBar = memo(() => {
    const hasModel = useSelector(selectHasModel)
    const feedType = useSelector(selectFeedType)

    return (
        <FilterBarRoot>
            <div id={styles.filterbar}>
                <div className={styles.left}>
                    <FiltersWrapper>
                        <SortByFilter />
                        <PostTypeFilter />
                        <MediumFilter />
                    </FiltersWrapper>
                    {feedType === 0 && <TimeRangeFilters />}
                    {feedType === 1 && <DateRangeFilter type="posts" />}

                </div>
                <RightContainer>
                    <ExportBtn />
                    {feedType !== 1 && <SmartTrainButton />}
                    <MoreActions />
                </RightContainer>
            </div>
            <SwitchContainer>
                {hasModel && <SmartFilterSwitch />}
                <HighlightKeywordSwitch />
            </SwitchContainer>
        </FilterBarRoot>

    )
})

export default FilterBar