import React from 'react'
import { useDispatch } from 'react-redux'
import { fetchSentimentPostData } from '../../../../../../../app/store/overviewSlice'
import NegativeFaceIcon from "../../../../../../reusable/icons/NegativeFaceIcon"
import NeutralFaceIcon from "../../../../../../reusable/icons/NeutralFaceIcon"
import PositiveFaceIcon from "../../../../../../reusable/icons/PositiveFaceIcon"
import Progress from "../../../../../../reusable/utils/ProgressBar"
import { IconContainer, PercentageLabel, SentimentIndicatorRoot } from "./styled"

interface SentimentIndicatorProp {
  type: "positive" | "neutral" | "negative"
  value: number
}

function SentimentIndicator({ type, value }: SentimentIndicatorProp) {

  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(fetchSentimentPostData(type))
  }
  
  return (
    <SentimentIndicatorRoot onClick={handleClick}>
      <IconContainer>
        {type === "positive" && <PositiveFaceIcon height="1.5rem" width="1.5rem" />}
        {type === "neutral" && <NeutralFaceIcon height="1.5rem" width="1.5rem" />}
        {type === "negative" && <NegativeFaceIcon height="1.5rem" width="1.5rem" />}

        <p>{type.charAt(0).toUpperCase() + type.substring(1)}</p>

      </IconContainer>
      <Progress
        value={value || 0}
        hideLabel={true}
        loadingColor={type === "positive" ? "#73D844" : type === "negative" ? "#ED5252" : "#898989"}
        finishedColor={type === "positive" ? "#73D844" : type === "negative" ? "#ED5252" : "#898989"}
        hasHover={true}
        hoverColor={type === "positive" ? "#2EC757" : type === "negative" ? "#D72A2A" : "#737373"}
      />
      <PercentageLabel>{Math.round(value * 100) || 0}%</PercentageLabel>
    </SentimentIndicatorRoot>
  )
}

export default SentimentIndicator

