import React from 'react'
import styled from 'styled-components'
import EmptyDashboardIcon from '../../../reusable/icons/EmptyDashboard'

const EmptyDashboard = ({EmptyText, EmptyContactText}: EmptyDashboardProps) => {
  return (
    <EmptyDashboardContainer>
      <EmptyDashboardIcon />
      <p>
        {EmptyText.split('/n')[0]}
        {EmptyText.split('/n').length > 1 && <><br />{EmptyText.split('/n')[1]}</>}
      </p>
      {
        EmptyContactText && (
          <small>
            Contact us at 3705-1012 or email us via <br />
            <a href="mailto:lenxcare@lenx.ai">lenxcare@lenx.ai</a> to upgrade your plan.
          </small>
        )
      }
    </EmptyDashboardContainer>
  )
}

export default EmptyDashboard

const EmptyDashboardContainer = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 3rem;
  row-gap: 1rem;
  
  >p {
    width:100%;
    text-align: center;
    color:#283044;
    font-weight: 600;
    font-size: 1.25rem;
  }

  a{
    text-decoration: underline;
    color: #00ACEE;
  }
`

interface EmptyDashboardProps{
  EmptyText: string
  EmptyContactText?: boolean
}