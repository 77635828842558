import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import ToTop from "../../../reusable/icons/ToTop"

interface ToTopButtonProp {
  isActive: boolean
}

const Button = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 5px;
  padding: 7px;
  &:focus {
    outline: none;
  }
  ${props => props.isActive ? css`
    cursor: pointer;
    background-color: #516380;
    &:hover {
    background-color: #304877;
    }
  ` : css`
    background-color: #DADADA;
  `};

`

const ToTopButton = memo<ToTopButtonProp>(({ isActive }) => {
  return (
    <Button isActive={isActive}>
      <ToTop fill={"#FFFFFF"} />
    </Button>
  )
})

export default ToTopButton
