import React, { useEffect, useState } from "react";
import {
  PreviousVerisonsHeader,
  PreviousVersionsMenu,
  PreviousVersionText,
} from "./styled";

import { usePostsStore } from "../../../postStore";

import { versiontimestampList } from "../../../helperFn/groupVersionData";
import { sortBriefTopicDataInitial } from "../../../helperFn/sortTopicData";

// import icons
import { ReactComponent as CloseSvg } from "../../../../../assets/icons/closeIcon.svg";
import Timeframe from "./Timeframe";
import VersionItem from "./VersionItem";

const PreviousVersions = ({ refetchDetail }: PreviousVersionsProps) => {
  const {
    versionList,
    setSelectedVersion,
    selectedVersion,
    setAllTopicData,
    setSelectedTrendId,
  } = usePostsStore((state) => ({
    versionList: state.versionList,
    setSelectedVersion: state.setSelectedVersion,
    selectedVersion: state.selectedVersion,
    setAllTopicData: state.setAllTopicData,
    setSelectedTrendId: state.setSelectedTrendId,
  }));

  console.log("Version List", versionList);

  return (
    <PreviousVersionsMenu>
      <PreviousVerisonsHeader>
        <PreviousVersionText>Previous Versions</PreviousVersionText>
        <CloseSvg />
      </PreviousVerisonsHeader>
      {versionList?.versiontimestampList.map((versionTimestamp: string) => {
        if (versionList?.[versionTimestamp]?.length) {
          return (
            <>
              <Timeframe title={versionTimestamp} />
              {versionList?.[versionTimestamp]?.map(
                (version: any, index: number) => {
                  return (
                    <VersionItem
                      version={version}
                      key={index}
                      isActive={selectedVersion === version?.gen_at}
                      onClick={() => {
                        setSelectedVersion(version?.gen_at);
                        setAllTopicData(
                          sortBriefTopicDataInitial(version?.results?.topics) ||
                            null
                        );
                        setSelectedTrendId(version?.trend_id);
                        refetchDetail();
                      }}
                      isCurrentversion={
                        index === 0 && version?.freq === "Latest"
                      }
                    />
                  );
                }
              )}
            </>
          );
        }
      })}
    </PreviousVersionsMenu>
  );
};

interface PreviousVersionsProps {
  refetchDetail?: any;
}

export default PreviousVersions;
