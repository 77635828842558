import React, { useEffect, useState } from 'react'
import { SectionBody, SectionHeader, SectionRoot } from '../styled'
import SingleSelect from '../../../reusable/inputs/select/singleSelect'
import AuthorList from './AuthorList'
import MultiSelect from '../../../reusable/inputs/select/multiSelect'
import { AuthorsForTopAuthors, MediumKey, TopAuthorsViewByFilter } from '../../interface'
import { usePostsStore } from '../../postStore'
import { ColumnHeader } from './styled'
import { sortAuthorByNumberOfPosts } from '../../helperFn/sortSiteRankingFn'

function TopAuthors() {
    const {overviewPost} = usePostsStore(state => ({
        overviewPost : state.overviewPost,
    }))
    
    const [viewBy, setViewBy] = useState<keyof TopAuthorsViewByFilter>("engagement")
    const [medium, setMedium] = useState<MediumKey[]>([])
    const [authors, setAuthors] = useState<AuthorsForTopAuthors[]>()

    const handleMediumPick = (value: MediumKey) => {
        if (medium.includes(value)) {
            setMedium(medium.filter(item => item !== value))
        } else {
            setMedium([...medium, value])
        }
    }

    const mappedMediumLabel = (overviewPost && 
        Object.keys(overviewPost?.aggregations?.top_authors?.medium_author).map((medium) => ({
            label: medium, 
            value: medium as MediumKey
        }))) || []

    const viewOptions = [
        { label: "Total Engagement", value: "engagement" },
        { label: "No. of threads and comments", value: "total" },
        { label: "No. of threads", value: "threads" },
        { label: "No. of comments", value: "comments" },
    ]

    const selectedLabel = viewOptions.filter((option)=>(option.value === viewBy))[0].label

    useEffect(()=>{
        if(medium.length === 0 || medium.length === mappedMediumLabel.length){
            const allMediumAllAuthors = (overviewPost && 
                overviewPost?.aggregations?.top_authors?.[viewBy]?.map((author)=>({
                    name: author.author_name,
                    avatar: author.author_image || author.author_img || null,
                    count: author.count,
                    site: author.site,
                })).sort(sortAuthorByNumberOfPosts)) || []
            setAuthors(allMediumAllAuthors)
        } else {
            const selectedMediumAuthors = (overviewPost && medium.map((medium)=>(
                overviewPost?.aggregations?.top_authors?.medium_author?.[medium]?.[viewBy].map((author)=>({
                    name: author.author_name,
                    avatar: author.author_image || author.author_img || null,
                    count: author.count,
                    site: author.site,
                }))
            ))[0].sort(sortAuthorByNumberOfPosts)) || []
            setAuthors(selectedMediumAuthors)
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[medium, overviewPost, viewBy])


    return (
        <SectionRoot>
            <SectionHeader>
                <h3>Top Author</h3>
                <div>
                    <MultiSelect
                        id="top-author-view-by-medium"
                        options={mappedMediumLabel}
                        values={medium}
                        setValues={handleMediumPick}
                        width={120}
                    />
                </div>
                <div>
                    <SingleSelect
                        id="top-author-view-by"
                        options={viewOptions}
                        value={selectedLabel}
                        setValue={(value) => setViewBy(value as keyof TopAuthorsViewByFilter)}
                        width={180}
                        label="View by:"
                    />
                </div>
            </SectionHeader>
            <SectionBody expanded={true}>
                <ColumnHeader>
                    <div style={{flex:'4 0 0'}}>Author</div>
                    <div style={{flex:'3 0 0'}}>Site</div>
                    <div style={{flex:'2 0 0', textAlign:'right'}}>No. of posts</div>
                </ColumnHeader>
                <AuthorList authors={authors} />
            </SectionBody>
        </SectionRoot>
    )
}

export default React.memo(TopAuthors)
