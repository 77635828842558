import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { setShowSmartTrainManager } from "../../../../../app/store/smartTrainSlice"
import { selectSubscriptedToSmartTrain } from "../../../../../app/store/userSlice"
import IconButton from "../../../../reusable/buttons/IconButton"
import SmartTrainIcon from "../../../../reusable/icons/SmartTrainIcon"

export const StyledIconButton = styled(IconButton)`

  &:hover {
    background-color: white;
  }
`

function SmartTrainButton() {
  const dispatch = useDispatch()

  const isSubscribedToSmartTrain = useSelector(selectSubscriptedToSmartTrain)

  const handleClick = () => {
    if (isSubscribedToSmartTrain) {
      dispatch(setShowSmartTrainManager(true))
    }
  }

  return (
    <>
      <div data-tip={isSubscribedToSmartTrain ? "Customized AI Tagging" : "Upgrade your plan to enable smart feed"}>
        <StyledIconButton onClick={handleClick} >
          <SmartTrainIcon height="2rem" width="2rem" fill={isSubscribedToSmartTrain ? "#516380" : "#DADADA"} />
        </StyledIconButton>
      </div>
    </>
  )
}

export default SmartTrainButton
