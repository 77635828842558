import React from 'react'
import styled from 'styled-components'
import ThreadRow from '../../../feed/task/overview/gadgets/topthreads/ThreadRow'
import { ThreadForTopThreads } from '../../interface'

interface ThreadListProp {
  threads: ThreadForTopThreads[],
}

const ListContainer = styled.ul`
  width: 100%;
  height: 120vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

function ThreadList({ threads }: ThreadListProp) {
  return (
    <ListContainer>
      {threads.map((thread,i) => (
        <a href={thread.url} key={i} target="_blank" rel="noopener noreferrer">
          <div>
            <ThreadRow
              title={thread.title}
              site={thread.site}
              count={thread.count}
            />
          </div>
        </a>
      ))}
    </ListContainer>
  )
}

export default ThreadList
