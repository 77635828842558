import React, { useState } from 'react'
import ThreeDotsIcon from "../../../../../reusable/icons/ThreeDotsIcon"
import ActionMenuContent from "./ActionMenuContent"
import { MoreActionsRoot, ActionMenu, StyledIconButton } from "./styled"

function MoreActions() {
  const [show, setShow] = useState(false)

  const toggleMenu = () => {
    if (!show) {
      document.addEventListener('click', closeMenu)
      setShow(true)
    }
  }

  const closeMenu = (e: any) => {
    if (!e.target.closest(".dropdownMenu")) {
      setShow(false)
      document.removeEventListener('click', closeMenu)
    }
  }

  return (
    <MoreActionsRoot>
      <StyledIconButton onClick={toggleMenu}>
        <ThreeDotsIcon fill="#516380" />
      </StyledIconButton>
      {show && (
        <ActionMenu className="dropdownMenu">
          <ActionMenuContent/>
        </ActionMenu>
      )}
    </MoreActionsRoot>
  )
}

export default MoreActions
