import React from 'react'
import { IconProp } from "./interface"

function LightBulbIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      width={width} 
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={9} cy={9} r={9} fill="#BDBDBD" />
      <path
        d="M6.781 13.15c0 .13.022.258.108.387l.365.537a.705.705 0 00.58.322h1.31a.705.705 0 00.58-.322l.366-.537a.875.875 0 00.107-.387v-.838H6.781v.838zM4.72 7.156c0 .967.343 1.826.924 2.492.365.409.902 1.268 1.117 1.977v.021h3.459v-.021c.215-.709.752-1.568 1.117-1.977.58-.666.945-1.525.945-2.492 0-2.084-1.719-3.781-3.802-3.781-2.192.021-3.76 1.783-3.76 3.781zM8.5 5.438a1.71 1.71 0 00-1.719 1.718.353.353 0 01-.343.344.34.34 0 01-.344-.344A2.416 2.416 0 018.5 4.75a.37.37 0 01.344.344.353.353 0 01-.344.343z"
        fill="#fff"
      />
    </svg>
  )
}

export default LightBulbIcon
