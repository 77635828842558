import React from 'react'
import Skeleton from '../../../../../../../reusable/utils/Skeleton'
import Tag from '../../../../../../components/Tag'
import { Frequency, Results, TopicData } from '../../../../../../interface'
import { SummaryRowContainer, RelatedTagsContainer, AISummaryContainer, SectionHeading, TagsContainer, TopicSummary } from '../styled'

const SummaryRow = ({topicData, frequency}: SummaryRowProps) => {

  return (
    <SummaryRowContainer>
      <RelatedTagsContainer>
        <TagsContainer>
          <SectionHeading>Related Keyword(s):</SectionHeading>
          {topicData.detailData ? (topicData.detailData.results?.[frequency as keyof Results]?.topic?.related_word?.map((topic, i)=>(
            <Tag key={i} topic={topic} />
          ))):(
            <Tag isLoading={true} />
          )
          }
        </TagsContainer>
        {/* <TagsContainer>
          <SectionHeading>Related Brand(s):</SectionHeading>
          {post.relatedBrands.map((brand)=>(
            <TagWrapper key={brand}><Tag>{brand}</Tag></TagWrapper>
          ))}
        </TagsContainer>
        <TagsContainer>
          <SectionHeading>Related Name(s):</SectionHeading>
          {post.relatedNames.map((name)=>(
            <TagWrapper key={name}><Tag>{name}</Tag></TagWrapper>
          ))}
        </TagsContainer> */}
      </RelatedTagsContainer>

      <AISummaryContainer>
        <SectionHeading>Selected Post:</SectionHeading>
          {topicData.detailData ? (
            <TopicSummary>
              { topicData.detailData.results?.[frequency as keyof Results]?.summary.length > 150 ? (
                `${topicData.detailData.results?.[frequency as keyof Results]?.summary.slice(0,150)}...`
              ):(
                topicData.detailData.results?.[frequency as keyof Results]?.summary ? (
                  topicData.detailData.results?.[frequency as keyof Results]?.summary
                ):(
                  <span>Selected post is not available.</span>
                )
              )}
            </TopicSummary>
          ):(
            <div style={{display: 'flex', flexWrap:'wrap'}}>
              <Skeleton width="30%" height="1rem" margin="1rem 1rem 0rem 1rem" />
              <Skeleton width="20%" height="1rem" margin="1rem 1rem 0rem 1rem" />
              <Skeleton width="10%" height="1rem" margin="1rem 1rem 0rem 1rem" />
              <Skeleton width="40%" height="1rem" margin="1rem 1rem 0rem 1rem" />
              <Skeleton width="30%" height="1rem" margin="1rem 1rem 0rem 1rem" />
            </div>
        )}
      </AISummaryContainer>
    </SummaryRowContainer>
  )
}

export default SummaryRow

interface SummaryRowProps {
  topicData: TopicData
  frequency: Frequency
}