import React from 'react'
import { Link, useHistory, useRouteMatch } from "react-router-dom"
import styled from 'styled-components'
import BaseButton from "../../reusable/buttons/BaseButton"
import PlanUsageIcon from "../../reusable/icons/PlanUsageIcon"
// import UserIcon from "../../reusable/icons/UserIcon"

const NavigationSideBar = styled.div`
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  padding: 3rem 0rem;
`

const NavItem = styled(BaseButton) <{ isActive: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
  border-radius: 4px;
  color: ${props => props.isActive ? "#29D1D7" : "#868686"};
  background-color: ${props => props.isActive ? "white" : "transparent"};
  font-size: 1rem;
  width: 200px;
  height: 40px;
  padding-left: 1rem;
  box-shadow: ${props => props.isActive ? "0px 2px 10px rgba(187, 186, 200, 0.3)" : "none"};

  >svg>path {
    fill: ${props => props.isActive ? "#29D1D7" : "#868686"};
  }
`

function NavigationBar() {
  const match = useRouteMatch()
  const { location: { pathname } } = useHistory()
  const [tab] = pathname.split("/").slice(-1)

  return (
    <NavigationSideBar>
      {/* <Link to={`${match.url}/profile`}>
        <NavItem isActive={tab === "profile"} >
          <UserIcon />Profile
        </NavItem>
      </Link> */}
      <Link to={`${match.url}/usage`}>
        <NavItem isActive={tab === "usage"} >
          <PlanUsageIcon /> Page Usage
        </NavItem>
      </Link>
    </NavigationSideBar>
  )
}

export default NavigationBar
