import * as React from "react"
import { IconProp } from "./interface"

function SkipIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 30 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.355 4.415C7.298 1.472 10.847 0 15 0s7.681 1.472 10.585 4.415C28.528 7.32 30 10.847 30 15c0 4.153-1.472 7.702-4.415 10.645C22.68 28.548 19.153 30 15 30c-4.153 0-7.702-1.452-10.645-4.355C1.452 22.702 0 19.153 0 15S1.452 7.319 4.355 4.415zm21.713 9.738c-.201-2.742-1.27-5.08-3.205-7.016-1.936-1.935-4.274-3.004-7.016-3.206-2.742-.241-5.162.404-7.258 1.936L24.133 21.41c1.532-2.097 2.178-4.516 1.936-7.258zM3.872 15.847c.242 2.742 1.33 5.08 3.266 7.016 1.936 1.935 4.274 3.024 7.016 3.266 2.742.202 5.161-.464 7.258-1.996L5.867 8.59c-1.532 2.096-2.198 4.516-1.996 7.258z"
        fill={fill}
      />
    </svg>
  )
}

export default SkipIcon