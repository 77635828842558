import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  selectTaskUnixStart,
  selectTaskUnixEnd,
} from "../../../../app/store/feedSlice";
import { DateRangePicker } from "react-dates";
import { StyledDateRangeFilter } from "./styled";
import {
  fetchOverviewData,
  fetchCompareOverviewData,
} from "../../../../app/store/overviewSlice";
import {
  setFilterUnixEnd,
  setFilterUnixStart,
} from "../../../../app/store/livefeedpost";
import { setOverviewFilter } from "../../../../app/store/overviewSlice";
import { selectDatefrom } from "../../../../app/store/userSlice";

interface DateRangeFilterProp {
  type: "posts" | "overview";
  isCompare?: boolean;
}

const FilterRoot = styled.div`
  height: 40px;
`;

function DateRangeFilter({ type, isCompare }: DateRangeFilterProp) {
  const dispatch = useDispatch();

  const taskUnixStart = useSelector(selectTaskUnixStart);
  const taskUnixEnd = useSelector(selectTaskUnixEnd);
  const dateFromUnixTime = useSelector(selectDatefrom)


  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);



  useEffect(() => {
    if (taskUnixStart && taskUnixEnd) {
      setStartDate(moment(taskUnixStart));
      setEndDate(moment(taskUnixEnd));
    }
    if(taskUnixStart*1000 < dateFromUnixTime){
      const dateFrom = moment(dateFromUnixTime/1000)
      setStartDate(dateFrom);
    }
  }, [taskUnixStart, taskUnixEnd, dateFromUnixTime]);

  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null);
  const handleFocusChange = (focusedInput: "startDate" | "endDate" | null) => {
    if (focusedInput === "startDate") {
      setEndDate(null);
    }

    setFocusedInput(focusedInput);
  };

  const checkOutsideRange = (day: moment.Moment) => {
    const dayUnix = day.unix() * 1000;

   
    if (taskUnixStart && taskUnixEnd) {
      if (dayUnix < taskUnixStart || dayUnix > taskUnixEnd) {
        return true;
      }
    }
    if(dateFromUnixTime && dayUnix < dateFromUnixTime){
      return true
    }


    return false;
  };

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    if (startDate) {
      setStartDate(startDate);
    }

    if (endDate) {
      setEndDate(endDate);
    }

    if (startDate && endDate) {
      // moment unix output is in seconds, Date.getTime() output is in milliseconds
      // startDate, endDate give the time at 12:00, therefore we deduct 12hrs from start, add 12hrs to end
      if (type === "overview") {
        const start = startDate.unix() * 1000 - 1000 * 60 * 60 * 12;
        const end = endDate.unix() * 1000 + 1000 * 60 * 60 * 12 - 1;

        dispatch(setOverviewFilter({ start, end }));
        dispatch(fetchOverviewData({ start, end }));
        if (isCompare) {
          dispatch(fetchCompareOverviewData({ start, end }));
        }
      }

      if (type === "posts") {
        dispatch(
          setFilterUnixStart(startDate.unix() * 1000 - 1000 * 60 * 60 * 12)
        );
        dispatch(
          setFilterUnixEnd(endDate.unix() * 1000 + 1000 * 60 * 60 * 12 - 1)
        );
      }
    }
  };

  return (
    <FilterRoot>
      <StyledDateRangeFilter>
        <DateRangePicker
          startDate={startDate}
          startDateId="filter_start_date_id"
          endDate={endDate}
          endDateId="filter_end_date_id"
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={handleFocusChange}
          isOutsideRange={checkOutsideRange}
          minimumNights={0}
          small={true}
          noBorder={true}
          hideKeyboardShortcutsPanel={true}
          displayFormat={() => "MMM D, YYYY"}
          readOnly
        />
      </StyledDateRangeFilter>
    </FilterRoot>
  );
}

export default DateRangeFilter;
