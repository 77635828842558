import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import styled from 'styled-components'
import { getAccess, selectAdHocFeedNumber, selectLiveFeedNumber } from "../../../app/store/feedSlice"
import { selectAdHocFeedLimit, selectLiveFeedLimit } from "../../../app/store/userSlice"
import UsageMeter from "./UsageMeter"

const PlanUsageRoot = styled.div`
 
`

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
`

function PlanUsage() {
  const dispatch = useDispatch()

  const liveFeedLimit = useSelector(selectLiveFeedLimit)
  const liveFeedNumber = useSelector(selectLiveFeedNumber)

  const adHocFeedLimit = useSelector(selectAdHocFeedLimit)
  const adHocFeedNumber = useSelector(selectAdHocFeedNumber)

  useEffect(() => {
    dispatch(getAccess())
  }, [dispatch])

  return (
    <PlanUsageRoot>
      <Title>Plan Usage</Title>
      <UsageMeter type="Live Feed" used={liveFeedNumber} quota={liveFeedLimit} />
      <UsageMeter type="Ad-hoc Feed" used={adHocFeedNumber} quota={adHocFeedLimit} />
      <p>Contact us at 3705-1012 or email us via <b>lenxcare@lenx.ai</b> to upgrade your plan.</p>
    </PlanUsageRoot>
  )
}

export default PlanUsage