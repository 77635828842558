import React, { useEffect } from "react";
import { useQuery } from "react-query";
import Cards from "./Cards";
import {
  TopicDataBrief,
  TopicDataDetail,
  Frequency,
  IndustryLabel,
  TrendAccess,
  VersionData,
} from "../interface";
import { usePostsStore } from "../postStore";
import SelectRow from "./SelectRow";
import {
  HomeContainer,
  SelectContainer,
  CardsContainer,
  LoaderContainer,
} from "./styled";
import Loader from "../../reusable/loader";
import { RouteComponentProps } from "react-router-dom";
import { mapBannerUrl } from "../helperFn/mapBannerImage";
import BannerRow from "./BannerRow";
import { parseQueryString } from "../helperFn/querystring";
import EmptyDashboard from "./EmptyDashboard";
import { destructuredQueryFn } from "../../../app/query/client";
import { timeFrameSelections } from "../components/menuSelections";
import FeedbackModal from "./Cards/FeedbackModal";
import { mergeTopicData } from "../helperFn/mergeTopicData";
import { sortBriefTopicDataInitial } from "../helperFn/sortTopicData";
import { groupVersionData } from "../helperFn/groupVersionData";

interface TrendHomeProps extends RouteComponentProps {}

function Home(props: TrendHomeProps) {
  const {
    allTopicData,
    setAllTopicData,
    setIsLoadingDetailedFromStore,
    frequency,
    setFrequency,
    setLabelId,
    labelId,
    isOpen,
    toggleOpen,
    activeCardId,
    setVersionData,
    setSelectedVersion,
    selectedVersion,
    setSelectedTrendId,
    selectedTrendId,
  } = usePostsStore((state) => ({
    allTopicData: state.allTopicData,
    setAllTopicData: state.setAllTopicData,
    setIsLoadingDetailedFromStore: state.setIsLoadingDetailed,
    frequency: state.frequency,
    setFrequency: state.setFrequency,
    setLabelId: state.setLabelId,
    labelId: state.labelId,
    isOpen: state.isOpen,
    toggleOpen: state.toggleOpen,
    activeCardId: state.activeCardId,
    setVersionData: state.setVersionData,
    setSelectedVersion: state.setSelectedVersion,
    selectedVersion: state.selectedVersion,
    setSelectedTrendId: state.setSelectedTrendId,
    selectedTrendId: state.selectedTrendId,
  }));

  //parse query strings
  const reqFrequency =
    props.location.search && parseQueryString(props.location.search).f;
  const reqIndustry =
    props.location.search && parseQueryString(props.location.search).label;

  useEffect(() => {
    reqFrequency && setFrequency(reqFrequency as Frequency);
    reqIndustry && setLabelId(+reqIndustry);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //check access rights
  const { data: accessRightsWithFreqDetails, isLoading: isLoadingAccessRight } =
    useQuery<TrendAccess | null>(
      ["/api/users/trendaccess"],
      destructuredQueryFn
    );

  const accessRights =
    accessRightsWithFreqDetails &&
    Object.keys(accessRightsWithFreqDetails).map((item) => +item);

  //fetch industry list
  const { data: industries } = useQuery<IndustryLabel[]>(
    ["/api/misc/list/ind_labels"],
    destructuredQueryFn,
    {
      enabled: !!accessRightsWithFreqDetails,
      onSuccess: (data) => {
        const industries = data.sort((a, b) => a.label_id - b.label_id);
        !+reqIndustry ||
        !industries.filter((ind) => ind.label_id === +reqIndustry)
          ? setLabelId(industries[0].label_id)
          : setLabelId(+reqIndustry);
      },
    }
  );

  const industryLabels =
    industries &&
    accessRightsWithFreqDetails &&
    industries
      .sort((a, b) => a.label_id - b.label_id)
      .filter((industry) => accessRights!.indexOf(industry.label_id) !== -1)
      .map((industry) => ({
        title: industry.label_name,
        value: industry.label_id,
        disabled: false,
      }));

  const frequencyLabels =
    labelId &&
    accessRightsWithFreqDetails &&
    timeFrameSelections
      .map((item) => ({
        ...item,
        disabled:
          !accessRightsWithFreqDetails[labelId.toString()]?.freq.includes(
            item.value
          ) ?? true,
      }))
      .filter((item) => item.show);

  //fetch post data
  const { isLoading: isLoadingBrief, data: allTopicsBrief } = useQuery<
    PostData<TopicDataBrief, VersionData>
  >(
    [
      `/api/trend/topics?label_id=${labelId}&frequency=${frequency}&detail=${false}&size=4`,
    ],
    {
      enabled: !!accessRightsWithFreqDetails && !!labelId,
      onSuccess: (item) => {
        setAllTopicData(sortBriefTopicDataInitial(item.data) || null);
        setSelectedVersion(item.gen_at);
        setVersionData(groupVersionData(item.results) || null);
        setSelectedTrendId(item?.results?.[0]?.trend_id);
      },
    }
  );

  const {
    isLoading: isLoadingDetailed,
    refetch,
    data: detailTopics,
  } = useQuery<PostDetailData<TopicDataDetail>>(
    [
      `/api/trend/topics?label_id=${labelId}&frequency=${frequency}&detail=${true}&trend_id=${selectedTrendId}`,
      // selectedTrendId,
    ],
    {
      enabled: false,
      onSuccess: (item) => {
        console.log("Item Detail data", item.data);
        setAllTopicData(mergeTopicData(allTopicData!, item.data) || null);
      },
    }
  );

  useEffect(() => {
    if (!!selectedTrendId) {
      refetch();
    }
  }, [selectedTrendId]);

  // useEffect(() => {}, [selectedTrendId]);

  useEffect(() => {
    isLoadingDetailed
      ? setIsLoadingDetailedFromStore(isLoadingDetailed)
      : setIsLoadingDetailedFromStore(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDetailed]);

  const bannerUrl = labelId && mapBannerUrl(labelId);

  return (
    <HomeContainer>
      {!accessRightsWithFreqDetails && !isLoadingAccessRight ? (
        <EmptyDashboard
          EmptyText={`Gain Access to AI-generated Trending Topics /n happening in Hong Kong`}
          EmptyContactText={true}
        />
      ) : !allTopicData || isLoadingBrief ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {industryLabels && bannerUrl && (
            <BannerRow
              bannerUrl={bannerUrl}
              labelText={
                industryLabels.find((label) => label.value === labelId)
                  ?.title || "Hong Kong"
              }
            />
          )}
          <SelectContainer>
            {industryLabels &&
              frequencyLabels &&
              accessRightsWithFreqDetails && (
                <SelectRow
                  industries={industryLabels}
                  frequencies={frequencyLabels}
                  accessRights={accessRightsWithFreqDetails}
                />
              )}
          </SelectContainer>
          {allTopicsBrief && allTopicsBrief.data?.length === 0 ? (
            <EmptyDashboard EmptyText="No results available for the selected date period. /n Please try another period." />
          ) : (
            <CardsContainer>
              <Cards genAt={selectedVersion} refetchDetail={refetch} />
            </CardsContainer>
          )}
        </>
      )}

      {isOpen && (
        <FeedbackModal
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          activeCardId={activeCardId}
          activeCardData={
            allTopicData?.find(
              (item) => item.briefData.topic_id === activeCardId
            )!
          }
          labelId={labelId || 1}
          frequency={frequency}
        />
      )}
    </HomeContainer>
  );
}

export default React.memo(Home);

interface PostData<T, V> {
  data: T[];
  gen_at: number;
  results: V[];
}

interface PostDetailData<T> {
  data: T[];
  gen_at: number;
}
