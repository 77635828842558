import React from 'react'
import styled from 'styled-components'

export interface SearcherProp {
  searchText: string
  handleSearchInput: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SearcherRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  width: 200px;
  &:hover {
    border-color: #b5b5b5;
    i {
      color: #b5b5b5;
    }
  }
  &:focus {
    border: 1px solid #29d1d7;
  }

  i {
    margin-right: 8px;
    font-size: 12px;
    color: #DADADA;
  }
`

const StyledInput = styled.input`
  height: 26px;
  font-size: 12px;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`

function Searcher({ searchText, handleSearchInput }: SearcherProp) {
  return (
    <SearcherRoot>
      <StyledInput className="input" type="text" placeholder="Search posts" value={searchText} onChange={handleSearchInput} />
      <i className="fas fa-search"></i>
    </SearcherRoot>
  )
}

export default React.memo(Searcher)
