import React from 'react'
import { IconProp } from "./interface"

function ExportIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      height={height} 
      width={width}
    >
      <defs>
        <style>{`.Export_svg__cls-1{fill:${fill}}`}</style>
      </defs>
      <g id="Export_svg__old_Version">
        <path
          className="Export_svg__cls-1"
          d="M136 13.47L127.56 5c-1.16-1.16-2.73-1.47-3.51-.69l-78.7 78.7c-.78.77-.46 2.35.7 3.51L54.48 95c1.16 1.16 2.73 1.48 3.51.7L136.72 17c.75-.79.43-2.36-.72-3.53z"
        />
        <path
          className="Export_svg__cls-1"
          d="M138.49.21h-74.2A4.3 4.3 0 0060 4.56v7.63a4.18 4.18 0 004.13 4.23h62.69v62.14a4.25 4.25 0 004.19 4.3h7.55a4.13 4.13 0 004.08-4.18V4.51a4.25 4.25 0 00-4.15-4.3z"
        />
      </g>
      <g id="Export_svg__updated">
        <path
          className="Export_svg__cls-1"
          d="M5 126.73h111.66a5 5 0 015 5v5a5 5 0 01-5 5H4.83a4.88 4.88 0 01-4.88-4.88v-5.12a5 5 0 015.05-5z"
        />
        <path
          className="Export_svg__cls-1"
          d="M82.24 97.85h65.85a3.91 3.91 0 013.91 3.91V109a3.91 3.91 0 01-3.91 3.91h-66a3.8 3.8 0 01-3.8-3.8v-7.32a3.91 3.91 0 013.95-3.94z"
          transform="rotate(-90 115.17 105.36)"
        />
        <path
          className="Export_svg__cls-1"
          d="M-48.23 73.74H63.6a5 5 0 015 5v5a5 5 0 01-5 5h-112a4.88 4.88 0 01-4.88-4.88v-5.09a5 5 0 015.05-5.03z"
          transform="rotate(-90 7.68 81.25)"
        />
        <path
          className="Export_svg__cls-1"
          d="M4.06 20.31h64.8a3.88 3.88 0 013.88 3.88v7.27a3.88 3.88 0 01-3.88 3.88H4a3.77 3.77 0 01-3.81-3.78v-7.37a3.88 3.88 0 013.88-3.88z"
        />
      </g>
    </svg>
  )
}

export default ExportIcon
