import * as React from "react"
import { IconProp } from "./interface"

function MehIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg viewBox="0 0 141.73 141.73" height={height} width={width} >
      <path
        d="M70.93.55C31.78.55.06 31.93.06 70.67s31.72 70.12 70.87 70.12 70.86-31.38 70.86-70.12S110.08.55 70.93.55zm0 126.67c-31.52 0-57.15-25.36-57.15-56.55s25.63-56.55 57.15-56.55 57.15 25.36 57.15 56.55-25.63 56.55-57.15 56.55zM48.07 48.05a9.05 9.05 0 109.14 9.05 9.09 9.09 0 00-9.14-9.05zm45.72 0a9.05 9.05 0 109.14 9.05 9.09 9.09 0 00-9.14-9.05z"
        fill={fill}
      />
    </svg>
  )
}

export default MehIcon