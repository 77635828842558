export const options = [
    { label: "ID", value: "hash" },
    { label: "Medium", value: "medium" },
    { label: "Site", value: "site" },
    { label: "Thread Title", value: "thread_title" },
    { label: "Post Message", value: "post_message" },
    { label: "Author Name", value: "author_name" },
    { label: "Channel", value: "channel" },
    { label: "Post Timestamp", value: "post_timestamp" },
    { label: "Thread Link", value: "thread_link" },
    { label: "Comment Count", value: "comment_count" },
    { label: "Share Count", value: "share_count" },
    { label: "View Count", value: "view_count" },
    { label: "Reaction Count", value: "reaction_count" },
    { label: "Like (reaction)", value: "reaction_like" },
    { label: "Dislike (reaction)", value: "reaction_dislike" },
    { label: "Angry (reaction)", value: "reaction_angry" },
    { label: "Haha (reaction)", value: "reaction_haha" },
    { label: "Love (reaction)", value: "reaction_love" },
    { label: "Sad (reaction)", value: "reaction_sad" },
    { label: "Wow (reaction)", value: "reaction_wow" },
    { label: "Sentiment", value: "ai_sentiment" },
    { label: "Impact", value: "ai_impact" },
]
