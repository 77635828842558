import React, { useEffect } from "react"
import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import Ctx, { initState } from "./ctx"
import { feedReducer } from "./ctx/reducer"
import { setAccess } from "./ctx/action"
import { getAccess, selectFetchingTaskAccess } from "../../app/store/feedSlice"
import { getSites } from "../../app/store/miscSlice"
import Task from "./task"
import Dashboard from "./dashboard"
import Loading from "../reusable/loader"
import { useDispatch, useSelector } from "react-redux"

const Feed = () => {

    const dispatch = useDispatch()

    // context
    const [feed, setFeed] = React.useReducer(feedReducer, initState)

    // fetch latest live access
    useEffect(() => {
        Promise.all([
            dispatch(getSites()),
            dispatch(getAccess()),
            setAccess(setFeed),
        ])
    }, [dispatch])



    const fetching = useSelector(selectFetchingTaskAccess)

    return (
        <Ctx.Provider value={{ feed, setFeed }}>
            {
                fetching ? <Loading /> :
                    <Router>
                        <Switch>
                            <Route path="/feed" exact component={Dashboard} />
                            <Route path="/feed/:taskid" component={Task} />
                        </Switch>
                    </Router>
            }
        </Ctx.Provider>

    )
}

export default Feed