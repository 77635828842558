import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import styled from 'styled-components'
import { ajax } from "usefuljs"
import { selectTaskId } from "../../../../../../app/store/feedSlice"
import { selectToken } from "../../../../../../app/store/userSlice"
import Button from "../../../../../reusable/buttons/Button"
import { toast } from "react-toastify"
import SmartTrainV2Icon from "../../../../../reusable/icons/SmartTrainV2Icon"
import {
  clearTagHistory,
  triggerValidation,
  setHasModel,
  selectCurrent,
  selectSmartTrainMode,
  setIsConfirming,
  setShowModelSelector,
  setIsTraining,
  ModelNameToAPIMapping,
  setShouldNotCreateModel
} from "../../../../../../app/store/smartTrainSlice"

const ConfirmRoot = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    color: #516380;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
`

const Description = styled.p`
  text-align: center;
  color: #516380;
  font-size: 18px;
`

const TipsLabel = styled.p`
  text-align: center;
  color: #868686;
  font-size: 14px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 2rem;
`

function Confirmation() {
  const dispatch = useDispatch()

  const taskId = useSelector(selectTaskId)
  const token = useSelector(selectToken)
  const mode = useSelector(selectSmartTrainMode)

  const current = useSelector(selectCurrent)

  const numberOfTaggedIrrelevant = current.tagHistory.filter(item => item.tag === 1).length
  const numberOfTaggedNegative = current.tagHistory.filter(item => item.tag === -1).length
  const numberOfTaggedNeutral = current.tagHistory.filter(item => item.tag === 0).length
  const numberOfTaggedPositive = current.tagHistory.filter(item => item.tag === 1).length
  const tagHistoryLength = current.tagHistory.filter(item => item.tag !== -10).length
  const isCreatingModel = current.shouldCreateModel

  const activateModel = async () => {
    try {
      dispatch(setIsTraining(true))
      dispatch(setIsConfirming(false))
      dispatch(setShowModelSelector(true))

      const res: { result: string, error?: string } = await ajax({
        method: "post",
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/smart_train/${ModelNameToAPIMapping[mode]}/confirm`,
      })

      if (res.result === "success") {
        if (mode === "relevancy") {
          toast.info("Smart Filter Activated")
        }

        if (mode === "sentiment") {
          toast.info("Custom sentiment model activated")
        }
        dispatch(setHasModel(true))
        dispatch(setShouldNotCreateModel(mode))
      } else {
        throw new Error(res.error!)
      }

      dispatch(setIsTraining(false))
      dispatch(clearTagHistory())
    } catch (err) {
      console.error(err)

      if (mode === "relevancy") {
        toast.info("Failed to activate smart filter")
      }

      if (mode === "sentiment") {
        toast.info("Failed to activate custom sentiment model")
      }
    }
  }

  const validateModel = async () => {
    dispatch(triggerValidation())
    dispatch(setIsConfirming(false))
    dispatch(setShowModelSelector(true))
    dispatch(clearTagHistory())
  }

  const returnPrevious = () => {
    dispatch(setIsConfirming(false))
  }

  function generateValidationLabel() {
    switch (mode) {
      case "relevancy":
        return "Confirm this session to see less irrelevant posts in the next cycle"
      case "sentiment":
        return "Confirm this session to improve detection accuracy in the next cycle"
    }
  }

  function generateValidationDescription() {
    switch (mode) {
      case "relevancy":
        return `You are about to confirm ${numberOfTaggedIrrelevant} irrelevant posts`
      case "sentiment":
        return `You are about to confirm posts for ${numberOfTaggedPositive} positive, ${numberOfTaggedNegative} negative, and ${numberOfTaggedNeutral} neutral`
    }
  }

  function ToActivateSmartFeed() {
    return (
      <ConfirmRoot>
        <SmartTrainV2Icon width="5rem" height="5rem" />
        <h4>ACTIVATE SMART FILTER</h4>
        <Description>Submit {tagHistoryLength} example posts to activate smart filter</Description>
        <ButtonContainer>
          <Button variant="tertiary" onClick={returnPrevious}>Go Back</Button>
          <Button variant="primary" onClick={activateModel} >Activate</Button>
        </ButtonContainer>
      </ConfirmRoot>
    )
  }

  function ToConfirmValidation() {
    return (
      <ConfirmRoot>
        <SmartTrainV2Icon width="5rem" height="5rem" />
        <h4>CONFIRM SESSION</h4>
        <Description>{generateValidationDescription()}</Description>
        <TipsLabel>{generateValidationLabel()}</TipsLabel>
        <ButtonContainer>
          <Button variant="tertiary" onClick={returnPrevious}>Go Back</Button>
          <Button variant="primary" onClick={validateModel} >Confirm session</Button>
        </ButtonContainer>
      </ConfirmRoot>
    )
  }

  if (isCreatingModel) {
    return <ToActivateSmartFeed />
  } else {
    return <ToConfirmValidation />
  }


}

export default Confirmation
