import { ajax } from "usefuljs"
import { store } from "../../app/store"

export const fetchAllPosts = async ({ queryKey }: any) => {
	// Latest, Daily, Weekly, Biweekly, Monthly
	const [, indLabel, frequency, isDetail] = queryKey
	const token = store.getState().user.token

	const data: any = await ajax({
		method: "get",
		headers: { token },
		url: `${process.env.REACT_APP_ENDPOINT}/api/trend/topics?label_id=${indLabel}&frequency=${frequency}&detail=${isDetail}`,
	})

	return data
}

export const fetchIndustries = async () => {
	// ind label = 1-7
	const token = store.getState().user.token

	const { data }: any = await ajax({
		method: "get",
		headers: { token },
		url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/ind_labels`,
	})

	return data
}

export const fetchAccessRights = async () => {
	const token = store.getState().user.token

	const { data }: any = await ajax({
		method: "get",
		headers: { token },
		url: `${process.env.REACT_APP_ENDPOINT}/api/users/trendaccess`,
	})

	return data
}

export const fetchTrendOvData = async ({ queryKey }: any) => {
	const [, topicId, frequency] = queryKey
	const token = store.getState().user.token

	const data: any = await ajax({
		method: "get",
		headers: { token },
		url: `${process.env.REACT_APP_ENDPOINT}/api/trend/topic?topic_id=${topicId}&frequency=${frequency}`,
	})

	return data
}
