import React from 'react'
import { IconProp } from "./interface"

function UpgradeIcon({
  height = "1rem",
  width = "1rem"
}: IconProp) {
  return (
    <svg
      width={width} 
      height={height}
      viewBox="0 0 50 57"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5 21C31.3 21 36 16.3 36 10.5S31.3 0 25.5 0 15 4.7 15 10.5 19.7 21 25.5 21zm0-18.968c4.657 0 8.468 3.81 8.468 8.468 0 4.7-3.81 8.468-8.468 8.468a8.438 8.438 0 01-8.468-8.468c0-4.657 3.768-8.468 8.468-8.468zm.847 13.887a.515.515 0 00.508-.508V10.5h2.837a.489.489 0 00.338-.847L25.84 5.462a.487.487 0 00-.72 0l-4.192 4.191c-.296.339-.084.847.381.847h2.837v4.911a.49.49 0 00.508.508h1.694zM33.238 38.449c-1.329 0-2.579-.704-3.204-1.798l-5-8.366-5.08 8.366c-.624 1.172-1.874 1.876-3.203 1.876-.39 0-.703-.078-1.015-.156L5.03 35.243V49.16c0 1.173.782 2.19 1.876 2.424l16.877 4.3a6.31 6.31 0 002.422 0l16.877-4.3c1.172-.235 1.953-1.251 1.953-2.424V35.243l-10.704 3.128c-.391.078-.704.078-1.094.078zm16.642-8.756l-4.063-8.053c-.234-.469-.703-.703-1.25-.625l-19.533 2.423 7.11 11.962c.312.469.937.703 1.484.547l15.47-4.456c.782-.235 1.095-1.095.782-1.798zM4.172 21.64L.109 29.693c-.313.703.078 1.563.781 1.798l15.47 4.456a1.308 1.308 0 001.485-.625l7.189-11.884-19.612-2.423c-.547-.078-1.016.156-1.25.625z"
        fill="#29D1D7"
      />
      <path
        d="M1 24.795C5.8 23.379 7 16.342 7 13c0 8.492 4 11.402 6 11.795-4.267 0-5.778 7.47-6 11.205 0-8.964-4-11.205-6-11.205zM20 45.77c4.4-1.293 5.5-7.719 5.5-10.77 0 7.754 3.667 10.41 5.5 10.77-3.911 0-5.296 6.82-5.5 10.23 0-8.185-3.667-10.23-5.5-10.23z"
        fill="#FFEAB1"
        stroke="#fff"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UpgradeIcon
