import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import "./react_dates_overrides.css"
import { setFilterUnixStart, setFilterUnixEnd } from "../../../../../../app/store/livefeedpost"
import { selectTaskUnixStart } from "../../../../../../app/store/feedSlice"
import { CalendarInfoLabel, CustomButton, StyledDatePickerWrapper } from "./styled"


interface DateRange {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
}

interface DateRangeCalendarProp {
  showCalendar: boolean
  setShowCalendar: React.Dispatch<React.SetStateAction<boolean>>
}

function generateCalendarInfoLabel() {
  return (
    <CalendarInfoLabel>
      <i className="fas fa-exclamation-circle" />Only 3 months of data can be displayed
    </CalendarInfoLabel>
  )
}

function DateRangeCalendar({ showCalendar, setShowCalendar }: DateRangeCalendarProp) {
  const dispatch = useDispatch()
  const taskUnixStart = useSelector(selectTaskUnixStart)

  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate" | null>(null)

  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)

  const handleDatesChange = ({ startDate, endDate }: DateRange) => {

    if (startDate) {
      setStartDate(startDate)
    }

    if (endDate) {
      setEndDate(endDate)
    }

    if (startDate && endDate) {
      // moment unix output is in seconds, Date.getTime() output is in milliseconds
      // startDate, endDate give the time at 12:00, therefore we deduct 12hrs from start, add 12hrs to end
      dispatch(setFilterUnixStart(startDate.unix() * 1000 - 1000 * 60 * 60 * 12))
      dispatch(setFilterUnixEnd(endDate.unix() * 1000 + 1000 * 60 * 60 * 12 - 1))
    }
  }

  const handleFocusChange = (focusedInput: "startDate" | "endDate" | null) => {
    if (focusedInput === "startDate") {
      setEndDate(null)
    }

    setFocusedInput(focusedInput)
  }

  const checkOutsideRange = (day: moment.Moment) => {
    const dayUnix = day.unix() * 1000
    const today = new Date()
    today.setHours(23, 59, 59, 999)

    if (dayUnix > today.getTime()) {
      // disable days after today
      return true
    }

    const threeMonthsAgo = new Date()
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3)
    threeMonthsAgo.setHours(0, 0, 0, 0)

    if (dayUnix < threeMonthsAgo.getTime()) {
      // disable days before three months ago
      return true
    }

    if (taskUnixStart && dayUnix < taskUnixStart) {
      return true
    }

    return false
  }

  return (
    <>
      {
        showCalendar ? (<StyledDatePickerWrapper>
          <DateRangePicker
            startDate={startDate}
            startDateId="start_date_id"
            endDate={endDate}
            endDateId="end_date_id"
            onDatesChange={handleDatesChange}
            focusedInput={focusedInput}
            onFocusChange={handleFocusChange}
            isOutsideRange={checkOutsideRange}
            minimumNights={0}
            small={true}
            noBorder={true}
            hideKeyboardShortcutsPanel={true}
            displayFormat={() => "YYYY/MM/DD"}
            renderCalendarInfo={generateCalendarInfoLabel}
            readOnly
          />
        </StyledDatePickerWrapper>)
          : (
            <CustomButton
              onClick={() => {
                setFocusedInput("startDate")
                setShowCalendar(true)
                dispatch(setFilterUnixStart(Date.now()))
              }}>
              <i className="far fa-calendar" />
              <span>Custom</span>
            </CustomButton>
          )
      }
    </>
  )
}

export default DateRangeCalendar
