export type DateRange = {
	Latest: string
	Daily: string
	Weekly: string
	BiWeekly: string
	Monthly: string
	// "threeMonths": string
	// "halfYearly": string
}

export const DateRangeLabelMapping: DateRange = {
	Latest: "Last 24 hours",
	Daily: "Yesterday",
	Weekly: "Past 7 days",
	BiWeekly: "Past 14 days",
	Monthly: "Past 30 days",
	// "threeMonths": "Past 3 months",
	// "halfYearly": "Past 6 months"
}

export type MediumKey =
	| "Total"
	| "Facebook"
	| "Social"
	| "Forum"
	| "Blog"
	| "News"
	| "Video"
	| "Telegram"

export type Collection<Keys extends string, Type> = {
	[Key in Keys]?: Type
}

export const MediumKeyLabelMap: Collection<MediumKey, string> = {
	Total: "Total",
	Facebook: "Facebook",
	Social: "Social",
	Forum: "Forums",
	Blog: "Blogs",
	News: "News",
	Video: "Videos",
	Telegram: "Telegram",
}

export const MediumKeyColorMap: Collection<MediumKey, string> = {
	Total: "#29D1D7",
	Facebook: "#1877F2",
	Social: "#FFCC4D",
	Forum: "#C79AFF",
	Blog: "#3C9FA3",
	News: "#92A5C3",
	Video: "#FF6E86",
	Telegram: "#00ACEE",
}

export type Maturity =
	| "dormant"
	| "emerging"
	| "growing"
	| "mature"
	| "declining"
	| "fading"

export const MaturityColorMap: Collection<Maturity, string> = {
	dormant: "#92A5C3B3",
	emerging: "#ED5252B3",
	growing: "#00ACEEA6",
	mature: "#29D1D7B3",
	declining: "#F7CE64B3",
	fading: "#C79AFFB3",
}
