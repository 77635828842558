import React from "react";
import styles from "./loading3.module.scss";


const Loading3 = ()=> {
    const loadlink:string = React.useMemo(()=> {
        const links:string[] = [
            "https://media.tenor.com/images/4abc7ce7da92a335d22d10739c8b5f5e/tenor.gif",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/lala-loader.gif",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/lala-loader2.gif",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/lala-loader3.gif",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/lala-loader4.gif"
        ];
        return links[Math.floor(Math.random() * links.length)];
    },[]);
    return (
        <div className={styles.loading}><h3>Loading</h3><img src={loadlink} alt="loading" /></div>
    );
}

export default Loading3;