import React, { useState, useEffect } from "react";
import moment from "moment";
import { FeedDataHead } from "./interface";
import styles from "./head.module.scss";
import { siteicon } from "../../../../../../../../app/tools/display";

interface HeadProp {
  head: FeedDataHead;
}

const Head = (props: HeadProp) => {
  const formattedPostTime = moment(props.head.unix_timestamp).format(
    "DD MMMM YYYY [at] HH:mm"
  );

  const site_img = siteicon(props.head.site ?? "");

  const [hasError, setHasError] = useState(true);

  useEffect(() => {
    setHasError(props.head.author_image === null);
  }, [props.head.author_image]);

  return (
    <div className={styles.head}>
      <div className={styles.author_image}>
        {!hasError ? (
          <img
            src={props.head.author_image ?? ""}
            alt={props.head.author_name ?? ""}
            onError={() => {
              setHasError(true);
            }}
          />
        ) : (
          <i className="fas fa-user-circle"></i>
        )}
      </div>
      <a
        className={styles.author_link}
        href={props.head.author_link ?? ""}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.head.author_name}
      </a>
      <span className={styles.time}>{formattedPostTime}</span>
      <span className={styles.site}>
        {site_img ? <img src={site_img} alt={props.head.site ?? ""} /> : null}
        <p>{props.head.site}</p>
        {props.head.site === "Facebook Group" && <p>- {props.head.channel}</p>}
      </span>
    </div>
  );
};

export default Head;
