import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import html2canvas from "html2canvas";
import { PDFDocument, PageSizes } from "pdf-lib";
import pptxgen from "pptxgenjs";
import uf from "usefuljs";
import logo from '../../../../../assets/icons/lenx_logo.webp'

import {
  selectDateRange,
  setDateRange,
  clearOverviewData,
  setOverviewFilter,
  selectOverviewFilter,
  fetchCompareOverviewData,
  SelectAuthors,
  selectTopSites,
  selectTopEngagementThreads,
  selectTopGrowthThreads,
  selectSentiments,
  selectWordCloud,
  selectTopTrends,
  selectPostCountChart,
  selectStats,
  selectCompareStats,
  selectComparePostCountChart,
  selectCompareSentiments,
  selectCompareWordCloud,
  selectCompareTopEngagementThreads,
  selectCompareTopGrowthThreads,
  SelectCompareAuthors,
  selectCompareTopSites,
} from "../../../../../app/store/overviewSlice";
import {
  Author,
  DateRange,
  Site,
  TopAuthors,
  TopEngagementThread,
  TopGrowthThread,
  TopSites,
} from "../../../../../app/interfaces/overview";
import {
  selectCompareFeedTaskInfo,
  selectFeedTaskAccess,
  selectFeedTaskInfo,
  selectFeedType,
  selectTaskUnixEnd,
  selectTaskUnixStart,
  setCompareDisplayTask,
} from "../../../../../app/store/feedSlice";
import DateRangeFilter from "../../DateRangeFilter";
import BaseButton from "../../../../reusable/buttons/BaseButton";
import styles from "./head.module.scss";
import { TaskInfo } from "../../../../../app/interfaces/feed";
import { LiveFeedFromAPI } from "../../../../../app/interfaces/ResponseFromAPIs";
import ComponentButton from "../../../../reusable/buttons/Button";
import ExportpptIcon from "../../../../reusable/icons/ExportpptIcon";
import ExportpdfIcon from "../../../../reusable/icons/ExportpdfIcon";
import IconButton from "../../../../reusable/buttons/IconButton";

import {
  generateStartOfRangeFromDateRangeUnixTimestamp,
  generateEndOfRangeFromDateRangeUnixTimestamp,
  generateStartOfRangeFromIntervalUnixTimestamp,
  convertIntervalStepAndIntervalToDateRange,
  generateEndOfRangeFromIntervalUnixTimestamp,
} from "../../../../../app/tools/dateRangeConverter";
import {
  generateLiveChartPointLabel,
  generateAdHocChartPointLabel,
} from "../gadgets/sentiment/timeline/helper";
import { IntervalContext } from "../context/IntervalContext";
import {
  formatLiveChartPoints,
  formatAdHocChartPoints,
} from "../gadgets/linegraph/helper";
import useInterval from "../gadgets/linegraph/hook/useInterval";
import moment from "moment";

export const COMPARE_COLORS = [
  "29D1D7",
  "ffcc4d"
]

export const COLORS_CHART = [
  "73D844",
  "898989",
  "ED5252",
  "177e89",
  "3066be",
  "00a9b5",
  "58508d",
  "bc5090",
  "db3a34",
  "ff6361",
  "ffa600",
];
export const COLORS_ACCENT = ["4472C4", "ED7D31", "FFC000", "70AD47"]; // 1,2,4,6

const tableHeaderStyles = {
  valign: "top",
  align: "left",
  fill: { color: "4472c4" },
  color: "FFFFFF",
  bold: true,
};
const tableCellStyles = {
  valign: "center",
  align: "center",
  fill: { color: "cdd4ea" },
  color: "000000",
  fontSize: 9,
  fit: "shrink",
  autoFit: true,
};

const HeadRoot = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
`;

const ButtonsContainer = styled.div`
  justify-self: left;
  display: flex;
`;

const Button = styled(BaseButton)<{ isActive: boolean }>`
  border: 1px solid #dbdbdb;
  padding: 0px 1rem;
  height: 35px;
  font-size: 0.9rem;
  color: #363636;
  background-color: white;

  ${(props) =>
    props.isActive &&
    css`
      color: white;
      background-color: #29d1d7;
    `}

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  &:not(:last-child) {
    border-right-color: transparent;
  }

  &:hover {
    border: 1px solid #b5b5b5;
    z-index: 2;
  }
`;

const StyledIconButton = styled(IconButton)`
  /* padding: 0.6rem; */
  position: relative;

  &:hover {
    background-color: white;
  }
`;

const Head = ({ isAuthorLoaded }: any) => {
  const dispatch = useDispatch();
  const task: TaskInfo = useSelector(selectFeedTaskInfo);
  const dateRange = useSelector(selectDateRange);
  const feedType = useSelector(selectFeedType);
  const [showCalendar, setShowCalendar] = useState(false);
  const dateRangeFilter = useSelector(selectOverviewFilter);
  const compareTask = useSelector(selectCompareFeedTaskInfo);

  const taskUnixStart = useSelector(selectTaskUnixStart);
  const taskUnixEnd = useSelector(selectTaskUnixEnd);

  const liveTasks = useSelector(selectFeedTaskAccess);

  const [dropdownExpanded, setDropdownExpanded] = React.useState(false);
  const stats = useSelector(selectStats);
  const compareStats = useSelector(selectCompareStats);
  const topAuthors: TopAuthors | null = useSelector(SelectAuthors);
  const topCompareAuthors: TopAuthors | null = useSelector(SelectCompareAuthors);
  const topSites: TopSites | null = useSelector(selectTopSites);
  const topCompareSites: TopSites | null = useSelector(selectCompareTopSites);
  const topEngagementThreads = useSelector(selectTopEngagementThreads);
  const topCompareEngagementThreads = useSelector(selectCompareTopEngagementThreads);
  const topGrowthThreads = useSelector(selectTopGrowthThreads);
  const topCompareGrowthThreads = useSelector(selectCompareTopGrowthThreads);
  const sentiments = useSelector(selectSentiments);
  const compareSentiments = useSelector(selectCompareSentiments);
  const topWords = useSelector(selectWordCloud);
  const topCompareWords = useSelector(selectCompareWordCloud);
  const trendData = useSelector(selectTopTrends);

  const intervalObject = useInterval();

  const customDateRange = useSelector(selectOverviewFilter);
  const postCountChartPoints = useSelector(selectPostCountChart);
  const comparePostCountChartPoints = useSelector(selectComparePostCountChart);

  const formatted =
    feedType === 0
      ? formatLiveChartPoints(postCountChartPoints, dateRange)
      : formatAdHocChartPoints(postCountChartPoints, intervalObject.interval);
  const compareFormatted =
    feedType === 0
      ? formatLiveChartPoints(comparePostCountChartPoints, dateRange)
      : formatAdHocChartPoints(
          comparePostCountChartPoints,
          intervalObject.interval
        );

  const { interval, intervalStep } = useContext(IntervalContext);

  const onClickDr = (dateRange: DateRange) => {
    dispatch(clearOverviewData());
    dispatch(setDateRange(dateRange));
    dispatch(
      setOverviewFilter({
        start: null,
        end: null,
      })
    );
    setShowCalendar(false);
  };

  const handleClickExpand = () => {
    if (!dropdownExpanded) {
      document.addEventListener("click", collapseDropdown);
    }
    setDropdownExpanded(true);
  };

  const collapseDropdown = () => {
    setDropdownExpanded(false);
    document.removeEventListener("click", collapseDropdown);
  };

  const getAdhocTasks = () => {
    let list: any = [];
    liveTasks?.forEach((data) => {
      if (data?.task_type === 1 && data.task_id !== task.task_id) {
        list.push(data);
      }
    });

    return list;
  };

  const onTakeScreenshot = async () => {
    document.getElementById("export-button")!.style.visibility = "hidden";
    document.getElementById("date_filter")!.style.display = "none";
    document.getElementById("date_filter_name")!.style.display = "flex";
    document.getElementById("product_logo")!.style.display = "block";
    html2canvas(document?.querySelector("#capture1")!, {
      useCORS: true,
      allowTaint: true,
      logging: true,

    }).then(async (canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);

      console.log("canvas", image);
      const pdfDoc = await PDFDocument.create();
      let data = await pdfDoc.embedPng(image);
      const page = pdfDoc.addPage(PageSizes.A2);
      const { width, height } = page.getSize();

      console.log(
        "PDF data",
        data,
        data.scale(0.1).width,
        data.scale(0.15).height,
        width,
        height
      );
      // page.drawImage(data, {
      //   width: data.scale(0.5).width,
      //   height: data.scale(0.5).height,
      //   x: 100,
      //   y: 100,
      // });

      let imageDims = data.size();
      // Make sure the image is not larger than the page, and scale down to fit if it is
      if (imageDims.width > width || imageDims.height > height) {
        imageDims = data.scaleToFit(width, height);
      }

      page.drawImage(data, {
        x: width / 2 - imageDims.width / 2,
        y: height / 2 - imageDims.height / 2,
        width: imageDims.width,
        height: imageDims.height,
      });
      // page.drawImage(data, {
      //   width: data.scale(0.1).width,
      //   height: data.scale(0.12).height,
      //   x: 0,
      //   y: 0,
      // });

      // page.drawImage(data, {
      //   x: width / 2 - data.width / 2,
      //   y: height / 2 - data.height / 2,
      //   width: data.width,
      //   height: data.height,
      // });
      let b64Chunk = await pdfDoc.saveAsBase64();
      b64Chunk = "data:application/pdf;base64," + b64Chunk;
      const blob = await (await fetch(b64Chunk)).blob();
      downloadFile(blob);
      // downloadImage(image, "compare");
      document.getElementById("export-button")!.style.visibility = "visible";
      document.getElementById("date_filter")!.style.display = "block";
      document.getElementById("date_filter_name")!.style.display = "none";
      document.getElementById("product_logo")!.style.display = "none";
    });
    // html2canvas(document?.querySelector("#capture")!);
  };

  const onExportIntoPPT = () => {
    let pres = new pptxgen();
    let masterSlide = pres.addSlide();
    masterSlide.addText(
      `Comparison of ${task.task_name} and ${compareTask.task_name} Report`,
      {
        x: 1,
        y: 2,
        w: "80%",
        h: 1,
        fontSize: 36,
        align: "center",
        fill: { color: "D3E3F3" },
        color: "008899",
        valign: "middle",
      }
    );
    let slide = pres.addSlide();

    let objOpts3 = {
      x: 0.5,
      y: 0.7,
      h: 0.3,
      margin: 0,
      fontSize: 18,
      fontFace: "Arial",
      color: "0088CC",
    };
    slide.addText("Overview Daily Summary", objOpts3);
    let taskOptions = {
      x: 0.5,
      y: 1.3,
      w: 4.0,
      margin: 0,
      fontSize: 14,
      fontFace: "Arial",
      color: "29D1D7",
    };

    slide.addText(`${task?.task_name}`, taskOptions);

    let compareTaskOptions = {
      x: 5,
      y: 1.3,
      w: 4.0,
      margin: 0,
      fontSize: 14,
      fontFace: "Arial",
      color: "ffcc4d",
    };

    slide.addText(`${compareTask?.task_name}`, compareTaskOptions);

    let arrTabRows1: any = [
      [
        { text: "Total Posts", options: tableHeaderStyles },
        { text: "Total Threads", options: tableHeaderStyles },
        { text: "Total Authors", options: tableHeaderStyles },
      ],
      [
        { text: stats?.postCount?.total?.toString(), options: tableCellStyles },
        {
          text: stats?.threadCount?.total?.toString(),
          options: tableCellStyles,
        },
        {
          text: stats?.authorCount?.total?.toString(),
          options: tableCellStyles,
        },
      ],
    ];
    slide.addTable(arrTabRows1, {
      x: 0.5,
      y: 1.5,
      w: 4.0,
      rowH: 0.75,
      fill: { color: "F7F7F7" },
      color: "6c6c6c",
      fontSize: 14,
      align: "center",
      border: { pt: 1, color: "ffffff" },
    });

    let arrTabRows3: any = [
      [
        { text: "Total Posts", options: tableHeaderStyles },
        { text: "Total Threads", options: tableHeaderStyles },
        { text: "Total Authors", options: tableHeaderStyles },
      ],
      [
        {
          text: compareStats?.postCount?.total?.toString(),
          options: tableCellStyles,
        },
        {
          text: compareStats?.threadCount?.total?.toString(),
          options: tableCellStyles,
        },
        {
          text: compareStats?.authorCount?.total?.toString(),
          options: tableCellStyles,
        },
      ],
    ];
    slide.addTable(arrTabRows3, {
      x: 5.0,
      y: 1.5,
      w: 4.0,
      rowH: 0.75,
      fill: { color: "F7F7F7" },
      color: "6c6c6c",
      fontSize: 14,
      align: "center",
      border: { pt: 1, color: "ffffff" },
    });

    let arrTabRows2: any = [
      [
        { text: "Total Engagement", options: tableHeaderStyles },
        { text: "Total View Count", options: tableHeaderStyles },
      ],
      [
        {
          text: stats?.engagementCount?.total?.toString(),
          options: tableCellStyles,
        },
        { text: stats?.viewCount?.total?.toString(), options: tableCellStyles },
      ],
    ];

    slide.addTable(arrTabRows2, {
      x: 0.5,
      y: 3.4,
      w: 4.0,
      rowH: 0.75,
      fill: { color: "F7F7F7" },
      color: "6c6c6c",
      fontSize: 14,
      align: "center",
      border: { pt: 1, color: "ffffff" },
    });

    let arrTabRows4: any = [
      [
        { text: "Total Engagement", options: tableHeaderStyles },
        { text: "Total View Count", options: tableHeaderStyles },
      ],
      [
        {
          text: compareStats?.engagementCount?.total?.toString(),
          options: tableCellStyles,
        },
        {
          text: compareStats?.viewCount?.total?.toString(),
          options: tableCellStyles,
        },
      ],
    ];

    slide.addTable(arrTabRows4, {
      x: 5.0,
      y: 3.4,
      w: 4.0,
      rowH: 0.75,
      fill: { color: "F7F7F7" },
      color: "6c6c6c",
      fontSize: 14,
      align: "center",
      border: { pt: 1, color: "ffffff" },
    });

    addThreadCount(
      pres,
      objOpts3,
      "threads",
      `${task.task_name} and ${compareTask.task_name} Threads Count`
    );
    addThreadCount(
      pres,
      objOpts3,
      "comments",
      `${task.task_name} and ${compareTask.task_name} Comments Count`
    );
    addThreadCount(
      pres,
      objOpts3,
      "total",
      `${task.task_name} and ${compareTask.task_name} Threads and Comments Count`
    );

    addSentimentOverviewSlide(pres, objOpts3);

    addSentimentTimeline(pres, objOpts3);
    addSentimentTimelineVolume(pres, objOpts3);

    addTopTrends(pres, objOpts3);

    addEngagementSlide(pres, objOpts3, topEngagementThreads, topCompareEngagementThreads, "engagement");
    addEngagementSlide(pres, objOpts3, topGrowthThreads, topCompareGrowthThreads,  "growth");

    addIntelligentWorldCloudSlide(pres, objOpts3);

    addTopAuthors(
      pres,
      objOpts3,
      topAuthors?.byThreads,
      topCompareAuthors?.byThreads,
      "Top Authors Summary (By Threads)"
    );
    addTopAuthors(
      pres,
      objOpts3,
      topAuthors?.byComments,
      topCompareAuthors?.byComments,
      "Top Authors Summary (By Comments)"
    );
    addTopAuthors(
      pres,
      objOpts3,
      topAuthors?.byTotal,
      topCompareAuthors?.byTotal,
      "Top Authors Summary (Total)"
    );

    addTopSitesByActivity(
      pres,
      objOpts3,
      topSites?.byThreads,
      topCompareSites?.byThreads,
      "Top Sites by Activity (By Threads)"
    );
    addTopSitesByActivity(
      pres,
      objOpts3,
      topSites?.byComments,
      topCompareSites?.byComments,
      "Top Sites by Activity (By Comments)"
    );
    addTopSitesByActivity(
      pres,
      objOpts3,
      topSites?.byTotal,
      topCompareSites?.byTotal,
      "Top Sites by Activity (Total)"
    );
    pres.writeFile({
      fileName: `Comparison of ${task.task_name} and ${compareTask.task_name} Report.pptx`,
    });
  };

  const addTopAuthors = (
    pres: any,
    objOpts3: any,
    authorList: any,
    compareAuthorList: any,
    title: string
  ) => {
    let slide = pres.addSlide();
    slide.addText(title, objOpts3);
    let taskOptions = {
      x: 0.5,
      y: 1.3,
      w: 4.0,
      margin: 0,
      fontSize: 14,
      fontFace: "Arial",
      color: "29D1D7",
    };

    slide.addText(`${task?.task_name}`, taskOptions);

    let compareTaskOptions = {
      x: 5,
      y: 1.3,
      w: 4.0,
      margin: 0,
      fontSize: 14,
      fontFace: "Arial",
      color: "ffcc4d",
    };

    slide.addText(`${compareTask?.task_name}`, compareTaskOptions);
    let arrTabRows: any = [
      [
        { text: "Author", options: tableHeaderStyles },
        { text: "Channel", options: tableHeaderStyles },
        { text: "Post", options: tableHeaderStyles },
      ],
    ];
    authorList?.forEach((item: Author) => {
      arrTabRows.push([
        { text: item?.name, options: tableCellStyles },
        {
          text: item?.channel,
          options: tableCellStyles,
        },
        {
          text: item?.postCount,
          options: tableCellStyles,
        },
      ]);
    });
    slide.addTable(arrTabRows, {
      x: 0.5,
      y: 1.5,
      w: 4,
      rowH: [0.3],
      fill: { color: "F7F7F7" },
      color: "6c6c6c",
      fontSize: 14,
      align: "center",
      border: { pt: 1, color: "ffffff" },
    });


    let arrTabRows1: any = [
      [
        { text: "Author", options: tableHeaderStyles },
        { text: "Channel", options: tableHeaderStyles },
        { text: "Post", options: tableHeaderStyles },
      ],
    ];
    compareAuthorList?.forEach((item: Author) => {
      arrTabRows1.push([
        { text: item?.name, options: tableCellStyles },
        {
          text: item?.channel,
          options: tableCellStyles,
        },
        {
          text: item?.postCount,
          options: tableCellStyles,
        },
      ]);
    });
    slide.addTable(arrTabRows1, {
      x: 5,
      y: 1.5,
      w: 4,
      rowH: [0.3],
      fill: { color: "F7F7F7" },
      color: "6c6c6c",
      fontSize: 14,
      align: "center",
      border: { pt: 1, color: "ffffff" },
    });

  
  };

  const addTopSitesByActivity = (
    pres: any,
    objOpts3: any,
    sitesList: any,
    compareSitesList: any,
    title: string
  ) => {
    if (sitesList) {
      let slide = pres.addSlide();
      slide.addText(title, objOpts3);
      let taskOptions = {
        x: 0.5,
        y: 1.3,
        w: 4.0,
        margin: 0,
        fontSize: 14,
        fontFace: "Arial",
        color: "29D1D7",
      };
  
      slide.addText(`${task?.task_name}`, taskOptions);
  
      let compareTaskOptions = {
        x: 5,
        y: 1.3,
        w: 4.0,
        margin: 0,
        fontSize: 14,
        fontFace: "Arial",
        color: "ffcc4d",
      };
  
      slide.addText(`${compareTask?.task_name}`, compareTaskOptions);
      let arrTabRows: any = [
        [
          { text: "Site", options: tableHeaderStyles },
          { text: "Channel", options: tableHeaderStyles },
          { text: "Post Count", options: tableHeaderStyles },
        ],
      ];
      sitesList?.forEach((item: Site) => {
        arrTabRows.push([
          { text: item?.site, options: tableCellStyles },
          {
            text: item?.channel,
            options: tableCellStyles,
          },
          {
            text: item?.postCount,
            options: tableCellStyles,
          },
        ]);
      });
      slide.addTable(arrTabRows, {
        x: 0.5,
        y: 1.5,
        w: 4,
        rowH: [0.3],

        fill: { color: "F7F7F7" },
        color: "6c6c6c",
        fontSize: 14,
        align: "center",
        border: { pt: 1, color: "ffffff" },
      });


      let arrTabRows1: any = [
        [
          { text: "Site", options: tableHeaderStyles },
          { text: "Channel", options: tableHeaderStyles },
          { text: "Post Count", options: tableHeaderStyles },
        ],
      ];
      compareSitesList?.forEach((item: Site) => {
        arrTabRows1.push([
          { text: item?.site, options: tableCellStyles },
          {
            text: item?.channel,
            options: tableCellStyles,
          },
          {
            text: item?.postCount,
            options: tableCellStyles,
          },
        ]);
      });
      slide.addTable(arrTabRows1, {
        x: 0.5,
        y: 1.5,
        w: 4,
        rowH: [0.3],

        fill: { color: "F7F7F7" },
        color: "6c6c6c",
        fontSize: 14,
        align: "center",
        border: { pt: 1, color: "ffffff" },
      });
    }
  };

  const addEngagementSlide = (
    pres: any,
    objOpts3: any,
    threadList: any,
    compareThreadList: any,
    type: string
  ) => {
    if (threadList?.length || compareThreadList?.length) {
      let slide = pres.addSlide();
      slide.addText(`Top Threads (Total ${type})`, objOpts3);

      let taskOptions = {
        x: 0.5,
        y: 1.3,
        w: 4.0,
        margin: 0,
        fontSize: 14,
        fontFace: "Arial",
        color: "29D1D7",
      };

      slide.addText(`${task?.task_name}`, taskOptions);

      let compareTaskOptions = {
        x: 5,
        y: 1.3,
        w: 4.0,
        margin: 0,
        fontSize: 14,
        fontFace: "Arial",
        color: "ffcc4d",
      };

      slide.addText(`${compareTask?.task_name}`, compareTaskOptions);
      let arrTabRows: any = [
        [
          { text: "Site", options: tableHeaderStyles },
          { text: "Title", options: tableHeaderStyles },
          { text: "Count", options: tableHeaderStyles },
        ],
      ];
      let list = threadList
        ? threadList.filter((thread: any) => thread.title !== "")
        : null;
      let uniqueList: any = [];
      list?.forEach((thread: TopEngagementThread | TopGrowthThread) => {
        const isFound = uniqueList?.find(
          (item: TopEngagementThread | TopGrowthThread) =>
            item?.title === thread?.title
        );

        if (!isFound) {
          uniqueList.push(thread);
        }
      });
      uniqueList?.forEach((item: any, index: number) => {
        // if (index % 10 !== 0 || index === 0) {
  
          arrTabRows.push([
            { text: item?.site, options: tableCellStyles },
            {
              text: item?.title,
              options: tableCellStyles,
            },
            {
              text: type === "engagement" ? item?.engagementCount : item?.roc,
              options: tableCellStyles,
            },
          ]);
          // if (index === uniqueList?.length - 1) {
          //   slide.addTable(arrTabRows, {
          //     x: 0.5,
          //     y: 1.5,
          //     w: 4,
          //     rowH: [0.3],
          //     fill: { color: "F7F7F7" },
          //     color: "6c6c6c",
          //     fontSize: 10,
          //     align: "center",
          //     border: { pt: 1, color: "ffffff" },
          //   });
          // }
        // } else if (
        //   (index % 10 === 0 || index === uniqueList?.length - 1) &&
        //   index !== 0
        // ) {
        //   console.log("I am getting called!!!", index, uniqueList?.length - 1);
        //   slide.addTable(arrTabRows, {
        //     x: 0.5,
        //     y: 1.5,
        //     w: 4,
        //     rowH: [0.3],
        //     fill: { color: "F7F7F7" },
        //     color: "6c6c6c",
        //     fontSize: 10,
        //     align: "center",
        //     border: { pt: 1, color: "ffffff" },
        //   });
        //   if (index !== uniqueList?.length - 1 && index !== 0) {
        //     slide = pres.addSlide();
        //     slide.addText(`Top Threads (Total ${type}) Cont..`, objOpts3);
        //     arrTabRows = [
        //       [
        //         { text: "Site", options: tableHeaderStyles },
        //         { text: "Title", options: tableHeaderStyles },
        //         { text: "Count", options: tableHeaderStyles },
        //       ],
        //     ];
        //     arrTabRows.push([
        //       { text: item?.site, options: tableCellStyles },
        //       {
        //         text: item?.title,
        //         options: tableCellStyles,
        //       },
        //       {
        //         text: type === "engagement" ? item?.engagementCount : item?.roc,
        //         options: tableCellStyles,
        //       },
        //     ]);
        //   }
        // }
      });

      slide.addTable(arrTabRows, {
        x: 0.5,
        y: 1.5,
        w: 4,
        rowH: [0.3],
  
        fill: { color: "F7F7F7" },
        color: "6c6c6c",
        fontSize: 10,
        align: "center",
        border: { pt: 1, color: "ffffff" },
        autoPage: true,
        autoPageRepeatHeader: true,
        autoPageLineWeight:0.5
      });


      let arrTabRows1: any = [
        [
          { text: "Site", options: tableHeaderStyles },
          { text: "Title", options: tableHeaderStyles },
          { text: "Count", options: tableHeaderStyles },
        ],
      ];
      let list1 = compareThreadList
        ? compareThreadList.filter((thread: any) => thread.title !== "")
        : null;
      let uniqueList1: any = [];
      list1?.forEach((thread: TopEngagementThread | TopGrowthThread) => {
        const isFound = uniqueList1?.find(
          (item: TopEngagementThread | TopGrowthThread) =>
            item?.title === thread?.title
        );

        if (!isFound) {
          uniqueList1.push(thread);
        }
      });
      uniqueList1?.forEach((item: any, index: number) => {
        // if (index % 10 !== 0 || index === 0) {
  
          arrTabRows1.push([
            { text: item?.site, options: tableCellStyles },
            {
              text: item?.title,
              options: tableCellStyles,
            },
            {
              text: type === "engagement" ? item?.engagementCount : item?.roc,
              options: tableCellStyles,
            },
          ]);
          if (index === uniqueList1?.length - 1) {
            slide.addTable(arrTabRows1, {
              x: 5,
              y: 1.5,
              w: 4,
              rowH: [0.3],
              fill: { color: "F7F7F7" },
              color: "6c6c6c",
              fontSize: 10,
              align: "center",
              border: { pt: 1, color: "ffffff" },
            });
          }
        // } else if (
        //   (index % 10 === 0 || index === uniqueList1?.length - 1) &&
        //   index !== 0
        // ) {
        //   console.log("I am getting called!!!", index, uniqueList1?.length - 1);
        //   slide.addTable(arrTabRows1, {
        //     x: 5,
        //     y: 1.5,
        //     w: 4,
        //     rowH: [0.3],
        //     fill: { color: "F7F7F7" },
        //     color: "6c6c6c",
        //     fontSize: 10,
        //     align: "center",
        //     border: { pt: 1, color: "ffffff" },
        //   });
        //   if (index !== uniqueList1?.length - 1 && index !== 0) {
        //     slide = pres.addSlide();
        //     slide.addText(`Top Threads (Total ${type}) Cont..`, objOpts3);
        //     arrTabRows1 = [
        //       [
        //         { text: "Site", options: tableHeaderStyles },
        //         { text: "Title", options: tableHeaderStyles },
        //         { text: "Count", options: tableHeaderStyles },
        //       ],
        //     ];
        //     arrTabRows1.push([
        //       { text: item?.site, options: tableCellStyles },
        //       {
        //         text: item?.title,
        //         options: tableCellStyles,
        //       },
        //       {
        //         text: type === "engagement" ? item?.engagementCount : item?.roc,
        //         options: tableCellStyles,
        //       },
        //     ]);
        //   }
        // }
      });

      slide.addTable(arrTabRows1, {
        x: 5,
        y: 1.5,
        w: 4,
        rowH: [0.3],
        fill: { color: "F7F7F7" },
        color: "6c6c6c",
        fontSize: 10,
        align: "center",
        border: { pt: 1, color: "ffffff" },
        autoPage: true,
        autoPageRepeatHeader: true,
        autoPageLineWeight:0.5
      });
    }
  };

  const addSentimentOverviewSlide = (pres: any, objOpts3: any) => {
    if (sentiments?.length || compareSentiments?.length) {
      let slide = pres.addSlide();
      slide.addText(`Sentiment Summary`, objOpts3);
      let taskOptions = {
        x: 0.5,
        y: 1.3,
        w: 4.0,
        margin: 0,
        fontSize: 14,
        fontFace: "Arial",
        color: "29D1D7",
      };

      slide.addText(`${task?.task_name}`, taskOptions);

      let compareTaskOptions = {
        x: 5,
        y: 1.3,
        w: 4.0,
        margin: 0,
        fontSize: 14,
        fontFace: "Arial",
        color: "ffcc4d",
      };

      slide.addText(`${compareTask?.task_name}`, compareTaskOptions);
      const positive  = sentiments?.reduce((sum, item) => sum + item.positive, 0) || 0;
      const neutral = sentiments?.reduce((sum, item) => sum + item.neutral, 0) || 0;
      const negative = sentiments?.reduce((sum, item) => sum + item.negative, 0) || 0;
      const sum = positive + neutral + negative;
      let arrTabRows: any = [
        [
          { text: "", options: tableHeaderStyles },
          { text: `Percentage %`, options: tableHeaderStyles },
        ],
        [
          { text: "Positive", options: tableCellStyles },
          {
            text: `${Math.round((positive / sum) * 100)}%`,
            options: tableCellStyles,
          },
        ],
        [
          { text: "Neutral", options: tableCellStyles },
          {
            text: `${Math.round((neutral / sum) * 100)}%`,
            options: tableCellStyles,
          },
        ],
        [
          { text: "Negative", options: tableCellStyles },
          {
            text: `${Math.round((negative / sum) * 100)}%`,
            options: tableCellStyles,
          },
        ],
      ];
      slide.addTable(arrTabRows, {
        x: 0.5,
        y: 1.5,
        w: 4,
        rowH: [0.3],
        fill: { color: "F7F7F7" },
        color: "6c6c6c",
        fontSize: 10,
        align: "center",
        border: { pt: 1, color: "ffffff" },
      });

      const comparePositive =
        compareSentiments?.reduce((sum, item) => sum + item.positive, 0) || 0;
      const compareNeutral =
        compareSentiments?.reduce((sum, item) => sum + item.neutral, 0) || 0;
      const compareNegative =
        compareSentiments?.reduce((sum, item) => sum + item.negative, 0) || 0;

      const compareSum = comparePositive + compareNeutral + compareNegative;
      let arrTabRows1: any = [
        [
          { text: "", options: tableHeaderStyles },
          { text: `Percentage %`, options: tableHeaderStyles },
        ],
        [
          { text: "Positive", options: tableCellStyles },
          {
            text: `${Math.round((comparePositive / compareSum) * 100)}%`,
            options: tableCellStyles,
          },
        ],
        [
          { text: "Neutral", options: tableCellStyles },
          {
            text: `${Math.round((compareNeutral / compareSum) * 100)}%`,
            options: tableCellStyles,
          },
        ],
        [
          { text: "Negative", options: tableCellStyles },
          {
            text: `${Math.round((compareNegative / compareSum) * 100)}%`,
            options: tableCellStyles,
          },
        ],
      ];
      slide.addTable(arrTabRows1, {
        x: 5,
        y: 1.5,
        w: 4,
        rowH: [0.3],
        fill: { color: "F7F7F7" },
        color: "6c6c6c",
        fontSize: 10,
        align: "center",
        border: { pt: 1, color: "ffffff" },
        
      });
    }
  };

  const addIntelligentWorldCloudSlide = (pres: any, objOpts3: any) => {
    if (topWords?.length || topCompareWords?.length) {
      let slide = pres.addSlide();
      slide.addText(`Intelligent Word Cloud`, objOpts3);
      let taskOptions = {
        x: 0.5,
        y: 1.3,
        w: 4.0,
        margin: 0,
        fontSize: 14,
        fontFace: "Arial",
        color: "29D1D7",
      };

      slide.addText(`${task?.task_name}`, taskOptions);

      let compareTaskOptions = {
        x: 5,
        y: 1.3,
        w: 4.0,
        margin: 0,
        fontSize: 14,
        fontFace: "Arial",
        color: "ffcc4d",
      };

      slide.addText(`${compareTask?.task_name}`, compareTaskOptions);
      let arrTabRows: any = [
        [
          { text: "Word", options: tableHeaderStyles },
          { text: "Count", options: tableHeaderStyles },
        ],
      ];
      let arrTabRows1: any = [
        [
          { text: "Word", options: tableHeaderStyles },
          { text: "Count", options: tableHeaderStyles },
        ],
      ];
      topWords?.forEach((item: any, index: number) => {
        // if (index % 15 !== 0 || index === 0) {
        
          arrTabRows.push([
            {
              text: item?.word,
              options: tableCellStyles,
            },
            {
              text: item?.count,
              options: tableCellStyles,
            },
          ]);
          // if (index === topWords?.length - 1) {
           
          // }
        // } 
        // else if (
        //   (index % 15 === 0 || index === topWords?.length - 1) &&
        //   index !== 0
        // ) {
        //   slide.addTable(arrTabRows, {
        //     x: 0.5,
        //     y: 1.1,
        //     rowH: [0.3],
        //     colW: [3],
        //     fill: { color: "F7F7F7" },
        //     color: "6c6c6c",
        //     fontSize: 10,
        //     align: "center",
        //     border: { pt: 1, color: "ffffff" },
        //   });
        //   if (index !== topWords?.length - 1 && index !== 0) {
        //     slide = pres.addSlide();
        //     slide.addText(`Intelligent Word Cloud Cont..`, objOpts3);
        //     arrTabRows = [
        //       [
        //         { text: "Word", options: tableHeaderStyles },
        //         { text: "Count", options: tableHeaderStyles },
        //       ],
        //     ];
        //     arrTabRows.push([
        //       {
        //         text: item?.word,
        //         options: tableCellStyles,
        //       },
        //       {
        //         text: item?.count,
        //         options: tableCellStyles,
        //       },
        //     ]);
        //   }
        // }
      });

      topCompareWords?.forEach((item: any, index: number) => {
        // if (index % 15 !== 0 || index === 0) {

          arrTabRows1.push([
            {
              text: item?.word,
              options: tableCellStyles,
            },
            {
              text: item?.count,
              options: tableCellStyles,
            },
          ]);
          // if (index === topWords?.length - 1) {
           
          // }
        // } 
        // else if (
        //   (index % 15 === 0 || index === topWords?.length - 1) &&
        //   index !== 0
        // ) {
        //   slide.addTable(arrTabRows, {
        //     x: 0.5,
        //     y: 1.1,
        //     rowH: [0.3],
        //     colW: [3],
        //     fill: { color: "F7F7F7" },
        //     color: "6c6c6c",
        //     fontSize: 10,
        //     align: "center",
        //     border: { pt: 1, color: "ffffff" },
        //   });
        //   if (index !== topWords?.length - 1 && index !== 0) {
        //     slide = pres.addSlide();
        //     slide.addText(`Intelligent Word Cloud Cont..`, objOpts3);
        //     arrTabRows = [
        //       [
        //         { text: "Word", options: tableHeaderStyles },
        //         { text: "Count", options: tableHeaderStyles },
        //       ],
        //     ];
        //     arrTabRows.push([
        //       {
        //         text: item?.word,
        //         options: tableCellStyles,
        //       },
        //       {
        //         text: item?.count,
        //         options: tableCellStyles,
        //       },
        //     ]);
        //   }
        // }
      });
      slide.addTable(arrTabRows, {
        x: 0.5,
        y: 1.5,
        w: 4,
        rowH: [0.3],
        fill: { color: "F7F7F7" },
        color: "6c6c6c",
        fontSize: 10,
        align: "center",
        border: { pt: 1, color: "ffffff" },
        autoPage: true,
autoPageRepeatHeader: true,
      });

      slide.addTable(arrTabRows1, {
        x: 5,
        y: 1.5,
        w: 4,
        rowH: [0.3],
        fill: { color: "F7F7F7" },
        color: "6c6c6c",
        fontSize: 10,
        align: "center",
        border: { pt: 1, color: "ffffff" },
        autoPage: true,
autoPageRepeatHeader: true,
      });
    }
  };

  const addTopTrends = (pres: any, objOpts3: any) => {
    if (trendData?.length) {
      const formattedTopics = trendData
        ? trendData.length
          ? trendData[0].result.slice().map((topic) => {
              return {
                topicString: [topic.word, ...(topic.related_word ?? [])].reduce(
                  (string, word) => `${string}${word ? ` · ${word}` : ""}`
                ),
                score: topic.ai_score,
                commentScore: topic.doc_freq,
              };
            })
          : []
        : null;

      const sortedTopics = formattedTopics
        ? formattedTopics.sort(uf.obj_sort("commentScore", false, true))
        : null;
      let slide = pres.addSlide();
      slide.addText(`Trending Topic`, objOpts3);
      let arrTabRows: any = [
        [
          { text: "Rank", options: tableHeaderStyles },
          { text: "Topic", options: tableHeaderStyles },
          { text: "Score", options: tableHeaderStyles },
        ],
      ];
      sortedTopics?.forEach((item: any, index: number) => {
        if (index % 13 !== 0 || index === 0) {
          console.log("I am getting called!!", index, sortedTopics?.length - 1);
          arrTabRows.push([
            {
              text: index + 1,
              options: tableCellStyles,
            },
            {
              text: item?.topicString,
              options: tableCellStyles,
            },
            {
              text: `${(item?.commentScore * 100).toFixed(2)}%`,
              options: tableCellStyles,
            },
          ]);
          if (index === sortedTopics?.length - 1) {
            slide.addTable(arrTabRows, {
              x: 0.5,
              y: 1.1,
              rowH: [0.3],
              colW: [3],
              fill: { color: "F7F7F7" },
              color: "6c6c6c",
              fontSize: 10,
              align: "center",
              border: { pt: 1, color: "ffffff" },
            });
          }
        } else if (
          (index % 13 === 0 || index === sortedTopics?.length - 1) &&
          index !== 0
        ) {
          slide.addTable(arrTabRows, {
            x: 0.5,
            y: 1.1,
            rowH: [0.3],
            colW: [3],
            fill: { color: "F7F7F7" },
            color: "6c6c6c",
            fontSize: 10,
            align: "center",
            border: { pt: 1, color: "ffffff" },
          });
          if (index !== sortedTopics?.length - 1 && index !== 0) {
            slide = pres.addSlide();
            slide.addText(`Intelligent Word Cloud Cont..`, objOpts3);
            arrTabRows = [
              [
                { text: "Rank", options: tableHeaderStyles },
                { text: "Topic", options: tableHeaderStyles },
                { text: "Score", options: tableHeaderStyles },
              ],
            ];
            arrTabRows.push([
              {
                text: index + 1,
                options: tableCellStyles,
              },
              {
                text: item?.topicString,
                options: tableCellStyles,
              },
              {
                text: `${(item?.commentScore * 100).toFixed(2)}%`,
                options: tableCellStyles,
              },
            ]);
          }
        }
      });
    }
  };

  const addSentimentTimeline = (pres: any, objOpts3: any) => {
    let slide = pres.addSlide();
    slide.addText(`Sentiment Overview (Percentage)`, objOpts3);
    const data = sentiments?.map(
      ({ timestamp, positive, neutral, negative }, index) => {
        const sum = positive + neutral + negative;
        const positivePercent = Math.round((positive / sum) * 100) || 0;
        const negativePercent = Math.round((negative / sum) * 100) || 0;
        return {
          name:
            feedType === 0
              ? generateLiveChartPointLabel(timestamp, dateRange)
              : generateAdHocChartPointLabel(timestamp, interval),
          positive: positivePercent,
          neutral: 100 - positivePercent - negativePercent,
          negative: negativePercent,
          start:
            feedType === 0
              ? generateStartOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateStartOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
          end:
            feedType === 0
              ? generateEndOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateEndOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
        };
      }
    );

    const compareData = compareSentiments?.map(
      ({ timestamp, positive, neutral, negative }, index) => {
        const sum = positive + neutral + negative;
        const positivePercent = Math.round((positive / sum) * 100) || 0;
        const negativePercent = Math.round((negative / sum) * 100) || 0;
        return {
          name: generateAdHocChartPointLabel(timestamp, interval),
          positive: positivePercent,
          neutral: 100 - positivePercent - negativePercent,
          negative: negativePercent,
          start: generateStartOfRangeFromIntervalUnixTimestamp(
            timestamp,
            convertIntervalStepAndIntervalToDateRange(intervalStep!, interval!)
          ),
          end: generateEndOfRangeFromIntervalUnixTimestamp(
            timestamp,
            convertIntervalStepAndIntervalToDateRange(intervalStep!, interval!)
          ),
        };
      }
    );
    let optsChart = {
      x: 0.5,
      y: 1.1,
      w: "45%",
      h: "70%",
      barDir: "col",
      barGrouping: "stacked",
      chartColors: COLORS_CHART,
      invertedColors: ["C0504D"],
      showLegend: true,
      //
      showTitle: true,
      title: `${task.task_name}`,
      titleFontFace: "Arial",
      titleFontSize: 14,
      titleColor: "29D1D7",
      titlePos: { x: 1.5, y: 0 },
      lineDataSymbolSize: 10,
      shadow: { type: "none" },
      legendPos: "b",
      //titleRotate: 10,
      //
      showCatAxisTitle: true,
      catAxisLabelColor: COLORS_ACCENT[1],
      catAxisTitleColor: COLORS_ACCENT[1],
      catAxisTitle: "Date",
      catAxisTitleFontSize: 14,
      //
      showValAxisTitle: true,
      valAxisLabelColor: COLORS_ACCENT[2],
      valAxisTitleColor: COLORS_ACCENT[2],
      valAxisTitle: "Percentage(%)",
      valAxisTitleFontSize: 14,
    };

    const dataChartBar8Series: any = [
      { name: "Positive", labels: [], values: [] },
      { name: "Neutral", labels: [], values: [] },
      { name: "Negative", labels: [], values: [] },
    ];
    let labels: string[] = [];
    data?.forEach((item: any) => {
      labels.push(item?.name);
      dataChartBar8Series[0].values.push(item?.positive);
      dataChartBar8Series[1].values.push(item?.neutral);
      dataChartBar8Series[2].values.push(item?.negative);
    });

    dataChartBar8Series[0].labels = labels;
    dataChartBar8Series[1].labels = labels;
    dataChartBar8Series[2].labels = labels;
    slide.addChart(pres.charts.BAR, dataChartBar8Series, optsChart);

    
    let optsChart1 = {
      x: 5,
      y: 1.1,
      w: "45%",
      h: "70%",
      barDir: "col",
      barGrouping: "stacked",
      chartColors: COLORS_CHART,
      invertedColors: ["C0504D"],
      showLegend: true,
      legendPos: "b",
      //
      showTitle: true,
      title: `${compareTask.task_name}`,
      titleFontFace: "Arial",
      titleFontSize: 14,
      titleColor: "ffcc4d",
      titlePos: { x: 1.5, y: 0 },
      lineDataSymbolSize: 10,
      shadow: { type: "none" },
      //titleRotate: 10,
      //
      showCatAxisTitle: true,
      catAxisLabelColor: COLORS_ACCENT[1],
      catAxisTitleColor: COLORS_ACCENT[1],
      catAxisTitle: "Date",
      catAxisTitleFontSize: 14,
      //
      showValAxisTitle: true,
      valAxisLabelColor: COLORS_ACCENT[2],
      valAxisTitleColor: COLORS_ACCENT[2],
      valAxisTitle: "Percentage(%)",
      valAxisTitleFontSize: 14,
    };

    const dataChartBar8Series1: any = [
      { name: "Positive", labels: [], values: [] },
      { name: "Neutral", labels: [], values: [] },
      { name: "Negative", labels: [], values: [] },
    ];
    let labels1: string[] = [];
    compareData?.forEach((item: any) => {

      labels1.push(item?.name);
      dataChartBar8Series1[0].values.push(item?.positive);
      dataChartBar8Series1[1].values.push(item?.neutral);
      dataChartBar8Series1[2].values.push(item?.negative);
    });

    dataChartBar8Series1[0].labels = labels1;
    dataChartBar8Series1[1].labels = labels1;
    dataChartBar8Series1[2].labels = labels1;

    console.log("Labels Text", labels, labels1, compareData, data)
    slide.addChart(pres.charts.BAR, dataChartBar8Series1, optsChart1);
  };

  const addSentimentTimelineVolume = (pres: any, objOpts3: any) => {
    let slide = pres.addSlide();
    slide.addText(`Sentiment Overview (Volume)`, objOpts3);
    const data = sentiments?.map(
      ({ timestamp, positive, neutral, negative }, index) => {
        return {
          name:
            feedType === 0
              ? generateLiveChartPointLabel(timestamp, dateRange)
              : generateAdHocChartPointLabel(timestamp, interval),
          positive,
          neutral,
          negative,
          start:
            feedType === 0
              ? generateStartOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateStartOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
          end:
            feedType === 0
              ? generateEndOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateEndOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
        };
      }
    );

    const compareData = compareSentiments?.map(
      ({ timestamp, positive, neutral, negative }, index) => {
        return {
          name:
            feedType === 0
              ? generateLiveChartPointLabel(timestamp, dateRange)
              : generateAdHocChartPointLabel(timestamp, interval),
          positive,
          neutral,
          negative,
          start:
            feedType === 0
              ? generateStartOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateStartOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
          end:
            feedType === 0
              ? generateEndOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateEndOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
        };
      }
    );

    // FULL SLIDE:
    const OPTS_CHART = {
      x: 0.5,
      y: 1.1,
      w: "45%",
      h: "80%",
      plotArea: { fill: { color: "F2F9FC" } },
      chartColors: COLORS_CHART,
      //
      showLegend: true,
      legendPos: "b",
      //
      showTitle: true,
      lineDataSymbol: "none",
      title: `${task?.task_name}`,
      titleColor: COMPARE_COLORS[0],
      titleFontFace: "Arial",
      titleFontSize: 14,
    };

    const OPTS_CHART1 = {
      x: 5,
      y: 1.1,
      w: "45%",
      h: "85%",
      plotArea: { fill: { color: "F2F9FC" } },
      chartColors: COLORS_CHART,
      //
      showLegend: true,
      legendPos: "b",
      //
      showTitle: true,
      lineDataSymbol: "none",
      title: `${compareTask?.task_name}`,
      titleColor: COMPARE_COLORS[1],
      titleFontFace: "Arial",
      titleFontSize: 14,
    };

    const dataChartBar8Series: any = [
      { name: "Positive", labels: [], values: [] },
      { name: "Neutral", labels: [], values: [] },
      { name: "Negative", labels: [], values: [] },
    ];
    let labels: string[] = [];
    data?.forEach((item: any) => {
      console.log("Item Name", item, dataChartBar8Series);
      labels.push(item?.name);
      dataChartBar8Series[0].values.push(item?.positive);
      dataChartBar8Series[1].values.push(item?.neutral);
      dataChartBar8Series[2].values.push(item?.negative);
    });
    dataChartBar8Series[0].labels = labels;
    dataChartBar8Series[1].labels = labels;
    dataChartBar8Series[2].labels = labels;



    const dataChartBar8Series1: any = [
      { name: "Positive", labels: [], values: [] },
      { name: "Neutral", labels: [], values: [] },
      { name: "Negative", labels: [], values: [] },
    ];
    let labels1: string[] = [];
    compareData?.forEach((item: any) => {
      console.log("Item Name", item, dataChartBar8Series);
      labels1.push(item?.name);
      dataChartBar8Series1[0].values.push(item?.positive);
      dataChartBar8Series1[1].values.push(item?.neutral);
      dataChartBar8Series1[2].values.push(item?.negative);
    });
    dataChartBar8Series1[0].labels = labels1;
    dataChartBar8Series1[1].labels = labels1;
    dataChartBar8Series1[2].labels = labels1;
    slide.addChart(pres.charts.LINE, dataChartBar8Series, OPTS_CHART);
    slide.addChart(pres.charts.LINE, dataChartBar8Series1, OPTS_CHART1);
  };

  const addThreadCount = (
    pres: any,
    objOpts3: any,
    type: string,
    title: string
  ) => {
    console.log("Formatted Line Graph", formatted);

    let slide = pres.addSlide();

    // FULL SLIDE:
    const OPTS_CHART = {
      x: 0.5,
      y: 0.5,
      w: "95%",
      h: "85%",
      plotArea: { fill: { color: "F2F9FC" } },
      chartColors: ["29d1d7", "ffcc4d"],
      dataBorder: { pt: 1, color: "29d1d7" },
      chartColorsOpacity: 25,
      //
      showLegend: true,
      legendPos: "r",
      //
      showTitle: true,
      lineDataSymbol: "none",
      title: title,
      titleColor: "0088CC",
      titleFontFace: "Arial",
      titleFontSize: 18,
    };
    let dataChartBar8Series: any = [];
    if (type === "threads") {
      dataChartBar8Series = [
        { name: `${task?.task_name} Threads`, labels: [], values: [] },
        { name: `${compareTask?.task_name} Threads`, labels: [], values: [] },
      ];
    }
    if (type === "comments") {
      dataChartBar8Series = [
        { name: `${task?.task_name} Comments`, labels: [], values: [] },
        { name: `${compareTask?.task_name} Comments`, labels: [], values: [] },
      ];
    }
    if (type === "total") {
      dataChartBar8Series = [
        { name: `${task?.task_name} Total`, labels: [], values: [] },
        { name: `${compareTask?.task_name} Total`, labels: [], values: [] },
      ];
    }

    let labels: string[] = [];
    formatted?.forEach((item: any) => {
      console.log("Item Name", item, dataChartBar8Series);
      labels.push(item?.label);
      if (type === "threads") {
        dataChartBar8Series[0].values.push(item?.threadCount);
      }
      if (type === "comments") {
        dataChartBar8Series[0].values.push(item?.commentCount);
      }
      if (type === "total") {
        dataChartBar8Series[0].values.push(item?.postCount);
      }
    });
    compareFormatted?.forEach((item: any) => {
      console.log("Item Name", item, dataChartBar8Series);
      if (type === "threads") {
        dataChartBar8Series[1].values.push(item?.threadCount);
      }
      if (type === "comments") {
        dataChartBar8Series[1].values.push(item?.commentCount);
      }
      if (type === "total") {
        dataChartBar8Series[1].values.push(item?.postCount);
      }
    });
    dataChartBar8Series[1].labels = labels;

    slide.addChart(pres.charts.AREA, dataChartBar8Series, OPTS_CHART);
  };

  const downloadFile = async (blob: any) => {
    const URL = window.URL.createObjectURL(blob);
    const el = document.createElement("a");
    el.download = `compare_${task?.task_name}-${compareTask.task_name}.pdf`;
    el.href = URL;
    el.click();
    window.URL.revokeObjectURL(URL);
  };

  const downloadImage = (blob: any, fileName: any) => {
    let fakeLink = window.document.createElement("a");

    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();

    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  return (
    <>
    <img className={styles.img} src={logo} alt="logo" id="product_logo"/>
    <HeadRoot>
        
        <div
          id={styles.pagination}
          style={{ display: "flex", alignItems: "center" }}
        >
          <span
            style={{
              color: "#29D1D7",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
            //   className={tabActive("overview") ? "active" : ""}
            //   onClick={() => history.push(`${currPath}/overview`)}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "10px",
                backgroundColor: "#29D1D7",
                marginRight: "7px",
              }}
            ></div>
            {task.task_name}
          </span>
          <span
            style={{
              color: "#ffcc4d",
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
            }}
            //   className={tabActive("posts") ? "active" : ""}
            //   onClick={() => history.push(`${currPath}/posts`)}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "10px",
                backgroundColor: "#ffcc4d",
                marginRight: "5px",
              }}
            ></div>
            <div
              className={
                "dropdown is-right" + (dropdownExpanded ? " is-active" : "")
              }
              id={styles.dropdownContainer}
            >
              <div
                className="dropdown-trigger"
                onClick={handleClickExpand}
                id={styles.dropdownTriggerContainer}
              >
                <button
                  className="button"
                  aria-haspopup="true"
                  aria-controls="taskMenu"
                  id={styles.dropdownButton}
                >
                  <span className={styles.chosen}>{compareTask.task_name}</span>
                  <span className="icon is-small">
                    {dropdownExpanded ? (
                      <i className="fas fa-angle-up" aria-hidden="true" />
                    ) : (
                      <i className="fas fa-angle-down" aria-hidden="true" />
                    )}
                  </span>
                </button>
              </div>
              <div className="dropdown-menu" id="taskMenu" role="menu">
                <div
                  className="dropdown-content"
                  style={{
                    minHeight: "200px",
                    height: "200px",
                    overflowY: "auto",
                  }}
                >
                  {getAdhocTasks().map((task: any) => {
                    return (
                      <div
                        key={task?.task_id}
                        className={"dropdown-item " + styles.dropdownOption}
                        onClick={() => {
                          dispatch(
                            setCompareDisplayTask({
                              ...task,
                              taskType: task.task_type,
                            })
                          );
                          dispatch(
                            fetchCompareOverviewData({
                              start: taskUnixStart,
                              end: taskUnixEnd,
                            })
                          );
                          // setSortOption(sortOption);
                          // setPage(0);
                        }}
                      >
                        {task?.task_name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </span>
        </div>
        {feedType === 1 && (
          <div style={{ display: "flex" }}>
            <div style={{ flex: "1 1 auto" }} id="date_filter">
              <DateRangeFilter type="overview" />
            </div>
            <div className={styles["date-filter-text-container"]} id="date_filter_name">
                <div className={styles["date-filter-text"]}>{moment(taskUnixStart).format("MMM DD, YYYY")}</div>
                <div className={styles["date-filter-separator"]}>-</div>
                <div className={styles["date-filter-text"]}>{moment(taskUnixEnd).format("MMM DD, YYYY")}</div>
              </div>
           
            {/* <div id="export-button" style={{ marginLeft: "10px" }}>
             
              <StyledIconButton onClick={isAuthorLoaded && onExportIntoPPT}>
                  <ExportpptIcon height="2.5rem" width="2.5rem" fill={isAuthorLoaded ? "#516380": "#eee" } />
                </StyledIconButton>
            </div> */}
            <div id="export-button" style={{ marginLeft: "10px" }}>
              {/* <ComponentButton
                onClick={onTakeScreenshot}
                disabled={!isAuthorLoaded}
              >
                Export as PDF
              </ComponentButton> */}
               <StyledIconButton onClick={isAuthorLoaded && onTakeScreenshot}>
                  <ExportpdfIcon height="2.3rem" width="2.3rem" fill={isAuthorLoaded ? "#516380": "#eee" } />
                </StyledIconButton>
            </div>
          </div>
        )}
        <div></div>
      </HeadRoot>
    </>
   
  );
};

export default Head;
