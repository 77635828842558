import styled from 'styled-components'

const BaseButton = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

export default BaseButton