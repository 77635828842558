import React from 'react'
import styled, { css } from 'styled-components'

interface SwitchProp {
  checked: boolean
  handleClick: () => void
}

const SwitchRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
`

const SwitchBase = styled.span<{ checked: boolean }>`
  z-index: 1;
  margin: 0px 1px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12);
  background-color: white;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${props => props.checked && (
    css`
      transform: translateX(19px);
    `
  )}
`

const SwitchTrack = styled.span<{ checked: boolean }>`
  position: absolute;
  border-radius: 11px;
  height: 22px;
  width: 40px;
  border: 1px solid #dbdbdb;
  ${props => props.checked ? (
    css`
      background-color: #29D1D7;
    `) : (
    css`
       background-color: #f2f2f2;
    `
  )}
`

function Switch({ checked, handleClick }: SwitchProp) {
  return (
    <SwitchRoot onClick={handleClick}>
      <SwitchTrack checked={checked}></SwitchTrack>
      <SwitchBase checked={checked}></SwitchBase>
    </SwitchRoot>
  )
}

export default Switch