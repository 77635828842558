import React from 'react'
import { TopicData } from '../../../../../interface'
import { usePostsStore } from '../../../../../postStore'
import RelatedPostCarousel from './RelatedPostCarousel'
import { ContextSummaryContainer } from './styled'
import SummaryRow from './SummaryRow'

const ContextSummary = ({topicData}: ContextSummaryProps) => {
  const frequency = usePostsStore(state => state.frequency)

  return (
    <ContextSummaryContainer>
      <SummaryRow 
        topicData={topicData}
        frequency={frequency}
      />
      <RelatedPostCarousel 
        topicData={topicData}
        frequency={frequency}
      />
    </ContextSummaryContainer>
  )
}

export default ContextSummary

interface ContextSummaryProps{
  topicData: TopicData
}