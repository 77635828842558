import React, { useState, useContext, useMemo } from "react"
import { FeedDataContent } from "./interface"
import { showThread } from "../../../../../../ctx/action"
import Ctx, { context } from "../../../../../../ctx"
import styles from "./content.module.scss"
import { selectIsHighlightingKeyword } from "../../../../../../../../app/store/livefeedpost"
import { useSelector } from "react-redux"
import { selectFeedKeywords } from "../../../../../../../../app/store/feedSlice"
import EQL2RG from "tshl"
import ReactHtmlParser from 'react-html-parser'
import "./keyword.css"

interface ContentProp {
    content: FeedDataContent
}

const Content = (props: ContentProp) => {
    const MAX_TITLE_LENGTH = 25
    const MESSAGE_LIMIT = 100

    const isHighlightingKeyword = useSelector(selectIsHighlightingKeyword)
    const keywords = useSelector(selectFeedKeywords)
    const EQ = new EQL2RG(keywords?.guided ?? [], false)

    const [expand, setExpand] = useState(false)

    const expandText = expand ? "See Less ..." : "See More ..."

    const thread_title = useMemo(() => {
        if (!props.content.thread_title) {

            if (props.content.thread_link) {
                return (props.content.post_message ?? "").slice(0, MAX_TITLE_LENGTH) + " ..."
            }

            return ""
        }
        if (props.content.thread_title.length > MAX_TITLE_LENGTH) return props.content.thread_title.slice(0, MAX_TITLE_LENGTH) + " ..."
        return props.content.thread_title
    }, [props.content.post_message, props.content.thread_link, props.content.thread_title])

    const postMessage = useMemo(() => {
        if (expand) return props.content.post_message
        return (props.content.post_message ?? "").slice(0, MESSAGE_LIMIT)
    }, [expand, props.content.post_message])

    const ctx = useContext(Ctx) as context
    const currThreadLink = ctx.feed.threadviewer.thread_link

    const clickThread = () => {
        if (currThreadLink !== props.content.thread_link) {
            showThread({
                thread_title: (props.content.thread_title ?? ""),
                thread_link: (props.content.thread_link ?? ""),
                unix_timestamp: props.content.unix_timestamp
            }, ctx.setFeed)
        }
    }


    return (
        <div className={styles.content}>
            <div className={styles.title}>
                {props.content.is_comment ? <p>Replying to :</p> : null}
                <a className="has-tooltip-arrow has-tooltip-bottom" data-tooltip={props.content.thread_title} href={props.content.thread_link ?? ""} target="_blank" rel="noopener noreferrer">
                    {isHighlightingKeyword ? ReactHtmlParser(EQ.html_wrap(thread_title ?? "", "span", "keyword")) : thread_title}
                </a>
            </div>

            <div className={styles.message} onClick={clickThread}>
                <p>{isHighlightingKeyword ? ReactHtmlParser(EQ.html_wrap(postMessage ?? "", "span", "keyword")) : postMessage}</p>
            </div>
            {
                (props.content.post_message ?? "").length > MESSAGE_LIMIT ? <span onClick={() => setExpand(!expand)}>{expandText}</span> : null
            }

        </div>
    )
}

export default Content