import React from 'react'
import styled from 'styled-components'
import UpgradeIcon from "../icons/UpgradeIcon"
import ModalHeader from "../utils/Modal/ModalHeader"
import ModalMain from "../utils/Modal/ModalMain"
import ModalRoot from "../utils/Modal/ModalRoot"

export type NoticeType = "feedLimit" | "exportLimit" | "trial" | "general" | "modificationLimit"
interface UpgradePlanNoticeProp {
  onClose: () => void
  noticeType: NoticeType
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0rem 2rem 4rem 2rem;
  
  div {
    margin-bottom: 1rem;
    color: #54637E;
    h3 {
      font-size: 20px;
      font-weight: 500;

    }
    p {
      margin-top: 0.5rem;
      font-weight: 400;
    }
  }



  p {
    font-size: 14px;
    font-weight: 300;
    color: #516380;
    span {
      text-decoration: underline;
    }
  }
`

function UpgradePlanNotice({ onClose, noticeType }: UpgradePlanNoticeProp) {
  const maximumOfFeed = 3
  return (
    <ModalRoot show={true}>
      <ModalMain>
        <ModalHeader title="" onClose={onClose} />
        <ContentContainer>
          <div>
            <UpgradeIcon height="50px" width="57px" />
            <h3>Upgrade your Plan</h3>
            {noticeType === "general" && <p></p>}
            {noticeType === "feedLimit" && <p>You have reached your maximum amount of<br />feed in your plan ({maximumOfFeed}).</p>}
            {(noticeType === "trial" || noticeType === "exportLimit") && <p>Subscribe to create more feed, and export feed data.</p>}
            {noticeType === "modificationLimit" && <p>You have reached the <b>maximum amount of<br />monthly changes</b>.</p>}
          </div>
          <p>Contact us at 3705-1012 or email us via <span>lenxcare@lenx.ai</span> to<br />upgrade your plan.</p>
        </ContentContainer>
      </ModalMain>
    </ModalRoot>
  )
}

export default UpgradePlanNotice
