import { ESQLayer } from "../interface";

class ESConvert {
    #data:ESQLayer[] = [];
    constructor(data:ESQLayer[]=[]){
        this.#data = data;
    }

    #in = (x:(string|string[])[]):string => {
        if (Array.isArray(x[0])) {
            return `${x.map(y => `(${this.#in(y as string[])})`).join(" AND ")}`;
        }
        return `${x.map(y => `(${y})`).join(" OR ")}`;
    }
    
    #ex = (x:(string|string[])[]):string => `NOT (${this.#in(x)})`;
    
    #main = (x:ESQLayer):string => Object.keys(x).filter(j => j !== "ex").map(k => {
        switch (k.toLowerCase()) {
            case "in": {
                return this.#in(x.in!);
            }
            default:
                return "";
        }
    }).join(" OR ") + (x.ex ? `${this.#ex(x.ex)}` : " ");

    convert = ():string=>{
        const no_in = this.#data.filter(x => !Object.keys(x).includes("in")); // just exclude
        const iv_no_in = this.#data.filter(y => !no_in.includes(y) ); // inverse of the above

        return iv_no_in.map(d => `(${
            this.#main(d)})`).join(" OR ") + no_in.map(d1 => this.#main(d1)).join(" ");
    }
}

export default ESConvert;