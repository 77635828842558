import React from 'react'
import { shorttxt } from '../../../../app/tools/string'
import Skeleton from '../../../reusable/utils/Skeleton'
import { usePostsStore } from '../../postStore'
import { SectionBody, SectionHeader, TopicSummaryRoot } from "../styled"

function TopicSummary() {
  const { overviewPost } = usePostsStore(state => ({overviewPost: state.overviewPost}))
  
  return (
    <TopicSummaryRoot>
      <SectionHeader>
        <h3>Selected Post</h3>
      </SectionHeader>
      <SectionBody>
        {overviewPost ? (
          <p>{shorttxt(overviewPost.summary, 500)}</p>
        ):(
          <div style={{display: 'flex'}}>
            <Skeleton height="1rem" width="4rem" margin="0 1rem 0 0" />
            <Skeleton height="1rem" width="6rem" margin="0 1rem 0 0" />
            <Skeleton height="1rem" width="3rem" margin="0 1rem 0 0" />
            <Skeleton height="1rem" width="4rem" margin="0 1rem 0 0" />
            <Skeleton height="1rem" width="2rem" margin="0 1rem 0 0" />
            <Skeleton height="1rem" width="3rem" margin="0 1rem 0 0" />
            <Skeleton height="1rem" width="6rem" margin="0 1rem 0 0" />
            <Skeleton height="1rem" width="2rem" margin="0 1rem 0 0" />
            <Skeleton height="1rem" width="3rem" margin="0 1rem 0 0" />
            <Skeleton height="1rem" width="4rem" margin="0 1rem 0 0" />
            <Skeleton height="1rem" width="3rem" margin="0 1rem 0 0" />
          </div>
        )}
      </SectionBody>
    </TopicSummaryRoot>
  )
}

export default React.memo(TopicSummary)
