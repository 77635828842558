import styled, {css} from 'styled-components'

const ModalMain = styled.section<{width?: number, backgroundColor?:string, height?: number, maxHeight?: string}>`
  position: fixed;
  top:50%;
  left:50%;
  background: ${props => props.backgroundColor ?? "white"};
  width: ${props => props.width ? `${props.width}px` : "500px"};
  height: ${props => props.height ? `${props.height}px` : "auto"};
  ${props => props.maxHeight && css`
    max-height: ${props.maxHeight};
  `}
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(5, 5, 5, 0.8);
  transform: translate(-50%,-50%);
  transition: all ease-out 250ms;
`

export default ModalMain