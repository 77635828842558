import styled from 'styled-components'

export const OverviewRoot = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.5rem 4rem;
    overflow-y: auto;
    column-gap: 1rem;
    row-gap: 1rem;
    @media only screen and (max-width: 1024px) {
        padding: 1.25rem 2rem;
    }
`

export const HeaderContainer = styled.div`
    width: 100%;
`

export const StatsContainer = styled.div`
    width: 100%;
    align-self: center;
    justify-self: center;
`

export const LineGraphContainer = styled.div`
    width: 100%;
    align-self: center;
    justify-self: center;
`

export const HorizontalFlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    row-gap: 1rem;
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }
`

export const VerticalLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    row-gap: 1rem;
    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
`

export const VerticalRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    row-gap: 1rem;
    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
`

export const GadgetRoot = styled.section`
    background-color: white;
    width: 100%;
`

export const GadgetHeader = styled.header`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 41px;
    border-bottom: 1px solid #f8f8fa;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
        font-weight: 600;
    }
`

export const GadgetBody = styled.main`
    height: 17.5rem;
`


export const RowGridContainer = styled.div<{ $isClickable?: boolean }>`
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  width: 100%;
  height: 40px;
  cursor: ${props => props.$isClickable ? "pointer" : "default"};
  column-gap: 0.5rem;
  &:hover {
      background-color: #f5f5f5;
  }


  div:first-child {
    grid-column: 1 / 5;
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  div:nth-child(2) {
    grid-column: 5 / 13;
    padding: 7.2px 10.8px;
  }

  div:nth-child(3) {
    grid-column: 13 / 14;
    padding: 7.2px 10.8px;
  }
`