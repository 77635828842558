import React from "react";
import { TooltipProps } from "recharts";
import { siteicon } from "../../../../../../../app/tools/display";
import { LineGraphViewBY } from "../index";
import { uuidv4 } from "usefuljs";
import { DateRange } from "../../../../../../../app/interfaces/overview";
import { TaskType } from "../../../../editor/interface";

import styles from "../linegraph.module.scss";
import {
  deduplicateThreads,
  generateAdHocTimeRangeLabel,
  generateLiveTimeRangeLabel,
} from "./helper";
import {
  ContentContainer,
  ThreadItem,
  ThreadsContainer,
  TimeRangeContainer,
  TooltipContainer,
  TooltipSummary,
} from "./styled";
import { Interval } from "../../../interface";

interface CustomTooltipProp extends TooltipProps {
  viewBy: LineGraphViewBY;
  dateRange: DateRange;
  interval: Interval | null;
  feedType: TaskType | null;
}

function CustomTooltip(props: CustomTooltipProp) {
  console.log("Custom Tooltip value", props);
  const timestamp =
    props.payload !== undefined ? props.payload[0]?.payload?.point : "";

  const task1 =
    props.payload !== undefined ? props.payload[0]?.payload?.task1 : "";

  const commentCount =
    props.payload !== undefined ? props.payload[0]?.payload?.commentCount : "";
  const threadCount =
    props.payload !== undefined ? props.payload[0]?.payload?.threadCount : "";
  const postCount =
    props.payload !== undefined ? props.payload[0]?.payload?.postCount : "";

  const task2 =
    props.payload !== undefined ? props.payload[0]?.payload?.task2 : "";

  const compareCommentCount =
    props.payload !== undefined
      ? props.payload[0]?.payload?.compareCommentCount
      : "";
  const compareThreadCount =
    props.payload !== undefined
      ? props.payload[0]?.payload?.compareThreadCount
      : "";
  const comparePostCount =
    props.payload !== undefined
      ? props.payload[0]?.payload?.comparePostCount
      : "";

  const topThreads: {
    site: string;
    threadLink: string;
    threadTitle: string;
  }[] =
    props.payload !== undefined
      ? deduplicateThreads(props.payload[0]?.payload?.topThreads)
      : [];

  const compareTopThreads: {
    site: string;
    threadLink: string;
    threadTitle: string;
  }[] =
    props.payload !== undefined
      ? deduplicateThreads(props.payload[0]?.payload?.compareTopThreads)
      : [];

  function generateSummary() {
    switch (props.viewBy) {
      case "Threads":
        return (
          <p>
            There are <b>{threadCount}</b> threads with {commentCount} comments.
          </p>
        );
      case "Comments":
        return (
          <p>
            There are <b>{commentCount}</b> comments from {threadCount} threads.
          </p>
        );
      case "Threads and comments":
        return (
          <p>
            There are <b>{postCount}</b> posts with {threadCount} threads and{" "}
            {commentCount} comments.
          </p>
        );
    }
  }

  function generateCompareSummary() {
    switch (props.viewBy) {
      case "Threads":
        return (
          <p>
            There are <b>{compareThreadCount}</b> threads with{" "}
            {compareCommentCount} comments.
          </p>
        );
      case "Comments":
        return (
          <p>
            There are <b>{compareCommentCount}</b> comments from{" "}
            {compareThreadCount} threads.
          </p>
        );
      case "Threads and comments":
        return (
          <p>
            There are <b>{comparePostCount}</b> posts with {compareThreadCount}{" "}
            threads and {commentCount} comments.
          </p>
        );
    }
  }

  return (
    <TooltipContainer>
      <TimeRangeContainer>
        {props.feedType === 0
          ? generateLiveTimeRangeLabel(timestamp, props.dateRange)
          : generateAdHocTimeRangeLabel(timestamp, props.interval)}
      </TimeRangeContainer>
      <ContentContainer style={{ borderBottom: "1px grey solid" }}>
        <div
          className={styles["custom-tooltip-task-container"]}
          style={{ color: "#29d1d7" }}
        >
          {task1}
        </div>

        <TooltipSummary>{generateSummary()}</TooltipSummary>
        {commentCount !== 0 && (
          <ThreadsContainer>
            <p>Top {topThreads?.length ?? 0} threads with most comments:</p>
            {topThreads?.map((thread) => (
              <ThreadItem key={uuidv4()}>
                <img
                  src={siteicon(thread.site, "News")}
                  alt={`${thread.site} logo`}
                />
                <p>{thread.threadTitle}</p>
              </ThreadItem>
            ))}
          </ThreadsContainer>
        )}
      </ContentContainer>
      <ContentContainer>
        <div
          className={styles["custom-tooltip-task-container"]}
          style={{ color: "#ffd45d", marginTop: "5px" }}
        >
          {task2}
        </div>
        <TooltipSummary>{generateCompareSummary()}</TooltipSummary>
        {compareCommentCount !== 0 && (
          <ThreadsContainer>
            <p>
              Top {compareTopThreads?.length ?? 0} threads with most comments:
            </p>
            {compareTopThreads?.map((thread) => (
              <ThreadItem key={uuidv4()}>
                <img
                  src={siteicon(thread.site, "News")}
                  alt={`${thread.site} logo`}
                />
                <p>{thread.threadTitle}</p>
              </ThreadItem>
            ))}
          </ThreadsContainer>
        )}
      </ContentContainer>
    </TooltipContainer>
  );
}

export default CustomTooltip;
