import { DateRangeFilter } from "../../../../app/interfaces/misc"
import { Interval, IntervalStep } from "./interface"

export function calculateIntervalStep(range: DateRangeFilter): IntervalStep {
  const unixDifference = range.end - range.start
  const unixOneDay = 1000 * 60 * 60 * 24
  if (unixDifference < unixOneDay) {
    return "hourOnly"
  } else if (unixDifference < unixOneDay * 3) {
    return "hourDay"
  } else if (unixDifference < unixOneDay * 60) {
    return "dayOnly"
  } else if (unixDifference < unixOneDay * 730) {
    return "dayMonth"
  } else {
    return "monthYear"
  }
}

export function calculateDefaultInterval(step: IntervalStep): Interval {
  switch (step) {
    case "hourOnly":
      return "day"
    case "hourDay":
      return "hour"
    case "dayOnly":
      return "day"
    case "dayMonth":
      return "day"
    case "monthYear":
      return "month"
  }
}

export function mapIntervalToDateRangeCode(interval: Interval) {
  switch (interval) {
    case "hour":
      return 0
    case "day":
      return 1
    case "month":
      return 2
    case "year":
      return 3
  }
}
