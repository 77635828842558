import React from 'react'
import styled from 'styled-components'
import { siteicon } from '../../../../../../app/tools/display'

interface ThreadRowProp {
    site: string
    title: string
    count: number | string
}

export const RowRoot = styled.li`
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    &:not(:last-child) {
        border-bottom: 1px solid #f8f8fa;
    }
    &:hover {
        background-color: #f8f8fa;
    }

    >div:first-child {
        width: calc(100% - 100px);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        img {
            height: 20px;
            padding-right: 10px;
        }

        p {
            padding: 0.5rem;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    >div:nth-child(2) {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`

const ThreadRow = ({ site, title, count }: ThreadRowProp) => {

    return (
        <RowRoot>
            <div>
                <img src={siteicon(site, "News")} alt={`${site} logo`} />
                <p>{title}</p>
            </div>
            <div>
                <p>{count}</p>
            </div>
        </RowRoot>
    )
}

export default ThreadRow