import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { TaskInfo, Bookmark } from "../interfaces/feed";
import { store, RootState } from ".";
import uf from "usefuljs";
import { FeedTask, FeedState } from "../interfaces/feed";
import ESConvert from "../../components/feed/task/editor/qrybuilder/esconvert";
import { guided2view } from "../../components/feed/task/editor/qrybuilder/converter";
import { removeUser } from "./userSlice";
import {
  AccessesFromAPI,
  AccessFromAPI,
  UserInfoFromAPI,
  UserInfoFromAPIData,
} from "../interfaces/ResponseFromAPIs";
import { mapAccesses } from "../tools/mapping/feedMapping";
import { DateRangeFilter } from "../interfaces/misc";

const initialState: FeedState = {
  task_id: 0,
  task_name: "",
  bookmarks: [],
  has_task_access: false,
  isLoadingTaskAccess: true,
  access: [],
  fetchingAccess: true,
  taskType: null,
  postsExportedCount: null,
  feedModificationCount: null,
  settings: {
    type: null,
    name: null,
    indus_id: null,
    keywords: null,
    lang: "",
    mediums: [],
    unix_start: null,
    unix_end: null,
    frequency: null,
  },
  compare: {
    task_id: 0,
    task_name: "",
    taskType: 0,
  },
};

// action thunk
export const getAccess = createAsyncThunk(
  "feed/getAccess",
  async (_, thunkAPI) => {
    try {
      const token = store.getState().user.token;
      thunkAPI.dispatch(setFetchingAccess(true));
      const response: AccessesFromAPI = await uf.ajax({
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/users/liveaccess`,
      });

      if (response.result !== "success") {
        throw new Error("Failed to fetch access");
      }

      return mapAccesses(response.data as AccessFromAPI[]);
    } catch (e) {
      console.error(e);
    }
  }
);

export const deleteAccess = createAsyncThunk(
  "feed/deleteAccess",
  async (taskId: number, thunkAPI): Promise<FeedTask[]> => {
    const token = store.getState().user.token;
    thunkAPI.dispatch(setFetchingAccess(true));
    const liveAccess: FeedTask[] = await uf
      .ajax({
        method: "delete",
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/delete/${taskId}`,
      })
      .then((res: any) => {
        if (res.result !== "success") {
          throw new Error("Failed to delete access");
        }
        return res.data;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        thunkAPI.dispatch(setFetchingAccess(false));
      });
    return liveAccess;
  }
);

export const DisplayTask = createAsyncThunk(
  "feed/displaytask",
  (task_id: number, thunkAPI): void => {
    const liveAccess = store.getState().feed.access;
    const targetTask = liveAccess.find((l) => l.task_id === task_id);
    if (targetTask)
      thunkAPI.dispatch(
        setDisplayTask({
          task_id: targetTask.task_id,
          task_name: targetTask.task_name,
          taskType: targetTask.task_type,
        })
      );
    if (targetTask === undefined) throw new Error("No Access");
  }
);

export const DisplayCompareTask = createAsyncThunk(
  "feed/compare/displaytask",
  (task_id: number, thunkAPI): void => {
    const liveAccess = store.getState().feed.access;
    const targetTask = liveAccess.find((l) => l.task_id === task_id);
    if (targetTask)
      thunkAPI.dispatch(
        setCompareDisplayTask({
          task_id: targetTask.task_id,
          task_name: targetTask.task_name,
          taskType: targetTask.task_type,
        })
      );
    if (targetTask === undefined) throw new Error("No Access");
  }
);

export const fetchFeedSetting = createAsyncThunk(
  "feed/fetchSettings",
  async (taskId: number, thunkAPI) => {
    try {
      const res: any = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/info/${taskId}`,
      });

      if (res.result !== "success") {
        throw new Error(res.error);
      }

      thunkAPI.dispatch(setFeedSetting(res.data));
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchBookmarks = createAsyncThunk(
  "feed/fetchBookmarks",
  async (taskId: number) => {
    try {
      const res: {
        data: {
          task_id: number;
          ac_id: number;
          user_id: string;
          live_sid: string;
          created_at: string;
          user_display_name: string;
        }[];
        result: string;
        error?: string;
      } = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/bookmark`,
      });

      if (res.result === "success") {
        return res.data
          ? res.data.map((bookmark) => ({
              taskId: bookmark.task_id,
              accountId: bookmark.ac_id,
              userId: bookmark.user_id,
              postId: bookmark.live_sid,
              createdAt: bookmark.created_at,
              userDisplayName: bookmark.user_display_name,
            }))
          : [];
      }

      if (res.error) {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const deleteBookmark = createAsyncThunk(
  "feed/deleteBookmark",
  async (postId: string) => {
    try {
      const taskId = store.getState().feed.task_id;
      const res: {
        data: {
          task_id: number;
          ac_id: number;
          user_id: string;
          live_sid: string;
          created_at: string;
          user_display_name: string;
        }[];
        result: string;
        error: string;
      } = await uf.ajax({
        method: "delete",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/bookmark/${postId}`,
      });

      if (res.result === "success") {
        return res.data
          ? res.data.map((bookmark) => ({
              taskId: bookmark.task_id,
              accountId: bookmark.ac_id,
              userId: bookmark.user_id,
              postId: bookmark.live_sid,
              createdAt: bookmark.created_at,
              userDisplayName: bookmark.user_display_name,
            }))
          : [];
      }

      if (res.error) {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const saveBookmark = createAsyncThunk(
  "feed/saveBookmark",
  async (postId: string) => {
    try {
      const taskId = store.getState().feed.task_id;
      const res: {
        data: {
          task_id: number;
          ac_id: number;
          user_id: string;
          live_sid: string;
          created_at: string;
          user_display_name: string;
        }[];
        result: string;
        error: string;
      } = await uf.ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/bookmark/${postId}`,
      });

      if (res.result === "success") {
        return res.data
          ? res.data.map((bookmark) => ({
              taskId: bookmark.task_id,
              accountId: bookmark.ac_id,
              userId: bookmark.user_id,
              postId: bookmark.live_sid,
              createdAt: bookmark.created_at,
              userDisplayName: bookmark.user_display_name,
            }))
          : [];
      }

      if (res.error) {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchByFeedUserInfo = createAsyncThunk(
  "feed/fetchByFeedUserInfo",
  async () => {
    try {
      const taskId = store.getState().feed.task_id;
      const res: UserInfoFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/user_info`,
      });

      if (res.result !== "success")
        throw new Error("failed to fetch posts exported count");

      return res.data as UserInfoFromAPIData;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
);

// slice
export const FeedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    setDisplayTask: (state, action: PayloadAction<TaskInfo>) => {
      state.task_id = action.payload.task_id;
      state.task_name = action.payload.task_name;
      state.taskType = action.payload.taskType;
    },
    setCompareDisplayTask: (state, action: PayloadAction<TaskInfo>) => {
      state.compare.task_id = action.payload.task_id;
      state.compare.task_name = action.payload.task_name;
      state.compare.taskType = action.payload.taskType;
    },
    setAccess: (state, action: PayloadAction<FeedTask[]>) => {
      state.access = action.payload;
    },
    setFetchingAccess: (state, action: PayloadAction<boolean>) => {
      state.fetchingAccess = action.payload;
    },
    clearTaskSettings: (state) => {
      state.task_id = initialState.task_id;
      state.task_name = initialState.task_name;
      state.taskType = initialState.taskType;
      state.settings = initialState.settings;
      state.bookmarks = initialState.bookmarks;
      state.isLoadingTaskAccess = initialState.isLoadingTaskAccess;
      state.postsExportedCount = initialState.postsExportedCount;
    },
    setFeedSetting: (state, action) => {
      state.settings.name = action.payload.task_name;
      state.settings.type = action.payload.task_type;
      state.settings.indus_id = action.payload.indus_id;
      state.settings.keywords = {
        guided: action.payload.query_layer,
        advanced:
          action.payload.query_string ??
          new ESConvert(action.payload.query_layer).convert(),
        guidedView: guided2view(action.payload.query_layer),
      };
      state.settings.lang = action.payload.lang_abbr;
      state.settings.mediums = action.payload.list_medium ?? [];
      state.settings.unix_start = action.payload.adhoc_from;
      state.settings.unix_end = action.payload.adhoc_to;
      state.settings.frequency = action.payload.cron;
    },
    setPostsExportedCount: (state, action: PayloadAction<number>) => {
      state.postsExportedCount = action.payload;
    },
    increaseModificationCount: (state) => {
      state.feedModificationCount =
        state.feedModificationCount !== null
          ? ++state.feedModificationCount
          : null;
    },
  },
  extraReducers: {
    [getAccess.fulfilled.toString()]: (state, action) => {
      state.access = action.payload;
      state.fetchingAccess = false;
    },
    [getAccess.rejected.toString()]: (state, action) => {
      state.fetchingAccess = false;
    },
    [deleteAccess.fulfilled.toString()]: (state, action) => {
      state.access = action.payload;
    },
    [DisplayTask.fulfilled.toString()]: (state) => {
      state.has_task_access = true;
      state.isLoadingTaskAccess = false;
    },
    [DisplayTask.rejected.toString()]: (state) => {
      state.has_task_access = false;
      state.isLoadingTaskAccess = false;
    },
    [fetchBookmarks.fulfilled.toString()]: (
      state,
      action: PayloadAction<Bookmark[]>
    ) => {
      state.bookmarks = action.payload ?? [];
    },
    [saveBookmark.fulfilled.toString()]: (
      state,
      action: PayloadAction<Bookmark[]>
    ) => {
      state.bookmarks = action.payload;
    },
    [deleteBookmark.fulfilled.toString()]: (
      state,
      action: PayloadAction<Bookmark[]>
    ) => {
      state.bookmarks = action.payload;
    },
    [removeUser.fulfilled.toString()]: (state) => {
      Object.assign(state, initialState);
    },
    [fetchByFeedUserInfo.fulfilled.toString()]: (
      state,
      action: PayloadAction<UserInfoFromAPIData>
    ) => {
      state.postsExportedCount = action.payload.exported_rows;
      state.feedModificationCount = action.payload.mod;
    },
  },
});

export const {
  setDisplayTask,
  setAccess,
  setFetchingAccess,
  clearTaskSettings,
  setFeedSetting,
  setPostsExportedCount,
  increaseModificationCount,
  setCompareDisplayTask,
} = FeedSlice.actions;

// selectors
export const selectFeedTaskInfo = (state: RootState): TaskInfo => ({
  task_id: state.feed.task_id,
  task_name: state.feed.task_name,
  taskType: state.feed.taskType,
});

export const selectCompareFeedTaskInfo = (state: RootState): TaskInfo => ({
  task_id: state.feed.compare.task_id,
  task_name: state.feed.compare.task_name,
  taskType: state.feed.compare.taskType,
});
export const selectFeedTaskHasAccess = (state: RootState) =>
  state.feed.has_task_access;
export const selectIsLoadingTaskAccess = (state: RootState) =>
  state.feed.isLoadingTaskAccess;
export const selectFeedTaskAccess = (state: RootState) => state.feed.access;
export const selectFetchingTaskAccess = (state: RootState) =>
  state.feed.fetchingAccess;
export const selectTaskId = (state: RootState) => state.feed.task_id;
export const selectCompareTaskId = (state: RootState) =>
  state.feed.compare.task_id;
export const selectTaskSetting = (state: RootState) => state.feed.settings;
export const selectFeedKeywords = (state: RootState) =>
  state.feed.settings.keywords;
export const selectFeedType = (state: RootState) => state.feed.settings.type;
export const selectTaskUnixStart = (state: RootState) =>
  state.feed.settings.unix_start;
export const selectTaskUnixEnd = (state: RootState) =>
  state.feed.settings.unix_end;
export const selectTaskMediums = (state: RootState) =>
  state.feed.settings.mediums;
export const selectBookmarks = (state: RootState) => state.feed.bookmarks;
export const selectLiveFeedNumber = (state: RootState) =>
  state.feed.access?.filter((task) => task.task_type === 0).length;
export const selectTaskName = (state: RootState) => state.feed.task_name;
export const selectAdHocFeedNumber = (state: RootState) =>
  state.feed.access?.filter((task) => task.task_type === 1).length;
export const selectFeedDateRange = (state: RootState) =>
  state.feed.settings.unix_start && state.feed.settings.unix_end
    ? ({
        start: state.feed.settings.unix_start,
        end: state.feed.settings.unix_end,
      } as DateRangeFilter)
    : null;
export const selectPostsExportedCount = (state: RootState) =>
  state.feed.postsExportedCount;
export const selectFeedModificationCount = (state: RootState) =>
  state.feed.feedModificationCount;

export default FeedSlice.reducer;
