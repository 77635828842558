import { TopicDataBrief, TopicData, SortTypes } from "../interface"

export const sortBriefTopicDataInitial = (briefData: TopicDataBrief[]) => {
	return briefData
		.sort((a, b) =>
			a.data.ai_score < b.data.ai_score
				? 1
				: b.data.ai_score < a.data.ai_score
				? -1
				: 0
		)
		.map((data) => ({ briefData: data }))
}

export const sortTopicsByRankingScore = (
	topicData: TopicData[],
	sortType: SortTypes
) => {
	if (sortType === "asc") {
		return topicData.sort((a, b) =>
			a.briefData.data.ai_score > b.briefData.data.ai_score
				? 1
				: b.briefData.data.ai_score > a.briefData.data.ai_score
				? -1
				: 0
		)
	} else if (sortType === "desc") {
		return topicData.sort((a, b) =>
			a.briefData.data.ai_score < b.briefData.data.ai_score
				? 1
				: b.briefData.data.ai_score < a.briefData.data.ai_score
				? -1
				: 0
		)
	} else {
	}
}

export const sortTopicsByTrendForecast = (
	topicData: TopicData[],
	sortType: SortTypes
) => {
	if (sortType === "asc") {
	} else if (sortType === "desc") {
	} else {
	}
}

export const sortTopicsBySentiment = (
	topicData: TopicData[],
	sortType: SortTypes
) => {
	if (sortType === "asc") {
	} else if (sortType === "desc") {
	} else {
	}
}

export const sortTopicBySocialOutreach = (
	topicData: TopicData[],
	sortType: SortTypes
) => {
	if (sortType === "asc") {
	} else if (sortType === "desc") {
	} else {
	}
}
