import styled from 'styled-components'
import BaseButton from "./BaseButton"

const IconButton = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.3rem;
  border-radius: 50%;

  &:hover {
    background-color: #F7F7F9;
  }
`

export default IconButton