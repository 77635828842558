import React from 'react'
import { uuidv4 } from "usefuljs"
import Skeleton from "../../../../../reusable/utils/Skeleton"
import { RowRoot } from './ThreadRow'

function ThreadItemSkeleton() {
  return (
    <RowRoot>
      <div>
        <Skeleton variant="circle" width="20px" height="20px" />
        <Skeleton height="20px" margin="0px 0px 0px 0.5rem" />
      </div>
      <div>
        <Skeleton width="30px" height="20px" />
      </div>
    </RowRoot>
  )
}


function ThreadsSkeleton() {
  return (
    <div>
      {Array.from(Array(26)).map(_ => <ThreadItemSkeleton key={uuidv4()} />)}
    </div>
  )
}

export default ThreadsSkeleton
