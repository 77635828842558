function useAbort() {
  let abortController = new AbortController()

  const abort = () => {
    abortController.abort()
    abortController = new AbortController()
  }

  return [abortController, abort] as [AbortController, () => void]
}

export default useAbort