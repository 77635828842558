import React from 'react'
import IconButton from "../../../../../../reusable/buttons/IconButton"
import BinIcon from "../../../../../../reusable/icons/BinIcon"

function MarkIrrelevantButton() {
  return (
    <IconButton data-tip="Mark as irrelevant">
      <BinIcon />
    </IconButton>
  )
}

export default MarkIrrelevantButton