import React from 'react'
import styled from 'styled-components'

export const CheckBox = ({ ...props }) => {
	return (
		<>
			<CheckBoxContainer>
				<ColorCheckBox
					type="checkbox"
					{...props}
				/>
				<CheckBoxLabelText>{props.labelText}</CheckBoxLabelText>
				<CheckMark className="checkmark" />
			</CheckBoxContainer>
		</>
	)
}

const CheckBoxContainer = styled.label`
	display: block;
	position: relative;
	padding-left: 20px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom: 12px;
`

const CheckBoxLabelText = styled.span`
	vertical-align: sub;
	font-weight:400;
`

const ColorCheckBox = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;

	:checked ~ .checkmark {
		background-color: #29D1D7;
		border:0;
	}
	:checked ~ .checkmark:after {
		display: block;
	}
`
const CheckMark = styled.span`
	position: absolute;
	top: 0;
	left: 0;
	height: 1rem;
	width: 1rem;
	background-color: #fff;
	border: 0.5px solid #c4c4c4;
	border-radius: 2px;
	:after {
		content: "";
		position: absolute;
		display: none;
		left: 5.25px;
		top: 2px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`