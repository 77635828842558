import styled from 'styled-components'

export const TooltipContainer = styled.div`
  height: fit-content;
  width: 280px;
  color: white;
  background-color: #475973;
  border: 1px solid grey;
  padding: 0.5rem 1rem;
  border-radius: 4px;
`

export const TimeRangeContainer = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  border-bottom: 1px grey solid;
  font-size: 14px;
  margin-top: -0.5rem;
  p:first-child {
    font-weight: 600;
    margin-right: 0.5rem;
  }
`

export const ContentContainer = styled.div`

`

export const TooltipSummary = styled.div`
p {
  font-size: 14px;
  font-weight: 300;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
`

export const ThreadsContainer = styled.div`
  p {
    font-size: 14px;

    &:first-child {
      font-weight: 300;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const ThreadItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: 0.5rem;
    height: 18px;
    width: 18px;
  }

  p {
    font-size: 12px;
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`