import React, {useState} from 'react'
import styles from './MultiSelectDropdown.module.scss'

interface MultiSelectDropdownProp {
  options:{
    checked: boolean;
    value: string;
    label: string;
  }[]
  setOptions: React.Dispatch<React.SetStateAction<{
    checked: boolean;
    value: string;
    label: string;
  }[]>>
}

function MultiSelectDropdown({options, setOptions}:MultiSelectDropdownProp) {

  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const [isAll, setIsAll] = useState(true)

  const handleClick = (_: React.MouseEvent, targetIndex:number) => {
    setOptions([...options].map((option, index) => {
      if (index === targetIndex){
        option.checked = !option.checked
      }
      return option
    }))

    if (options.every(option=>option.checked)){
      setIsAll(true)
    } else {
      setIsAll(false)
    }
  }

  const handleClickAll = () => {
    if (isAll){
      setOptions(options.map(option=>{
        option.checked = false
        return option
      }))
    } else {
      setOptions(options.map(option=>{
        option.checked = true
        return option
      }))
    }
    setIsAll(!isAll)
  }

  const checkedOptions = options.filter(option=> option.checked)

  const triggerText = () => {
    if (isAll){
      return "All columns"
    }

    if (checkedOptions.length > 0 ){
      return checkedOptions.reduce((acc, curr, index)=>{
        if (index!==0){
          return acc + `, ${curr.label}`
        }
        return acc
      }, checkedOptions[0].label)
    }

    return "Please select"
  }

  const toggleMenu = () => {        
    if (!showDropdownMenu){
        document.addEventListener('click', closeMenu)    
        setShowDropdownMenu(true)
    }
}

  const closeMenu = (e: any) => {
    if (!e.target.closest(".dropdownItem")){
      setShowDropdownMenu(false)
      document.removeEventListener('click', closeMenu)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.dropdownTrigger} onClick={toggleMenu}>
        <p className={styles.dropdownTriggerText}>{triggerText()}</p>
        <i className="fas fa-caret-down" />
      </div>
       {
        showDropdownMenu && <div className={styles.dropdownMenu} >

          <div className={"dropdownItem " +styles.dropdownItem} onClick={handleClickAll} style={{borderBottom: "#BCBCBC 1px solid"}} >
            <input type="checkbox" checked={isAll} readOnly/>
            <span>All Columns</span>
          </div>

        {options.map((option, index) => {
          return (
            <div className={"dropdownItem " +styles.dropdownItem} onClick={(event)=>{handleClick(event, index)}} key={option.label}>
              <input type="checkbox" checked={option.checked} readOnly/>
              <span>{option.label}</span>
            </div>
          )
        })}
        
      </div> 
       }
    </div>
  )
}

export default MultiSelectDropdown
