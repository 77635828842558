import React from "react"
import Ctx, { Context } from "../../ctx"
import styles from "./preview.module.scss"
import uf from "usefuljs"
import EQL2RG from "tshl"
import ReactHtmlParser from "react-html-parser"
import Loader from "../../../../../reusable/loader"
import { FeedData } from "../../../../../../app/interfaces/feed"

import { fetchPreviewData } from "../../ctx/action"
import { ESQLayer } from "../../interface"

const Preview = () => {
	const ctx = React.useContext(Ctx) as Context
	const prevdata: FeedData[] | null = React.useMemo(
		() => ctx.editor.preview.data,
		[ctx.editor.preview]
	)
	const fetching: boolean = React.useMemo(
		() => ctx.editor.preview.fetching,
		[ctx.editor.preview]
	)
	const canPressPreview: boolean = React.useMemo(
		() => !fetching && !!ctx.editor.detail.keywords?.advanced,
		[fetching, ctx.editor.detail.keywords]
	)
	const queryKeywords: ESQLayer[] | undefined = React.useMemo(
		() => ctx.editor.detail.keywords?.guided,
		[ctx.editor.detail.keywords]
	)

	let EQ: EQL2RG;
    try{
		EQ = new EQL2RG(queryKeywords ?? [{in:[""]}], false)
	}
	catch(e){
		console.error(e)	
	}
	

	const onClickPreview = React.useCallback(() => {
		if (!fetching) {
			fetchPreviewData(ctx.setEditor)
		}
	}, [fetching, ctx.setEditor])

	return (
		<div className={styles.preview}>
			<button
				className={"button is-small " + styles.prevBtn}
				disabled={!canPressPreview}
				onClick={onClickPreview}>
				Preview
			</button>
			{fetching ? (
				<Loader />
			) : (
				<>
					{prevdata !== null && (
						<div className={styles.viewer}>
							<div className={styles.topbar}>
								<div>Preview</div>
								{prevdata.length < 500 ? (
									<div>(Showing {prevdata.length} records)</div>
								) : (
									<div>(Showing top {prevdata.length})</div>
								)}
								<div className={styles.sort}>
									<p>Sort by date</p>
									<span>
										<i className="fas fa-sort-amount-down"></i>
									</span>
								</div>
							</div>
							<div className={styles.records}>
								{prevdata.length ? (
									prevdata.map((p) => (
										<div
											className={styles.record}
											key={uf.crc32(
												`${p.post_link}${p.author_id}${p.post_timestamp}${p.channel_link}`
											)}>
											<div className={styles.top}>
												<div className={styles.author}>{p.author_name}</div>
												<div className={styles.date}>{p.post_timestamp}</div>
											</div>
											<div className={styles.content}>
												<a
													className={styles.thread}
													href={p.thread_link ?? ""}
													target="_blank"
													rel="noopener noreferrer">
													{ReactHtmlParser(
														EQ.html_wrap(
															p.thread_title ?? "",
															"span",
															"keyword"
														)
													)}
												</a>
												<p>
													{ReactHtmlParser(
														EQ.html_wrap(
															p.post_message ?? "",
															"span",
															"keyword"
														)
													)}
												</p>
											</div>
										</div>
									))
								) : (
									<p>No data found</p>
								)}
							</div>
						</div>
					)}
				</>
			)}
		</div>
	)
}


export default Preview