import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  showThreadData,
  selectHiddenSites,
  selectTopEngagementThreads,
  selectTopGrowthThreads,
  selectCompareTopEngagementThreads,
  selectCompareTopGrowthThreads,
} from "../../../../../../app/store/overviewSlice";
import {
  TopEngagementThread,
  TopGrowthThread,
  TopThread,
} from "../../../../../../app/interfaces/overview";
import EmptyStatePlaceholder from "../../components/EmptyStatePlaceholder";
import styled from "styled-components";
import SelectViewBy from "./SelectViewBy";
import HiddenSiteFilter from "./HiddenSiteFilter";
import ThreadRow from "./ThreadRow";
import ThreadsSkeleton from "./Skeleton";
import {
  selectCompareFeedTaskInfo,
  selectFeedTaskInfo,
  selectFeedType,
} from "../../../../../../app/store/feedSlice";

interface TopThreadsProp {
  isExpanded: boolean;
}

const ThreadsRoot = styled.div`
  background-color: white;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #f8f8fa;
  h3 {
    font-weight: 600;
    margin-right: 6px;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ThreadsContainer = styled.div<{ isExpanded: boolean }>`
  height: ${(props) => (props.isExpanded ? "953px" : "597px")};
  overflow-y: scroll;
`;

export enum ViewByOptions {
  "Total engagement",
  "Hourly engagement growth",
}

const TopThreads = (props: TopThreadsProp) => {
  const dispatch = useDispatch();

  const [viewBy, setViewBy] = useState(ViewByOptions[0]);
  const topEngagementThreads = useSelector(selectTopEngagementThreads);
  const topCompareEngagementThreads = useSelector(
    selectCompareTopEngagementThreads
  );
  const topGrowthThreads = useSelector(selectTopGrowthThreads);
  const topCompareGrowthThreads = useSelector(selectCompareTopGrowthThreads);
  const hidden = useSelector(selectHiddenSites);
  const feedType = useSelector(selectFeedType);

  const compareTask = useSelector(selectCompareFeedTaskInfo);
  const task = useSelector(selectFeedTaskInfo);

  const handleSelectViewBy = (event: React.MouseEvent) => {
    setViewBy(event.currentTarget.textContent ?? "none");
  };

  const chosenThreads: TopEngagementThread[] | TopGrowthThread[] | null =
    useMemo(() => {
      switch (viewBy) {
        case ViewByOptions[0]:
          let list = topEngagementThreads
            ? topEngagementThreads
                .filter((thread: any) => !hidden?.includes(thread.site))
                .filter((thread) => thread.title !== "")
            : null;
          let uniqueList: TopEngagementThread[] = [];
          list?.forEach((thread: TopEngagementThread) => {
            const isFound = uniqueList?.find(
              (item: TopEngagementThread) => item?.title === thread?.title
            );
            if (!isFound) {
              uniqueList.push(thread);
            }
          });
          return uniqueList;
        case ViewByOptions[1]:
          let growthList = topGrowthThreads
            ? topGrowthThreads
                .filter((thread) => !hidden.includes(thread.site))
                .filter((thread) => thread.title !== "")
            : null;
          let growthUniqueList: TopGrowthThread[] = [];
          growthList?.forEach((thread: TopGrowthThread) => {
            const isFound = growthUniqueList?.find(
              (item: TopGrowthThread) => item?.title === thread?.title
            );
            console.log("Is Found", isFound, growthUniqueList);
            if (!isFound) {
              growthUniqueList.push(thread);
            }
          });
          return growthUniqueList;
        default:
          return topEngagementThreads
            ? topEngagementThreads.filter((thread) => thread.title !== "")
            : null;
      }
    }, [viewBy, topEngagementThreads, topGrowthThreads, hidden]);

  const compareChosenThreads: TopEngagementThread[] | TopGrowthThread[] | null =
    useMemo(() => {
      switch (viewBy) {
        case ViewByOptions[0]:
          let list = topCompareEngagementThreads
            ? topCompareEngagementThreads
                .filter((thread: any) => !hidden?.includes(thread.site))
                .filter((thread) => thread.title !== "")
            : null;
          let uniqueList: TopEngagementThread[] = [];
          list?.forEach((thread: TopEngagementThread) => {
            const isFound = uniqueList?.find(
              (item: TopEngagementThread) => item?.title === thread?.title
            );
            if (!isFound) {
              uniqueList.push(thread);
            }
          });
          return uniqueList;
        case ViewByOptions[1]:
          let growthList = topCompareGrowthThreads
            ? topCompareGrowthThreads
                .filter((thread) => !hidden.includes(thread.site))
                .filter((thread) => thread.title !== "")
            : null;
          let growthUniqueList: TopGrowthThread[] = [];
          growthList?.forEach((thread: TopGrowthThread) => {
            const isFound = growthUniqueList?.find(
              (item: TopGrowthThread) => item?.title === thread?.title
            );
            console.log("Is Found", isFound, growthUniqueList);
            if (!isFound) {
              growthUniqueList.push(thread);
            }
          });
          return growthUniqueList;
        default:
          return topCompareEngagementThreads
            ? topCompareEngagementThreads.filter(
                (thread) => thread.title !== ""
              )
            : null;
      }
    }, [viewBy, topCompareEngagementThreads, topCompareGrowthThreads, hidden]);

  return (
    <ThreadsRoot>
      <Header>
        <HeaderLeft>
          <h3>{`Top Threads`}</h3>
          <HiddenSiteFilter hiddenSites={hidden} />
        </HeaderLeft>
        <SelectViewBy
          options={[ViewByOptions[0], ViewByOptions[1]]}
          viewByOption={viewBy}
          hasEngagement={
            topEngagementThreads !== null && topEngagementThreads.length > 0
          }
          hasGrowth={topGrowthThreads !== null && topGrowthThreads.length > 0}
          handleSelectViewBy={handleSelectViewBy}
          isReadOnly={feedType === 1}
        />
      </Header>

      <ThreadsContainer isExpanded={props.isExpanded}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1 1", width: "50%" }}>
            <div
              style={{
                marginLeft: "26px",
                marginTop: "10px",
                marginBottom: "10px",
                color: "#29d1d7",
                fontWeight: "bold",
              }}
            >
              {task?.task_name}
            </div>
            {chosenThreads ? (
              chosenThreads.length ? (
                (chosenThreads as TopThread[]).map((thread) => (
                  <div
                    onClick={() => dispatch(showThreadData(thread.url))}
                    key={thread.url}
                  >
                    <ThreadRow
                      title={thread.title}
                      site={thread.site}
                      count={
                        viewBy === "Total engagement"
                          ? (thread as TopEngagementThread).engagementCount
                          : (thread as TopGrowthThread).roc.toFixed(2)
                      }
                    />
                  </div>
                ))
              ) : (
                <EmptyStatePlaceholder />
              )
            ) : (
              <ThreadsSkeleton />
            )}
          </div>

          <div style={{ flex: "1 1", width: "50%" }}>
            <div
              style={{
                marginLeft: "26px",
                marginTop: "10px",
                marginBottom: "10px",
                color: "#ffd45d",
                fontWeight: "bold",
              }}
            >
              {compareTask?.task_name}
            </div>
            {compareChosenThreads ? (
              compareChosenThreads.length ? (
                (compareChosenThreads as TopThread[]).map((thread) => (
                  <div
                    onClick={() => dispatch(showThreadData(thread.url))}
                    key={thread.url}
                  >
                    <ThreadRow
                      title={thread.title}
                      site={thread.site}
                      count={
                        viewBy === "Total engagement"
                          ? (thread as TopEngagementThread).engagementCount
                          : (thread as TopGrowthThread).roc.toFixed(2)
                      }
                    />
                  </div>
                ))
              ) : (
                <EmptyStatePlaceholder />
              )
            ) : (
              <ThreadsSkeleton />
            )}
          </div>
        </div>
      </ThreadsContainer>
    </ThreadsRoot>
  );
};

export default TopThreads;
