// pretty print numbers as string
export const prettynum = (x:number):string =>{
    if (!isNaN(x)) {
        if(x < 1000)return `${x}`;
        if(x < 1000000)return `${(x / 1000).toFixed(2)}K`;
        if(x < 1000000000)return `${(x / 1000000).toFixed(2)}M`;
        return `${(x / 10000000000).toFixed(2)}B`;
    } else {
        return "0"
    }
}

// shorten text, add ... at the end if length exceeded
export const shorttxt = (s:string,length:number = 20):string =>{
    if(!s)return "";
    if(s.length < length)return s;
    return `${s.slice(0,length)} ...`;
}