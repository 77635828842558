import * as React from "react"

interface AngleLeftEndProp {
  height?: string
}

function AngleLeftEnd({height = "1rem"}:AngleLeftEndProp) {
  return (
    <svg viewBox="0 0 141.73 141.73" height={height} >
      <defs>
        <style>{".icon3_svg__cls-1{fill:#414b59}"}</style>
      </defs>
      <g id="icon3_svg__\u5716\u5C64_2" data-name="\u5716\u5C64 2">
        <path
          className="icon3_svg__cls-1"
          d="M53.89 73.93l46.49 42.31a3.35 3.35 0 004.59 0l5-4.54a3 3 0 001-2.09 3 3 0 00-1-2.09L70.75 71.84 110 36.16a3 3 0 001-2.09 3 3 0 00-1-2.07l-5-4.54a3.35 3.35 0 00-4.59 0L53.89 69.75a2.68 2.68 0 000 4.18zM41.85 26.53H33c-1.22 0-2.21.7-2.21 1.56v87.5c0 .86 1 1.56 2.21 1.56h8.86c1.23 0 2.22-.7 2.22-1.56v-87.5c-.01-.86-1-1.56-2.23-1.56z"
        />
      </g>
    </svg>
  )
}

export default AngleLeftEnd