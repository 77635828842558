import React from "react";
import styles from "./stats.module.scss";
import {
  selectStats,
  selectDateRange,
  selectOverviewFilter,
  selectCompareStats,
} from "../../../../../../app/store/overviewSlice";
import { useSelector } from "react-redux";
import StatCard from "./StatCard";
import {
  selectCompareFeedTaskInfo,
  selectFeedTaskInfo,
  selectFeedType,
} from "../../../../../../app/store/feedSlice";
import CardIcon from "../../../../../reusable/cards/summaryCard/SummaryCardIcon";
import { TaskInfo } from "../../../../../../app/interfaces/feed";

export type StatType = "post" | "thread" | "author" | "engagement" | "view";

interface StatsProps {
  isCompare: boolean;
  color: string;
}

const Stats = ({ isCompare, color }: StatsProps) => {
  const stats = useSelector(selectStats);
  const compareStats = useSelector(selectCompareStats);
  const dayRange = useSelector(selectDateRange);
  const taskType = useSelector(selectFeedType);
  const customDateRange = useSelector(selectOverviewFilter);
  const task: TaskInfo = useSelector(selectFeedTaskInfo);
  const compareTask: TaskInfo = useSelector(selectCompareFeedTaskInfo);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div style={{ padding: "10px 20px", color: color, fontWeight: "bold" }}>
        {isCompare ? compareTask?.task_name : task?.task_name}
      </div>
      <section className={styles.stats}>
        {/* <StatCard count={stats?.postCount} icon={<CardIcon className={"far fa-share-square"} />} dateRange={dayRange} type={"post"} isAdHoc={taskType === 1} customDateRange={customDateRange}/> */}
        <StatCard
          count={isCompare ? compareStats?.threadCount : stats?.threadCount}
          icon={<CardIcon className={"far fa-newspaper"} />}
          dateRange={dayRange}
          type={"thread"}
          isAdHoc={taskType === 1}
          customDateRange={customDateRange}
          color={color}
        />
        <StatCard
          count={isCompare ? compareStats?.authorCount : stats?.authorCount}
          icon={<CardIcon className={"fas fa-user-edit"} />}
          dateRange={dayRange}
          type={"author"}
          isAdHoc={taskType === 1}
          customDateRange={customDateRange}
          color={color}
        />
        <StatCard
          count={
            isCompare ? compareStats?.engagementCount : stats?.engagementCount
          }
          icon={<CardIcon className={"far fa-thumbs-up"} />}
          dateRange={dayRange}
          type={"engagement"}
          isAdHoc={taskType === 1}
          customDateRange={customDateRange}
          color={color}
        />
        {/* <StatCard count={stats?.viewCount} icon={<CardIcon className={"far fa-eye"} />} dateRange={dayRange} type={"view"} isAdHoc={taskType === 1} customDateRange={customDateRange}/> */}
      </section>
    </div>
  );
};

export default Stats;
