import React from 'react'
import styled from 'styled-components'
import { CheckBox } from "../../CheckBox"
import { newsChineseData } from '../../../../feed/task/posts/news_chinese_data'

interface SelectItemProp {
  isActive: boolean
  label: string
  onClick: () => void
}

const ItemRoot = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.4rem 0.8rem;
  column-gap: 0.5rem;
  /* cursor: pointer; */

  &:hover {
    background-color: #f5f5f5;
  }
`

function SelectItem({
  isActive,
  label, 
  onClick
}: SelectItemProp) {
  return (
    <ItemRoot>
      <CheckBox type="checkbox" checked={isActive} onClick={onClick} readOnly />
      <span>{label}12</span>
      <span>{newsChineseData?.[label]}</span>
    </ItemRoot>
  )
}

export default SelectItem
