import { Industry, Language } from "../interfaces/misc"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { store, RootState } from "."
import uf from "usefuljs"
import { removeUser } from "./userSlice"

interface MiscState {
    industries: Industry[],
    sites: string[],
    mediums: string[],
    languages: Language[],
    sources: {
        medium: string,
        sites: string[]
    }[]
}

// action thunk
export const getIndus = createAsyncThunk("misc/getIndus", async (_, thunkApi): Promise<Industry[]> => {
    const currIndus = store.getState().misc.industries
    if (currIndus.length) return currIndus

    const token = store.getState().user.token
    const ind: Industry[] = await uf.ajax({
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/industries`
    }).then((res:any) => res.data)
        .catch(err => { throw err })
    return ind
})

export const getSites = createAsyncThunk("misc/getSites", async (_, thunkApi): Promise<string[]> => {
    const currSites = store.getState().misc.sites
    if (currSites.length) return currSites


    const token = store.getState().user.token
    const sites: string[] = await uf.ajax({
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/sites`
    }).then((res: any) => res.data)
        .catch(err => { throw err })
    return sites
})


export const getLangs = createAsyncThunk("misc/getLangs", async (_, thunkApi): Promise<Language[]> => {
    const currLangs = store.getState().misc.languages
    if (currLangs.length) return currLangs

    const token = store.getState().user.token
    const langs: Language[] = await uf.ajax({
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/langs`
    }).then((res: any) => res.data)
        .catch(err => { throw err })
    return langs
})


export const getMediums = createAsyncThunk("misc/getMediums", async (_, thunkApi): Promise<string[]> => {
    const currMed = store.getState().misc.mediums
    if (currMed.length) return currMed

    const token = store.getState().user.token
    const mediums: string[] = await uf.ajax({
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/mediums`
    }).then((res: any) => res.data)
        .catch(err => { throw err })
    return mediums
})

export const getSources = createAsyncThunk("misc/getSources", async (_, thunkApi): Promise<{ medium: string, sites: string[] }[]> => {
    const currentSources = store.getState().misc.sources
    if (currentSources.length) return currentSources

    const token = store.getState().user.token
    try {
        const res: any = await uf.ajax({
            headers: { token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/medsites`
        })
         return res.data

    } catch (error) {
        throw new Error(error)
    }
})


const initialState: MiscState = {
    industries: [],
    sites: [],
    languages: new Array<Language>(),
    mediums: [],
    sources: []
}


// slice
export const MiscSlice = createSlice({
    name: "misc",
    initialState,
    reducers: {},
    extraReducers: {
        // FETCH INDUSTRIES LIST
        [getIndus.fulfilled.toString()]: (state, action: PayloadAction<Industry[]>) => {
            state.industries = action.payload
        },
        // FAILED TO FETCH INDUSTRIES LIST
        [getIndus.rejected.toString()]: (_) => { },
        // FETCH SITES LIST
        [getSites.fulfilled.toString()]: (state, action: PayloadAction<string[]>) => {
            state.sites = action.payload
        },
        // FAILED TO FETCH SITES LIST
        [getSites.rejected.toString()]: (_) => { },
        // FETCH MEDIUMS LIST
        [getMediums.fulfilled.toString()]: (state, action: PayloadAction<string[]>) => {
            state.mediums = action.payload
        },
        // FAILED TO FETCH MEDIUMS LIST
        [getMediums.rejected.toString()]: (_) => { },
        // FETCH LANG LIST
        [getLangs.fulfilled.toString()]: (state, action: PayloadAction<Language[]>) => {
            state.languages = action.payload
        },
        // FAILED TO FETCH LANG LIST
        [getLangs.rejected.toString()]: (_) => { },
        // FETCH SOURCES
        [getSources.fulfilled.toString()]: (state, action: PayloadAction<{ medium: string, sites: string[] }[]>) => {
            state.sources = action.payload
        },
        // FAILED TO FETCH SOURCES
        [getSources.rejected.toString()]: (_) => { },
        [removeUser.fulfilled.toString()]: (state) => {
            Object.assign(state, initialState)
        },
    }
})


// selector
export const selectIndustries = (state: RootState) => state.misc.industries
export const selectSites = (state: RootState) => state.misc.sites
export const selectMediums = (state: RootState) => state.misc.mediums
export const selectLangs = (state: RootState) => state.misc.languages
export const selectSources = (state: RootState): { medium: string, sites: string[] }[] => state.misc.sources


export default MiscSlice.reducer