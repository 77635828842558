import React, { memo } from "react";
import styled from "styled-components";
import SentimentFilter from "./SentimentFilter";
import SentimentIndicator from "./SentimentIndicator";
import { useSelector } from "react-redux";
import { selectFeedType } from "../../../../../app/store/feedSlice";
import {css} from 'styled-components'



const MiniBarRoot = styled.div<{feedTypeData:any}>`
  display: grid;
  grid-auto-flow: column;
  text-align: center;
  grid-template-columns: 13fr 4fr;
  // grid-template-columns: 13fr 4fr 4fr; 
  /* ${props => (props.feedTypeData === 0) ? css`grid-template-columns: 13fr 4fr;` : css`grid-template-columns: 13fr 4fr 4fr;`} */
`;



const Label = styled.p`
  text-align: left;
  min-width: fit-content;
`;

const MiniContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
`;

interface MinibarProp {
  noOfRecords: number;
}

const Minibar = memo<MinibarProp>((props) => {
  const feedType = useSelector(selectFeedType);
  console.log("feedType", feedType)
  return (
    <MiniBarRoot feedTypeData={feedType}>
      <Label>Total records : {props.noOfRecords}</Label>
      <MiniContainer>
        <SentimentIndicator />
        <span>Sentiment</span>
        <SentimentFilter />
      </MiniContainer>
      {/* {!(feedType === 0) && (
        <span>Impact</span>
      )} */}
     
    </MiniBarRoot>
  );
});

export default Minibar;
