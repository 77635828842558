import React, { memo } from "react"
import Ctx, { Context } from "./ctx"
import { useSelector } from "react-redux"
import { selectTaskSetting } from "../../../../app/store/feedSlice"
import _isEqual from "lodash.isequal"

interface HeaderProp {
    onClose: () => void
    onQuitDuringEdit: () => void
}


const Header = memo<HeaderProp>((props) => {
    const ctx = React.useContext(Ctx) as Context
    const taskSetting = useSelector(selectTaskSetting)

    const title = ctx.editor.type === 0 ? "Create New Feed" : "Edit Feed"

    const handClick = () => {
        if (checkChanges()) {
            props.onQuitDuringEdit()
        } else {
            props.onClose()
        }
    }

    const checkChanges = () => {
        if (taskSetting.type === null || taskSetting.type !== ctx.editor.detail.type) return true
        if (taskSetting.name !== ctx.editor.detail.name) return true
        if (taskSetting.indus_id !== ctx.editor.detail.indus_id) return true
        if (taskSetting.mediums?.length !== ctx.editor.detail.mediums?.length || !_isEqual(taskSetting.mediums?.slice().sort(), ctx.editor.detail.mediums?.slice().sort())) return true
        if (taskSetting.frequency !== ctx.editor.detail.frequency) return true
        if (taskSetting.unix_start !== ctx.editor.detail.unix_start || taskSetting.unix_end !== ctx.editor.detail.unix_end) return true
        if (taskSetting.lang !== ctx.editor.detail.lang) return true
        if (taskSetting.keywords?.advanced !== ctx.editor.detail.keywords?.advanced) return true

        return false
    }

    return (
        <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            <button className="delete" aria-label="close" onClick={handClick}></button>
        </header>
    )
})

export default Header