import React, {useState} from 'react'
import styles from "./PostTypeFilter.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify";
import { selectPostType, setPostTypeFilter } from "../../../../../app/store/livefeedpost"

interface PostType {
  checked: boolean
  value: "comments" | "threads"
  label: "Comments" | "Threads"
}

function PostTypeFilter() {
  const dispatch = useDispatch()

  const pickedPostTpye = useSelector(selectPostType)

  const [postType, setPostType] = useState<PostType[]>([{
      checked: pickedPostTpye === 0 || pickedPostTpye === 1,
      value: "comments",
      label: "Comments"
    },{
    checked: pickedPostTpye === 0 || pickedPostTpye === 2,
    value: "threads",
    label: "Threads"
  }])

  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const handleClick = (targetIndex:number) => {
    setPostType([...postType].map((option, index) => {
      if (index === targetIndex){
        option.checked = !option.checked
      }
      return option
    }))
  }

  const applyFilter = () => {
    
    if (postType[0].checked && postType[1].checked){
      dispatch(setPostTypeFilter(0))
    } else if (postType[0].checked) {
      dispatch(setPostTypeFilter(1))
    } else if (postType[1].checked) {
      dispatch(setPostTypeFilter(2))
    }

    if (postType[0].checked || postType[1].checked){
      setShowDropdownMenu(false)
    } else {
      toast.error("Please select at least one post type")
    }
  }

  const labelTest = () => {
    switch(pickedPostTpye){
      case 0:
        return "All post types"
      case 1:
        return "Comments"
      case 2:
         return "Threads"
    }
  }

  const toggleMenu = () => {        
    if (!showDropdownMenu){
        document.addEventListener('click', closeMenu)    
        setShowDropdownMenu(true)
    }
}

  const closeMenu = (e: any) => {
    if (!e.target.closest(".dropdownItem")){
      setShowDropdownMenu(false)
      document.removeEventListener('click', closeMenu)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.dropdownTrigger}>
        <button onClick={toggleMenu}>
          <span>{labelTest()}</span>
          <span><i className="fas fa-filter" /></span>
        </button>
      </div>
      {
        showDropdownMenu && (
          <div className={styles.dropdownMenu} >
            {postType.map((option, index) => {
              return (
                <div className={"dropdownItem " + styles.dropdownItem} onClick={()=>{handleClick(index)}} key={option.label}>
                  <input type="checkbox" checked={option.checked} readOnly/>
                  <span>{option.label}</span>
                </div>
              )
            })}
            <div className={styles.applyButtonContainer}>
              <button onClick={applyFilter}>
                Apply filter
              </button>
            </div>
          </div> )  
      }
    </div>
  )
}

export default PostTypeFilter
