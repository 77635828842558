import React from "react";
import styles from "./loading5.module.scss";


const Loading5 = ()=> {
    const loadlink:string = React.useMemo(()=> {
        const links:string[] = [
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty0.jpeg",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty1.jpeg",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty3.jpeg",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty4.gif",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty5.gif",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty6.gif",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty7.gif",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty8.gif",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty9.jpeg",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty10.jpeg",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty11.jpeg",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty12.jpeg",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty13.jpeg",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty14.jpeg",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty15.jpeg",
            "https://d18dyb0v4b4cx9.cloudfront.net/loading/matty16.jpeg"
        ];
        return links[Math.floor(Math.random() * links.length)];
    },[]);
    return (
        <div className={styles.loading}><h3>Loading</h3><img src={loadlink} alt="loading" /></div>
    );
}

export default Loading5;