import React from 'react'
import ViralityScoreHigh from '../../../../../reusable/icons/ViralityScoreHigh'
import ViralityScoreMedium from '../../../../../reusable/icons/ViralityScoreMedium'
import ViralityScoreLow from '../../../../../reusable/icons/ViralityScoreLow'
import { TrendIndexContainer, TrendIndexText } from '../../styled'

const TrendIndex = ({trendIndex}:TrendIndexProps) => {
  //TODO categorise trend indices
  return (
    <>
      {trendIndex <= 3 && (
        <TrendIndexContainer>
          <ViralityScoreLow width="2rem" />
          <TrendIndexText>{trendIndex.toFixed(2)}</TrendIndexText>
        </TrendIndexContainer>
      )}

      {trendIndex > 3 && trendIndex <= 6 && (
        <TrendIndexContainer>
          <ViralityScoreMedium width="2rem" />
          <TrendIndexText>{trendIndex.toFixed(2)}</TrendIndexText>
        </TrendIndexContainer>
      )}

      {trendIndex > 6 && (
        <TrendIndexContainer>
          <ViralityScoreHigh width="2rem" />
          <TrendIndexText>{trendIndex.toFixed(2)}</TrendIndexText>
        </TrendIndexContainer>
      )}
    </>
  )
}

export default TrendIndex

interface TrendIndexProps{
  trendIndex: number
}
