import React from "react"
import Ctx, { Context } from "./ctx"
import { saveTask, nextPage, createTask } from "./ctx/action"
import { EditorView, EditorDetail } from "./interface"
import { toast } from "react-toastify"
import styles from "./footer.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { increaseModificationCount, selectFeedModificationCount } from "../../../../app/store/feedSlice"
import { selectFeedModificationLimit } from "../../../../app/store/userSlice"
import { checkLastUpdateTimestamp } from "./helper"

interface prop {
    onClose: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    onSaveSucceeded: () => void
    onSaveFailed: () => void
    onReachedLimit: () => void
    onUpdateTooFrequently: () => void
    onCreateSucceeded: () => void
    taskId: number
    type: number
    taskType: number
}


const Footer = (props: prop) => {
    const ctx = React.useContext(Ctx) as Context

    const view: EditorView = React.useMemo(() => ctx.editor.view, [ctx.editor.view])
    const editorDetail: EditorDetail = React.useMemo(() => ctx.editor.detail, [ctx.editor.detail])
    const submitting: boolean = React.useMemo(() => ctx.editor.submitting, [ctx.editor.submitting])
    const dispatch = useDispatch()

    const feedModificationCount = useSelector(selectFeedModificationCount)
    const modificationLimit = useSelector(selectFeedModificationLimit)

    const remainingModification = (modificationLimit && feedModificationCount) ? (modificationLimit - feedModificationCount) : null

    const btnText: string = React.useMemo(() => {
        if (props.type === 1) return "Save and update"
        switch (view) {
            case EditorView.Settings:
                return "Create"
            default:
                return "Next"
        }
    }, [props.type, view])



    const canNext: boolean = React.useMemo(() => {
        switch (view) {
            case EditorView.Type:
                return editorDetail.type !== null
            case EditorView.Name:
                return editorDetail.name !== "" && editorDetail.name !== null
            case EditorView.Keywords:
                return editorDetail.keywords !== null
            case EditorView.Sources:
                return editorDetail.mediums === null || editorDetail.mediums.length > 0
            case EditorView.Settings:
                if(props.taskType === 1){
                    return editorDetail.frequency !== null && editorDetail.lang !== "" && editorDetail.unix_start !== 0 && editorDetail.unix_end !== 0
                }
                return editorDetail.frequency !== null && editorDetail.lang !== "" && editorDetail.unix_start !== 0
                
            default:
                return false
        }
    }, [view, editorDetail])

    const onClickBtn = React.useCallback(async () => {
        if (props.type === 1) { // edit feed setting

            try {
                if (feedModificationCount === null || (modificationLimit !== null && feedModificationCount > modificationLimit)) {
                    props.onReachedLimit()
                    return
                }

                const canUpdate = checkLastUpdateTimestamp(props.taskId)

                if (canUpdate) {
                    const result = await saveTask(props.taskId, editorDetail)

                    if (result === "success") {
                        props.onSaveSucceeded()
                        dispatch(increaseModificationCount())
                    } else {
                        throw new Error("failed to update the feed setting")
                    }

                } else {

                    // trigger update too frequently notice
                    console.log("so frequently")
                    props.onUpdateTooFrequently()
                }

            } catch (err) {
                // show error page, yet designed
                console.error(err)
                props.onSaveFailed()
            }
        }

        if (props.type === 0) { // create feed 
            switch (view) {
                case EditorView.Settings:
                    try {
                        await createTask(editorDetail, ctx.setEditor)
                        props.onCreateSucceeded()
                    } catch (err) {
                        if ((err.message as string).includes("SQLSTATE P0001")) {
                            toast.error((err.message as string).replace(" (SQLSTATE P0001)", "").replace("scany: rows final error: ERROR: ", ""))
                        } else {
                            toast.error(err.message)
                        }

                    }
                    break
                default:
                    nextPage(ctx.setEditor)
                    break
            }
        }

    }, [props, editorDetail, feedModificationCount, modificationLimit, view, ctx.setEditor, dispatch])

    return (
        <footer className={"modal-card-foot"}>
            <div className={styles.foot}>
                {
                    view !== EditorView.Name &&
                    view !== EditorView.Type &&
                    modificationLimit !== null &&
                    feedModificationCount !== null && (
                        <p>Monthly changes remaining: {modificationLimit - feedModificationCount}</p>
                    )
                }

                <div className={"control" + (submitting ? " is-loading" : "")}>
                    <button className="button is-success" disabled={submitting || !canNext || (remainingModification !== null && remainingModification <= 0)} onClick={onClickBtn}>{btnText}</button>
                </div>
            </div>
        </footer >
    )
}

export default Footer