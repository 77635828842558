import React from "react";
import { IconProp } from "./interface";

function ExportpdfIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044",
}: IconProp) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 74 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1 37.7C21.9 37.7 24 37.1 25.3 35.9C26.7 34.7 27.3 32.8 27.3 30.3C27.3 27.7 26.6 25.8 25.3 24.6C24 23.4 21.9 22.7 19 22.7H12.3V43.5H16.5V37.7H19.1ZM16.5 26.7H18.9C20.3 26.7 21.3 27 22 27.5C22.7 28 23 28.9 23 30.1C23 31.3 22.7 32.2 22.2 32.8C21.7 33.4 20.7 33.7 19.3 33.7H16.5V26.7Z"
        fill={fill}
      />
      <path
        d="M43.8 40.6C45.6 38.7 46.4 36.2 46.4 32.9C46.4 29.7 45.5 27.1 43.7 25.3C41.9 23.5 39.3 22.6 35.9 22.6H29.2V43.4H35.7C39.3 43.5 42 42.5 43.8 40.6ZM40.6 37.8C39.6 38.9 38.1 39.4 36.2 39.4H33.5V26.7H35.9C38 26.7 39.6 27.3 40.7 28.4C41.7 29.5 42.3 31.1 42.3 33.1C42.2 35.1 41.6 36.7 40.6 37.8Z"
        fill={fill}
      />
      <path
        d="M61.7 26.8V22.7H48.7V43.5H52.9V35.3H61.2V31.3H52.9V26.8H61.7Z"
        fill={fill}
      />
      <path
        d="M67.2 0.799988H6.8C3.3 0.799988 0.5 3.59999 0.5 7.09999V80.9C0.5 84.4 3.3 87.2 6.8 87.2H20.7L18.8 85.3L16.9 83.4H6.9C5.5 83.4 4.4 82.3 4.4 80.9V7.09999C4.4 5.69999 5.5 4.59999 6.9 4.59999H67.4C68.8 4.59999 69.9 5.69999 69.9 7.09999V80.9C69.9 82.3 68.8 83.4 67.4 83.4H58.8L56.9 85.3L55 87.2H67.4C70.9 87.2 73.7 84.4 73.7 80.9V7.09999C73.6 3.59999 70.7 0.799988 67.2 0.799988Z"
        fill={fill}
      />
      <path
        d="M59.1 75.4L46 75.3V53.7H28V75L14.9 74.8L37.3 97.2L59.1 75.4Z"
        fill={fill}
      />
    </svg>
  );
}

export default ExportpdfIcon;
