import moment from "moment"
import { DateRange } from "../../../../../../../app/interfaces/overview"
import { Interval } from "../../../interface"

export function generateLiveChartPointLabel(timestamp: number, dateRange: DateRange) {
  return moment(timestamp).format(dateRange === 0 ? "HH:mm" : "MMM D")
}

export function generateAdHocChartPointLabel(timestamp: number, interval: Interval | null) {
  if (interval === null) return ""

  switch (interval) {
    case "hour":
      return moment(timestamp).format("HH:mm")
    case "day":
      return moment(timestamp).format("D MMM YYYY")
    case "month":
      return moment(timestamp).format("MMM YYYY")
    case "year":
      return moment(timestamp).format("YYYY")
  }
}