import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import styles from "./GraphHeader.module.scss"
import { DateRange } from "../../../../../../app/interfaces/overview"
import IntervalSwitch from "../../components/IntervalSwitch"
import { TaskType } from "../../../editor/interface"
import { DateRangeFilter } from "../../../../../../app/interfaces/misc"

enum LineGraphViewBY {
    thread = "Threads",
    comment = "Comments",
    post = "Threads and comments",
}

interface GraphHeaderProp {
    dateRange: DateRange
    viewBy: LineGraphViewBY
    setViewBy: React.Dispatch<React.SetStateAction<LineGraphViewBY>>
    feedType: TaskType,
    customDateRange: DateRangeFilter | null
}
const LeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
`

const CountLabel = styled.h3`
    color: #516380;
    font-weight: 500;
`


function GraphHeader({ dateRange, viewBy, setViewBy, feedType, customDateRange }: GraphHeaderProp) {

    const [dropdownExpanded, setDropdownExpanded] = useState(false)
   
    const rangeText = React.useMemo(() => {
        switch (dateRange) {
            case DateRange.Today:
                return "Last 24 hours"
            case DateRange.Week:
                return "Last 7 days"
            case DateRange.Month:
            default:
                return "Last 30 days"
        }
    }, [dateRange])

    const labelText =  React.useMemo(() => {
        if(!customDateRange?.start){
            return `${viewBy} Count in ${rangeText}`
        }
        return `${viewBy} Count`
       
    }, [customDateRange])


    const handleClickExpand = () => {
        if (!dropdownExpanded) {
            document.addEventListener('click', collapseDropdown)
        }
        setDropdownExpanded(true)
    }

    const collapseDropdown = () => {
        setDropdownExpanded(false)
        document.removeEventListener('click', collapseDropdown)
    }
    

    return (
        <div className={styles.head}>
            <LeftContainer>
                {
                    feedType === 0 ? (
                        <CountLabel>{`${labelText}`}</CountLabel>
                    ) : (
                        <CountLabel>{`${viewBy} Count`}</CountLabel>
                    )
                }

                <IntervalSwitch />
            </LeftContainer>

            <div className={styles.dropdownContainer}>
                <p>View by:</p>
                <div
                    className={
                        "dropdown is-right" +
                        (dropdownExpanded ? " is-active" : "")
                    }
                >
                    <div
                        className="dropdown-trigger"
                        onClick={handleClickExpand}
                    >
                        <button
                            className="button"
                            aria-haspopup="true"
                            aria-controls="taskMenu"
                            id={styles.dropdownbutton}
                        >
                            <div className={styles.buttonContentWrapper}>
                                <div>{viewBy}</div>
                                <div className="icon is-small">
                                    {dropdownExpanded ? <i className="fas fa-angle-up" aria-hidden="true" /> : <i className="fas fa-angle-down" aria-hidden="true" />}
                                </div>
                            </div>
                        </button>
                    </div>
                    <div className="dropdown-menu" id="taskMenu" role="menu">
                        <div className="dropdown-content">
                            {Object.entries(LineGraphViewBY).map((key) => {
                                return (
                                    <div
                                        className={
                                            "dropdown-item " + styles.dropdownOption
                                        }
                                        onClick={() => {
                                            setViewBy(key[1])
                                            setDropdownExpanded(false)
                                        }}
                                        key={key[1]}
                                    >
                                        No. of{" "}
                                        {key[1].toLowerCase()}
                                    </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GraphHeader
