export const mapBannerUrl = (labelId: number) => {
	switch (labelId) {
		case 1:
			return "https://d18dyb0v4b4cx9.cloudfront.net/banner/hongkong.jxl"
		case 2:
			return "https://d18dyb0v4b4cx9.cloudfront.net/banner/politics.jxl"
		case 3:
			return "https://d18dyb0v4b4cx9.cloudfront.net/banner/finance.jxl"
		case 4:
			return "https://d18dyb0v4b4cx9.cloudfront.net/banner/art_music.jxl"
		case 5:
			return "https://d18dyb0v4b4cx9.cloudfront.net/banner/kids_family.jxl"
		case 6:
			return "https://d18dyb0v4b4cx9.cloudfront.net/banner/fashion_beauty.jxl"
		case 7:
			return "https://d18dyb0v4b4cx9.cloudfront.net/banner/f_and_b.jxl"
		case 8:
			return "https://d18dyb0v4b4cx9.cloudfront.net/banner/f_and_b.jxl"
		case 9:
			return "https://d18dyb0v4b4cx9.cloudfront.net/banner/telecom.jxl"
		default:
			return "https://d18dyb0v4b4cx9.cloudfront.net/banner/hongkong.jxl"
	}
}
