import React from "react"
import styles from "./ExitWarning.module.scss"

interface ExitWarningProp {
  onResume: ()=>void
  onClose: ()=>void
}

function ExitWarning({onResume, onClose}:ExitWarningProp) {
    return (
        <div>
            <div className={"modal is-active" }>
                <div className="modal-background"> </div>
                <div className="modal-card">
                  <header className={"modal-card-head " + styles.cardHead} >
                    <p className="modal-card-title">
                      <i className={"fas fa-exclamation-triangle " + styles.cardHeadIcon} />
                      <span className={styles.cardHeadTitle}>Warning</span>
                    </p>
                  </header>
                  <section className="modal-card-body">
                    <div className={styles.contentContainer}>
                      <h1 className={styles.heading}>
                        <span>Are you sure you want to exit?</span>
                      </h1>
                      <p className={styles.subHeading}>Your feed settings will not be saved.</p>
                      <div className={styles.options}>
                        <button className={styles.resumeButton} onClick={onResume}>Resume</button>
                        <button className={styles.exitButton} onClick={onClose}>Exit anyway</button>
                      </div>
                    </div>
                  </section>
                </div>
            </div>
        </div>
    )
}

export default ExitWarning
