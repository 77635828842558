import React from "react";
import { TimeframeContainer } from "./styled";

const Timeframe = ({ title }: TimeframeProps) => {
  return <TimeframeContainer>{title}</TimeframeContainer>;
};

interface TimeframeProps {
  title: string;
}

export default Timeframe;
