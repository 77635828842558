import React from 'react'
import styled from 'styled-components'
import SmartFeedIcon from "../../../../../reusable/icons/SmartFeedIcon"

const RootContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  background-color: rgba(211, 210, 224, 0.4);
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: 2rem;
`

const Label = styled.p`
  color: #516380;
  font-weight: 600;
  font-size: 14px;
`

function Banner() {
  return (
    <RootContainer>
      <SmartFeedIcon width="1.5rem" height="1.5rem" />
      <Label>Irrelevant posts are used as training material for your detection model. Improve your model by marking more irrelevant posts.</Label>
    </RootContainer>
  )
}

export default React.memo(Banner) 
