export const newsChineseData: any = {
    "01ICON" : "巨子ICON",
    "881903" : "商業電台",
"AAstocks" : "阿思達克財經",
"Allin Media" :"濠博新聞",
"am730" :"am730",
"Anue" : "鉅亨網",
"AP News" : "美聯社新聞",
"AppleDaily" : "蘋果日報",
"Asia Asset" : "亞洲資產",
"Asia Times" : "亞洲時報",

}