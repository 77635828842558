const loadState = (name:string):any =>{
    try{
        const serialisedState = sessionStorage.getItem(name);
        if(serialisedState === null){
            return undefined
        }
        return JSON.parse(serialisedState);
    }catch(err){
        console.error(err);
        return undefined;
    }
}


const saveState = (state:any,name:string):void =>{
    try{
        const serialisedState = JSON.stringify(state);
        sessionStorage.setItem(name,serialisedState);
    }catch(err){
        console.error(err);
    }
}


const clearState = (name:string):void =>{
    try{
        sessionStorage.removeItem(name);
    }catch(err){console.error(err)}
}

export { loadState, saveState, clearState };