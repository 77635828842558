import React from "react"
import styles from "./stats.module.scss"
import { selectStats, selectDateRange, selectOverviewFilter } from "../../../../../../app/store/overviewSlice"
import { useSelector } from "react-redux"
import StatCard from "./StatCard"
import { selectFeedType } from "../../../../../../app/store/feedSlice"
import CardIcon from "../../../../../reusable/cards/summaryCard/SummaryCardIcon"

export type StatType = "post" | "thread" | "author" | "engagement" | "view"

const Stats = () => {
    const stats = useSelector(selectStats)
    const dayRange = useSelector(selectDateRange)
    const taskType = useSelector(selectFeedType)
    const customDateRange = useSelector(selectOverviewFilter)

    return (
        <section className={styles.stats}>
            <StatCard count={stats?.postCount} icon={<CardIcon className={"far fa-share-square"} />} dateRange={dayRange} type={"post"} isAdHoc={taskType === 1} customDateRange={customDateRange}/>
            <StatCard count={stats?.threadCount} icon={<CardIcon className={"far fa-newspaper"} />} dateRange={dayRange} type={"thread"} isAdHoc={taskType === 1} customDateRange={customDateRange}/>
            <StatCard count={stats?.authorCount} icon={<CardIcon className={"fas fa-user-edit"} />} dateRange={dayRange} type={"author"} isAdHoc={taskType === 1} customDateRange={customDateRange}/>
            <StatCard count={stats?.engagementCount} icon={<CardIcon className={"far fa-thumbs-up"} />} dateRange={dayRange} type={"engagement"} isAdHoc={taskType === 1} customDateRange={customDateRange}/>
            <StatCard count={stats?.viewCount} icon={<CardIcon className={"far fa-eye"} />} dateRange={dayRange} type={"view"} isAdHoc={taskType === 1} customDateRange={customDateRange}/>
        </section>
    )
}

export default Stats