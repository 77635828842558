import styled from 'styled-components'
import BaseButton from '../../../reusable/buttons/BaseButton'

export const Container = styled.div`
  position: relative;
`

export const OpenMenuButton = styled.button`
  height: 38px;
  min-width: 180px;
  background-color: #fff;
  border: 1px solid #DADADA;
  border-radius: 4px;

  padding: 0 1rem;
  margin: 0.6rem 1.5rem 0rem 0.6rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  &:disabled{
    background-color: #F8F8FA;
    cursor: auto;
    span{
      color: #D3D2E0;
    }
  }
`

export const ButtonSpan = styled.span`
  color: #516380;
  margin-right: 1.5rem;
`

export const MenuSelectionButton = styled.button`
  background-color:#fff;
  border: none;
  padding: 8px 15px;
  width: 100%;
  text-align: left;
  
  font-size: 1rem;
  color: #283044;

  cursor: pointer;

  &:disabled{
    color: #D3D2E0;
    cursor: auto;
  }
  :hover{
    background-color: #F8F8FA;
  }
`

export const MenuListContainer = styled.div`
  position: absolute;
  top: 47px;
  margin-left: 10px;
  min-width: 180px;
  z-index: 10;

  border: 1px solid #DADADA;
  border-radius: 4px;
  background-color:#fff;

  padding: 5px 0;
  margin-right:5px;

  box-shadow: 0px 0px 15px rgba(211, 210, 224, 0.4);
`


export const MenuFilterSelection = styled.div`
  background-color:#fff;
  border: none;
  padding: 8px 15px;
  
  font-size: 1rem;
  color: #283044;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`

export const FilterButtonsContainer = styled.div`
  border-top: 1px solid #D3D2E0;
  `

export const FilterButtonRow = styled.div`
  margin: 0.8rem;
  text-align: center;
`

export const ActionButton = styled(BaseButton)`
  color: #fff;
  background-color: #29D1D7;
  border-radius: 4px;
  width: 112px;
  height: 33px;

  font-weight: bolder;
`

export const SecondaryButton = styled(BaseButton)`
  color: #D3D2E0;
  font-size: 0.8rem;
  background-color: #fff;
  border: 1px solid #DADADA;
  border-radius: 4px;
  width: 102px;
  height: 28px;
`