import styled from "styled-components"

export const PickerRoot = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 1rem 1.5rem;
	column-gap: 1rem;
`

export const AllSitesOption = styled.div<{ isActive: boolean }>`
	height: 35px;
	width: 35px;
	border-radius: 50%;
	background: linear-gradient(105.96deg, #29d1d7 7.62%, #3a89ff 127.23%);
	color: white;
	filter: ${(props) => (props.isActive ? "opacity(1)" : "opacity(0.65)")};
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 14px;
	font-weight: 600;

	&:hover {
		filter: opacity(1);
	}
`
