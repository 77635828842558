import React from 'react'
import { IconProp } from "./interface"

function LogoutIcon({
  height = "1rem",
  width = "1rem",
  fill = "283044"
}: IconProp) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.03 6.215l-4.593 4.594c-.2.2-.437.246-.71.136-.274-.11-.411-.31-.411-.601V7.719H4.597a.633.633 0 01-.465-.192.633.633 0 01-.191-.465V4.438c0-.182.064-.337.191-.464a.633.633 0 01.465-.192h3.719V1.156c0-.291.137-.492.41-.601.274-.11.51-.064.711.136l4.594 4.594a.633.633 0 01.191.465.633.633 0 01-.191.465zm-8.34 4.457c0 .219-.108.328-.327.328H3.066c-.73 0-1.349-.255-1.86-.766a2.532 2.532 0 01-.765-1.859v-5.25c0-.73.255-1.349.766-1.86C1.717.756 2.337.5 3.066.5h2.297c.219 0 .328.11.328.328v1.094c0 .219-.11.328-.328.328H3.066a.865.865 0 00-.629.273.818.818 0 00-.246.602v5.25c0 .237.082.447.246.629a.913.913 0 00.629.246h2.297c.219 0 .328.11.328.328v1.094z"
        fill={fill}
      />
    </svg>
  )
}

export default LogoutIcon
