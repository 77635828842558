import * as React from "react";

function AuthorWithPen(props:any) {
  return (
    <svg
      width={22}
      height={23}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.998 23l2.486-.56-1.912-1.867L10.998 23zM10.508 21.401l.412-1.74c0-.005.002-.009.003-.013l.006-.021a.423.423 0 01.008-.02l.007-.018a.33.33 0 01.03-.057.49.49 0 01.034-.045l.018-.018.007-.008 5.543-5.413c-1.548-3.108-4.41-4.958-7.693-4.958-2.407 0-4.632 1.006-6.263 2.834C.977 13.764.05 16.329 0 19.169c.971.479 4.96 2.316 8.883 2.316.543 0 1.085-.029 1.625-.084zM11.927 19.753l6.58-6.427 2.394 2.337-6.581 6.427-2.393-2.337zM8.883 8.264c2.337 0 4.231-1.85 4.231-4.132C13.114 1.85 11.22 0 8.884 0 6.545 0 4.651 1.85 4.651 4.132c0 2.282 1.894 4.132 4.231 4.132zM22 13.91c0-.326-.1-.646-.285-.918a1.685 1.685 0 00-.76-.608 1.73 1.73 0 00-1.844.358l2.393 2.337A1.607 1.607 0 0022 13.91z"
        fill="#283044"
      />
    </svg>
  );
}

export default AuthorWithPen
