import styled from 'styled-components'

export const Label = styled.p`
  display: flex;
  flex-direction: row;

  span:first-child {
    display: block;
    margin-right: 1.5rem;
    width: 20px;
  }
`

export const PercentageLabel = styled.p`
  margin-right: 6px;
  color: #29d1d7 !important;
  font-weight: 700;
`

export const RightContainer = styled.div`
  display: flex;
`

export const SettingContainer = styled.div`
  position: relative;
  z-index: 2;
`

export const HiddenItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.5rem;
  padding: 6px 1rem;
  width: 220px;
  background-color: white;
  color: #2F3647;
  &:hover {
    background-color: #f5f5f5;
  }

  div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    height: 24px;
    width: 24px;
  }

  div:nth-child(2) {
    p:nth-child(2) {
      font-size: 14px;
      font-weight: 300;
    }
  }
`

export const Icon = styled.img`
  margin-right: 1rem;
`
