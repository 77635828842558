import { ESQLayer } from "./interface"

export function calculateIncludes(data: ESQLayer[]) {
  let count = 0
  data.forEach(item => {
    count += item.in?.flat(2).length ?? 0
  })

  return count
}

export function checkLastUpdateTimestamp(taskId: number) {

  const lastUpdateTimestamp = window.localStorage.getItem(`${taskId}_update_timestamp`)
  
  if (lastUpdateTimestamp) { // have last update timestamp
    const lastUpdate = (new Date(lastUpdateTimestamp)).getTime()
    const now = (new Date()).getTime()

    if ((now - lastUpdate) < 60000) {
      return false
    }
  }

  return true 
}