import React from 'react'
import { IconProp } from "./interface"

function NeutralFaceIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      id="Neutral_face_(1)_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24.4 24.4"
      xmlSpace="preserve"
      height={height}
      width={width}
    >
      <style>{".Neutral_face_(1)_svg__st2{fill:#516380}"}</style>
      <circle cx={12.2} cy={12.2} r={12.2} fill="#ffd366" />
      <circle cx={12.2} cy={12.2} r={11.7} fill="none" stroke="#ffcc4d" />
      <circle className="Neutral_face_(1)_svg__st2" cx={8} cy={10} r={1.6} />
      <path
        className="Neutral_face_(1)_svg__st2"
        d="M17.3 10c0 .9-.7 1.6-1.6 1.6-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6.9-.1 1.6.7 1.6 1.6z"
      />
    </svg>
  )
}

export default NeutralFaceIcon
