import React from "react"
import CrossIcon from "../../../../reusable/icons/CrossIcon"
import styles from "./header.module.scss"
import styled from 'styled-components'
import { useSelector } from "react-redux"
import { selectDisplayOvdata, selectSentimentType } from "../../../../../app/store/overviewSlice"

interface prop {
    onClose: () => void
}

const CrossIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
    svg >g >path {
            fill: #bdbdbd;
        }

    &:hover {
        svg >g >path {
            fill: #898989;
        }
    }
`

const Header = (props: prop) => {
    const displayDataType = useSelector(selectDisplayOvdata)
    const sentimentType = useSelector(selectSentimentType)

    const sentiment = (() => {
        switch (sentimentType) {
            case -1:
                return "Negative"
            case 0:
                return "Neutral"
            case 1:
                return "Positive"
            default:
                return null
        }
    })()

    return (
        <header className={`modal-card-head ${styles.head}`}>
            <p className="modal-card-title">Post Details {displayDataType === 4 && (" - " + sentiment)}</p>
            <CrossIconWrapper onClick={props.onClose}>
                <CrossIcon height="1.5rem" width="1.5rem" />
            </CrossIconWrapper>
        </header>
    )
}

export default Header