import * as React from "react"
import { IconProp } from "./interface"

const WideAngleArrow = ({
  height = "1rem",
  width = "1rem",
  fill = "#868686",
  stroke = 2,
  rotate = "0",
}: WideAngleArrowProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1l8 6 8-6" stroke={fill} strokeWidth={stroke} transform={`rotate(${rotate})`}/>
    </svg>
  )
}

export default WideAngleArrow

interface WideAngleArrowProps extends IconProp{
  rotate?: string
}