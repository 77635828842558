import React, { useState, useEffect } from 'react'
import { setMediumFilter } from "../../../../../../app/store/livefeedpost"
import { useDispatch, useSelector } from "react-redux"
import { selectTaskMediums } from "../../../../../../app/store/feedSlice"
import { selectSources } from "../../../../../../app/store/miscSlice"
import { toast } from "react-toastify"
import MediumFilterItem, { baseFontSize, baseHeight, baseWidth } from "./MediumFilterItem"
import styled from 'styled-components'

export interface Medium {
  value: string
  label: string
  checked: boolean
  sites: Site[]
}

interface Site {
  value: string
  label: string
  checked: boolean
}

const MediumFilterRoot = styled.div`
  position: relative;
`

const DropdownHeader = styled.div`
  button {
    font-size: ${baseFontSize};
    height: ${baseHeight};
    width: ${baseWidth};
    color: #516380;
    background-color: white;
    border: 1px #dbdbdb solid;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    padding: 7px 12px;
    cursor: pointer;
    span {
      display: block;
      max-width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      border: 1px #b5b5b5 solid;
    }
  }
`

const DropdownMenu = styled.div`
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  padding: 0.5rem 0 0.1rem 0;
  width: ${baseWidth};
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0 0 1px rgba(10, 10, 10, 0.02);
  z-index: 11;
`

const DropdownMenuContent = styled.div`
  overflow: auto;
  width: 100%;
  max-height: 300px;
`

const MediumFilterItemRoot = styled.div`
  width: 100%;
  font-size: ${baseFontSize};
`

const MediumFilterItemContent = styled.div`
  min-height: ${baseHeight};
  width: 100%;
  padding: 0.4rem 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`

const CheckBox = styled.input`
  cursor: pointer;
  margin-right: 10px;
  width: ${baseFontSize};
  height: ${baseFontSize};
`

const DropdownActions = styled.div`
    height: ${baseHeight} + 10px;
    width: 100%;
    padding: 0.4rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const ApplyFilterButton = styled.button`
  height: 30px;
  width: 100%;
  border: none;
  border-radius: 4px;
  color:white;
  background-color: #29d1d7;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
`

function MediumFilter() {
  const dispatch = useDispatch()

  const sources = useSelector(selectSources)
  const requestedMediums = useSelector(selectTaskMediums)
  const [mediums, setMediums] = useState<Medium[]>([])

  useEffect(() => {
    setMediums((sources ?? []).filter(source => {
      if (requestedMediums && requestedMediums.length > 0) {
        return requestedMediums.includes(source.medium)
      } else {
        return true
      }
    }).map<Medium>(item => {
      return {
        checked: true,
        value: item.medium,
        label: item.medium,
        sites: item.sites.map<Site>(site => {
          return {
            checked: true,
            value: site,
            label: site,
          }
        })
      }
    }))
  }, [requestedMediums, sources])

  const [isAll, setIsAll] = useState(true)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const handleMediumClick = (mediumIndex: number) => {
    setMediums([...mediums].map((medium, index) => {
      if (index === mediumIndex) {
        medium.checked = !medium.checked
        medium.sites = medium.sites.map(site => {
          site.checked = medium.checked
          return site
        })
      }
      return medium
    }))

    isAllStatusCheck(mediums)
  }

  const handleSiteClick = (mediumValue: string, siteValue: string) => {
    setMediums([...mediums].map((medium) => {
      if (medium.value === mediumValue) {
        medium.sites = medium.sites.map(site => {
          if (site.value === siteValue) {
            site.checked = !site.checked
          }
          return site
        })
      }
      medium.checked = medium.sites.every(site => site.checked)
      return medium
    }))

    isAllStatusCheck(mediums)
  }

  const isAllStatusCheck = (mediums: Medium[]) => {
    if (mediums.every(medium => medium.checked && (medium.sites.length === 0 || medium.sites.every(site => site.checked)))) {
      setIsAll(true)
    } else {
      setIsAll(false)
    }
  }

  const numberOfMediumsChecked = (mediums: Medium[]) => {
    return mediums.reduce((accumulator, currentMedium) => {
      if (currentMedium.checked) {
        return accumulator + currentMedium.sites.length
      }

      return accumulator + currentMedium.sites.filter(site => site.checked).length
    }, 0)
  }

  const labelText = (mediums: Medium[]) => {

    if (isAll) {
      return "All medium"
    }

    const numberOfChosen = numberOfMediumsChecked(mediums)

    if (numberOfChosen === 0) {
      return "No medium"
    }

    if (numberOfChosen > 3) {
      return "Multiple medium"
    }

    return mediums.reduce<string>((accumulator, currentMedium): string => {
      for (let site of currentMedium.sites) {
        if (site.checked) {

          if (accumulator === "") {
            accumulator = site.label
          } else {
            accumulator = `${accumulator}, ${site.label}`
          }

        }
      }
      return accumulator
    }, "")
  }

  const handleClickAll = () => {
    if (isAll) {
      setMediums(mediums.map(medium => {
        medium.checked = false
        medium.sites = medium.sites.map(site => {
          site.checked = false
          return site
        })
        return medium
      }))
    } else {
      setMediums(mediums.map(medium => {
        medium.checked = true
        medium.sites = medium.sites.map(site => {
          site.checked = true
          return site
        })
        return medium
      }))
    }
    setIsAll(!isAll)
  }

  const applyFilter = () => {

    if (mediums.every(medium => medium.sites.every(site => !site.checked))) {
      toast.error("Please select at least one medium")
      return
    }

    dispatch(setMediumFilter({
      mediums: mediums.filter(medium => medium.checked).map(medium => medium.value),
      sites: mediums.reduce((acc: string[], curr) => {
        let list: string[] = []
        curr.sites.forEach(site => {
          if (site.checked) {
            list.push(site.value)
          }
        })
        return [...acc, ...list]
      }, [])
    }))
  }

  const toggleMenu = () => {
    if (!showDropdownMenu) {
      document.addEventListener('click', closeMenu)
      setShowDropdownMenu(true)
    }
  }

  const closeMenu = (e: any) => {
    if (!e.target.closest("#dropdownMenuContent")) {
      setShowDropdownMenu(false)
      document.removeEventListener('click', closeMenu)
    }
  }

  return (
    <MediumFilterRoot>
      <DropdownHeader>
        <button onClick={toggleMenu}>
          <span>{labelText(mediums)}</span>
          <span><i className="fas fa-filter" /></span>
        </button>
      </DropdownHeader>
      {
        showDropdownMenu && (
          <DropdownMenu>
            <DropdownMenuContent id="dropdownMenuContent" >

              <MediumFilterItemRoot onClick={handleClickAll} >
                <MediumFilterItemContent>
                  <div>
                    <CheckBox type="checkbox" checked={isAll} readOnly />
                    <span>All medium</span>
                  </div>
                </MediumFilterItemContent>
              </MediumFilterItemRoot>

              {mediums.map((medium, index) => (
                <MediumFilterItem
                  medium={medium}
                  index={index}
                  handleMediumClick={handleMediumClick}
                  handleSiteClick={handleSiteClick}
                  key={medium.value}
                />))}

            </DropdownMenuContent>
            <DropdownActions>
              <ApplyFilterButton onClick={() => { applyFilter() }}>
                Apply filter
              </ApplyFilterButton>
            </DropdownActions>
          </DropdownMenu>)
      }
    </MediumFilterRoot>
  )
}

export default MediumFilter