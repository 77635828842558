import React from 'react'
import { useSelector } from "react-redux"
import styled from 'styled-components'
import { selectSentiment } from "../../../../../app/store/smartTrainSlice"
import LightBulbIcon from "../../../../reusable/icons/LightBulbIcon"

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #BDBDBD;
  
  &:hover {
    svg {
      circle {
        fill: #FFD366;
      }
    }
  }

`

function SentimentIndicator() {
  const sentiment = useSelector(selectSentiment)
  return (
    <>
      {
        sentiment.hasModel && (
          <>
            <IconWrapper
              data-tip="<p>You are using custom sentiment tagging. Smart<br/>Train the model if you wish to improve its accuracy.</p>"
              data-html={true}
            >
              <LightBulbIcon />
            </IconWrapper>
          </>
        )
      }
    </>
  )
}

export default SentimentIndicator
