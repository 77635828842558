import React from "react"
import { useSelector } from "react-redux"
import styled from 'styled-components'
import TaskList from "./tasklist"
import { selectFeedTaskAccess } from "../../../app/store/feedSlice"
import CreateFeedButton from "../create/button"
import Empty from "./empty"

const DashboardRoot = styled.div`
    display: grid;
    padding: 3rem;
    grid-template-rows: min-content auto;
    min-width: 960px;
    overflow: auto;
`

const Header = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    column-gap: 1rem;
    >h1 {
        font-weight: 500;
        font-size: 1.5rem;
    }
    >* {
        align-self: center;
    }
`

const FeedDashboard = () => {
    const liveTasks = useSelector(selectFeedTaskAccess)

    return (
        <DashboardRoot>
            <Header>
                <h1>My Feeds</h1>
                <CreateFeedButton />
            </Header>
            {liveTasks && liveTasks.length > 0 ? <TaskList tasks={liveTasks} /> : <Empty />}
        </DashboardRoot>
    )
}

export default FeedDashboard
