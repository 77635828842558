import styled from 'styled-components'
import Button from '../../../../reusable/buttons/Button'

export const FeedbackButton = styled(Button)`

`

export const FeedbackRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  column-gap: 1rem;
  color: #868686;
`