import * as React from "react"
import { IconProp } from "./interface"

const ViralityScoreLow = ({
  width = "1rem",
  fill = "#29D1D7",
  stroke = 1.5,
}: IconProp) => {
  return (
    <svg
      viewBox="0 0 35 25"
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.148 7.6l-7.528 5.646 7.22 5.416a10.755 10.755 0 01-9.076 4.976C4.819 23.638 0 18.818 0 12.874 0 6.929 4.82 2.11 10.764 2.11a10.76 10.76 0 019.384 5.49z"
        fill={fill}
      />
      <path
        d="M22.667 8.407c1.47 1.47 3.526 5.415 0 9.445"
        stroke={fill}
        strokeWidth={stroke}
        strokeLinecap="round"
      />
      <path
        d="M26.444 4.629c1.9 2.546 4.562 9.386 0 16.37M30.223.852c2.85 3.721 6.842 13.717 0 23.925"
        stroke="#F8F8FA"
        strokeWidth={stroke}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ViralityScoreLow
