import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import uf from "usefuljs"
import {
  showTopicData,
  selectHiddenTopics,
  setHidden,
} from "../../../../../../../app/store/overviewSlice"
import { selectTaskId } from "../../../../../../../app/store/feedSlice"
import TrendItemRoot, { SettingMenu } from "./styled/TrendItemRoot"
import SettingButton from "../styled/SettingButton"
import { store } from "../../../../../../../app/store"
import { toast } from "react-toastify"
import { HiddenItemContainer, Label, PercentageLabel, RightContainer, SettingContainer } from "./styled"
import EditHiddenIcon from "../../../../../../reusable/icons/EditHiddenIcon"

interface TrendItemProp {
  topic: string
  score: number
  rank: number
}

function TrendItem(props: TrendItemProp) {
  const dispatch = useDispatch()

  const taskId = useSelector(selectTaskId)
  const hiddenTopics = useSelector(selectHiddenTopics)

  const [showSetting, setShowSetting] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const handleSettingButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setShowMenu(!showMenu)
  }

  const hideTopic = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    try {
      event.stopPropagation()

      const res: any = await uf.ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/overview/${store.getState().feed.task_id}/hide`,
        data: {
          ...store.getState().overview.hide,
          topics: [...hiddenTopics, props.topic]
        }
      })

      if (res.result === "success") {
        dispatch(setHidden(res.data))
        toast.info(`"${props.topic}" added to hidden topics`)
      } else {
        throw new Error(res.result)
      }

    } catch (err) {
      toast.error(err)
    }
  }

  return (
    <TrendItemRoot
      onClick={() =>
        dispatch(
          showTopicData({
            topics: props.topic.split(" · "),
            taskId,
          })
        )
      }
      onMouseEnter={() => setShowSetting(true)}
      onMouseLeave={() => {
        setShowSetting(false)
        setShowMenu(false)
      }
      }
    >
      <Label>
        <span>{props.rank}</span>
        <span>{props.topic}</span>
      </Label>
      <RightContainer>
        <PercentageLabel>
          {(props.score * 100).toFixed(2)}%
        </PercentageLabel>
        {
          showSetting && (
            <SettingContainer>
              <SettingButton onClick={handleSettingButtonClick}>
                <i className="fas fa-ellipsis-v" />
              </SettingButton>
              {showMenu && (
                <SettingMenu>
                  <HiddenItemContainer onClick={hideTopic}>
                    <div>
                      <EditHiddenIcon height="1.2rem" width="1.2rem" fill="#2F3647" />
                    </div>
                    <div>
                      <p>Hide this topic</p>
                      <p>See fewer topic like this</p>
                    </div>

                  </HiddenItemContainer>
                </SettingMenu>
              )}
            </SettingContainer>
          )
        }
      </RightContainer>
    </TrendItemRoot >
  )
}

export default TrendItem