import React from 'react'
import styled from 'styled-components'
import { siteicon } from "../../../../app/tools/display"
import Progress from "../../../reusable/utils/ProgressBar"

interface RowProp {
  site: string
  channel: string
  postCount: number
  maxSortedPostCount: number
}

const RowRoot = styled.li<{ isClickable?: boolean }>`
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  width: 100%;
  height: 40px;
  cursor: ${props => props.isClickable ? "pointer" : "default"};
  column-gap: 0.5rem;
  &:hover {
      background-color: #f5f5f5;
  }


  div:first-child {
    grid-column: 1 / 5;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  div:nth-child(2) {
    grid-column: 5 / 13;
    padding: 7.2px 10.8px;
  }

  div:nth-child(3) {
    grid-column: 13 / 14;
    padding: 7.2px 10.8px;
  }
`

const Site = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CountLabel = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
  max-width: 100%;
  min-width: 50px;
  text-align: center;
`

const Logo = styled.img`
  height: 20px;
  margin-right: 0.5rem;
`

const MiddleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 0.5rem;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 40%;
    flex: 2 0 0;
  }

  div {
    width: 100%;
    flex: 3 0 0;
  }
`

function SiteRow({ site, channel, postCount, maxSortedPostCount }: RowProp) {
  return (
    <RowRoot isClickable>
      <div>
        <Logo src={siteicon(site, "News")} alt={`${site} logo`} /> {"  "}
        <Site>{site}</Site>
      </div>
      <MiddleContainer>
        <p>{channel}</p>
        <div>
          <Progress
            height={12}
            value={postCount / maxSortedPostCount}
            loadingColor="#29D1D7"
            backgroundColor="transparent"
            hideLabel
          />
        </div>
      </MiddleContainer>
      <div>
        <CountLabel>{postCount}</CountLabel>
      </div>
    </RowRoot>
  )
}

export default SiteRow
