import styled, { css } from 'styled-components'
import BaseButton from '../../../../../../reusable/buttons/BaseButton'

export const SentimentRoot = styled.div`
    cursor: pointer;
    position: relative;
`

export const SentimentLabel = styled.p<{ score: number | null }>`

    font-size: 12px;
    font-weight: 600;
    width: 92px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 4px;

    ${props => props.score === 1 && css`
        color: #44D86C;
        background-color: #E5FCEB;
        border: 1px solid #6BD086;
    `}

    ${props => props.score === 0 && css`
        color: #898989;
        background-color: #F8F8FA;
        border: 1px solid #BDBDBD;
    `}

    ${props => props.score === -1 && css`
        color: #ED5252;
        background-color: #F6D7D8;
        border: 1px solid #FF6E86;
    `}

    ${props => props.score === null && css`
        display: hidden;
    `}
`

export const NoSentimentWrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 0.4rem;
    p {
        justify-self: center;
        align-self: start;
        font-size: 12px;
        color: #DADADA;
        text-align: center;
    }
    svg {
        justify-self: center;
        align-self: end;
    }
`

export const PickerRoot = styled.div`
  position: absolute;
  top: -94px;
  right: -80px;
  z-index: 999;
  background-color: white;
  padding: 0.5rem 0;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
  border-radius: 4px;
`

export const SentimentContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    padding: 0px 8px;
`

export const ButtonWrapper = styled(BaseButton)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    row-gap: 0.4rem;
    
    &:hover {
        border-radius: 5px;
        background-color: #F8F8FA;
    }
`