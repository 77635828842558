import {
  LiveFeedFilter,
  FeedData,
  SortBy,
  SentimentType,
} from "../interfaces/feed";
import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ajax } from "usefuljs";
import { store, RootState } from ".";
import { convertIsComment, date_conv } from "../tools/task";
import { removeUser } from "./userSlice";
import { LiveFeedsFromAPI } from "../interfaces/ResponseFromAPIs";

interface LiveFeedPostState {
  filter: LiveFeedFilter;
  fetching: boolean;
  total: number;
  data: FeedData[];
  displayFeeds: FeedData[];
  isSelecting: boolean;
  isHighlightingKeywords: boolean;
  exportFeeds: FeedData[];
}

const initialState: LiveFeedPostState = {
  filter: {
    page: 1,
    postsPerPage: 20,
    unix_start: -3,
    unix_end: -1,
    sites: [],
    task_id: 0,
    limit: 10,
    search: "",
    mediums: [],
    is_comment: 0,
    sortBy: "unix_timestamp",
    disableSmartFilter: false,
    sentiments: ["unassigned", "negative", "neutral", "positive"],
  },
  data: [],
  displayFeeds: [],
  isSelecting: false,
  isHighlightingKeywords: false,
  exportFeeds: [],
  fetching: true,
  total: 0,
};

// fetch live data
export const fetchData = createAsyncThunk(
  "feed/posts/fetch",
  async (_, thunkAPI): Promise<FeedData[]> => {
    const currentFilter: LiveFeedFilter = store.getState().livefeed.filter;

    try {
      const res: LiveFeedsFromAPI = await ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/search`,
        data: {
          task_id: currentFilter.task_id,
          from: date_conv(currentFilter.unix_start),
          to: date_conv(currentFilter.unix_end),
          sites: currentFilter.sites.length ? currentFilter.sites : null,
          mediums: currentFilter.mediums.length ? currentFilter.mediums : null,
          is_comment: convertIsComment(currentFilter.is_comment),
          size: currentFilter.postsPerPage,
          offset: (currentFilter.page - 1) * currentFilter.postsPerPage,
          query: currentFilter.search.length ? currentFilter.search : null,
          sort: currentFilter.sortBy,
          spam: currentFilter.disableSmartFilter, // null- ignore spams boolean: true - show ai spam hide manual spam / false - hide both spams
          // eslint-disable-next-line array-callback-return
          sentiment: currentFilter.sentiments.map((sentiment) => {
            switch (sentiment) {
              case "unassigned":
                return -128;
              case "negative":
                return -1;
              case "neutral":
                return 0;
              case "positive":
                return 1;
            }
          }),
        },
      });

      if (res.result !== "success") {
        throw new Error("failed to fetch data");
      }
      thunkAPI.dispatch(setTotal(res.total)); // total items in database

      return res.data;
    } catch (err) {
      throw new Error(err);
    }
  }
);

export const truncateData = createAsyncThunk(
  "feed/posts/truncate",
  (_, thunkAPI) => {
    thunkAPI.dispatch(setData([]));
    thunkAPI.dispatch(setDisplayData([]));
  }
);

// filters that will trigger FULLRELOAD (EMPTY STORE + RESET DISPLAY) then fetchdata
export const setFilterSearch = createAsyncThunk(
  "feed/posts/setSearch",
  (search: string, thunkAPI) => {
    thunkAPI.dispatch(setPage(1));
    thunkAPI.dispatch(setSearch(search));
    thunkAPI.dispatch(truncateData());
    thunkAPI.dispatch(fetchData());
  }
);

export const setFilterSites = createAsyncThunk(
  "feed/posts/setSites",
  (sites: string[], thunkAPI) => {
    thunkAPI.dispatch(setPage(1));
    thunkAPI.dispatch(setSites(sites));
    thunkAPI.dispatch(truncateData());
    thunkAPI.dispatch(fetchData());
  }
);

export const setFilterUnixStart = createAsyncThunk(
  "feed/posts/setUnixStart",
  (unixStart: number, thunkAPI) => {
    thunkAPI.dispatch(setPage(1));
    thunkAPI.dispatch(setUnixStart(unixStart));
  }
);

export const setFilterUnixEnd = createAsyncThunk(
  "feed/posts/setUnixEnd",
  (unixEnd: number, thunkAPI) => {
    thunkAPI.dispatch(setPage(1));
    thunkAPI.dispatch(setUnixEnd(unixEnd));
    thunkAPI.dispatch(truncateData());
    thunkAPI.dispatch(fetchData());
  }
);

export const setSortByFilter = createAsyncThunk(
  "feed/posts/setSortBy",
  (sortBy: SortBy, thunkAPI) => {
    thunkAPI.dispatch(setPage(1));
    thunkAPI.dispatch(setSortBy(sortBy));
    thunkAPI.dispatch(truncateData());
    thunkAPI.dispatch(fetchData());
  }
);

export const setPostTypeFilter = createAsyncThunk(
  "feed/posts/setPostType",
  (postType: number, thunkAPI) => {
    thunkAPI.dispatch(setPage(1));
    thunkAPI.dispatch(setPostType(postType));
    thunkAPI.dispatch(truncateData());
    thunkAPI.dispatch(fetchData());
  }
);

export const setMediumFilter = createAsyncThunk(
  "feed/posts/setMedium",
  (mediumFilter: { mediums: string[]; sites: string[] }, thunkAPI) => {
    thunkAPI.dispatch(setPage(1));
    thunkAPI.dispatch(setMediumsAndSites(mediumFilter));
    thunkAPI.dispatch(truncateData());
    thunkAPI.dispatch(fetchData());
  }
);

export const setPaginationPage = createAsyncThunk(
  "feed/posts/setPaginationPage",
  (page: number, thunkAPI) => {
    thunkAPI.dispatch(setPage(page));
    thunkAPI.dispatch(truncateData());
    thunkAPI.dispatch(fetchData());
  }
);

export const setPaginationPostsPerPage = createAsyncThunk(
  "feed/posts/setPaginationPostsPerPage",
  (postsPerPage: number, thunkAPI) => {
    thunkAPI.dispatch(setPage(1));
    thunkAPI.dispatch(setPostsPerPage(postsPerPage));
    thunkAPI.dispatch(truncateData());
    thunkAPI.dispatch(fetchData());
  }
);

export const setIrrelevant = createAsyncThunk(
  "feed/posts/setIrrelevant",
  async (postHash: string, thunkAPI) => {
    try {
      const taskId = store.getState().feed.task_id;
      const res: any = await ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/spam/tag`,
        data: {
          live_sid: `${taskId}_${postHash}`,
          user_spam: 1,
        },
      });

      if (res.result !== "success") {
        throw new Error("failed to set irrelevant");
      }

      thunkAPI.dispatch(removePost(postHash));
      // return res.data
      // thunkAPI.dispatch(fetchData())
    } catch (err) {
      throw new Error(err);
    }
  }
);

export const switchSmartFilter = createAsyncThunk(
  "feed/posts/switchSmartFilter",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(switchDisableSmartFilter());
      thunkAPI.dispatch(fetchData());
    } catch (err) {
      throw new Error(err);
    }
  }
);

// slice
export const LiveFeedPostSlice = createSlice({
  name: "feed/posts",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<LiveFeedFilter>) => {
      state.filter = action.payload;
    },
    clearPosts: (state) => {
      state.filter = { ...initialState.filter };
      state.data = [];
      state.displayFeeds = [];
      state.exportFeeds = [];
      state.fetching = false;
      state.isSelecting = false;
      state.total = 0;
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.filter.search = action.payload;
    },
    setSites: (state, action: PayloadAction<string[]>) => {
      state.filter.sites = action.payload;
    },
    setUnixStart: (state, action: PayloadAction<number>) => {
      state.filter.unix_start = action.payload;
    },
    setUnixEnd: (state, action: PayloadAction<number>) => {
      state.filter.unix_end = action.payload;
    },
    setSortBy: (state, action: PayloadAction<SortBy>) => {
      state.filter.sortBy = action.payload;
    },
    setPostType: (state, action: PayloadAction<number>) => {
      state.filter.is_comment = action.payload;
    },
    setMediumsAndSites: (
      state,
      action: PayloadAction<{ mediums: string[]; sites: string[] }>
    ) => {
      state.filter.mediums = action.payload.mediums;
      state.filter.sites = action.payload.sites;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filter.page = action.payload;
    },
    setPostsPerPage: (state, action: PayloadAction<number>) => {
      state.filter.postsPerPage = action.payload;
    },
    setTaskId: (state, action: PayloadAction<number>) => {
      state.filter.task_id = action.payload;
    },
    setData: (state, action: PayloadAction<FeedData[]>) => {
      state.data = action.payload;
    },
    setDisplayData: (state, action: PayloadAction<FeedData[]>) => {
      state.displayFeeds = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.filter.limit = action.payload;
    },
    addExportFeed: (state, action: PayloadAction<FeedData>) => {
      state.exportFeeds.push(action.payload);
    },
    removeExportFeed: (state, action: PayloadAction<FeedData>) => {
      state.exportFeeds = [...state.exportFeeds].filter(
        (feed) => feed.hash !== action.payload.hash
      );
    },
    clearExportFeeds: (state) => {
      state.exportFeeds = [];
    },
    setIsSelecting: (state, action: PayloadAction<boolean>) => {
      state.isSelecting = action.payload;
    },
    setIsHighlightingKeyword: (state, action: PayloadAction<boolean>) => {
      state.isHighlightingKeywords = action.payload;
    },
    switchDisableSmartFilter: (state) => {
      state.filter.disableSmartFilter = !state.filter.disableSmartFilter;
    },
    removePost: (state, action: PayloadAction<string>) => {
      state.displayFeeds = state.displayFeeds.filter(
        (post) => post.hash !== action.payload
      );
    },
    setSentiments: (state, action: PayloadAction<SentimentType[]>) => {
      state.filter.sentiments = action.payload;
    },
    updatePostSentiment: (
      state,
      action: PayloadAction<{ taskHash: string; sentiment: number }>
    ) => {
      state.displayFeeds = state.displayFeeds.map((feed) => {
        if (feed.hash === action.payload.taskHash) {
          return {
            ...feed,
            ai_sentiment: action.payload.sentiment,
          };
        }

        return feed;
      });
    },
  },
  extraReducers: {
    [fetchData.pending.toString()]: (state) => {
      state.fetching = true;
    },
    [fetchData.fulfilled.toString()]: (
      state,
      action: PayloadAction<FeedData[]>
    ) => {
      state.data = action.payload;
      state.displayFeeds = action.payload;
      state.fetching = false;
    },
    [fetchData.rejected.toString()]: (state) => {
      state.fetching = false;
    },
    [removeUser.fulfilled.toString()]: (state) => {
      Object.assign(state, initialState);
    },
  },
});

// actions
export const {
  setFilter,
  clearPosts,
  setFetching,
  setSearch,
  setTaskId,
  setData,
  setDisplayData,
  setTotal,
  setLimit,
  setSites,
  setPage,
  setPostsPerPage,
  setUnixStart,
  setUnixEnd,
  setSortBy,
  setPostType,
  setMediumsAndSites,
  addExportFeed,
  removeExportFeed,
  clearExportFeeds,
  setIsSelecting,
  switchDisableSmartFilter,
  removePost,
  setIsHighlightingKeyword,
  setSentiments,
  updatePostSentiment,
} = LiveFeedPostSlice.actions;

// selector
export const selectFetching = (state: RootState) => state.livefeed.fetching;
export const selectDisplayData = (state: RootState) =>
  state.livefeed.displayFeeds;
export const selectStoreData = (state: RootState) => state.livefeed.data;
export const selectStoreRecords = (state: RootState) =>
  state.livefeed.data.length;
export const selectTotal = (state: RootState) => state.livefeed.total;

export const selectFilterUnixStart = (state: RootState) =>
  state.livefeed.filter.unix_start;
export const selectFilterUnixEnd = (state: RootState) =>
  state.livefeed.filter.unix_end;
export const selectSortBy = (state: RootState) => state.livefeed.filter.sortBy;
export const selectPostType = (state: RootState) =>
  state.livefeed.filter.is_comment;

export const selectExportFeed = (state: RootState) =>
  state.livefeed.exportFeeds;
export const selectIsSelecting = (state: RootState) =>
  state.livefeed.isSelecting;

export const selectIsHighlightingKeyword = (state: RootState) =>
  state.livefeed.isHighlightingKeywords;

export const selectPage = (state: RootState) => state.livefeed.filter.page;
export const selectPostsPerPage = (state: RootState) =>
  state.livefeed.filter.postsPerPage;

export const selectDisableSmartFilter = (state: RootState) =>
  state.livefeed.filter.disableSmartFilter;
export const selectSentimentFilters = (state: RootState) =>
  state.livefeed.filter.sentiments;

export default LiveFeedPostSlice.reducer;
