import React from 'react'
import styled, {css} from 'styled-components'
import pulse from "../../../../../reusable/animation/pulse"
import wave from "../../../../../reusable/animation/wave"

interface ChartSkeletonProp {
  animation: "wave" | "pulse"
}

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 30vh;
`

const ChartSkeleton = styled.div<ChartSkeletonProp>`
  width: 100%;
  height: 100%;
  max-height: 170px;
  mask: url("https://lenx-public-assets.s3.amazonaws.com/loading/mountains.png");
  mask-repeat: repeat-x;
  background-color: rgba(0, 0, 0, 0.05);

  ${props => props.animation === "wave" && css`
    position: relative;
    overflow: hidden;
    &::after {
      animation: ${wave} 1.25s linear 0.5s infinite;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      transform: translateX(-100%);
      background: linear-gradient(90deg, transparent, transparent, transparent, transparent, rgba(0, 0, 0, 0.04), transparent);
    }
  `}

  ${props => props.animation === "pulse" && css`
    animation: ${pulse} 1.5s ease-in-out 0.5s infinite;
  `}
`

function LineChartSkeleton() {
  return (
    <Container>
      <ChartSkeleton animation="wave" />
    </Container>
  )
}

export default LineChartSkeleton
