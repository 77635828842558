import React, { useState } from 'react'
import styled from "styled-components"

interface DropdownWrapperProp {
  Header: ({ show }: { show: boolean }) => JSX.Element
  Menu: React.ReactNode
  direction?: "up" | "down"
}

const MenuContainer = styled.div<{ direction: "up" | "down" }>`
   transform: ${props => props.direction === "up" && "translate3d(0px, -125px, 0px)"};
`

function DropdownWrapper({
  Header,
  Menu,
  direction = "down"
}: DropdownWrapperProp) {
  const [show, setShow] = useState(false)

  const toggleMenu = () => {
    if (!show) {
      document.addEventListener('click', closeMenu)
      setShow(true)
    }
  }

  const closeMenu = (e: any) => {
    if (!e.target.closest(".dropdownMenu")) {
      setShow(false)
      document.removeEventListener('click', closeMenu)
    }
  }

  return (
    <div>
      <div onClick={toggleMenu}>
        <Header show={show} />
      </div>
      <MenuContainer className="dropdownMenu" direction={direction}>
        {show && Menu}
      </MenuContainer>
    </div>
  )
}

export default DropdownWrapper
