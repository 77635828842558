import { TopicDataDetail, TopicData } from "../interface";

//handle pre-merge
export const formatBriefTopicDataPreMerge = () => {};

export const mergeTopicData = (
  briefDataFromStore: TopicData[],
  detailDataFromApi: TopicDataDetail[]
) => {
  const mergedData = [];
  // console.log("Brief Data", briefDataFromStore);
  // console.log("Detail Data from api", detailDataFromApi);
  for (let i = 0; i < briefDataFromStore?.length; i++) {
    for (let j = 0; j < detailDataFromApi?.length; j++) {
      if (
        briefDataFromStore[i].briefData.topic_id ===
        detailDataFromApi[j].topic_id
      ) {
        console.log("Any Top Id matched");
        mergedData.push({
          briefData: briefDataFromStore[i].briefData,
          detailData: detailDataFromApi[j],
        });
      }
    }
  }

  return mergedData;
};
