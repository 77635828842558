import React, { useEffect, useMemo, useState } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  useRouteMatch,
  match,
} from "react-router-dom";

import {
  DisplayTask,
  selectFeedTaskHasAccess,
  selectIsLoadingTaskAccess,
  fetchFeedSetting,
  clearTaskSettings,
  fetchBookmarks,
  fetchByFeedUserInfo,
  selectFeedTaskInfo,
  DisplayCompareTask,
  selectFeedTaskAccess
} from "../../../app/store/feedSlice";

import SubNav from "./subnav";
import ThreadViewer from "./threadviewer";
import Posts from "./posts";
import Overview from "./overview";
import Compare from "./compare";
import styles from "./task.module.scss";
import { useDispatch, useSelector } from "react-redux";
import AccessDenied from "./AccessDenied";
import { getSources } from "../../../app/store/miscSlice";
import {
  getDisplaySmartTrainPrompt,
  fetchModelStatus,
} from "../../../app/store/smartTrainSlice";
import SmartFeedPrompt from "./SmartFeedPrompt";
import SmartTrainManager from "./posts/smartTrainManager";
import { TaskInfo } from "../../../app/interfaces/feed";
import Loader from "../../reusable/loader";

const Task = () => {
  const matches: match<any> = useRouteMatch();
  const dispatch = useDispatch();
  const path = useMemo(() => matches.path, [matches]);
  const taskId = useMemo(() => parseInt(matches.params.taskid), [matches]);
  const liveTasks = useSelector(selectFeedTaskAccess);
  const hasAccess = useSelector(selectFeedTaskHasAccess);
  const isLoadingTaskAccess = useSelector(selectIsLoadingTaskAccess);
  const task: TaskInfo = useSelector(selectFeedTaskInfo);
  const getAdhocTasks = () => {
    let list: any = [];
    liveTasks?.forEach((task) => {
      if (task?.task_type === 1 && task?.task_id !== taskId) {
        list.push(task);
      }
    });

    return list;
  };

  useEffect(() => {
    dispatch(DisplayTask(taskId));
    if (task?.taskType === 1) {
      const adhocTasks = getAdhocTasks();
      dispatch(DisplayCompareTask(adhocTasks[0]?.task_id));
    }

    if (hasAccess) {
      dispatch(fetchFeedSetting(taskId));
      dispatch(getSources());
      dispatch(fetchBookmarks(taskId));
      dispatch(fetchByFeedUserInfo());
      dispatch(fetchModelStatus());
      dispatch(getDisplaySmartTrainPrompt());
    }

    return () => {
      dispatch(clearTaskSettings());
    };
  }, [taskId, dispatch, hasAccess, liveTasks]);

  console.log("Task Type", task?.taskType);


  return (
    <div id={styles.task}>
      {hasAccess ? (
        <Router>
          <SubNav />
          <ThreadViewer />
          <Switch>
            <Route path={`${path}/overview`} component={Overview} />
            <Route path={`${path}/posts`} component={Posts} />
            <Route path={`${path}/compare`} component={Compare} />
            <Route path={path} component={Overview} />
          </Switch>
          <SmartFeedPrompt />
          <SmartTrainManager />
        </Router>
      ) : isLoadingTaskAccess ? null : (
        <AccessDenied />
      )}
    </div>
  );
};

export default Task;
