import React from 'react'
import { useDispatch } from "react-redux"
import { FeedData } from "../../../../../../../app/interfaces/feed"
import { setIrrelevant } from "../../../../../../../app/store/livefeedpost"
import MarkIrrelevantButton from "./MarkIrrelevantButton"
import SelectButton from "./SelectButton"
import { OptionsContainer } from "./styled"

interface MessageOptionsProp {
  data: FeedData
}

function MessageOptions({ data }: MessageOptionsProp) {
  const dispatch = useDispatch()
  return (
    <OptionsContainer>
      <SelectButton data={data} />
      <div onClick={() => dispatch(setIrrelevant(data.hash))}>
        <MarkIrrelevantButton />
      </div>
    </OptionsContainer>
  )
}

export default MessageOptions
