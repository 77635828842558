import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "."
import { UserInfo, UserConfig, UserState } from "../interfaces/user"
import uf from "usefuljs"
import { EditUserInfoForm } from "../../components/account/Profile/EditForm"
import { UserLimitAccountLevelInfoFromAPI, UserLimitInfoFromAPI } from "../interfaces/ResponseFromAPIs"



const initialState: UserState = {
    username: "",
    user_id: "",
    display_name: "",
    email: "",
    token: "",
    loader: 4,
    font: "",
    ac_id: 0,
    liveFeedLimit: null,
    adhocFeedLimit: null,
    exportPerMonthPerTaskQuota: null,
    keywordPerTask: null,
    queryModificationPerMonthPerTask: null,
    subscribedToSmartFeed: false,
    subscribedToAlert: false,
    dateFromUnixTime: null,
    keywordLimit: null

}


// auto refresh user data
export const refreshUser = createAsyncThunk(
    "user/refresh",
    async (token: string): Promise<UserState> => {
        try {
            const response: any = await uf.ajax({
                url: `${process.env.REACT_APP_ENDPOINT}/api/users/token/refresh`,
                headers: {
                    token
                }
            })
            if (!response.token) { throw new Error("no token") }
            return {
                ...response.data,
                token: response.token,
                liveFeedLimit: response.live_max
            } as UserState
        } catch (err) {
            throw new Error(err)
        }
    }
)


// logout,remove userinfo
export const removeUser = createAsyncThunk(
    "user/remove",
    async (token: string, _): Promise<void> => {
        await uf.ajax({
            method: "delete",
            url: `${process.env.REACT_APP_ENDPOINT}/api/users/logout`,
            headers: {
                token
            }
        }).catch(err => { console.error(err) })
        return
    }
)



// fetch user config
export const fetchUserConfig = createAsyncThunk<UserConfig, string>("user/fetchConfig", async (token: string, thunk): Promise<UserConfig> => {
    const fetchcf: any = await uf.ajax({
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/users/config`
    }).catch(err => { throw err })
    if (!fetchcf.data) {
        if (fetchcf.error === "Auth Fail") { thunk.dispatch(resetUser()) }
        throw new Error("Failed to fetch config")
    };
    return {
        ...fetchcf.data
    }
})

export const fetchUserInfo = createAsyncThunk("user/fetchUserInfo", async (token: string) => {
    try {
        // const token = store.getState().user.token
        const res: UserLimitInfoFromAPI = await uf.ajax({
            headers: { token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/users/info`
        })

        if (res.result === "success" && res.data.acc_level) {
            return res.data.acc_level
        }

        throw new Error("failed to fetch user info")
    } catch (e) {
        console.log(e)
    }
})


// slice
export const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setInfo: (state, action: PayloadAction<UserInfo>) => {
            Object.assign(state, action.payload)
        },
        resetUser: (state) => {
            const emptystate = initialState
            Object.assign(state, emptystate)
        },
        setUserInfo: (state, action: PayloadAction<EditUserInfoForm>) => {
            state.username = action.payload.userName
            state.display_name = action.payload.displayName
            state.email = action.payload.email
        }
    },
    extraReducers: {
        // REFRESH TOKEN SUCCESS
        [refreshUser.fulfilled.toString()]: (state, action: PayloadAction<UserInfo>) => {
            state.display_name = action.payload.display_name
            state.email = action.payload.email
            state.token = action.payload.token
            state.user_id = action.payload.user_id
            state.username = action.payload.username
        },
        // FAILED TO REFRESH TOKEN
        [refreshUser.rejected.toString()]: (state, action) => {
            const emptystate = initialState
            Object.assign(state, emptystate)
        },
        // LOGOUT / REMOVE USER
        [removeUser.fulfilled.toString()]: (state, action) => {
            const emptystate = initialState
            Object.assign(state, emptystate)
        },
        // FETCH USER CONFIG
        [fetchUserConfig.fulfilled.toString()]: (state, action: PayloadAction<UserConfig>) => {
            state.font = action.payload.font
            state.loader = action.payload.loader
        },
        [fetchUserConfig.rejected.toString()]: (_) => {
            // failed to fetch
        },
        [fetchUserInfo.fulfilled.toString()]: (state, action: PayloadAction<UserLimitAccountLevelInfoFromAPI>) => {
            state.liveFeedLimit = action.payload?.live_limit ?? null
            state.adhocFeedLimit = action.payload?.adhoc_limit ?? null
            state.queryModificationPerMonthPerTask = action.payload?.live_qry_mod ?? null
            state.exportPerMonthPerTaskQuota = action.payload?.export_pm ?? null
            state.keywordLimit = action.payload?.keyword_limit ?? null
            let dateFromUnixTime: number = null
            if(action.payload.date_from){
                const date: Date = new Date(action.payload.date_from);
                dateFromUnixTime = date.getTime();
            }
            state.dateFromUnixTime = dateFromUnixTime ?? null
        }
    }
})

// actions
export const { setInfo, resetUser, setUserInfo } = UserSlice.actions

// selector
export const selectUserInfo = (state: RootState) => state.user
export const selectUserConfig = (state: RootState) => ({ loader: state.user.loader, font: state.user.font })
export const selectAccountId = (state: RootState) => state.user.ac_id
export const selectUserName = (state: RootState) => state.user.display_name
export const selectLiveFeedLimit = (state: RootState) => state.user.liveFeedLimit
export const selectAdHocFeedLimit = (state: RootState) => state.user.adhocFeedLimit
export const selectToken = (state: RootState) => state.user.token
export const selectSubscriptedToSmartTrain = (state: RootState) => state.user.subscribedToSmartFeed
export const selectExportTaskQuota = (state: RootState) => state.user.exportPerMonthPerTaskQuota
export const selectFeedModificationLimit = (state: RootState) => state.user.queryModificationPerMonthPerTask
export const selectKeywordPerTask = (state: RootState) => state.user.keywordPerTask
export const selectDatefrom = (state: RootState) => state.user.dateFromUnixTime
export const selectKeywordLimit = (state: RootState) => state.user.keywordLimit

export default UserSlice.reducer