import create, { SetState } from "zustand";
import { devtools } from "zustand/middleware";

import {
  Frequency,
  TopicData,
  TrendOverviewData,
  VersionData,
} from "./interface";

export const postsStore = (set: SetState<PostsStore>) => ({
  isLoadingDetailed: true,
  setIsLoadingDetailed: (isLoadingDetailed: boolean) =>
    set({ isLoadingDetailed }),

  frequency: "Latest" as Frequency,
  setFrequency: (frequency: Frequency) => set({ frequency }),

  frequencyLabel: "Last 24 hours",
  setFrequencyLabel: (frequencyLabel: string) => set({ frequencyLabel }),

  labelId: undefined,
  setLabelId: (labelId: number | undefined) => set({ labelId }),

  labelText: "All in Hong Kong",
  setLabelText: (labelText: string) => set({ labelText }),

  allTopicData: null,
  setAllTopicData: (allTopicData: TopicData[] | null) => set({ allTopicData }),

  clickedPostFrequencies: null,
  setClickedPostFrequencies: (clickedPostFrequencies: Frequency[] | null) =>
    set({ clickedPostFrequencies }),

  isOpen: false,
  toggleOpen: () => {
    set((state) => ({ isOpen: !state.isOpen }));
  },

  activeCardId: null,
  setActiveCardId: (activeCardId: string | null) => set({ activeCardId }),

  isLoadingOverview: true,
  setIsLoadingOverview: (isLoadingOverview: boolean) =>
    set({ isLoadingOverview }),

  overviewPost: null,
  setOverviewPost: (overviewPost: TrendOverviewData | null) =>
    set({ overviewPost }),

  versionList: null,
  setVersionData: (versionList: any | null) => set({ versionList }),

  selectedVersion: null,
  setSelectedVersion: (selectedVersion: number | null) =>
    set({ selectedVersion }),

  selectedTrendId: null,
  setSelectedTrendId: (selectedTrendId: string | null) =>
    set({ selectedTrendId }),
});

export const usePostsStore = create(devtools(postsStore));

interface PostsStore {
  isLoadingDetailed: boolean;
  setIsLoadingDetailed: (isLoading: boolean) => void;

  frequencyLabel: string;
  setFrequencyLabel: (frequencyLabel: string) => void;

  frequency: Frequency;
  setFrequency: (frequency: Frequency) => void;

  labelText: string;
  setLabelText: (labelText: string) => void;

  labelId: number | undefined;
  setLabelId: (labelId: number | undefined) => void;

  allTopicData: TopicData[] | null;
  setAllTopicData: (topics: TopicData[] | null) => void;

  clickedPostFrequencies: Frequency[] | null;
  setClickedPostFrequencies: (id: Frequency[] | null) => void;

  isOpen: boolean;
  toggleOpen: () => void;

  activeCardId: null | string;
  setActiveCardId: (activeCardId: string | null) => void;

  isLoadingOverview: boolean;
  setIsLoadingOverview: (isLoading: boolean) => void;

  overviewPost: TrendOverviewData | null;
  setOverviewPost: (posts: TrendOverviewData) => void;

  versionList: any | null;
  setVersionData: (versions: any | null) => void;

  selectedVersion: number | null;
  setSelectedVersion: (version: number | null) => void;

  selectedTrendId: string | null;
  setSelectedTrendId: (trendId: string | null) => void;
}
