import React from 'react'
import { useDispatch } from "react-redux"
import styled from 'styled-components'
import { fetchData } from "../../../../../app/store/livefeedpost"
import { untagIrrelevant } from "../../../../../app/store/smartTrainSlice"
import Button from "../../../../reusable/buttons/Button"

interface FooterProp {
  onClose: () => void
}

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
`

function Footer({ onClose }: FooterProp) {
  const dispatch = useDispatch()

  const handleRestore = () => {
    dispatch(untagIrrelevant())
    dispatch(fetchData())
  }

  return (
    <FooterContainer>
      <Button variant="tertiary" onClick={onClose}>Close</Button>
      <Button onClick={handleRestore}>Restore selected posts</Button>
    </FooterContainer>
  )
}

export default Footer
