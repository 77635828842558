import React from 'react'
import styled from 'styled-components'
import useDropdown from "../../../../hooks/useDropdown"
import FilterIcon from "../../../../reusable/icons/FilterIcon"
import { CheckBox } from "../../../../reusable/inputs/CheckBox"
import _ from 'lodash'
import { SentimentType } from "../../../../../app/interfaces/feed"
import { useDispatch, useSelector } from "react-redux"
import { fetchData, selectSentimentFilters, setSentiments } from "../../../../../app/store/livefeedpost"

const FilterRoot = styled.div`
  position: relative;
`

const DropdownMenu = styled.div`
  max-height: 40vh;
  right: -10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  padding: 0.5rem 0 0.1rem 0;
  width: 130px;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0 0 1px rgba(10, 10, 10, 0.02);
  z-index: 11;
`

const DropdownMenuContent = styled.ul`
  overflow: auto;
  width: 100%;
`

const DropdownItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
  color: #424C5A;
  font-size: 14px;
  padding: 0.4rem 0.8rem;
  height: 35px;
  cursor: default;
  &:hover {
    background-color: #f5f5f5;
  }
`

const sentimentTypes: SentimentType[] = [
  "unassigned",
  "negative",
  "neutral",
  "positive"
]

function SentimentFilter() {
  const dispatch = useDispatch()

  const { showDropdownMenu, toggleMenu } = useDropdown("sentimentFilterMenuContent")
  const sentimentFilters = useSelector(selectSentimentFilters)

  const selectSentiment = (key: any) => {
    if (sentimentFilters.includes(key)) {
      dispatch(setSentiments(sentimentFilters.filter(item => item !== key)))
    } else {
      dispatch(setSentiments([...sentimentFilters, key]))
    }

    dispatch(fetchData())
  }

  return (
    <FilterRoot onClick={toggleMenu}>
      <FilterIcon height={"12px"} fill="#516380" />
      {
        showDropdownMenu && (
          <DropdownMenu>
            <DropdownMenuContent id="sentimentFilterMenuContent" >
              {sentimentTypes.map(type => (
                <DropdownItem  key={type}>
                  <CheckBox type="checkbox" checked={sentimentFilters.includes(type)} onClick={() => selectSentiment(type)} readOnly />
                  <span>{_.capitalize(type)}</span>
                </DropdownItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>)
      }
    </FilterRoot>
  )
}

export default SentimentFilter
