import React, { memo, useEffect } from "react";
import { FeedData } from "../../../../../app/interfaces/feed";
import Message from "./message";
import styled from "styled-components";

interface MessageScrollerProp {
  data: FeedData[];
  scrollElement: HTMLElement;
}

const MessageScrollerRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const MessageScroller = memo<MessageScrollerProp>(({ data, scrollElement }) => {
  useEffect(() => {
    scrollElement?.scroll({ top: 0 });
  }, [scrollElement]);

  return (
    <MessageScrollerRoot>
      {data.map((feed) => (
        <Message key={feed.hash} data={feed} />
      ))}
    </MessageScrollerRoot>
  );
});

export default MessageScroller;
