import styled from 'styled-components'

export const OptionsContainer = styled.div`
  position: absolute;
  background-color: white;
  right: 10px;
  top: -20px;
  display: none;
  column-gap: 0.25rem;
  padding: 0.4rem;
  align-items: center;
  border: 1px #dbdbdb solid;
  border-radius: 4px;
`