import React from 'react'
import styled, { css } from 'styled-components'
import { Order, OrderBy } from "./interface"
import { SortingBarRoot, SortingLabel, HeadLabel } from "./styled"

export interface SortingBarProp {
  setOrderBy: React.Dispatch<React.SetStateAction<OrderBy>>
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  order: Order
  orderBy: OrderBy
}

const SortIcon = styled.i<{ isActive: boolean }>`
  visibility: hidden;
  ${props => props.isActive && css`
    color: #516380;
  `}
`

function SortingBar({ setOrderBy, setOrder, order, orderBy }: SortingBarProp) {

  const handlePostDateFilterClick = () => {
    if (orderBy === "post_timestamp") order === "asc" ? setOrder("desc") : setOrder("asc")
    setOrderBy("post_timestamp")
  }

  const handleRemoveDateFilterClick = () => {
    if (orderBy === "spam_at") order === "asc" ? setOrder("desc") : setOrder("asc")
    setOrderBy("spam_at")
  }

  return (
    <SortingBarRoot>
      <p></p>
      <HeadLabel>
        <p>Posts</p>
      </HeadLabel>
      <SortingLabel
        isActive={orderBy === "post_timestamp"}
        onClick={handlePostDateFilterClick}
      >
        <p>Post Date</p>
        <SortIndicator
          order={order}
          isActive={orderBy === "post_timestamp"}
        />
      </SortingLabel>
      <HeadLabel>
        <p>Removed By</p>
      </HeadLabel>
      <SortingLabel
        isActive={orderBy === "spam_at"}
        onClick={handleRemoveDateFilterClick}
      >
        <p>Date Removed</p>
        <SortIndicator
          order={order}
          isActive={orderBy === "spam_at"}
        />
      </SortingLabel>
    </SortingBarRoot>
  )
}

interface SortIndicatorProp {
  isActive: boolean
  order: Order
}

function SortIndicator({ isActive, order }: SortIndicatorProp) {
  return <SortIcon
    className={isActive ? (order === "asc" ? "fas fa-arrow-up" : "fas fa-arrow-down") : "fas fa-arrow-up"}
    isActive={isActive}
  />
}

export default React.memo(SortingBar)
