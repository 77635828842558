import React from 'react'
import { useSelector } from "react-redux"
import styled from 'styled-components'
import { selectCurrent, selectIsLoading } from "../../../../../../app/store/smartTrainSlice"
import BaseButton from "../../../../../reusable/buttons/BaseButton"
import Skeleton from "../../../../../reusable/utils/Skeleton"

const Button = styled(BaseButton)`
  display: none;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(211, 210, 224, 0.6);
  transition: all ease 250ms;

  &:hover {
    background-color: #F7F7F9;
  }
`

const PostViewerRoot = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 300px;

  &:hover {
    ${Button} {
      display: flex;
    }
  }
`
const CurrentPost = styled.div`
  height: 200px;
  width: 500px;
  padding: 1.5rem;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(211, 210, 224, 0.6);
`

const PostContent = styled.div`
  height: 100%;
  color: #516380;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 8px;
  position: relative;

  h3 {
    font-weight: 700;
    margin-bottom: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #C4C4C4;
  }
`

const Link = styled.a`
  color: #516380;

  &:hover {
    color: #516380;
  }
`

function PostViewer() {

  const current = useSelector(selectCurrent)
  const isLoading = useSelector(selectIsLoading)

  return (
    <PostViewerRoot>
      <CurrentPost>
        <PostContent>
          {isLoading ? (
            <>
              <Skeleton height="16px" width="70%" margin="0px 0px 16px 0px" />
              <Skeleton height="16px" margin="0px 0px 8px 0px" />
              <Skeleton height="16px" margin="0px 0px 8px 0px" />
              <Skeleton height="16px" margin="0px 0px 8px 0px" />
              <Skeleton height="16px" width="30%" margin="0px 0px 8px 0px" />
            </>
          ) : (
            <>
              <Link href={current.currentDisplayPost?.post_link ?? ""} target="_blank">
                <h3>{current.currentDisplayPost?.thread_title}</h3>
              </Link>
              <p>{current.currentDisplayPost?.post_message}</p>
            </>
          )}
        </PostContent>
      </CurrentPost>
    </PostViewerRoot>
  )
}

export default PostViewer
