import React from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from "react-redux"
import { selectIsConfirming, selectSentiment, selectRelevancy, selectSmartTrainMode, setSmartTrainMode } from "../../../../../app/store/smartTrainSlice"
import { ModelType } from "../../../../../app/interfaces/smartTrain"

const SelectorContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 2rem;
  border-bottom: 1px solid #E5E5E5;
`

const TabOption = styled.div<{ isCurrent: boolean, isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  width: 100px;
  font-size: 20px;
  padding-bottom: 0.4rem;
  color: ${props => props.isCurrent ? "#283044" : "#868686"};
  border-bottom: ${props => props.isCurrent ? "3px solid #29d1d7" : "none"};
  ${props => props.isDisabled ? css`

  ` : css`
    cursor: pointer;
  `}
`

function TabSelector() {
  const dispatch = useDispatch()
  const mode = useSelector(selectSmartTrainMode)
  const sentiment = useSelector(selectSentiment)
  const relevancy = useSelector(selectRelevancy)

  const isConfirming = useSelector(selectIsConfirming)

  const selectMode = (mode: ModelType) => {
    if (!isConfirming) {
      if (mode === "relevancy" && !relevancy.isTraining) {
        dispatch(setSmartTrainMode(mode))
      }

      if (mode === "sentiment" && !sentiment.isTraining) {
        dispatch(setSmartTrainMode(mode))
      }
    }
  }

  return (
    <SelectorContainer>
      <TabOption isCurrent={mode === "relevancy" && !relevancy.isTraining} onClick={() => selectMode("relevancy")}>Relevancy</TabOption>
      <TabOption isCurrent={mode === "sentiment" && !sentiment.isTraining} onClick={() => selectMode("sentiment")}>Sentiment</TabOption>
    </SelectorContainer>
  )
}

export default TabSelector

