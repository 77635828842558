import React from 'react'
import { IrrelevantPost } from "../../../../../../app/interfaces/smartTrain"
import PostItem from "./PostItem"
import { PostsContainer } from "./styled"

interface PostListProp {
  feeds: IrrelevantPost[] | any
}

function PostList({ feeds }: PostListProp) {
  return (
    <PostsContainer>
      {feeds && feeds.map(feed => <PostItem feed={feed} key={feed.hash} />)}
    </PostsContainer>
  )
}

export default PostList
