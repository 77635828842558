import * as React from "react";

function ShareIcon(props:any) {
  return (
    <svg
      width={25}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.161 16.25c2.678 0 4.839 2.177 4.839 4.875S22.839 26 20.161 26c-2.677 0-4.838-2.177-4.838-4.875 0-.39.048-.747.145-1.121l-7.065-3.738a4.752 4.752 0 01-3.564 1.609C2.16 17.875 0 15.697 0 13s2.161-4.875 4.839-4.875c1.42 0 2.677.634 3.564 1.609l7.065-3.738a4.367 4.367 0 01-.145-1.121C15.323 2.178 17.483 0 20.16 0S25 2.178 25 4.875 22.839 9.75 20.161 9.75c-1.42 0-2.677-.634-3.564-1.609L9.532 11.88a4.363 4.363 0 010 2.242l7.065 3.738a4.751 4.751 0 013.564-1.609z"
        fill="#283044"
      />
    </svg>
  );
}

export default ShareIcon
