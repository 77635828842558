import React from "react";
import { removeUser, selectUserInfo } from "../../app/store/userSlice";
import { useDispatch, useSelector } from "react-redux";

const Logout = ()=>{
    const dispatch = useDispatch();
    const userinfo = useSelector(selectUserInfo);
    React.useEffect(()=>{
        dispatch(removeUser(userinfo.token));
    },[userinfo,dispatch]);

    return <React.Fragment></React.Fragment>;
}

export default Logout;