export const ExportFields: Record<string, string> = {
  "hash": "ID",
  "medium": "Medium",
  "site": "Site",
  "thread_title": "Thead Title",
  "post_message": "Post Message",
  "author_name": "Author Name",
  "channel": "Channel",
  "post_timestamp": "Post Timestamp",
  "post_date": "Post Date",
  "post_time": "Post Time",
  "thread_link": "Thread Link",
  "post_link": "Post Link",
  "comment_count": "Comment Count",
  "share_count": "Share Count",
  "view_count": "View Count",
  "reaction_count": "Reaction Count",
  "reaction_like": "Like (reaction)",
  "reaction_dislike": "Dislike (reaction)",
  "reaction_angry": "Angry (reaction)",
  "reaction_haha": "Haha (reaction)",
  "reaction_love": "Love (reaction)",
  "reaction_sad": "Sad (reaction)",
  "reaction_wow": "Wow (reaction)",
  "ai_sentiment": "Sentiment",
  "ai_impact": "Impact",
}