import React from 'react'
import Button from '../../../../reusable/buttons/Button'
import { CheckBox } from '../../../../reusable/inputs/CheckBox'
import ModalHeader from '../../../../reusable/utils/Modal/ModalHeader'
import ModalMain from '../../../../reusable/utils/Modal/ModalMain'
import { Frequency, TopicData } from '../../../interface'
import UseSaveHook from '../UseSaveHook'
import { ModalRoot, ModalBody, TitleText, ButtonsContainer, CheckboxesContainer, StyledForm, CheckboxRowContainer } from './styled'

interface FeedbackModalProps {
  isOpen: boolean
  toggleOpen: () => void
  activeCardId: string | null
  activeCardData: TopicData
  labelId: number
  frequency: Frequency
}
interface CheckboxRowProps {
  text: string
  addFeedback: (value: string) => void
}

const CheckboxRow = ({text, addFeedback}: CheckboxRowProps) => {
  return (
    <CheckboxRowContainer>
      <CheckBox onChange={()=>addFeedback(text)} />
      <span>{text}</span>
    </CheckboxRowContainer>
  )
}

const FeedbackModal = ({
  isOpen, 
  toggleOpen, 
  activeCardId, 
  activeCardData, 
  labelId, 
  frequency
}: FeedbackModalProps) => {

  const {isSubmitted, setIsSubmitted, feedbackMutation} = UseSaveHook()

  const [feedback, setFeedback] = React.useState<string[]>([])
  const [textareaFeedback, setTextareaFeedback] = React.useState<string>("")

  const addFeedback = (value: string) => {
    const checked = feedback.find(el => el === value)
    checked ? setFeedback(feedback.filter(el => el !== value)) :
      setFeedback([...feedback, value])
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!feedback.length && !textareaFeedback.length) return

    const feedbackData = textareaFeedback.length > 0 ? [...feedback, textareaFeedback] : feedback
    const word = `${activeCardData.briefData.data.word} ` || ""
    const words = activeCardData.briefData.data.related_word.join(" ") || ""

    feedbackMutation.mutate({
      topic_id: activeCardId!,
      label_id: labelId,
      freq: frequency,
      words: word + words,
      feedback: feedbackData
    })

    setIsSubmitted(true)
    setTimeout(()=>{
      toggleOpen()
    }, 1500)
  }

  const checkboxOptions = [
    "Irrelevant trend", 
    "Duplicated trend", 
    "Confusing content", 
    "Too many ads in posts", 
    "Other"
  ]

  return (
    <ModalRoot show={isOpen}>
      <ModalMain width={425}>
        <ModalHeader onClose={toggleOpen} />
          <ModalBody>
            <TitleText>Sorry to hear that, <br/> please tell us why?</TitleText>
            <StyledForm onSubmit={onSubmit}>
              <CheckboxesContainer>
                {
                  checkboxOptions.map((option) => (
                    <CheckboxRow
                      key={option} 
                      text={option}
                      addFeedback={addFeedback}
                    />
                  ))
                }
                <textarea placeholder="Tell us more" disabled={feedback.indexOf("Other") === -1} onChange={(e)=> setTextareaFeedback(e.target.value)} />
              </CheckboxesContainer>
              <ButtonsContainer>
                <Button variant="tertiary" type="button" onClick={toggleOpen}>Cancel</Button>
                <Button variant="primary" type="submit">Submit</Button>
              </ButtonsContainer>
            {isSubmitted ? <p>Thank you for your feedback!</p>: <p>&nbsp;</p>}
            </StyledForm>
          </ModalBody>
      </ModalMain>
    </ModalRoot>
  )
}

export default FeedbackModal
