import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux"
import { selectDisableSmartFilter, switchSmartFilter } from "../../../../../../app/store/livefeedpost"
import Switch from "../../../../../reusable/inputs/Switch"

const SwitchRoot = styled.div`
   display: flex;
   align-items: center;
   column-gap: 0.5rem;
`

const SwitchLabel = styled.span`
  font-size: 16px;
  color: #516380;
`

const SwitchInfo = styled.i`
  color: #BDBDBD;
  font-size: 14px;
`

function SmartFilterSwitch() {
  const dispatch = useDispatch()
  const disabledFilter = useSelector(selectDisableSmartFilter)

  return (
    <SwitchRoot>
      <SwitchLabel>Smart filter</SwitchLabel>
      <SwitchInfo
        className="fas fa-question-circle"
        data-tip={"<p>Enable smart filter to exclude<br/> detected irrelevant post</p>"}
        data-place="bottom"
        data-effect="solid"
        data-html={true}
      />
      <Switch checked={!disabledFilter} handleClick={() => dispatch(switchSmartFilter())} />
    </SwitchRoot>
  )
}

export default SmartFilterSwitch
