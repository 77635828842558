import React, { useState, useEffect } from 'react'
import styles from './KeywordManager.module.scss'
import uf from "usefuljs"
import { WordCloudItem } from "../../../../../../../app/interfaces/overview"
import { store } from "../../../../../../../app/store"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setHidden } from "../../../../../../../app/store/overviewSlice"

interface KeywordManagerProp {
  words: WordCloudItem[]
  hidden: string[]
  cancel: () => void
}

function KeywordManager({ words, cancel, hidden }: KeywordManagerProp) {

  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState<0 | 1>(1)
  const [searchText, setSearchText] = useState<string>("")
  const [hiddenKeywords, setHiddenKeywords] = useState<string[]>([])

  useEffect(() => {
    setHiddenKeywords(hidden)
  }, [hidden])

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const displayWords = words.filter(word => {
    return activeTab ? !hiddenKeywords.includes(word.word) : hiddenKeywords.includes(word.word)
  }).filter(word => word.word.toUpperCase().includes(searchText.toUpperCase()))

  const hideKeyword = (keyword: string) => {
    setHiddenKeywords([...hiddenKeywords, keyword])
  }

  const unhideKeyword = (keyword: string) => {
    setHiddenKeywords([...hiddenKeywords].filter(word => word !== keyword))
  }

  const saveChanges = async () => {
    try {
      const res: any = await uf.ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/overview/${store.getState().feed.task_id}/hide`,
        data: {
          ...store.getState().overview.hide,
          keywords: hiddenKeywords
        }
      })

      if (res.result === "success") {
        dispatch(setHidden(res.data))
        cancel()
      } else {
        throw new Error(res.result)
      }

    } catch (err) {
      toast.error(err)
    }
  }

  return (
    <div onClick={(event) => { event.stopPropagation() }}>
      <div className={"modal is-active"}>
        <div className="modal-background"> </div>
        <div className={"modal-card " + styles.modalCard}>
          <header className={"modal-card-head " + styles.cardHeader} >
            <p className="modal-card-title">
              <span>Manage word cloud keywords</span>
            </p>
          </header>
          <section className={"modal-card-body " + styles.contentContainer}>
            <div className={styles.flexContainer}>
              <div>
                <div className={styles.filters}>
                  <div>
                    <button className={activeTab ? styles.active : undefined} onClick={() => { setActiveTab(1) }}>Shown keywords</button>
                  </div>
                  <div>
                    <button className={activeTab ? undefined : styles.active} onClick={() => { setActiveTab(0) }}>Hidden keywords</button>
                  </div>
                  <div className="field">
                    <div className="control has-icons-right">
                      <input className="input" type="text" value={searchText} onChange={handleSearchInput} />
                      <span className="icon is-right">
                        <i className="fas fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.keywordList}>
                  <div >
                    <div>
                      <p>Keyword</p>
                    </div>
                    <div>
                      <p>Occurrence</p>
                    </div>
                    <div></div>
                  </div>
                  <ul>
                    {displayWords.length ? displayWords.map(word => {
                      return (
                        <li key={word.word}>
                          <div className={styles.keyword}>{word.word}</div>
                          <div className={styles.count}>{word.count}</div>
                          <div className={styles.hideAndUnhide}>
                            <p
                              className={activeTab ? styles.hide : styles.unhide}
                              onClick={() => {
                                activeTab ? hideKeyword(word.word) : unhideKeyword(word.word)
                              }}
                            >
                              {activeTab ? "Hide" : "Unhide"}
                            </p>
                          </div>
                        </li>
                      )
                    }) :
                      <div className={styles.placeholder}>
                        <p>You don't have any {activeTab ? "unhidden" : "hidden"} keywords</p>
                      </div>
                    }
                  </ul>
                </div>
              </div>

              <div className={styles.buttons}>
                <button onClick={cancel}>Cancel</button>
                <button onClick={saveChanges}>Save changes</button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default KeywordManager
