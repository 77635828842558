import * as React from "react";

function SvgSentimentScaleNeutral() {
  return (
    <svg
      width={64}
      height={45}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity={0.4}
        x={5}
        y={16.999}
        width={59}
        height={7}
        rx={3.5}
        fill="url(#SentimentScaleNeutral_svg__paint0_linear)"
      />
      <g filter="url(#SentimentScaleNeutral_svg__filter0_d)">
        <circle cx={35.201} cy={20.2} r={12.201} fill="#FFD366" />
        <circle cx={35.201} cy={20.2} r={11.701} stroke="#FFCC4D" />
      </g>
      <circle cx={31.026} cy={17.953} r={1.605} fill="#516380" />
      <path
        d="M40.338 17.953a1.605 1.605 0 11-3.211 0 1.605 1.605 0 013.21 0z"
        fill="#516380"
      />
      <defs>
        <linearGradient
          id="SentimentScaleNeutral_svg__paint0_linear"
          x1={5}
          y1={20.499}
          x2={64}
          y2={20.499}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F75E5E" />
          <stop offset={1} stopColor="#5CE191" />
        </linearGradient>
        <filter
          id="SentimentScaleNeutral_svg__filter0_d"
          x={13}
          y={-0.001}
          width={44.401}
          height={44.401}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgSentimentScaleNeutral;
