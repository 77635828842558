import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { IrrelevantPost } from "../../../../../../app/interfaces/smartTrain"
import { selectChosenIrrelevant, setChosenIrrelevant } from "../../../../../../app/store/smartTrainSlice"
import { convertDateStandard } from "../../../../../../app/tools/date"
import { CheckBox } from "../../../../../reusable/inputs/CheckBox"
import PostMessage from "./PostMessage"
import { CheckBoxWrapper, PostFieldContainer, PostItemContent, PostItemLayout, PostMain, PostTitle, SpamByLabel } from "./styled"

interface PostItemProp {
  feed: IrrelevantPost
}

function PostItem({ feed }: PostItemProp) {
  const dispatch = useDispatch()
  const chosen = useSelector(selectChosenIrrelevant)
  const checked = chosen.includes(feed.hash)

  const handleClick = () => {
    if (checked) {
      dispatch(setChosenIrrelevant([...chosen].filter(item => item !== feed.hash)))
    } else {
      dispatch(setChosenIrrelevant([feed.hash, ...chosen]))
    }
  }

  return (
    <PostItemLayout>
      <CheckBoxWrapper>
        <CheckBox type="checkbox" checked={checked} onClick={handleClick} readOnly />
      </CheckBoxWrapper>
      <PostItemContent>
        <PostMain>
          <a href={feed.post_link ?? ""}>
            <PostTitle>{feed.thread_title}</PostTitle>
          </a>
          <PostMessage text={feed.post_message ?? ""} />
        </PostMain>
        <PostFieldContainer>
          <p>{convertDateStandard(feed.post_timestamp)}</p>
        </PostFieldContainer>
        <PostFieldContainer>
          {feed.spam_by ? (
            <SpamByLabel>{feed.spam_by}</SpamByLabel>
          ) : (
            <SpamByLabel isGrey={true}>Auto remove</SpamByLabel>
          )}

        </PostFieldContainer>
        <PostFieldContainer>
          <p>{convertDateStandard(feed.spam_at)}</p>
        </PostFieldContainer>
      </PostItemContent>
    </PostItemLayout>
  )
}

export default PostItem
