import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserInfo } from "../../app/store/userSlice";

const UAuthRoute = ({ component: Component, ...rest }:any) => {
    const userinfo = useSelector(selectUserInfo);
    return (
        !userinfo.username ? <Route {...rest} render={(props) => (
            <Component {...props} />
        )} /> : <Redirect to='/' />
    )
}

export default UAuthRoute;