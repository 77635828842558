import React, { useState, memo } from 'react'
import styled from 'styled-components'
import { useDispatch } from "react-redux"
import { setPaginationPage } from "../../../../../app/store/livefeedpost"

interface PageInputProp {
  count: number
  margin?: string
}

const PageInputRoot = styled.div<{ margin: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: fit-content;
  margin: ${props => props.margin};
  @media (max-width: 992px) {
    display: none;
  }
`

const LabelText = styled.p<{ margin?: string }>`
  font-size: 1rem;
  color: #868686;
  margin:${props => props.margin ?? "0px"};
`

const InputBox = styled.input`
  height: 30px;
  width: 65px;
  border-radius: 5px;
  border: 1px solid #F2F2F2;
  padding: 12px;
  font-size: 14px;
  color: #424C5A;
  margin-right: 1rem;

  &:focus {
    outline: none;
    border: 1px solid #29D1D7;
  }
  /* remove the input arrows */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`

const EnterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  color:  white;
  background-color: #D2D2D2;
  border: none;
  border-radius: 50%;
  font-size: 1rem;
  transition: 0.3s all ease;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #868686;
  }
`

const PageInput = memo<PageInputProp>(({
  count,
  margin = "0px"
}) => {
  const dispatch = useDispatch()

  const [inputPage, setInputPage] = useState<string>("")

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const input = Number(event.target.value)

    if (event.target.value === "" || (!isNaN(input) && input >= 1 && input <= count)) {
      setInputPage(event.target.value)
    }

  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      setPageInput()
      setInputPage("")
    }
    return
  }

  const setPageInput = () => {
    if (!inputPage) { return }

    const parsedPage = parseInt(inputPage)

    if (parsedPage) {
      dispatch(setPaginationPage(parsedPage))
    }
  }

  return (
    <PageInputRoot margin={margin}>
      <LabelText margin={"0px 12px 0px 0px"}>
        Go to page:
      </LabelText>
      <InputBox value={inputPage} onChange={handleInputChange} onKeyDown={handleKeyDown} />
      <EnterButton onClick={setPageInput}>
        <i className="fas fa-arrow-right" />
      </EnterButton>
    </PageInputRoot>
  )
})

export default PageInput
