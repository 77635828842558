import React, { useState, useRef, useCallback } from "react"
import { ESQLayer } from "../../../interface"
import Group from "../group"
import uf from "usefuljs"
import styled from 'styled-components'
import { useClickAway } from "react-use"

type prop = {
    data: ESQLayer[],
    onChange: (data: ESQLayer[]) => void,
    onDelete: () => void
}

const ExcludeGroupRoot = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 0.5rem;
    align-self: center;
    transition: all 0.2s ease;
    background-color: #FFEBEE;
    padding: 0.5rem;
    border-radius: 3px;
`

const ExcludeGroupLabel = styled.div`
    color: #ff496ad6;
    font-weight: 600;
`

const ExcludeGroupList = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap; 
    column-gap: 0.5rem;
    >div {
        margin: 0.2rem 0
    }
`

const AddOrButton = styled.button`
    background-color: white;
    height: 26px;
    color: #ff496ad6;
    padding: 1px 0.5rem;
    border: none;
    border-radius: 3px;
    text-transform: uppercase;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`

const Separator = styled.p`
    color: #283044;
`

const Exgroup = (props: prop) => {
    const [selected, setSelected] = useState(true)
    const groupref = useRef(null)

    useClickAway(groupref, () => {
        if (selected) {
            setSelected(false)
        }
    })

    const onChangeGroup = useCallback((layer: ESQLayer, index: number) => {
        const currdata = props.data
        currdata[index] = layer
        props.onChange(currdata)
    }, [props])


    const onDeleteGroup = useCallback((index: number) => {
        const currdata = props.data
        uf.arr_rmi(currdata, index, true)
        props.onChange(currdata)
    }, [props])


    const onAddOr = useCallback(() => {
        const currdata = props.data
        const empty: ESQLayer = { ex: [] }
        currdata.push(empty)
        props.onChange(currdata)
    }, [props])

    return (
        <ExcludeGroupRoot
            onClick={() => setSelected(true)}
            ref={groupref}
        >
            <ExcludeGroupLabel>Exclude :</ExcludeGroupLabel>
            <ExcludeGroupList>
                {
                    props.data.length ? props.data.map((d, i) =>
                        <Group
                            index={i}
                            onChange={onChangeGroup}
                            key={`${i}_${d?.ex?.length ?? 0}`}
                            layer={d}
                            onDelete={onDeleteGroup}
                            excludeOnly={true}
                            isLimited={false}
                        />
                    ).reduce((p, c) => <>{p} <Separator>OR</Separator> {c}</>) : null
                }
                {selected && <AddOrButton onClick={onAddOr}>+ Or</AddOrButton>}
            </ExcludeGroupList>
            {/* {selected && <span className={styles.delBtn} onClick={() => props.onDelete()}><i className="fas fa-times"></i></span>} */}
        </ExcludeGroupRoot>
    )
}
export default Exgroup