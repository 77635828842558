import React from "react"
import styles from "./UpdateFrequencyWarning.module.scss"

interface UpdateFrequencyWarningProp {
  onClose: () => void
}

function UpdateFrequencyWarning({ onClose }: UpdateFrequencyWarningProp) {
  return (
    <div>
      <div className={"modal is-active"}>
        <div className="modal-background"> </div>
        <div className="modal-content">
          <div className={styles.contentContainer}>
            <img src="https://lenx-public-assets.s3.amazonaws.com/Denied_Face.png" alt="denied face" />
            <h1 className={styles.heading}>
              <span>Preparing the previous request</span>
            </h1>
            <p className={styles.subHeading}>Please update after 60 seconds</p>
            <button className={styles.confirmButton} onClick={onClose}>OK</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateFrequencyWarning
