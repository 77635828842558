import moment from "moment";
import { LineGraphViewBY } from ".";
import {
  DateRange,
  PostCountChartPoint,
} from "../../../../../../app/interfaces/overview";
import { Interval } from "../../interface";

export function generateLiveTimeLabel(time: string, dateRange: DateRange) {
  switch (dateRange) {
    case 0:
      return moment(time).format("HH:mm");
    case 2:
      return moment(time).format("D/M");
    default:
    case 1:
      return moment(time).format("MMM D");
  }
}

export function formatLiveChartPoints(
  points: PostCountChartPoint[] | null,
  dateRange: DateRange
) {
  return points
    ? points.map((point) => ({
        ...point,
        label: generateLiveTimeLabel(point.point, dateRange),
      }))
    : [];
}

export const mergeTwoGraphsData = (
  formattedData,
  compareFormattedData,
  task1: string,
  task2: string
) => {
  let mergedPoints = [];
  if (compareFormattedData) {
    const filteredCompareFormattedData = compareFormattedData?.filter((data, index) => formattedData[index])
    filteredCompareFormattedData.map((data, index: number) => {
        mergedPoints.push({
          point: formattedData[index]?.point,
          postCount: formattedData[index]?.postCount,
          threadCount: formattedData[index]?.threadCount,
          commentCount: formattedData[index]?.commentCount,
          topThreads: formattedData[index]?.topThreads,
          label: formattedData[index]?.label,
          comparepPoint: data?.point,
          comparePostCount: data?.postCount,
          compareThreadCount: data?.threadCount,
          compareCommentCount: data?.commentCount,
          compareTopThreads: data?.topThreads,
          task1: task1,
          task2: task2,
        });
    });
  }
  return mergedPoints;
};

export function generateAdHocTimeLabel(time: string, interval: Interval) {
  switch (interval) {
    case "hour":
      return moment(time).format("HH:mm");
    case "day":
      return moment(time).format("D/M");
    case "month":
      return moment(time).format("MMM YYYY");
    case "year":
      return moment(time).format("YYYY");
  }
}

export function formatAdHocChartPoints(
  points: PostCountChartPoint[] | null,
  interval: Interval | null
) {
  if (!interval) return [];

  return points
    ? points.map((point) => ({
        ...point,
        label: generateAdHocTimeLabel(point.point, interval),
      }))
    : [];
}

export function getDataKey(viewBy: LineGraphViewBY) {
  switch (viewBy) {
    case "Threads":
      return "threadCount";
    case "Comments":
      return "commentCount";
    case "Threads and comments":
    default:
      return "postCount";
  }
}
