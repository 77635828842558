import { VersionData } from "../interface";

export const versiontimestampList = [
  "today",
  "yesterday",
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "this month",
  "this year",
];

const isToday = (someDate: any) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

const isYesterday = (someDate: any) => {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  return (
    someDate.getDate() === d.getDate() &&
    someDate.getMonth() === d.getMonth() &&
    someDate.getFullYear() === d.getFullYear()
  );
};

const isLast7Days = (someDate: any) => {
  let d = new Date();
  d.setDate(d.getDate() - 7);
  if (someDate.getTime() >= d.getTime()) {
    return true;
  }
  return false;
};

const isThisMonth = (someDate: any) => {
  let d = new Date();
  if (d.getMonth() === someDate.getMonth()) {
    return true;
  }
  return false;
};

let fulldays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const formatDate = (someDateTimeStamp: any) => {
  var dt = new Date(someDateTimeStamp);

  if (isToday(someDateTimeStamp)) {
    return "today";
  } else if (isYesterday(someDateTimeStamp)) {
    return "yesterday";
  } else if (isLast7Days(someDateTimeStamp)) {
    return fulldays[dt.getDay()];
  } else if (isThisMonth(someDateTimeStamp)) {
    return "this month";
  } else {
    return "this year";
  }
};

export const groupVersionData = (versionList: VersionData[]) => {
  let groupedVersionList: any = {
    today: [],

    yesterday: [],

    monday: [],

    tuesday: [],

    wednesday: [],

    thursday: [],

    friday: [],

    saturday: [],

    sunday: [],

    "this month": [],

    "this year": [],
  };
  let versiontimestampList: string[] = [];
  versionList?.forEach((version: any) => {
    const genDate = new Date(version.gen_at);
    const formattedTimestamp = formatDate(genDate);
    groupedVersionList[formattedTimestamp].push(version);
    if (versiontimestampList.indexOf(formattedTimestamp) === -1) {
      versiontimestampList.push(formattedTimestamp);
    }
  });
  groupedVersionList.versiontimestampList = versiontimestampList;
  return groupedVersionList;
};
