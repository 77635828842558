import React from "react"
import AddBtn from "./addbtn"
import styles from "./guided.module.scss"
import { ESQLayer, GuidedKeyword } from "../../interface"

import InGroup from "./ingroup"
import ExGroup from "./exgroup"
import { guided2view, view2guided } from "../converter"

type GuidedQryBuilderProp = {
    view: GuidedKeyword,
    qry: ESQLayer[],
    onChange: (data: ESQLayer[]) => void
    isLimited: boolean
}

const GuidedQryBuilder = (props: GuidedQryBuilderProp) => {

    const [data, setData] = React.useState(props.qry)
    const [vdata, setVData] = React.useState(props.view)
    const [hasEx, setHasEx] = React.useState(props.view.ExcludeOnly.length > 0)// has exclude

    React.useEffect(() => {
        const vd = guided2view(data)
        setVData(vd)
        if (vd.ExcludeOnly.length > 0) {
            setHasEx(true)
        }
    }, [data, setVData, setHasEx])

    React.useEffect(() => {
        setVData(props.view)
    }, [props.view])

    React.useEffect(() => {
        setData(props.qry)
    }, [props.qry, setData])

    const onClickAddBtn = React.useCallback((exclude: boolean) => {
        const empty: ESQLayer = exclude ? { ex: [] } : { in: [] }
        if (hasEx) {
            if (data.length) {
                // append to -1 position
                const d = [...data]
                d.splice(-1, 0, empty)
                setData(d)
            } else {
                // first group, so append to the end
                setData([...data, empty])
            }
        } else {
            setData([...data, empty])
        }

        if (exclude) {
            setHasEx(true)
        }
    }, [data, setData, setHasEx, hasEx])

    const onChangeIn = React.useCallback((layer: ESQLayer[]) => {
        const currview = vdata
        currview.NoExOnly = layer
        props.onChange(view2guided(currview))
    }, [props, vdata])

    const onChangeEx = React.useCallback((layer: ESQLayer[]) => {
        const currview = vdata
        currview.ExcludeOnly = layer
        props.onChange(view2guided(currview))
        if (!layer.length) {
            setHasEx(false)
        }
    }, [props, vdata, setHasEx])

    const onDeleteExonly = React.useCallback(() => {
        const currview = vdata
        currview.ExcludeOnly = []
        props.onChange(view2guided(currview))
        setHasEx(false)
    }, [props, vdata, setHasEx])


    return (
        <div className={styles.guided}>
            {vdata.NoExOnly && <InGroup onChange={onChangeIn} data={vdata.NoExOnly} isLimited={props.isLimited} />}
            <AddBtn showEx={!hasEx} clickBtn={onClickAddBtn} isLimited={props.isLimited} />
            {(vdata.ExcludeOnly && hasEx) && (
                <div className={styles.ex}>
                    <ExGroup onDelete={onDeleteExonly} onChange={onChangeEx} data={vdata.ExcludeOnly} />
                </div>
            )}
        </div>
    )
}

export default GuidedQryBuilder