import React, { useState, useEffect } from "react"
import moment from "moment"
import { FeedData } from "../../../../../../app/interfaces/feed"
import styles from "./comment.module.scss"

type prop = {
    data: FeedData
}

const Comment = (props: prop) => {
    const MESSAGE_LIMIT = 100
    const [expand, setExpand] = React.useState(false)

    const timetext = React.useMemo(() => moment(props.data.unix_timestamp).format("DD MMMM YYYY [at] HH:mm"), [props.data.unix_timestamp])

    const expandtext = React.useMemo(() => expand ? "See Less ..." : "See More ...", [expand])
    const postmsg = React.useMemo(() => {
        if (expand) return props.data.post_message
        return (props.data.post_message ?? "").slice(0, MESSAGE_LIMIT)
    }, [expand, props.data.post_message])


    const [hasImageError, setHasImageError] = useState(true)

    useEffect(() => {
        setHasImageError(props.data.author_image === null)
    }, [props.data.author_image])


    return (
        <div className={styles.comment}>
            <div className={styles.author_image}>
                {
                    !hasImageError ?
                        <img src={props.data.author_image ?? ""} alt={props.data.author_name ?? ""} onError={() => { setHasImageError(true) }} /> :
                        <i className="fas fa-user-circle"></i>
                }

            </div>
            <h2 className={styles.author_name}>{props.data.author_name}</h2>
            <p className={styles.time}>{timetext}</p>
            <div className={styles.message}>
                <a href={props.data.post_link ?? ""} target="_blank" rel="noopener noreferrer"><p>{postmsg}</p></a>
                {
                    (props.data.post_message ?? "").length > MESSAGE_LIMIT ? <span onClick={() => setExpand(!expand)}>{expandtext}</span> : null
                }
            </div>

        </div>
    )

}

export default Comment