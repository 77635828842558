import React from 'react'
import { IconProp } from "./interface"

function SmartFilterGraphicIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      height={height} 
      width={width}
      viewBox="0 0 117 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.267 115.063c31.627 0 57.267-25.758 57.267-57.532C114.534 25.758 88.894 0 57.267 0 25.639 0 0 25.758 0 57.531c0 31.774 25.64 57.532 57.267 57.532z"
        fill="#E8E9F0"
      />
      <path
        d="M107.179 10.722H41.975a.902.902 0 01-.897-.9V3.603c0-.495.404-.9.897-.9h65.204c.494 0 .897.405.897.9v6.172c0 .54-.403.946-.897.946z"
        fill="#29D1D7"
      />
      <path d="M105.385 10.723H43.724v11.353h61.661V10.723z" fill="#F2F2F2" />
      <path
        d="M66.998 45.683v34.78c0 .585.538.9.987.585L81.797 69.83c.18-.135.269-.36.269-.585V45.683c0-.226.09-.406.224-.541l23.14-23.112H43.724l23.05 23.112c.18.135.224.315.224.54z"
        fill="#fff"
      />
      <path
        d="M68.836 45.233h-2.915c-.179-.631-.448-1.217-.717-1.803l2.108-2.117a2.346 2.346 0 000-3.289l-2.602-2.613a2.32 2.32 0 00-3.273 0l-2.063 2.072c-.583-.315-1.21-.54-1.839-.765v-3.019a2.321 2.321 0 00-2.332-2.343h-3.722A2.321 2.321 0 0049.15 33.7v3.019c-.627.225-1.21.45-1.748.72l-2.153-2.162a2.32 2.32 0 00-3.274 0l-2.6 2.613a2.346 2.346 0 000 3.289l2.152 2.162c-.314.586-.538 1.217-.763 1.847H37.85a2.321 2.321 0 00-2.332 2.343v3.74a2.321 2.321 0 002.331 2.342h2.96c.224.63.449 1.216.763 1.757l-2.063 2.072a2.346 2.346 0 000 3.29l2.6 2.612a2.32 2.32 0 003.274 0l2.108-2.117c.538.27 1.121.495 1.704.72v3.02a2.321 2.321 0 002.332 2.342h3.722c1.3 0 2.332-1.036 2.332-2.343v-3.018c.628-.226 1.211-.45 1.75-.721l2.152 2.162a2.32 2.32 0 003.273 0l2.601-2.613a2.346 2.346 0 000-3.288l-2.152-2.163c.269-.54.493-1.126.717-1.712h2.96c1.3 0 2.332-1.036 2.332-2.343V47.53a2.38 2.38 0 00-2.377-2.297zM53.32 53.477c-2.242 0-4.08-1.847-4.08-4.1 0-2.252 1.838-4.1 4.08-4.1s4.08 1.848 4.08 4.1c0 2.253-1.838 4.1-4.08 4.1z"
        fill="#283044"
      />
      <path
        d="M80.316 73.705v2.703"
        stroke="#AFB7BF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.316 80.913v5.541l7.176 7.299"
        stroke="#AFB7BF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="10.01 10.01"
      />
      <path
        d="M89.061 95.33l1.884 1.937"
        stroke="#AFB7BF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.608 77.894V95.24M70.854 82.04v5.181l-10.987 9.146"
        stroke="#AFB7BF"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.143 104.069c2.427 0 4.395-1.976 4.395-4.415 0-2.438-1.968-4.415-4.395-4.415-2.427 0-4.395 1.977-4.395 4.415 0 2.439 1.968 4.415 4.395 4.415z"
        fill="#F2F5FA"
      />
      <path
        d="M75.609 104.069c2.427 0 4.395-1.976 4.395-4.415 0-2.438-1.968-4.415-4.395-4.415-2.427 0-4.395 1.977-4.395 4.415 0 2.439 1.968 4.415 4.395 4.415z"
        fill="#29D1D7"
      />
      <path
        d="M57.715 104.069c2.427 0 4.395-1.976 4.395-4.415 0-2.438-1.968-4.415-4.395-4.415-2.427 0-4.395 1.977-4.395 4.415 0 2.439 1.968 4.415 4.395 4.415z"
        fill="#FFCC4D"
      />
    </svg>
  )
}

export default SmartFilterGraphicIcon
