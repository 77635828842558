import React from 'react'
import { IconProp } from "./interface"

function EditHiddenIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      height={height} 
      width={width}
      viewBox="0 0 22 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.844 14.719c.125.104.187.229.187.375 0 .125-.041.24-.125.344l-.312.374a.42.42 0 01-.375.188.424.424 0 01-.313-.125L.22 1.281A.473.473 0 01.03.906c0-.125.042-.24.125-.344L.47.188A.42.42 0 01.844 0c.125 0 .229.042.312.125l18.688 14.594zM9.312 4.563c.23-.042.47-.063.72-.063.937 0 1.739.333 2.405 1a3.349 3.349 0 011.094 2.375L9.312 4.562zm1.438 6.875a4.01 4.01 0 01-.719.062c-.937 0-1.75-.323-2.437-.969A3.513 3.513 0 016.53 8.125l4.219 3.313zM10.031 3.5c-.562 0-1.166.073-1.812.219L6.75 2.594A9.675 9.675 0 0110.031 2c1.25 0 2.448.24 3.594.719a9.71 9.71 0 013.094 1.937c.896.813 1.635 1.771 2.218 2.875.063.146.094.302.094.469 0 .167-.031.323-.093.469a11.61 11.61 0 01-1.657 2.344l-1.187-.938c.541-.563 1-1.188 1.375-1.875-.771-1.396-1.823-2.49-3.157-3.281a7.982 7.982 0 00-4.28-1.219zm0 9a8.25 8.25 0 001.813-.219l1.469 1.125A9.674 9.674 0 0110.03 14a9.58 9.58 0 01-5.219-1.5 10.56 10.56 0 01-3.687-4.031A1.177 1.177 0 011.031 8c0-.167.032-.323.094-.469a11.614 11.614 0 011.656-2.343l1.188.937A8.69 8.69 0 002.594 8c.77 1.396 1.812 2.5 3.125 3.313A8.3 8.3 0 0010.03 12.5z"
        fill={fill}
        fillOpacity={0.97}
      />
      <path
        d="M16.684 9.816l2.5 2.5-5.429 5.428-2.226.254a.47.47 0 01-.39-.137.47.47 0 01-.137-.39l.254-2.226 5.428-5.429zm4.043-.371a.904.904 0 01.273.664c0 .26-.091.482-.273.664l-1.113 1.113-2.5-2.5 1.113-1.113A.904.904 0 0118.891 8c.26 0 .482.091.664.273l1.172 1.172z"
        fill={fill}
        fillOpacity={0.97}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.114 9.387l2.5 2.5 1.112-1.113A.904.904 0 0021 10.11a.904.904 0 00-.274-.664l-1.171-1.172a.904.904 0 00-.664-.273.904.904 0 00-.664.273l-1.113 1.113zm-3.037 9.026l-2.48.282-.01.001a1.17 1.17 0 01-.943-.34 1.17 1.17 0 01-.34-.943l.001-.01.283-2.48 7.144-7.144a1.603 1.603 0 011.159-.478c.444 0 .844.163 1.159.478l1.171 1.172c.315.315.479.715.479 1.159 0 .444-.164.844-.479 1.159l-7.144 7.144zm5.107-6.097l-2.5-2.5-5.428 5.43-.254 2.225a.47.47 0 00.137.39.47.47 0 00.39.138l2.226-.254 5.429-5.429z"
        fill="#fff"
      />
    </svg>
  )
}

export default EditHiddenIcon
