import React from "react"
import styles from "./CreateSucceeded.module.scss"

interface CreateSucceededProp {
  onClose: () => void
  feedName: string
  showLengthyWarning: boolean
}

function CreateSucceeded({ onClose, feedName, showLengthyWarning }: CreateSucceededProp) {
  return (
    <div>
      <div className={"modal is-active"}>
        <div className="modal-background"> </div>
        <div className="modal-content">
          <div className={styles.contentContainer}>
            <i className="fas fa-check-circle" />
            <h1 className={styles.heading}>
              <span>{`"${feedName}" has been created successfully`}</span>
            </h1>
            {
              showLengthyWarning ? <p className={styles.subHeading}>Analysis for data beyond 3 months might<br />take a longer time to appear</p> : <p className={styles.subHeading}></p>
            }
            <button className={styles.confirmButton} onClick={onClose}>OK</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateSucceeded
