import React, { useState } from "react"
import Ctx, { initState, dispatcher } from "./ctx"
import { EditorReducer } from "./ctx/reducer"
import { setEditorType, fetchTaskConfig } from "./ctx/action"
import { getIndus, getLangs, getMediums } from "../../../../app/store/miscSlice"
import { EditorType, EditorView } from "./interface"
import Header from "./header"
import Footer from "./footer"
import Breadcrumb from "./breadcrumb"
import TaskType from "./tasktype"
import TaskName from "./name"
import QryBuilder from "./qrybuilder"
import Sources from "./sources"
import Settings from "./settings"
import styles from "./editor.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { fetchFeedSetting, getAccess, selectTaskId, selectTaskUnixEnd, selectTaskUnixStart } from "../../../../app/store/feedSlice"
import ExitWarning from "./notices/ExitWarning"
import SaveFailed from "./notices/SaveFailed"
import SaveSucceeded from "./notices/SaveSucceeded"
import UpgradePlanNotice from "../../../reusable/notice/UpgradePlanNotice"
import UpdateFrequencyWarning from "./notices/UpdateFrequencyWarning"
import CreateSucceeded from "./notices/CreateSucceeded"
import { useLocation } from "react-router-dom"
import { fetchData } from "../../../../app/store/livefeedpost"
import { fetchOverviewData } from "../../../../app/store/overviewSlice"

interface TaskEditorProp {
    type: EditorType
    onClose: () => void
}

const TaskEditor = (props: TaskEditorProp) => {

    const location = useLocation()
    // context
    const [editor, setEditor] = React.useReducer(EditorReducer, props.type ? { ...initState, view: 2 } : initState)
    const [showExitWarning, setShowExitWarning] = useState(false)
    const [showSaveFailed, setShowSaveFailed] = useState(false)
    const [showSaveSucceeded, setShowSaveSucceeded] = useState(false)
    const [showUpgradePlanNotice, setShowUpgradePlanNotice] = useState(false)
    const [showUpdateFrequencyWarning, setShowUpdateFrequencyWarning] = useState(false)
    const [showCreateFeedSucceeded, setShowCreateFeedSucceeded] = useState(false)

    const taskId = useSelector(selectTaskId)
    const taskUnixStart = useSelector(selectTaskUnixStart)
    const taskUnixEnd = useSelector(selectTaskUnixEnd)

    const { name, unix_end, unix_start, type } = editor.detail

    React.useEffect(() => {
        setEditorType(props.type, setEditor)

        if (editor.type === 1 && taskId) {
            fetchTaskConfig(taskId, setEditor)
        }
    }, [editor.type, taskId, props.type])

    const DisplayContent = React.useMemo(() => {

        switch (editor.view) {
            case EditorView.Type:
                return TaskType
            case EditorView.Name:
                return TaskName
            case EditorView.Keywords:
                return QryBuilder
            case EditorView.Sources:
                return Sources
            case EditorView.Settings:
                return Settings
            default:
                return null
        }
    }, [editor])

    // === PREFETCH ===
    const dispatch = useDispatch()
    React.useEffect(() => {
        Promise.all([
            dispatch(getLangs()),
            dispatch(getIndus()),
            dispatch(getMediums()),
        ])
    }, [dispatch])

    const handleSuccessUpdate = () => {
        setShowSaveSucceeded(false)

        dispatch(fetchFeedSetting(taskId))

        if (location.pathname.includes("posts")) {
            dispatch(fetchData())
        } else {
            if (editor.type === 0) {
                dispatch(fetchOverviewData(null))
            }

            if (editor.type === 1 && taskUnixStart && taskUnixEnd) {
                dispatch(fetchOverviewData({
                    start: taskUnixStart,
                    end: taskUnixEnd
                }))
            }
        }
        props.onClose()
    }

    const handleSuccessCreate = () => {
        dispatch(getAccess())
        setShowCreateFeedSucceeded(false)
        props.onClose()
    }

    return (
        <Ctx.Provider
            value={{ editor, setEditor: dispatcher(setEditor, editor) }}
        >
            <div>
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className={styles.card_modal + " modal-card"}>
                        <Header onClose={props.onClose} onQuitDuringEdit={() => setShowExitWarning(true)} />
                        <section className={styles.card_body + " modal-card-body"}>
                            <Breadcrumb />
                            <div> {DisplayContent && <DisplayContent />} </div>
                        </section>
                        <Footer
                            onClose={props.onClose}
                            onSaveSucceeded={() => setShowSaveSucceeded(true)}
                            onCreateSucceeded={() => setShowCreateFeedSucceeded(true)}
                            onSaveFailed={() => setShowSaveFailed(true)}
                            onReachedLimit={() => setShowUpgradePlanNotice(true)}
                            onUpdateTooFrequently={() => setShowUpdateFrequencyWarning(true)}
                            taskId={taskId}
                            type={props.type}
                            taskType={type}
                        />
                    </div>
                </div>
            </div>
            {showExitWarning && <ExitWarning onResume={() => setShowExitWarning(false)} onClose={props.onClose} />}
            {showSaveFailed && <SaveFailed onResume={() => setShowSaveFailed(false)} onClose={props.onClose} />}
            {showSaveSucceeded && <SaveSucceeded onClose={handleSuccessUpdate} />}
            {showCreateFeedSucceeded && <CreateSucceeded onClose={handleSuccessCreate} feedName={name ?? "Your task"} showLengthyWarning={(
                type === 1 &&
                unix_start !== null &&
                unix_end !== null &&
                unix_end - unix_start > 1000 * 60 * 60 * 24 * 90
            )} />}
            {showUpgradePlanNotice && <UpgradePlanNotice onClose={() => setShowUpgradePlanNotice(false)} noticeType="modificationLimit" />}
            {showUpdateFrequencyWarning && <UpdateFrequencyWarning onClose={() => setShowUpdateFrequencyWarning(false)} />}
        </Ctx.Provider>
    )
}

export default TaskEditor
