import React from 'react'
import { 
  Cell, 
  LabelList, 
  Pie, 
  PieChart, 
  ResponsiveContainer, 
  // Tooltip 
} from "recharts"
// import CustomTooltip from './CustomTooltip'
import { LegendContainer, SentimentChartRoot, Legend } from "./styled"

interface ChartData {
  data: {
    sentimentScores: string
    sentimentCount: number
  }[]
}

function Legends() {
  return (
    <LegendContainer>
      <Legend color="#44D8A3">
        <div></div>
        <span>Positive</span>
      </Legend>
      <Legend color="#BDBDBD">
        <div></div>
        <span>Neutral</span>
      </Legend>
      <Legend color="#FA7A7A">
        <div></div>
        <span>Negative</span>
      </Legend>
    </LegendContainer>
  )
}

function SentimentChart({ data }: ChartData) {
  const colors:Colors = {
    Negative: '#FA7A7A', 
    Positive: '#44D8A3', 
    Neutral: '#BDBDBD'
  }

  return (
    <SentimentChartRoot>
      <ResponsiveContainer width='100%' height={250}>
        <PieChart>
          <Pie
            data={data}
            dataKey="sentimentCount"
            innerRadius={70}
            outerRadius={100}
          >
            {data.map((d)=>(
              <Cell 
              key={d.sentimentScores} 
              fill={colors[d.sentimentScores as keyof Colors]}
              />
              ))}
              {/* <Tooltip content={(
                <CustomTooltip />
              )} /> */}
            <LabelList dataKey="sentimentScore" position="insideTop" />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Legends />
    </SentimentChartRoot>
  )
}

export default SentimentChart

interface Colors {
  [sentimentLabel: string] : string
}