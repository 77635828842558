import React, { useEffect, useState } from 'react'
import AngleLeft from '../../../../../../../reusable/icons/AngleLeft'
import AngleRight from '../../../../../../../reusable/icons/AngleRight'
import { Frequency, Results, TopicData } from '../../../../../../interface'
import { CarouselContainer, CarouselWrapper, CarouselContentWrapper, CarouselContent, LeftButton, RightButton, CarouselTitle } from '../styled'
import RelatedPostPreview from './RelatedPostPreview'


const RelatedPostCarousel = ({topicData, frequency}: RelatedPostCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const filteredPosts = topicData.detailData?.results?.[frequency as keyof Results]?.aggregations?.preview_threads.filter((post)=>(post !== null)) || []
  const [length, setLength] = useState<number>(filteredPosts.length || 0)

  const handlePrev = () => {
    currentIndex > 0 && setCurrentIndex(prev => prev - 1)
  }

  const handleNext = () => {
    currentIndex < (length - 2) && setCurrentIndex(prev => prev + 1)
  }

  useEffect(()=>{
    setLength(filteredPosts.length)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[topicData])

  return (
    <CarouselContainer>
      {length === 0 ? (<CarouselTitle>No related thread available.</CarouselTitle>):(<CarouselTitle>Related Threads</CarouselTitle>)}
      <CarouselWrapper>
        { currentIndex > 0 && (
          <LeftButton onClick={handlePrev}><AngleLeft fill="#516380" /></LeftButton>
        )}
        <CarouselContentWrapper>
          <CarouselContent style={{transform: `translateX(-${currentIndex * 100/2}%)`}}>
            {filteredPosts.map((previewThread, i)=>(
              <RelatedPostPreview key={i} data={previewThread} />
            ))}
          </CarouselContent>
        </CarouselContentWrapper>
        {currentIndex < (length - 2) && (
          <RightButton onClick={handleNext}><AngleRight fill="#516380" /></RightButton>
        )}
        {
          length === 1 && (
            <CarouselContentWrapper/>
          )
        }
      </CarouselWrapper>
    </CarouselContainer>
  )
}

export default RelatedPostCarousel

interface RelatedPostCarouselProps{
  topicData: TopicData
  frequency: Frequency
}