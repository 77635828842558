import React from 'react'
import { useSelector } from "react-redux"
import styled from 'styled-components'
import { selectIsConfirming } from "../../../../../../app/store/smartTrainSlice"
import ActionMenu from "./ActionMenu"
import Classifier from "./Classifier"
import Confirmation from "./Confirmation"
import PostViewer from "./PostViewer"
import TrainingProgress from "./TrainingProgress"

const TrainerRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 720px;
`

const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.5rem;
`

function ModelTrainer() {

  const isConfirming = useSelector(selectIsConfirming)

  return (
    <TrainerRoot>
      {
        isConfirming ? (
            <Confirmation />
        ) : (
            <>
              <PostViewer />
              <Classifier />
              <BottomBar>
                <TrainingProgress />
                <ActionMenu />
              </BottomBar>
            </>
          )
      }

    </TrainerRoot>
  )
}

export default ModelTrainer
