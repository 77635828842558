import React, { useState } from 'react'
import styled, { css } from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { setFilterUnixStart, setFilterUnixEnd, selectFilterUnixStart, selectFilterUnixEnd } from "../../../../../app/store/livefeedpost"
import DateRangeCalendar from "./DateRangePicker/DateRangeCalendar"
import BaseButton from "../../../../reusable/buttons/BaseButton"

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
`

const TimeRangeButton = styled(BaseButton) <{ isActive: boolean }>`
  font-size: 0.9rem;
  height: 35px;
  min-width: fit-content;
  border-radius: 0px;
  border: 1px solid #dbdbdb;
  padding: 0rem 1rem;
  color: #516380;
  background-color: #FFFFFF;
  
  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  &:not(:last-child) {
    border-right-color: transparent;
  }

  ${props => props.isActive && css`
      color: white;
      background-color: #29d1d7;
  `}

  &:hover {
    border: 1px #b5b5b5 solid;
    z-index: 2;
  }
`

function TimeRangeFilters() {

  const dispatch = useDispatch()
  const selectedUnixStart: number = useSelector(selectFilterUnixStart)
  const selectedUnixEnd: number = useSelector(selectFilterUnixEnd)

  const [showCalendar, setShowCalendar] = useState(false)

  const updateTimeRange = React.useCallback((start: number, end: number) => {
    // if (start === selectedUnixStart && end === selectedUnixEnd) {
    //   start = 0
    //   end = -1
    // }
    dispatch(setFilterUnixStart(start))
    dispatch(setFilterUnixEnd(end))
    setShowCalendar(false)
  }, [dispatch])


  const currentTimeRange = React.useMemo(() => {
    if (selectedUnixStart === 0) return 0// all
    if (selectedUnixStart === -2 && selectedUnixEnd === -1) return 1// today
    if (selectedUnixStart === -3 && selectedUnixEnd === -1) return 2// week
    if (selectedUnixStart === -4 && selectedUnixEnd === -1) return 3// month
  }, [selectedUnixStart, selectedUnixEnd])

  return (
    <>
      <FiltersContainer>
        <TimeRangeButton
          isActive={currentTimeRange === 1}
          onClick={() => updateTimeRange(-2, -1)}
        >
          Last 24 hours
        </TimeRangeButton>

        <TimeRangeButton
          isActive={currentTimeRange === 2}
          onClick={() => updateTimeRange(-3, -1)}
        >
          Last 7 days
        </TimeRangeButton>

        <TimeRangeButton
          isActive={currentTimeRange === 3}
          onClick={() => updateTimeRange(-4, -1)}
        >
          Last 30 days
        </TimeRangeButton>
        <DateRangeCalendar
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
        />
      </FiltersContainer>
    </>
  )
}

export default TimeRangeFilters
