import styled from 'styled-components'

export const ColumnHeader = styled.div`
  display: flex; 
  width:95%; 
  margin-top: 5px;
  margin-left: 2.5%;
  margin-right: 2.5%;
  justify-content: space-between; 
  padding: 0.5rem  0.8rem; 
  color: #BDBDBD; 
  border-bottom: 1px solid #F2F5FA;
  border-top: 1px solid #F2F5FA;
`