import React from "react";
import { useSpring, animated } from "react-spring";
import { FeedData } from "../../../../../../app/interfaces/feed";
import Post from "./post";
import Sentiment from "./sentiment";
import Impact from "./impact";
import { FeedDataImpact } from "./impact/interface";
import styles from "./message.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  removeExportFeed,
  addExportFeed,
  selectExportFeed,
  selectIsSelecting,
} from "../../../../../../app/store/livefeedpost";
import Bookmark from "./bookmark";
import { Checkbox, MessageCard, MessageContainer } from "./styled";
import MessageOptions from "./options";
import { selectFeedType } from "../../../../../../app/store/feedSlice";

interface MessageProp {
  data: FeedData;
}

function Message({ data }: MessageProp) {
  const dispatch = useDispatch();
  const feedType = useSelector(selectFeedType);

  const exportFeeds = useSelector(selectExportFeed);
  const isSelecting = useSelector(selectIsSelecting);

  const props = useSpring({ opacity: 1, width: "100%", from: { opacity: 0 } });

  const impact = React.useMemo(() => {
    const id: FeedDataImpact = {
      ai_impact: data.ai_impact,
    };
    return id;
  }, [data]);

  const handleExportFeed = () => {
    if (isChecked()) {
      dispatch(removeExportFeed(data));
    } else {
      dispatch(addExportFeed(data));
    }
  };

  const isChecked = () => {
    return !exportFeeds.every((feed) => feed.hash !== data.hash);
  };

  return (
    <MessageContainer>
      <Checkbox
        type="checkbox"
        checked={isChecked()}
        selecting={isSelecting}
        onClick={handleExportFeed}
        readOnly
      />
      <animated.div style={props}>
        <MessageCard checked={isChecked()} feedType={feedType} >
          <div className={styles.bookmark}>
            <Bookmark postId={data.hash} />
          </div>
          <div className={styles.content}>
            <Post data={data} />
          </div>
          <div className={styles.sentiment}>
            <Sentiment sentiment={data.ai_sentiment} hash={data.hash} />
          </div>
          {/* {!(feedType===0) && (
            <div className={styles.impact}>
            <Impact impact={impact} />
          </div>
          )} */}
          
        </MessageCard>
        <MessageOptions data={data} />
      </animated.div>
    </MessageContainer>
  );
}

export default Message;
