import React from 'react'
import { useEffect } from 'react'
import useDropdown from '../../../hooks/useDropdown'

import { 
  Container, 
  ButtonSpan, 
  OpenMenuButton, 
  MenuSelectionButton, 
  MenuListContainer, 
} from './styled'

function MenuSelectSingle<T>({ 
  buttonText, 
  selections, 
  id, 
  selected,
  selectLabel, 
  selectLabelAction, 
  selectValueAction, 
  isOvData = false 
}: MenuButtonProps<T>){

  const { showDropdownMenu, toggleMenu } = useDropdown(id, false)

  const handleSelectSingle = (e:React.MouseEvent<HTMLButtonElement>, title: string, value: T) => {
    selectLabelAction(title)
    selectValueAction(value)
  }

  useEffect(()=>{
    const selectedLabel = selections && selections.filter((item) => item.value === selected)[0]?.title
    selections && selectLabelAction(selectedLabel)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <Container>
      <OpenMenuButton onClick={toggleMenu}>
        <ButtonSpan>{selectLabel}</ButtonSpan>
        <i className={`fas fa-angle-${showDropdownMenu ? "up" : "down"}`} style={{color:'#516380'}} aria-hidden="true" />
      </OpenMenuButton>

      {showDropdownMenu && (
        <MenuListContainer id={id}>
          <ul>
            {selections.map((selection)=>(
              <li key={selection.title}>
                  <MenuSelectionButton 
                    disabled={selection.disabled || false}
                    onClick={(e)=>handleSelectSingle(e, selection.title, selection.value)}
                  > 
                  {selection.title}
                  </MenuSelectionButton>
              </li>
            ))}
          </ul>
        </MenuListContainer>
      )}
    </Container>
  )
}

export default MenuSelectSingle


interface MenuButtonProps<T> {
  buttonText?: string
  selections: Selections<T>[]
  selected: T
  id: string
  selectLabel: string
  selectLabelAction: (label: string) => void
  selectValueAction: (value: T) => void
  isOvData?: boolean
}

export interface Selections<T> {
  title: string
  disabled?: boolean
  value: T
  show?:boolean
}