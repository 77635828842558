import React from 'react'
import styled, {css} from 'styled-components'
import Skeleton from '../../utils/Skeleton'

interface StatCardProp {
  icon: JSX.Element
  tooltip?: string
  title: string
  subtitle?: string
  percentage?: number // 0 to 1, if the change is infinite, put 99999
  appearance?: "dark" | "light"
  titleTextMarginLeft?: string
  pWidth?: string
}

export const CardRoot = styled.div<{ color: "dark" | "light" }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${props => props.color === "dark" ? "#F8F8FA" : "#FFF"};
  border-radius: 4px;
  padding: 1rem 1rem 0.5rem 1rem;
  row-gap: 0.5rem;
`

export const FirstRow = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const SecondRow = styled.div<{pWidth?:string}>`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  p {
    font-size: 0.8rem;
    color: #828282;
    max-width: ${props => props.pWidth ?? '140px'};
    text-overflow: wrap;
  }

  i {
    align-self: flex-end;
    color: #bdbdbd;
  }
`

const TotalCount = styled.p<{titleTextMarginLeft?: string}>`
  font-weight: 600;
  font-size: 1.5rem;
  margin-right: 1rem;

  ${props => props.titleTextMarginLeft && css`
    margin-left: ${props.titleTextMarginLeft};
  `}
`

const PercentageWrapper = styled.div<{ isIncreased: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.isIncreased ? "#29D1D7" : "#FF6E86"};
`

function SummaryCard({
  icon,
  tooltip,
  title,
  subtitle,
  percentage,
  appearance = "light",
  titleTextMarginLeft,
  pWidth,
}: StatCardProp) {
  return (
    <CardRoot color={appearance}>
      <FirstRow>
        {icon}
        {title ? (
          <TotalCount titleTextMarginLeft={titleTextMarginLeft}>{title}</TotalCount>
        ):(
          <Skeleton height="1rem" width="4rem" />
        )}
        {
          percentage && (
            <PercentageWrapper isIncreased={percentage >= 0}>
              <i className={`fas fa-long-arrow-alt-${percentage >= 0 ? "up" : "down"}`}></i>
              <p style={{marginLeft: '5px'}}>{percentage === 99999 ? "∞" : `${percentage.toLocaleString()}%`}</p>
            </PercentageWrapper>
          )
        }
      </FirstRow>
      <SecondRow pWidth={pWidth}>
        <p>{subtitle}</p>
        {tooltip && (
          <i
            data-tip={tooltip}
            data-place="bottom"
            data-effect="solid"
            className="fas fa-question-circle"
          />
        )}
      </SecondRow>
    </CardRoot>
  )
}

export default SummaryCard
