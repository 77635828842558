import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { uuidv4 } from 'usefuljs'
import AngleLeft from "../../../../reusable/icons/AngleLeft"
import AngleRight from "../../../../reusable/icons/AngleRight"
import AngleLeftEnd from "../../../../reusable/icons/AngleLeftEnd"
import AngleRightEnd from "../../../../reusable/icons/AngleRightEnd"
import { useDispatch, useSelector } from "react-redux"
import { selectPage, setPaginationPage } from "../../../../../app/store/livefeedpost"

interface PaginationProp {
  count: number
  margin?: string
  siblingCount?: number
  showFirstButton?: boolean
  showLastButton?: boolean
  hidePrevButton?: boolean
  hideNextButton?: boolean
}

interface PaginationItemProp {
  selected?: boolean
  leftMost?: boolean
  rightMost?: boolean
}

const PaginationRoot = styled.div<{ margin: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 45px;
  background-color: transparent;
  margin: ${props => props.margin ?? "0px"};
`

const PaginationItem = styled.button<PaginationItemProp>`
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #424C5A;
  background-color: white;
  border: 1px solid #F2F2F2;
  margin: 0px;
  cursor: pointer;
  &:hover {
    border: 1px solid #29D1D7;
  }
  &:focus {
    outline: none;
  }
  ${props => props.selected && css`
    background-color: #E3FEFF;
    color: #29D1D7;
    font-weight: 600;
  `}
  ${props => props.leftMost && css`
    border-radius: 5px 0px 0px 5px;
  `}
  ${props => props.rightMost && css`
    border-radius: 0px 5px 5px 0px;
  `}

  @media (max-width: 992px) {
    height: 2rem;
    width: 2rem;
  }

  @media (max-width: 768px) {
    height: 1.75rem;
    width: 1.75rem;
  }
`

const Pagination = memo<PaginationProp>(({
  count,
  siblingCount = 3,
  margin = "0px",
  showFirstButton = true,
  showLastButton = true,
  hidePrevButton = false,
  hideNextButton = false
}) => {

  const dispatch = useDispatch()

  const page = useSelector(selectPage)

  const PaginationItems = Array.from(Array(count), (_, i) => i + 1)
    .reduce((previousValue, currentValue) => {
      if (count <= siblingCount * 2) { return [...previousValue, currentValue] }
      if (page <= siblingCount + 1) {
        if (currentValue <= siblingCount * 2 + 1) { return [...previousValue, currentValue] }
      }
      if (page >= count - siblingCount) {
        if (currentValue >= count - siblingCount * 2) { return [...previousValue, currentValue] }
      }
      if (currentValue >= page - siblingCount && currentValue <= page + siblingCount) {
        return [...previousValue, currentValue]
      }
      return previousValue
    }, [] as number[])
    .map(item => (
      <PaginationItem
        key={uuidv4()}
        selected={item === page}
        onClick={() => { dispatch(setPaginationPage(item)) }}
      >
        {item}
      </PaginationItem>
    )
    )

  const FirstButton = () => {
    if (showFirstButton) {
      return (
        <PaginationItem
          leftMost
          onClick={() => {
            dispatch(setPaginationPage(1))
          }}
        >
          <AngleLeftEnd />
        </PaginationItem>
      )
    }

    return null
  }

  const LastButton = () => {
    if (showLastButton) {
      return (
        <PaginationItem
          rightMost
          onClick={() => {
            dispatch(setPaginationPage(count))
          }}
        >
          <AngleRightEnd />
        </PaginationItem>
      )
    }

    return null
  }

  const PrevButton = () => {
    if (!hidePrevButton) {
      return (
        <PaginationItem
          onClick={() => {
            if (page > 1) {
              dispatch(setPaginationPage(page - 1))
            }
          }}
        >
          <AngleLeft />
        </PaginationItem >
      )
    }

    return null
  }

  const NextButton = () => {
    if (!hideNextButton) {
      return (
        <PaginationItem
          onClick={() => {
            if (page < count) {
              dispatch(setPaginationPage(page + 1))
            }
          }}
        >
          <AngleRight />
        </PaginationItem>
      )
    }

    return null
  }


  return (
    <PaginationRoot margin={margin}>
      <FirstButton />
      <PrevButton />
      {PaginationItems}
      <NextButton />
      <LastButton />
    </PaginationRoot>
  )
})

export default Pagination
