import { LinkPreview } from '@dhaiwat10/react-link-preview'
import moment from 'moment'
import React from 'react'
import { PreviewThread } from '../../../../../../../interface'
import { PreviewLink, PreviewPostDataContainer, PreviewPostDataSiteAndDate, PreviewTitle, RelatedPostPreviewContainer } from '../../styled'

import './postPreviewStyle.scss'
interface RelatedPostPreviewProps{
  data: PreviewThread
}

const RelatedPostPreview = ({data}:RelatedPostPreviewProps) => {
  return (
    <>
    {data && (
      data?.site === "Instagram" || data?.site === "Twitter" || data?.medium === "Facebook" || data?.site === "BabyKingdom" || data?.site === 'YouTube' ? (
        <FallBackPostReview data={data} />
      ) : (
        <LinkPreview 
          url={data.thread_link} 
          className="link-preview"
          descriptionLength={50}
          imageHeight={250}
          width="48%"
          margin="1%"
          fallback={<FallBackPostReview data={data} />}
        />
      )
    )}
    </>

  )
}

export default RelatedPostPreview


const FallBackPostReview = ({data}:RelatedPostPreviewProps) => {
  return(
    <RelatedPostPreviewContainer>
      <PreviewLink href={data.thread_link} rel="noreferrer noopener" target="_blank">
        <PreviewTitle>{data.thread_title.slice(0,30)}...</PreviewTitle>
        <div>{data.post_message.slice(0,100)}...</div>
      </PreviewLink>
      <PreviewPostDataContainer>
        <PreviewPostDataSiteAndDate>
          {data.site} &emsp;
          {moment(data.post_timestamp).format('YYYY-MM-DD')}
        </PreviewPostDataSiteAndDate>
        <PreviewPostDataSiteAndDate>
          {data?.share_count > 0 && <span>{data.share_count} shares</span> }
        </PreviewPostDataSiteAndDate>
      </PreviewPostDataContainer>
    </RelatedPostPreviewContainer>
  )
}