import React from "react"
import styles from "./examples.module.scss"

type prop = {
    active: boolean,
    onClickClose: () => void
}

const Examples = (props: prop) => {
    const [tab, setTab] = React.useState(0) // 0 = manual, 1 = combo
    const img: string = React.useMemo(() =>
        tab ? "https://lenx-public-assets.s3.amazonaws.com/examples/combo.PNG" : "https://lenx-public-assets.s3.amazonaws.com/examples/manual.PNG"
        , [tab])




    return (
        <div>
            <div className={`modal ${props.active ? " is-active" : ""}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className={styles.header}>
                        <div className={`tabs ${styles.tabs}`}>
                            <ul>
                                <li onClick={() => setTab(0)} className={tab === 0 ? "is-active" : ""}><span className={styles.tabContent}>Operators Manual</span></li>
                                <li onClick={() => setTab(1)} className={tab === 1 ? "is-active" : ""}><span className={styles.tabContent}>Combination Examples</span></li>
                            </ul>
                        </div>
                        <span className={styles.close} onClick={props.onClickClose}>close</span>
                    </header>
                    <section className="modal-card-body" style={{height: "610px"}}>
                        <img src={img} alt="Example" />
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Examples


/*
<div className={styles.tabs}>
    <span className={styles.tab} onClick={()=>setTab(0)}>Operators Manual</span>
    <span className={styles.tab} onClick={()=>setTab(1)}>Combination Examples</span>
</div>

*/