import * as React from "react";

function ArrowUpWithCalendar(props:any) {
  return (
    <svg
      width={31}
      height={27}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.9 24.065H4.453A2.452 2.452 0 012 21.613V6.903a2.452 2.452 0 012.452-2.451h14.71a2.452 2.452 0 012.451 2.451v4.904M16.71 2v4.903M6.903 2v4.903M2 11.806h19.613"
        stroke="#283044"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.12 16.665c-.31 0-.605-.137-.824-.38a1.37 1.37 0 01-.34-.914c0-.344.122-.673.34-.915.219-.243.515-.38.824-.38h5.822c.31 0 .605.137.824.38.218.242.341.571.341.915v6.47c0 .342-.123.671-.341.914a1.109 1.109 0 01-.823.38c-.31 0-.606-.137-.824-.38a1.37 1.37 0 01-.341-.915v-3.346l-5 5.555a1.109 1.109 0 01-.823.379c-.308 0-.605-.137-.823-.38l-2.67-2.966-5 5.555c-.22.235-.514.366-.819.363a1.11 1.11 0 01-.813-.379 1.37 1.37 0 01-.341-.904c-.003-.339.114-.666.327-.91l5.822-6.47c.219-.242.515-.378.824-.378.308 0 .605.136.823.379l2.67 2.966 4.176-4.64H23.12z"
        fill="#1877F2"
      />
    </svg>
  );
}

export default ArrowUpWithCalendar
