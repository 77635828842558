import React from "react"
import Comment from "./comment"
import { FeedData } from "../../../../../app/interfaces/feed"
import styles from "./comments.module.scss"

interface CommentsProp {
    data: FeedData[]
}

function Comments(props: CommentsProp) {

    const records: number = React.useMemo(() => props.data.length, [props.data])

    return (
        <div id={styles.comments}>
            <h2 id={styles.title}>Post comments</h2>
            <p id={styles.totalrecords}>Total records: {records}</p>
            <span id={styles.sort}>
                <p>Sort By Date</p>
                <i className="fas fa-sort-amount-down"></i>
            </span>
            <div id={styles.commentsList}>
                {
                    props.data.map(d => <Comment key={d.hash} data={d} />)
                }
            </div>
        </div>
    )
};

export default Comments