import React from "react"
import { Reactions } from "../../../../../../../../app/interfaces/feed"
import styles from "./reactions.module.scss"

type prop = {
    reactions: Reactions
}

const ReactionsView = (props: prop) => {
    return (
        <div className={styles.reactions}>
            {props.reactions.reaction_like !== null && <span><i className="fas fa-thumbs-up"></i> {props.reactions.reaction_like} Likes</span>}
            {props.reactions.reaction_dislike !== null && <span><i className="fas fa-thumbs-down"></i> {props.reactions.reaction_dislike} Dislikes</span>}
            {props.reactions.reaction_love !== null && <span className={styles.love}><img src="https://d18dyb0v4b4cx9.cloudfront.net/fb_love.webp" alt="love" /> {props.reactions.reaction_love}</span>}
            {props.reactions.reaction_haha !== null && <span className={styles.haha}><img src="https://d18dyb0v4b4cx9.cloudfront.net/fb_haha.webp" alt="haha" /> {props.reactions.reaction_haha}</span>}
            {props.reactions.reaction_angry !== null && <span className={styles.angry}><img src="https://d18dyb0v4b4cx9.cloudfront.net/fb_angry.webp" alt="angry" /> {props.reactions.reaction_angry}</span>}
            {props.reactions.reaction_sad !== null && <span className={styles.sad}><img src="https://d18dyb0v4b4cx9.cloudfront.net/fb_sad.webp" alt="sad" /> {props.reactions.reaction_sad}</span>}
            {props.reactions.reaction_wow !== null && <span className={styles.wow}><img src="https://d18dyb0v4b4cx9.cloudfront.net/fb_wow.webp" alt="wow" /> {props.reactions.reaction_wow}</span>}
            {props.reactions.share_count !== null && <span><i className="fas fa-share"></i> {props.reactions.share_count}</span>}
            {props.reactions.comment_count !== null && <span><i className="fas fa-comment-alt"></i> {props.reactions.comment_count}</span>}
            {props.reactions.view_count !== null && <span><i className="far fa-eye"></i> {props.reactions.view_count}</span>}
        </div>
    )
}

export default ReactionsView