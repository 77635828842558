import React from 'react'
import styled, { css } from 'styled-components'
import _capitalize from "lodash.capitalize"
import { useDispatch } from "react-redux"
import { setShowModelSelector, setSmartTrainMode } from "../../../../../../app/store/smartTrainSlice"
import { ModelType } from "../../../../../../app/interfaces/smartTrain"
import SmartFilterGraphicIcon from "../../../../../reusable/icons/SmartFilterGraphicIcon"
import SentimentGraphicIcon from "../../../../../reusable/icons/SentimentGraphicIcon"
import Button from "../../../../../reusable/buttons/Button"
import Skeleton from "../../../../../reusable/utils/Skeleton"
import loadingBar from "../../../../../../assets/loadingbar.gif"

interface ModelCardProp {
  type: ModelType
  isTraining: boolean
  isLoading: boolean
  shouldCreateModel: boolean
  setIsResetting: React.Dispatch<React.SetStateAction<boolean>>
}

const ModelCardRoot = styled.div<{ isTraining: boolean }>`
  width: 332px;
  height: 395px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #E5E5E5;
 
  ${props => props.isTraining ? css`
    background: #F8F8FA;
  ` : css`
    background: #FFFFFF;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 2px 10px rgba(211, 210, 224, 0.6);
    }
  `}
`

const Title = styled.h3`
  color: #516380;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
`

const IconWrapper = styled.div`
  margin-bottom: 1rem;
`

const Description = styled.p`
  color: #7B7B7B;
  font-size: 1rem;
  text-align: center;
`

const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StatusWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  
  p {
    color: #516380;
    font-weight: 500;
    font-size: 16px;
  }

  margin-bottom: 1rem;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 300px;
`

const StyledButton = styled(Button)`
  width: 112px !important;
`

const LoadingBar = styled.img`
  height: 12px;
`

function generateDescription(mode: ModelType) {
  switch (mode) {
    case "relevancy":
      return "Relevancy model helps you filter out irrelevant posts from your feed"
    case "sentiment":
      return "Sentiment model helps you classify sentiment of each incoming posts based on your definition"
    default:
      return ""
  }
}

function getCardIcon(mode: ModelType) {
  switch (mode) {
    case "relevancy":
      return <SmartFilterGraphicIcon height="114px" width="114px" />
    case "sentiment":
      return <SentimentGraphicIcon height="114px" width="114px" />
    default:
      return ""
  }
}

function ModelCard(props: ModelCardProp) {
  const dispatch = useDispatch()

  const selectModel = () => {
    dispatch(setSmartTrainMode(props.type))
    dispatch(setShowModelSelector(false))
  }

  const goToReset = () => {
    dispatch(setSmartTrainMode(props.type))
    props.setIsResetting(true)
  }

  return (
    <ModelCardRoot isTraining={props.isTraining}>
      {
        props.isLoading ? (
          <>
            <UpperContainer>
              <Skeleton width="150px" height="20px" margin="0px 0px 1.5rem 0px" />
              <IconWrapper>
                <Skeleton height="114px" width="114px" variant="circle" />
              </IconWrapper>
              <Skeleton width="282px" height="1rem" margin="1rem 0px 0.5rem 0px" />
              <Skeleton width="282px" height="1rem" margin="0px 0px 0.5rem 0px" />
              <Skeleton width="282px" height="1rem" margin="0px 0px 0.5rem 0px" />
            </UpperContainer>
            <Skeleton width="84px" height="36px" />
          </>
        ) : (
          <>
            <UpperContainer>
              <Title>{_capitalize(props.type)} Model</Title>
              <IconWrapper>
                {getCardIcon(props.type)}
              </IconWrapper>
              {props.isTraining && (
                <StatusWrapper>
                  <p>Training</p>
                  <LoadingBar src={loadingBar} alt="training bar" />
                </StatusWrapper>
              )}
              <Description>
                {
                  props.isTraining ? "Come back for smart train to keep improving your detection model" : generateDescription(props.type)
                }
              </Description>
            </UpperContainer>
            {
              !props.isTraining && (
                props.shouldCreateModel ? (
                  <Button onClick={selectModel}>SET UP</Button>
                ) : (
                  <ButtonContainer>
                    <StyledButton onClick={goToReset} variant="tertiary">Reset</StyledButton>
                    <StyledButton onClick={selectModel}>Smart Train</StyledButton>
                  </ButtonContainer>
                )
              )
            }
          </>
        )
      }

    </ModelCardRoot>
  )
}

export default ModelCard
