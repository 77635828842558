import React, { useState } from 'react'
import IntervalSwitch from "../../../components/IntervalSwitch"
import { IntervalContext } from "../../../context/IntervalContext"
import { GadgetBody, GadgetHeader, GadgetRoot } from "../../../styled"
import ChartTypeSwitch from "./ChartTypeSwitch"
import useInterval from "./hook/useInterval"
import PercentageChart from "./percentage/PercentageChart"
import { LeftContainer } from "./styled"
import VolumeChart from "./volume/VolumeChart"

export type ChartType = "percentage" | "volume"

function SentimentTimeline() {
  const [chartType, setChartType] = useState<ChartType>("percentage")

  const intervalObject = useInterval()

  return (
    <IntervalContext.Provider value={intervalObject}>
      <GadgetRoot>
        <GadgetHeader>
          <LeftContainer>
            <h3>Sentiment Timeline</h3>
            <IntervalSwitch />
          </LeftContainer>
          <ChartTypeSwitch type={chartType} setType={setChartType} />
        </GadgetHeader>
        <GadgetBody>
          {chartType === "percentage" ? <PercentageChart /> : <VolumeChart />}
        </GadgetBody>
      </GadgetRoot>
    </IntervalContext.Provider>
  )
}

export default SentimentTimeline
