import React from 'react'
import styled from 'styled-components'
import Skeleton from '../../../reusable/utils/Skeleton'

const Tag = ({topic, isLoading}:TagProps) => {
  return (
      isLoading ? (
        <>
          <TagWrapper style={{marginRight:'10px'}}><Skeleton width="4rem" height="2rem"/></TagWrapper>
          <TagWrapper style={{marginRight:'10px'}}><Skeleton width="4rem" height="2rem"/></TagWrapper>
          <TagWrapper><Skeleton width="4rem" height="2rem"/></TagWrapper>
        </>
      ):(
        <TagWrapper>
          <TagBadge>{topic}</TagBadge>
        </TagWrapper>
      )
  )
}

export default Tag

export const TagWrapper = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
`

export const TagBadge = styled.span`
  border: 1px solid #DADADA;
  border-radius: 3px;

  padding: 5px 10px;
  margin-right: 10px;
  color: #516380;
`

interface TagProps {
  topic?: string
  isLoading?: boolean
}