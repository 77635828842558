import React, {useState, useEffect, useContext, useCallback} from "react";
import Ctx from "../../ctx";
import { setSearch ,resetSearch } from "../../ctx/action"
import styles from "./searcher.module.scss"
import { useDispatch } from "react-redux"
import { setFilterSearch } from "../../../../app/store/livefeedpost"

const Searcher = ()=>{
    const dispatch = useDispatch()
    const ctx:any = useContext(Ctx)
    const [srch,setSrch] = useState("")

    const handleKeyDown = useCallback((e:React.KeyboardEvent<HTMLInputElement>):void =>{
        if(e.keyCode === 13){
            setSearch(srch, ctx.setFeed)
            dispatch(setFilterSearch(srch))
        }
    },[ctx.setFeed,srch,dispatch])

    const resetSrch = useCallback(()=>{
        setSrch("")
        resetSearch(ctx.setFeed)
        dispatch(setFilterSearch(""))
    },[setSrch,ctx.setFeed,dispatch])

    useEffect(() => {
        return () => {
            resetSrch()
        }
    }, [resetSrch])

    return (
        <div id={styles.searcher} className="control has-icons-left has-icons-right">
            <input value={srch} onChange={e => setSrch(e.target.value)} onKeyDown={handleKeyDown} type="text" className="input" placeholder="Search here" />
            <span className="icon is-small is-left">
                <i className="fas fa-search"></i>
            </span>
            <span className={styles.reset + " icon is-small is-right"} onClick={resetSrch}>
                <i className="fas fa-times"></i>
            </span>
        </div>
    )
}

export default React.memo(Searcher)