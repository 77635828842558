import * as React from "react"
import { IconProp } from "./interface"

interface CrossIconProp extends IconProp {
  variant?: "default" | "fat"
}

function CrossIcon({
  height = "1rem",
  width = "1rem",
  variant = "default",
  fill = "#283044"
}: CrossIconProp) {

  if (variant === "fat") {
    return (
      <svg
        height={height}
        width={width}
        viewBox="0 0 32 32"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.75 24.924c.526.526.768 1.173.728 1.942.04.769-.202 1.416-.728 1.942l-1.942 1.942c-.526.526-1.193.789-2.003.789-.728 0-1.355-.263-1.88-.79l-8.74-8.738-8.737 8.739c-.526.526-1.174.768-1.942.728-.769.04-1.416-.202-1.942-.728l-1.942-1.942c-.526-.526-.789-1.194-.789-2.003 0-.728.263-1.355.789-1.88l8.738-8.74-8.738-8.738c-.526-.525-.81-1.173-.85-1.941.04-.769.324-1.416.85-1.942l1.942-1.942C4.09 1.096 4.717.833 5.445.833c.81 0 1.477.263 2.003.789l8.738 8.738 8.738-8.738c.526-.526 1.153-.789 1.881-.789.81 0 1.477.263 2.003.789l1.942 1.942c.526.526.768 1.173.728 1.942.04.768-.202 1.416-.728 1.941l-8.738 8.739 8.738 8.738z"
          fill={fill}
        />
      </svg>
    )
  }


  // variant is default
  return (
    <svg viewBox="0 0 141.73 141.73" height={height} width={width}>
      <g>
        <path
          className="Cancel_svg__cls-1"
          fill={fill}
          d="M123.52 139.21L3.06 18.54a7.25 7.25 0 010-10.24L8 3.36a7.42 7.42 0 0110.51 0l120.31 120.53a7.44 7.44 0 010 10.53l-4.82 4.79a7.43 7.43 0 01-10.48 0z"
        />
        <path
          className="Cancel_svg__cls-1"
          fill={fill}
          d="M139.53 19L19.2 139.59a7.32 7.32 0 01-10.35 0l-5.19-5.21a7.54 7.54 0 010-10.66L123.84 3.32a7.52 7.52 0 0110.64 0l5.05 5.06a7.54 7.54 0 010 10.62z"
        />
      </g>
    </svg>
  )

}

export default CrossIcon