import { createContext } from 'react'
import { Interval, IntervalStep } from "../interface"

export const IntervalContext = createContext<{
  interval: Interval | null
  intervalStep: IntervalStep | null
  chooseInterval: (interval: Interval) => void
}>({
  interval: null,
  intervalStep: null,
  chooseInterval: (interval: Interval) => null
})