import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";
import { selectFeedType } from "../../../../../../../../app/store/feedSlice";
import {
  fetchCompareSentimentTimelinePostData,
  fetchSentimentTimelinePostData,
  selectCompareSentiments,
  selectDateRange,
  selectSentiments,
} from "../../../../../../../../app/store/overviewSlice";
import {
  convertIntervalStepAndIntervalToDateRange,
  generateEndOfRangeFromDateRangeUnixTimestamp,
  generateEndOfRangeFromIntervalUnixTimestamp,
  generateStartOfRangeFromDateRangeUnixTimestamp,
  generateStartOfRangeFromIntervalUnixTimestamp,
} from "../../../../../../../../app/tools/dateRangeConverter";
import EmptyStatePlaceholder from "../../../../components/EmptyStatePlaceholder";
import { IntervalContext } from "../../../../context/IntervalContext";
import CustomTooltip from "../CustomTooltip";
import {
  generateAdHocChartPointLabel,
  generateLiveChartPointLabel,
} from "../helper";
import VolumeSkeleton from "./VolumeSkeleton";
import { Sentiment } from "../../../../../../../../app/interfaces/overview";
interface VolumeProps {
  isCompare?: boolean;
}
function VolumeChart({ isCompare }: VolumeProps) {
  const [sentiments, setSentments] = useState<Sentiment[] | null>([]);
  const actualSentiments: Sentiment[] | null = useSelector(selectSentiments);
  const compareSentiments: Sentiment[] | null = useSelector(
    selectCompareSentiments
  );
  const dateRange = useSelector(selectDateRange);
  const feedType = useSelector(selectFeedType);

  const { interval, intervalStep } = useContext(IntervalContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isCompare) {
      setSentments(compareSentiments);
    } else {
      setSentments(actualSentiments);
    }
  }, [isCompare, actualSentiments, compareSentiments]);

  const handleDotClick = (e: any) => {
    const { start, end } = e.payload;

    const sentiment = (() => {
      switch (e.dataKey) {
        case "negative":
          return -1;
        case "neutral":
          return 0;
        case "positive":
          return 1;
        default:
          return null;
      }
    })();
    if (isCompare) {
      dispatch(
        fetchCompareSentimentTimelinePostData({
          type: sentiment!,
          start,
          end,
        })
      );
    } else {
      dispatch(
        fetchSentimentTimelinePostData({
          type: sentiment!,
          start,
          end,
        })
      );
    }
  };

  if (sentiments) {
    const data = sentiments.map(
      ({ timestamp, positive, neutral, negative }) => {
        return {
          name:
            feedType === 0
              ? generateLiveChartPointLabel(timestamp, dateRange)
              : generateAdHocChartPointLabel(timestamp, interval),
          positive,
          neutral,
          negative,
          start:
            feedType === 0
              ? generateStartOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateStartOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
          end:
            feedType === 0
              ? generateEndOfRangeFromDateRangeUnixTimestamp(
                  timestamp,
                  dateRange
                )
              : generateEndOfRangeFromIntervalUnixTimestamp(
                  timestamp,
                  convertIntervalStepAndIntervalToDateRange(
                    intervalStep!,
                    interval!
                  )
                ),
        };
      }
    );

    return (
      <ResponsiveContainer width="100%" height="100%">
        {sentiments.length ? (
          <LineChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              tickSize={0}
              tickMargin={10}
              tick={{ fontSize: "10px", fill: "#BDBDBD" }}
              axisLine={{ stroke: "#D3D2E0" }}
            />
            <YAxis
              type="number"
              tickSize={0}
              tickMargin={10}
              tick={{ fontSize: "10px", fill: "#BDBDBD" }}
              axisLine={{ stroke: "#D3D2E0" }}
            >
              <Label
                value="Post Volume"
                offset={5}
                position="insideLeft"
                angle={-90}
                fontSize={14}
                fill="#DADADA"
              />
            </YAxis>
            <Tooltip content={<CustomTooltip type="volume" />} />
            <Line
              dataKey="positive"
              stroke="#73D844"
              fill="#73D844"
              activeDot={{ onClick: handleDotClick, cursor: "pointer" }}
            />
            <Line
              dataKey="neutral"
              stroke="#898989"
              fill="#898989"
              activeDot={{ onClick: handleDotClick, cursor: "pointer" }}
            />
            <Line
              dataKey="negative"
              stroke="#ED5252"
              fill="#ED5252"
              activeDot={{ onClick: handleDotClick, cursor: "pointer" }}
            />
          </LineChart>
        ) : (
          <EmptyStatePlaceholder />
        )}
      </ResponsiveContainer>
    );
  }

  return <VolumeSkeleton />;
}

export default VolumeChart;
