import React from 'react'
import { IconProp } from "./interface"

function SmartTrainIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 33 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="Fast_Train_icon_v2_svg__a" fill="#fff">
        <path d="M4.56 12.681c-.691-.185-1.108-.9-.854-1.569a12.781 12.781 0 11.31 9.829c-.295-.653.075-1.392.754-1.62.679-.23 1.407.14 1.72.785a10.188 10.188 0 10-.263-8.317c-.271.663-.975 1.078-1.667.892z" />
      </mask>
      <path
        d="M4.56 12.681c-.691-.185-1.108-.9-.854-1.569a12.781 12.781 0 11.31 9.829c-.295-.653.075-1.392.754-1.62v0c.679-.23 1.407.14 1.72.785a10.188 10.188 0 10-.263-8.317c-.271.663-.975 1.078-1.667.892v0z"
        stroke={fill}
        strokeWidth={3}
        mask="url(#Fast_Train_icon_v2_svg__a)"
      />
      <circle cx={26.609} cy={22.957} r={5.891} fill={fill} stroke="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.401 20.667a.75.75 0 01.172 1.047l-2.898 4.036a.75.75 0 01-1.143.09l-1.81-1.835a.75.75 0 111.067-1.054l1.186 1.202 2.38-3.314a.75.75 0 011.046-.172z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.394 6.124a.9.9 0 011.083.67l1.15 4.876 4.767-.564a.9.9 0 01.211 1.788l-5.564.658a.9.9 0 01-.982-.687L1.725 7.207a.9.9 0 01.67-1.083z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.539 21.072l-.176-.159.176.159zm6.497-9.521l1.422-1.072.106.097c.437.403.92.847 1.229 1.267-.128.172-.261.341-.398.516l-.037.047c-.157.2-.318.406-.473.62l-.145.2.116.219c.14.263.253.53.333.795l.072.238 1.784.273v1.841l-1.783.27-.078.227a4.61 4.61 0 01-.316.742l-.119.215.14.202c.302.435.62.826.938 1.212-.116.145-.427.494-.757.822-.177.176-.35.336-.491.45-.055.043-.1.075-.134.098l-1.387-1.085-.226.117c-.238.123-.491.229-.744.31l-.242.079-.03.253-.022.199c-.052.466-.103.919-.21 1.341h-1.848l-.273-1.796-.233-.076a4.785 4.785 0 01-.735-.301l-.222-.115-1.414 1.072-.045-.04c-.453-.41-.975-.883-1.3-1.32.128-.173.262-.344.4-.52l.037-.047c.157-.2.318-.406.473-.62l.145-.2-.116-.218a4.292 4.292 0 01-.332-.801l-.072-.243-1.785-.264v-1.841l1.79-.27.074-.236c.08-.257.184-.499.316-.745l.115-.216-.141-.2c-.3-.422-.614-.817-.933-1.205.114-.145.423-.492.754-.82.176-.175.349-.334.49-.446.052-.042.095-.073.129-.096l1.394 1.082.225-.117c.237-.122.491-.229.744-.31l.242-.079.03-.253.022-.198c.052-.467.103-.92.21-1.342h1.848l.273 1.797.233.075c.257.083.502.18.735.302l.222.114zm-2.382 6.76a2.663 2.663 0 002.659-2.657 2.663 2.663 0 00-2.658-2.659 2.663 2.663 0 00-2.659 2.659 2.663 2.663 0 002.659 2.658z"
        fill={fill}
      />
    </svg>
  )
}

export default SmartTrainIcon
