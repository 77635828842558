import styled from 'styled-components'

const ModalRoot = styled.div<{ show: boolean }>`
  display: ${props => props.show ? "block" : "none"};
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  cursor: default;
`

export default ModalRoot