import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { usePostsStore } from "../../postStore";
import CardBody from "./CardBody";
import PreviousVersions from "./PreviousVersions";
import {
  CardsContainer,
  CardsHeadRow,
  CardHeadTitle,
  LastUpdatedText,
  TooltipIcon,
  LastUpdatedValue,
} from "./styled";

const Cards = ({ genAt, refetchDetail }: CardsProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const allTopicData = usePostsStore((state) => state.allTopicData);
  const genTime = genAt && moment(genAt).calendar();

  const trendScoreText = `<p style="text-align:center">
                          Controversial topics have higher <br/> 
                          index scores (max. 10). Calculated by <br/> 
                          A.I. based on features such as <br /> 
                          occurrance, engagement rate and <br /> 
                          channel spread etc.</p>`;

  const sentimentText = `<p style="text-align:center">
                          A general sentiment model is used <br/> 
                          for this analysis</p>`;

  const socialOutreachText = `<p style="text-align:center">
                              Indicator of the spread <br/>
                               of a trend, the higher the <br/>
                               number the higher the exposure</p>`;

  const { activeCardId, setActiveCardId } = usePostsStore((state) => ({
    activeCardId: state.activeCardId,
    setActiveCardId: state.setActiveCardId,
  }));

  //reset active card index when switch industry/ frequency
  useEffect(() => {
    setActiveCardId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMenu = () => {
    if (!showDropdown) {
      document.addEventListener("click", closeMenu);
      setShowDropdown(true);
    }
  };

  const closeMenu = () => {
    setShowDropdown(false);
    document.removeEventListener("click", closeMenu);
  };

  return (
    <CardsContainer>
      <CardsHeadRow>
        <CardHeadTitle basis="1 0 0" align="left" relative>
          <LastUpdatedText>
            Result{" "}
            <LastUpdatedValue
              className={"has-tooltip-arrow has-tooltip-bottom "}
              onClick={toggleMenu}
            >
              last updated {genTime}
              {showDropdown && (
                <PreviousVersions refetchDetail={refetchDetail} />
              )}
            </LastUpdatedValue>{" "}
            <i>
              &nbsp; (AI results are under continuous improvement &#128522;)
            </i>
          </LastUpdatedText>
        </CardHeadTitle>

        <CardHeadTitle basis="16 0 0"></CardHeadTitle>

        <CardHeadTitle basis="3.5 0 0">
          RANKING SCORE
          <ReactTooltip />
          <TooltipIcon
            className="fas fa-info-circle"
            data-tip={trendScoreText}
            data-place="top"
            data-effect="solid"
            data-html={true}
          />
        </CardHeadTitle>

        <CardHeadTitle basis="3 0 0">TREND FORECAST</CardHeadTitle>

        <CardHeadTitle basis="3 0 0">
          SENTIMENT
          <TooltipIcon
            className="fas fa-info-circle"
            data-tip={sentimentText}
            data-place="top"
            data-effect="solid"
            data-html={true}
          />
        </CardHeadTitle>

        <CardHeadTitle basis="2.5 0 0">
          SOCIAL OUTREACH
          <TooltipIcon
            className="fas fa-info-circle"
            data-tip={socialOutreachText}
            data-place="top"
            data-effect="solid"
            data-html={true}
          />
        </CardHeadTitle>

        <CardHeadTitle basis="1 0 0" />
      </CardsHeadRow>
      {allTopicData?.map((post, i) => (
        <CardBody
          key={post.briefData.topic_id}
          index={i}
          activeCardId={activeCardId}
          setActiveCardId={setActiveCardId}
          post={post}
        />
      ))}
    </CardsContainer>
  );
};

export default React.memo(Cards);

interface CardsProps {
  genAt: number | null;
  refetchDetail?: any;
}
