import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router'
import useDropdown from '../../../hooks/useDropdown'
import { ButtonSpan, Container, MenuListContainer, MenuSelectionButton, OpenMenuButton } from './styled'

function PreviousResultTimeframeSelector<T>({ 
  selections, 
  id, 
  selected,
  selectLabelAction, 
  selectValueAction, 
  labelId,
}: MenuButtonProps<T>){

  const { showDropdownMenu, toggleMenu } = useDropdown(id, false)
  const history = useHistory()
  const [previousPageFrequency, setPreviousPageFrequency] = useState<string | null>(null)

  const handleSelectSingle = (e:React.MouseEvent<HTMLButtonElement>, title: string, value: T) => {
    selectLabelAction(title)
    selectValueAction(value)
    history.push(`/trend?f=${value}&label=${labelId}`)
  }

  useEffect(()=>{
    const selectedLabel = selections && selections.filter((item) => item.value === selected)[0]?.title
    selections && setPreviousPageFrequency(selectedLabel)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <OpenMenuButton onClick={toggleMenu}>
        <ButtonSpan>{previousPageFrequency}</ButtonSpan>
        <i className={`fas fa-angle-${showDropdownMenu ? "up" : "down"}`} style={{color:'#516380'}} aria-hidden="true" />
      </OpenMenuButton>

      {showDropdownMenu && (
        <MenuListContainer id={id}>
          <ul role="button">
            {selections.map((selection)=>(
              <li key={selection.title}>
                  <MenuSelectionButton 
                    disabled={selection.disabled || false}
                    onClick={(e)=>handleSelectSingle(e, selection.title, selection.value)}
                  > 
                  {selection.title}
                  </MenuSelectionButton>
              </li>
            ))}
          </ul>
        </MenuListContainer>
      )}
    </Container>
  )
}

export default PreviousResultTimeframeSelector


interface MenuButtonProps<T> {
  buttonText?: string
  selections: Selections<T>[]
  selected: T
  id: string
  selectLabelAction: (label: string) => void
  selectValueAction: (value: T) => void
  isOvData?: boolean
  labelId: number
}

export interface Selections<T> {
  title: string
  disabled?: boolean
  value: T
}