import React from "react"
import styles from "./SaveSucceeded.module.scss"

interface SaveSucceededProp {
  onClose: ()=>void
}

function SaveSucceeded({onClose}:SaveSucceededProp) {
    return (
        <div>
            <div className={"modal is-active" }>
                <div className="modal-background"> </div>
                <div className="modal-content">
                  <div className={styles.contentContainer}>
                    <h1 className={styles.heading}>
                      <i className="fas fa-check-circle" />
                      <span>Your changes have been saved</span>
                    </h1>
                    <p className={styles.subHeading}>Changes will take effect for all incoming posts</p>
                    <button className={styles.confirmButton} onClick={onClose}>OK</button>
                  </div>
                </div>
            </div>
        </div>
    )
}

export default SaveSucceeded
