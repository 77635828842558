import React from "react"
import { TheadViewerHead } from "./head/interface"
import Ctx, { context } from "../../ctx"
import Head from "./head"
import styles from "./threadviewer.module.scss"
import { ThreadView } from "../../../../app/interfaces/feed"
import Post from "../posts/messageScroller/message/post"
import Comments from "./comments"
import Loading from "../../../reusable/loader"

function ThreadViewer() {
    const ctx = React.useContext(Ctx) as context
    const threadView: ThreadView = ctx.feed.threadviewer

    const headData = React.useMemo(() => {
        const hd: TheadViewerHead = {
            thread_link: threadView.thread_link,
            thread_title: threadView.thread_title,
            unix_timestamp: threadView.unix_timestamp
        }
        return hd
    }, [threadView])

    return (
        <div className={threadView.thread_link !== "" ? "show" : ""} id={styles.threadViewer}>
            <Head head={headData} />
            {
                (!threadView.fetching) ?
                    <React.Fragment>
                        {threadView.message && <Post data={threadView.message} />}
                        <span id={styles.gapLine}></span>
                        <Comments data={threadView.comments} />
                    </React.Fragment>
                    : <Loading />
            }
        </div>
    )
}

export default ThreadViewer