import React from 'react'
import Skeleton from "../../../../../reusable/utils/Skeleton"
import { RowGridContainer } from "../../styled"

function RowSkeleton() {
  return (
    <RowGridContainer>
      <div>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
    </RowGridContainer>
  )
}

function AuthorsSkeleton() {
  return (
    <div>
      <RowSkeleton />
      <RowSkeleton />
      <RowSkeleton />
      <RowSkeleton />
      <RowSkeleton />
    </div>

  )
}

export default AuthorsSkeleton
