import styled from 'styled-components'

export const ManagerRoot = styled.div`
  padding-right: 1rem;
  padding-left: 0rem;
`
export const SortingLabel = styled.div<{ isActive: boolean }>`
  color: ${props => props.isActive ? "#516380" : "#9B9B9C"};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;

  i {
    visibility: ${props => props.isActive ? "visible" : "hidden"};
  }
`

export const HeadLabel = styled.div`
  color: #9B9B9C;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 12fr 3fr 3fr 3fr;
  margin-bottom: 0.5rem;
`

export const SortingBarRoot = styled(GridContainer)`
  /* width: calc(100% - 18px); */
`

export const PostsContainer = styled.div`
  width: 100%;
  height: 50vh;
  /* height: 500px; */
  overflow: auto;
  padding-right: -1rem;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #C4C4C4;
  }
`

export const PostItemLayout = styled(GridContainer)`
  margin: 0px;
  &:nth-child(odd) {
    div:nth-child(2){
      background-color: white;
    }
  }
`

export const CheckBoxWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const PostItemContent = styled.div`
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 6;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr;
`

export const PostMain = styled.div`
  /* height: 150px; */
  padding: 1rem;
  overflow: hidden;
`

export const PostFieldContainer = styled.div`
  padding: 1rem;

  p {
    font-size: 14px;
    text-align: center;
  }
`

export const SpamByLabel = styled.p<{ isGrey?: boolean }>`
  font-size: 14px;
  text-align: center;
  color: ${props => props.isGrey ? "#BDBDBD" : "#232830"};
`

export const PostTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #283044;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`