import { tasklive_dedupe } from "../../../app/tools/task"
import { FeedState } from "../interface"

const feedReducer: React.Reducer<FeedState, any> = (state: FeedState, action: any): FeedState => {
    if (!action) return state
    switch (action.type) {
        case "setAccess":
            return { ...state, access: action.payload ? [...action.payload] : [] }
        case "setFetchLiveFeedState":
            return { ...state, fetching_tasklive: action.payload }
        case "setTaskFeedLiveData":
            if (action.payload && action.payload.length === 0 && state.tasklive && state.tasklive.length > 0) return state
            return { ...state, tasklive: tasklive_dedupe([...state?.tasklive ?? [], ...action.payload]) }
        case "setTaskFeedLiveDataMax":
            return { ...state, tasklive_max: action.payload }
        case "truncateTaskFeedLiveData":
            return { ...state, tasklive: [], tasklive_max: 0 }
        case "setSites":
            return { ...state, sites: [...action.payload] }
        case "setFilter":
            return { ...state, filter: { ...action.payload } }
        case "setSearch":
            return { ...state, search: action.payload }
        case "setThreadViewerInfo":
            return { ...state, threadviewer: { ...state.threadviewer, ...action.payload } }
        default:
            return state
    }
}

export { feedReducer }