import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { selectShowIrrelevantManager, setShowIrrelevantManager } from "../../../../../app/store/smartTrainSlice"
import ModalHeader from "../../../../reusable/utils/Modal/ModalHeader"
import ModalMain from "../../../../reusable/utils/Modal/ModalMain"
import ModalRoot from "../../../../reusable/utils/Modal/ModalRoot"
import { ModalContent, ModalFooter } from "../styled"
import Manager from "./content"
import Footer from "./Footer"

function IrrelevantPostsManager() {
  const dispatch = useDispatch()
  const showModal = useSelector(selectShowIrrelevantManager)

  return (
    <>
      {showModal && (
        <ModalRoot show={true}>
          <ModalMain width={1080} backgroundColor="#F7F7F9">
            <ModalHeader
              title="Manage Irrelevant Posts"
              onClose={() => dispatch(setShowIrrelevantManager(false))}
            />
            <ModalContent>
              <Manager />
            </ModalContent>
            <ModalFooter>
              <Footer onClose={() => dispatch(setShowIrrelevantManager(false))} />
            </ModalFooter>
          </ModalMain>
        </ModalRoot>
      )}
    </>
  )
}

export default IrrelevantPostsManager
