import React, { useState, useMemo } from 'react'
import styled from 'styled-components'

interface PostMessageProp {
  text: string
}

const Text = styled.p`
  color: #516380;
  font-size: 14px;
`

const ExpandLabel = styled.p`
  color: #29d1d7;
  font-size: 14px;
  cursor: pointer;
`

const MESSAGE_LIMIT = 100

function PostMessage({ text }: PostMessageProp) {
  const [expand, setExpand] = useState(false)

  const expandText = expand ? "See Less ..." : "See More ..."

  const postMessage = useMemo(() => {
    return expand ? text : text.slice(0, MESSAGE_LIMIT)
  }, [expand, text])

  return (
    <div>
      <Text>{postMessage}</Text>
      {text.length > MESSAGE_LIMIT ? (
        <ExpandLabel onClick={() => setExpand(!expand)}>{expandText}</ExpandLabel>
      ) : null}
    </div>
  )
}

export default PostMessage
