import * as React from "react"
import { IconProp } from "./interface"

function TickIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 44 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.953 32.309L.602 17.969C.2 17.565 0 17.047 0 16.414c0-.634.2-1.152.602-1.555l3.18-3.11c.4-.461.887-.691 1.46-.691.63 0 1.175.23 1.633.69l9.625 9.676L37.125.691c.458-.46.974-.691 1.547-.691.63 0 1.146.23 1.547.691l3.18 3.11c.4.403.601.922.601 1.555 0 .634-.2 1.152-.602 1.555L18.047 32.309c-.401.46-.917.691-1.547.691-.63 0-1.146-.23-1.547-.691z"
        fill={fill}
      />
    </svg>
  )
}

export default TickIcon