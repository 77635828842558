import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { selectSources } from "../../../../../../app/store/miscSlice"
import { selectTaskMediums } from "../../../../../../app/store/feedSlice"
import styled from "styled-components"
import FilterIcon from "../../../../../reusable/icons/FilterIcon"
import HiddenSiteFilterItem, { baseWidth, baseFontSize, baseHeight, HiddenSiteFilterItemRoot } from "./HiddenSiteFilterItem"
import { toast } from "react-toastify"
import { ajax } from "usefuljs"
import { store } from "../../../../../../app/store"
import { setHidden } from "../../../../../../app/store/overviewSlice"

export interface Medium {
  name: string
  checked: boolean
  sites: Site[]
}

interface Site {
  name: string
  checked: boolean
}

interface HiddenSiteFilterProp {
  hiddenSites: string[]
}

const HiddenSiteFilterRoot = styled.div`
  position: relative;
`

const FilterSiteButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
      outline: none;
  }
`

const DropdownMenu = styled.div`
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  padding: 0.5rem 0 0.1rem 0;
  width: ${baseWidth};
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0 0 1px rgba(10, 10, 10, 0.02);
  z-index: 11;
`

const DropdownMenuContent = styled.div`
  overflow: auto;
  width: 100%;
  max-height: 300px;
`

const CheckBox = styled.input`
  cursor: pointer;
  margin-right: 10px;
  width: ${baseFontSize};
  height: ${baseFontSize};
`

const DropdownActions = styled.div`
    height: ${baseHeight} + 10px;
    width: 100%;
    padding: 0.4rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const ApplyFilterButton = styled.button`
  height: 30px;
  width: 100%;
  border: none;
  border-radius: 4px;
  color:white;
  background-color: #29d1d7;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
`

const AllMediumContent = styled.div`
  min-height: ${baseHeight};
  width: 100%;
  padding: 0.4rem 0.8rem;
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  div {
    display: flex;
    align-items: center;
  }
`

const Badge = styled.div<{ active: boolean }>`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: #ed5252;
  display: ${props => props.active ? "block" : "none"};
  position: absolute;
  top: 4px;
  left: 15px;
`

const checkIsAllChecked = (mediums: Medium[]) => {
  return mediums.every(medium => medium.checked && (medium.sites.length === 0 || medium.sites.every(site => site.checked)))
}

function HiddenSiteFilter({ hiddenSites }: HiddenSiteFilterProp) {

  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const toggleMenu = () => {
    if (!showDropdownMenu) {
      document.addEventListener('click', closeMenu)
      setShowDropdownMenu(true)
    }
  }

  const closeMenu = (e: any) => {
    if (!e.target.closest("#dropdownMenuContent")) {
      setShowDropdownMenu(false)
      document.removeEventListener('click', closeMenu)
    }
  }

  return (
    <HiddenSiteFilterRoot>
      <FilterSiteButton onClick={toggleMenu}>
        <FilterIcon height={"14px"} fill="#516380" />
        <Badge active={hiddenSites?.length > 0} />
      </FilterSiteButton>
      {showDropdownMenu && (
        <HiddenSiteFilterMenu
          hiddenSites={hiddenSites}
          showMenu={showDropdownMenu}
        />
      )}
    </HiddenSiteFilterRoot>
  )
}

interface HiddenSiteFilterMenuProp {
  hiddenSites: string[]
  showMenu: boolean
}

const HiddenSiteFilterMenu = ({
  hiddenSites,
  showMenu
}: HiddenSiteFilterMenuProp) => {

  const dispatch = useDispatch()

  const sources = useSelector(selectSources)
  const requestedMediums = useSelector(selectTaskMediums)

  const [allChecked, setAllChecked] = useState(false)
  const [mediums, setMediums] = useState<Medium[]>([])

  useEffect(() => {
    if (showMenu) {
      const initialState = sources.filter(source => {
        if (requestedMediums && requestedMediums.length > 0) {
          return requestedMediums.includes(source.medium)
        } else {
          return true
        }
      }).map(source => ({
          checked: true,
          name: source.medium,
          sites: source.sites.map(site => ({
            checked: !hiddenSites.includes(site),
            name: site,
          }))
        }))
        .map(medium => ({
          ...medium,
          checked: medium.sites.every(site => site.checked)
        })) as Medium[]

      setAllChecked(initialState.every(medium => medium.checked))
      setMediums(initialState)
    }
  }, [hiddenSites, requestedMediums, showMenu, sources])

  const handleMediumClick = (mediumIndex: number) => {
    setMediums([...mediums].map((medium, index) => {
      if (index === mediumIndex) {
        medium.checked = !medium.checked
        medium.sites = medium.sites.map(site => {
          site.checked = medium.checked
          return site
        })
      }
      return medium
    }))

    setAllChecked(checkIsAllChecked(mediums))
  }

  const handleSiteClick = (mediumValue: string, siteValue: string) => {
    setMediums([...mediums].map((medium) => {
      if (medium.name === mediumValue) {
        medium.sites = medium.sites.map(site => {
          if (site.name === siteValue) {
            site.checked = !site.checked
          }
          return site
        })
      }
      medium.checked = medium.sites.every(site => site.checked)
      return medium
    }))

    setAllChecked(checkIsAllChecked(mediums))
  }

  const handleClickAll = () => {
    if (allChecked) {
      setMediums(mediums.map(medium => {
        medium.checked = false
        medium.sites = medium.sites.map(site => {
          site.checked = false
          return site
        })
        return medium
      }))
    } else {
      setMediums(mediums.map(medium => {
        medium.checked = true
        medium.sites = medium.sites.map(site => {
          site.checked = true
          return site
        })
        return medium
      }))
    }
    setAllChecked(!allChecked)
  }

  const applyFilter = () => {
    if (mediums.every(medium => medium.sites.every(site => !site.checked))) {
      toast.error("Please select at least one site")
    }

    const sitesToHide = mediums.reduce((previousValue, currentMedium) => {
      if (currentMedium.checked) {
        return [...previousValue]
      } else {
        return [...previousValue, ...currentMedium.sites.filter(site => !site.checked).map(site => site.name)]
      }
    }, [] as string[])

    saveSites(sitesToHide)
  }

  const saveSites = async (hiddenSites: string[]) => {
    try {
      const res: any = await ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/overview/${store.getState().feed.task_id}/hide`,
        data: {
          ...store.getState().overview.hide,
          sites: hiddenSites
        }
      })

      if (res.result === "success") {
        dispatch(setHidden(res.data))
      } else {
        throw new Error(res.result)
      }

    } catch (err) {
      console.error(err)
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuContent id="dropdownMenuContent" >

        <HiddenSiteFilterItemRoot onClick={handleClickAll}>
          <AllMediumContent>
            <div>
              <CheckBox type="checkbox" checked={allChecked} readOnly />
              <span>All medium</span>
            </div>
          </AllMediumContent>
        </HiddenSiteFilterItemRoot>

        {mediums.map((medium, index) => (
          <HiddenSiteFilterItem
            medium={medium}
            index={index}
            handleMediumClick={handleMediumClick}
            handleSiteClick={handleSiteClick}
            key={medium.name}
          />))}

      </DropdownMenuContent>
      <DropdownActions>
        <ApplyFilterButton onClick={() => { applyFilter() }}>
          Apply filter
           </ApplyFilterButton>
      </DropdownActions>
    </DropdownMenu>
  )
}

export default HiddenSiteFilter
