import React from 'react'
import styled from 'styled-components'
import AuthorRow from '../../../reusable/modules/authors/AuthorRow'
import { AuthorsForTopAuthors } from '../../interface'

interface AuthorListProp {
  authors: AuthorsForTopAuthors[] | undefined
}

const ListContainer = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 35vh;
`

function AuthorList({ authors }: AuthorListProp) {
  return (
    <ListContainer>
      {
        authors && authors.map((author,i) => (
          <AuthorRow
            authorName={author.name}
            authorAvatar={author.avatar ?? ""}
            site={author.site}
            count={author.count}
            key={`${author.avatar}_${author.name}_${i}`}
          />
        ))
      }
    </ListContainer>
  )
}

export default AuthorList
