import React, { useContext, useState } from "react"
import ModalRoot from "../../../../reusable/utils/Modal/ModalRoot"
import ModalMain from "../../../../reusable/utils/Modal/ModalMain"
import ModalHeader from "../../../../reusable/utils/Modal/ModalHeader"
import { MainContent } from "./styled"
import Download from "./tabs/Download"
import Exporting from "./tabs/Exporting"
import { ExportType } from "./interface"
import ExportForm from "./form"
import useAbort from "./hooks/useAbort"
import AbortContext from "./context/AbortContext"
import CloseWindowContext from "./context/CloseWindowContext"

interface ExportOptionsProp {
  exportType: ExportType
}

function ExportOptions({ exportType }: ExportOptionsProp) {

  // state of the window
  const [isExporting, setIsExporting] = useState(false) 
  const [canDownload, setCanDownload] = useState(false) 
  const [downloadURL, setDownloadURL] = useState("") 
  const [abortController, abort] = useAbort()
  const closeWindow = useContext(CloseWindowContext)

  return (
    <AbortContext.Provider value={{ abortController, abort }}>
      <ModalRoot show={true}>
        <ModalMain width={600}>
          <ModalHeader
            title="Export Options"
            onClose={closeWindow}
          />
          <MainContent>
            {isExporting ? (
              canDownload ? (
                <Download
                  downloadURL={downloadURL}
                />
              ) : (
                <Exporting />
              )
            ) : (
              !canDownload && (
                <ExportForm
                  exportType={exportType}
                  setIsExporting={setIsExporting}
                  setCanDownload={setCanDownload}
                  setDownloadURL={setDownloadURL}
                />
              ))}
          </MainContent>

        </ModalMain>
      </ModalRoot>
    </AbortContext.Provider>
  )
}
export default ExportOptions