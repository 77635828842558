import React from "react";
import { useSelector } from "react-redux";
import {
  selectCompareSentiments,
  selectSentiments,
} from "../../../../../../../app/store/overviewSlice";
import EmptyStatePlaceholder from "../../../components/EmptyStatePlaceholder";
import { GadgetBody, GadgetHeader, GadgetRoot } from "../../../styled";
import SentimentIndicator from "./SentimentIndicator";
import OverviewSkeleton from "./Skeleton";
import { SentimentIndicatorContainer } from "./styled";
import {
  selectCompareFeedTaskInfo,
  selectFeedTaskInfo,
} from "../../../../../../../app/store/feedSlice";

function SentimentOverview() {
  const sentiments = useSelector(selectSentiments);
  const compareSentiments = useSelector(selectCompareSentiments);
  const compareTask = useSelector(selectCompareFeedTaskInfo);
  const task = useSelector(selectFeedTaskInfo);

  if (sentiments) {
    const positive = sentiments.reduce((sum, item) => sum + item.positive, 0);
    const neutral = sentiments.reduce((sum, item) => sum + item.neutral, 0);
    const negative = sentiments.reduce((sum, item) => sum + item.negative, 0);
    const sum = positive + neutral + negative;

    let comparePositive = 0;
    let compareNeutral = 0;
    let compareNegative = 0;
    let compareSum = 0;
    if (compareSentiments) {
      comparePositive = compareSentiments.reduce(
        (sum, item) => sum + item.positive,
        0
      );
      compareNeutral = compareSentiments.reduce(
        (sum, item) => sum + item.neutral,
        0
      );
      compareNegative = compareSentiments.reduce(
        (sum, item) => sum + item.negative,
        0
      );
      compareSum = comparePositive + compareNeutral + compareNegative;
    }

    return (
      <GadgetRoot>
        <GadgetHeader>
          <h3>Sentiment Overview</h3>
        </GadgetHeader>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: "1 1" }}>
            <div
              style={{
                marginLeft: "40px",
                marginTop: "10px",
                marginBottom: "10px",
                color: "#29d1d7",
                fontWeight: "bold",
              }}
            >
              {task?.task_name}
            </div>
            <GadgetBody>
              {sentiments.length ? (
                <SentimentIndicatorContainer>
                  <SentimentIndicator type="positive" value={positive / sum} />
                  <SentimentIndicator type="neutral" value={neutral / sum} />
                  <SentimentIndicator type="negative" value={negative / sum} />
                </SentimentIndicatorContainer>
              ) : (
                <EmptyStatePlaceholder />
              )}
            </GadgetBody>
          </div>
          <div style={{ flex: "1 1 " }}>
            <div
              style={{
                marginLeft: "40px",
                marginTop: "10px",
                marginBottom: "10px",
                color: "#ffd45d",
                fontWeight: "bold",
              }}
            >
              {compareTask?.task_name}
            </div>
            <GadgetBody>
              {compareSentiments && compareSentiments.length ? (
                <SentimentIndicatorContainer>
                  <SentimentIndicator
                    type="positive"
                    value={comparePositive / compareSum}
                  />
                  <SentimentIndicator
                    type="neutral"
                    value={compareNeutral / compareSum}
                  />
                  <SentimentIndicator
                    type="negative"
                    value={compareNegative / compareSum}
                  />
                </SentimentIndicatorContainer>
              ) : (
                <EmptyStatePlaceholder />
              )}
            </GadgetBody>
          </div>
        </div>
      </GadgetRoot>
    );
  }

  return <OverviewSkeleton />;
}

export default SentimentOverview;
