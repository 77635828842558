import React, { useState, useEffect } from 'react'
import styles from './TopicManager.module.scss'
import uf from "usefuljs"
import { store } from "../../../../../../../app/store"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { setHidden } from "../../../../../../../app/store/overviewSlice"

interface TopicManagerProp {
  topics: {
    topicString: string
    score: number
    commentScore: number
  }[]
  hidden: string[]
  cancel: () => void
}

function TopicManager({ topics, cancel, hidden }: TopicManagerProp) {

  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState<0 | 1>(0)
  const [searchText, setSearchText] = useState<string>("")
  const [hiddenTopics, setHiddenTopics] = useState<string[]>([])

  useEffect(() => {
    setHiddenTopics(hidden)
  }, [hidden])

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const displayTopics = topics.filter(topic => {
    return activeTab ? !hiddenTopics.includes(topic.topicString) : hiddenTopics.includes(topic.topicString)
  }).filter(word => word.topicString.toUpperCase().includes(searchText.toUpperCase()))

  const hideTopic = (topic: string) => {
    setHiddenTopics([...hiddenTopics, topic])
  }

  const unhideTopic = (topic: string) => {
    setHiddenTopics([...hiddenTopics].filter(hiddenTopic => hiddenTopic !== topic))
  }

  const saveChanges = async () => {
    try {
      const res: any = await uf.ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/overview/${store.getState().feed.task_id}/hide`,
        data: {
          ...store.getState().overview.hide,
          topics: hiddenTopics
        }
      })

      if (res.result === "success") {
        dispatch(setHidden(res.data))
        cancel()
      } else {
        throw new Error(res.result)
      }

    } catch (err) {
      toast.error(err)
    }
  }

  return (
    <div onClick={(event) => { event.stopPropagation() }}>
      <div className={"modal is-active"}>
        <div className="modal-background"> </div>
        <div className={"modal-card " + styles.modalCard}>
          <header className={"modal-card-head " + styles.cardHeader} >
            <p className="modal-card-title">
              <span>Manage Trending Topics</span>
            </p>
          </header>
          <section className={"modal-card-body " + styles.contentContainer}>
            <div className={styles.flexContainer}>
              <div>
                <div className={styles.filters}>
                  <div>
                    <button className={activeTab ? undefined : styles.active} onClick={() => { setActiveTab(0) }}>Hidden topics</button>
                  </div>
                  <div>
                    <button className={activeTab ? styles.active : undefined} onClick={() => { setActiveTab(1) }}>Shown topics</button>
                  </div>
                  <div className="field">
                    <div className="control has-icons-right">
                      <input className="input" type="text" value={searchText} onChange={handleSearchInput} />
                      <span className="icon is-right">
                        <i className="fas fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.topicList}>
                  <div >
                    <div>
                      <p>Topic</p>
                    </div>
                    <div>
                      <p>Activity score</p>
                    </div>
                    <div></div>
                  </div>
                  <ul>
                    {displayTopics.length ? displayTopics.map(topic => {
                      return (
                        <li key={topic.topicString}>
                          <div className={styles.keyword}>{topic.topicString}</div>
                          <div className={styles.count}>{(topic.commentScore * 100).toFixed(2)}%</div>
                          <div className={styles.hideAndUnhide}>
                            <p
                              className={activeTab ? styles.hide : styles.unhide}
                              onClick={() => {
                                activeTab ? hideTopic(topic.topicString) : unhideTopic(topic.topicString)
                              }}
                            >
                              {activeTab ? "Hide" : "Unhide"}
                            </p>
                          </div>
                        </li>
                      )
                    }) : 
                    <div className={styles.placeholder}>
                      <p>You don't have any {activeTab ? "unhidden" : "hidden"} topics</p>
                    </div>
                  }
                  </ul>
                </div>
              </div>

              <div className={styles.buttons}>
                <button onClick={cancel}>Cancel</button>
                <button onClick={saveChanges}>Save changes</button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default TopicManager
