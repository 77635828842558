import { ajax } from "usefuljs"
import { QueryClient } from "react-query"
import { store } from "../store"

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			queryFn: defaultQueryFn,
			refetchOnWindowFocus: false,
		},
	},
})

async function defaultQueryFn({ queryKey }: any) {
	const data: any = await ajax({
		method: "GET",
		url: `${process.env.REACT_APP_ENDPOINT}${queryKey[0]}`,
		headers: { token: store.getState().user.token },
	})

	return data
}

export default queryClient

export const destructuredQueryFn = async ({ queryKey }: any) => {
	const { data }: any = await ajax({
		method: "GET",
		url: `${process.env.REACT_APP_ENDPOINT}${queryKey[0]}`,
		headers: { token: store.getState().user.token },
	})

	return data
}
