import React from 'react'
import styled from 'styled-components'
import { TopicDetails } from '../../interface'

const TopicList = ({topics}: TopicListProps) => {
  return (
    <ListContainer>
      {
        topics.length > 0 ? (topics.map((topic, i)=>(
          <Row key={i}>
            <p><span>{i+1}</span> {topic.word}  {topic.related_word && <>&bull; {topic.related_word[0]}</>}</p>
            <p>{Math.floor(topic.doc_freq * 100)}%</p>
          </Row>
        ))):(
          <EmptyStateContainer>
            <div>
              <div>
                <i className="fas fa-chart-line" />
              </div>
            </div>
            <p>No associated topic at the moment.</p>
          </EmptyStateContainer>
        )
      }
    </ListContainer>
  )
}

export default TopicList

interface TopicListProps{
  topics: TopicDetails[]
}

const ListContainer = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 35vh;
  `

const Row = styled.li<{ $isClickable?: boolean }>`
  display: flex;
  flex-direction:row;
  padding: 0 1rem;
  width: 100%;
  border-bottom: 1px solid #f2f5fa;
  
  cursor: ${props => props.$isClickable ? "pointer" : "default"};
  justify-content: space-between;

  &:hover {
      background-color: #f5f5f5;
  }

  p:first-child { 
    font-size: 1rem;
    letter-spacing: 2px;
    line-height: 2;
    > span{
      font-size: 1.2rem;
      margin-right: 1rem;
    }
  }
  
  p:nth-child(2){
    color: #29D1D7;
    line-height:3;
  }
  `

  const EmptyStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:30vh;
    flex-wrap: wrap;
    width: 100%;

    >div >div{
      background-color: #E2E1EB;
      padding: 10px;
      border-radius: 50%;
      margin-bottom: 10px;
      >i{
        font-size: 2rem;
        color: #fff;
      }
    }

    >p{
      color: #BDBDBD;
    }
  `