import * as React from "react";

function SvgSentimentScaleExcited(props:any) {
  return (
    <svg
      width={76}
      height={45}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        opacity={0.4}
        y={17.001}
        width={59}
        height={7}
        rx={3.5}
        fill="url(#SentimentScaleExcited_svg__paint0_linear)"
      />
      <g filter="url(#SentimentScaleExcited_svg__filter0_d)">
        <circle cx={53.201} cy={20.201} r={12.201} fill="#FFD366" />
        <circle cx={53.201} cy={20.201} r={11.701} stroke="#FFCC4D" />
      </g>
      <path
        d="M47.862 15.804c-1.136.052-2.015 1.378-2.313 2.034.464-.342 1.381-.923 2.343-.967.757-.035 1.75.457 2.154.76-.255-.631-1.048-1.88-2.184-1.827zM58.707 15.833c1.188.068 2.1 1.407 2.407 2.067-.483-.35-1.44-.943-2.444-1-.793-.046-1.834.432-2.258.729.27-.627 1.107-1.865 2.295-1.796z"
        fill="#516380"
        stroke="#516380"
        strokeWidth={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.201 20.842c-2.247 0-5.565-.107-7.063-.642.107 2.034 1.67 6.422 7.063 6.422 5.394 0 6.529-4.388 6.422-6.422-1.285.535-4.367.642-6.422.642z"
        fill="#fff"
        stroke="#516380"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="SentimentScaleExcited_svg__paint0_linear"
          x1={0}
          y1={20.501}
          x2={59}
          y2={20.501}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F75E5E" />
          <stop offset={1} stopColor="#5CE191" />
        </linearGradient>
        <filter
          id="SentimentScaleExcited_svg__filter0_d"
          x={31}
          y={0}
          width={44.401}
          height={44.401}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgSentimentScaleExcited;
