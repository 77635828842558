import React from "react"
import styles from "./SaveFailed.module.scss"

interface SaveFailedProp {
  onResume: ()=>void
  onClose: ()=>void
}

function SaveFailed({onResume, onClose}:SaveFailedProp) {
    return (
        <div>
            <div className={"modal is-active" }>
                <div className="modal-background"> </div>
                <div className="modal-card">
                  <section className="modal-card-body">
                    <div className={styles.contentContainer}>
                      <h1 className={styles.heading}>
                        <i className="far fa-sad-cry" />
                        <span>Oh snap! Something went wrong</span>
                      </h1>
                      <p className={styles.subHeading}>Your changes cannot be saved, please try again later</p>
                      <div className={styles.options}>
                        <button className={styles.cancelButton} onClick={onClose}>Cancel</button>
                        <button className={styles.tryAgainButton} onClick={onResume}>Try again</button>
                      </div>
                    </div>
                  </section>
                </div>
            </div>
        </div>
    )
}

export default SaveFailed

