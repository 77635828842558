import React, { useState, useCallback, useRef, memo } from "react"
import uf from "usefuljs"
import { useClickAway } from "react-use"
import styled from "styled-components"
import { toast } from "react-toastify"

interface InputProp {
    inputs: string[],
    relationship: "AND" | "OR",
    index: number,
    onChange: (data: string[], index: number) => void,
    selectedGroup: boolean,
    sanitize: () => void,
    excludeGroup: boolean,
    excludeBlock: boolean,
    isLimited: boolean
}

const InputRoot = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const InputList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const InputListItem = styled.div<{ excludeBlock: boolean }>`
    border-radius: 3px;
    &:first-child {
        >div {
            background-color: ${props => props.excludeBlock ? "#ff6e86" : "#92a5c3"};
            color: white;
            height: 2rem;
        }
    }
`
const InputListItemContent = styled.div<{ excludeBlock: boolean }>`
    display: flex;
    align-items: center;
    height: 32px;
    column-gap: 0.5rem;
    padding: 0.2rem 0.5rem;
    border: 1px solid white;
    /* border-radius: 4px; */
    background-color: ${props => props.excludeBlock ? "#FFD9DF" : "#e6e6e6d2"};
`

const InputItemWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    border-radius: 3px;
    border: 0;
    background-color: transparent;
`

const InputBox = styled.input<{excludeBlock: boolean}>`
    color: #516380;
    background-color: #e6e6e6d2;
    border-radius: 0px;
    border: 0;
    height: 30px;
    padding: 0.3rem;
    align-self: center;
    box-sizing: border-box;
    &:focus {
        outline: none;
        border: ${props => props.excludeBlock ? "1px solid #ff496ad6" : "1px solid #29d1d7"};
    }
`

const ExpandButton = styled.button`
    color: white;
    border: none;
    padding: 0px;
    background-color: transparent;
    transition: all 0.2s ease;
    cursor: pointer;
    >i {
        font-size: 1rem;
        &[data-expand=true] {
            transform: rotate(-180deg);
        }
    }
    &:hover {
        color: #e6e6e6d2;
    }
    &:focus {
        outline: none;
    }
`

const DeleteButton = styled.button`
    color: inherit;
    border: none;
    background-color: transparent;
    padding: 0px;
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
        color: #ed5252;
    }
    &:focus {
        outline: none;
    }
`

const EnterButton = styled.button`
    position: absolute;
    right: 0.3rem;
    transition: all 0.2s ease;
    cursor: pointer;
    color: #bdbdbd;
    font-size: 0.8rem;
    background-color: transparent;
    border: none;
    &:hover {
        color: #4d5280;
    }
    &:focus {
        outline: none;
    }
`

const AddInputButton = styled.button<{ excludeBlock: boolean }>`
    font-size: 0.8rem;
    height: 2rem;
    width: 2rem;
    border: 1px solid white;
    /* border-radius: 4px; */
    background-color: ${props => props.excludeBlock ? "#FFD9DF" : "#e6e6e6d2"};
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
        color: #3a89ff;
        &::after {
            font-size: 0.9rem;
            font-weight: 600;
            content: "Or";
        }
        >i {
            display: none;
        }
    }
`

const Separator = styled.p<{ excludeBlock: boolean }>`
    font-size: 1rem;
    font-weight: 500;
    color: #516380;
    height: 2rem;
    width: 2rem;
    background-color: ${props => props.excludeBlock ? "#FFD9DF" : "#e6e6e6d2"};
    /* background-color: transparent; */
    border: 1px solid white;
    /* border-radius: 4px; */
    text-transform: uppercase;
    align-self: center;
    justify-self: center;
    text-transform: lowercase;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Input = memo<InputProp>((props) => {
    const [adding, setAdding] = useState(props.inputs.length === 0)
    const [addText, setAddText] = useState("")
    const [editText, setEditText] = useState("")
    const [expand, setExpand] = useState(true)
    const [edit, setEdit] = useState(-1)

    const addNewText = useCallback((text: string, index?: number) => {
        let newdata = [...props.inputs]
        if (index !== undefined) {
            newdata[index] = text
        } else {
            newdata = [...props.inputs, text]
        }
        newdata = uf.arr_dedup(newdata).filter(item => item !== "")
        setAdding(false)
        setAddText("")
        setExpand(true)
        props.onChange(newdata, props.index)
    }, [props])

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>, index?: number): void => {
        if (e.keyCode === 13) {
            if(e.currentTarget.value !== "*" && e.currentTarget.value !== "/"){
                addNewText(e.currentTarget.value, index)
            setEdit(-1)
            } else {
                toast.error("Characters like '*' and '/' are not allowed as keywords")
            }
            
        }
    }, [addNewText])

    const clickEnterToSubmit = (editText: string, index: number) => {
        if(editText!== "*" && editText !== "/"){
            addNewText(editText, index)
            setEdit(-1)
        } else {
            toast.error("Characters like '*' and '/' are not allowed as keywords")
        }
       
    }

    const onClickDel = useCallback((txt: string) => {
        const newdata = (uf.arr_rm([...props.inputs], txt, false) as string[]).filter(item => item !== "")
        props.onChange(newdata, props.index)
    }, [props])



    const ref = useRef(null)

    useClickAway(ref, () => {
        if (props.selectedGroup) {
            if (addText) {
                addNewText(addText)
                reset()
                return
            }

            if (edit !== -1) {
                addNewText(editText, edit)
                reset()
                return
            }

            // if (adding) {
            //     //  props.sanitize()
            // }

            // reset()
        }
    })

    const clickToEdit = (index: number, content: string) => {
        setEdit(index)
        setEditText(content)
    }

    const reset = () => {
        setAddText("")
        setAdding(false)
        setEditText("")
        setEdit(-1)
    }


    return (
        <InputRoot ref={ref}>

            <InputList>
                {
                    props.inputs.length > 0 && props.inputs.slice(0, expand ? props.inputs.length : 1).map((d, index) =>
                        <InputListItem key={d} excludeBlock={props.excludeBlock}>
                            <InputListItemContent excludeBlock={props.excludeBlock}>
                                {edit === index ?
                                    <InputItemWrapper>
                                        <InputBox
                                            autoFocus={true}
                                            value={editText}
                                            onChange={e => {
                                                if(e.target.value !== "*" && e.target.value !== "/"){
                                                    setEditText(e.target.value)
                                                } else {
                                                    toast.error("Characters like '*' and '/' are not allowed as keywords")
                                                }
                                                
                                            }}
                                            type="text"
                                            onKeyDown={e => handleKeyDown(e, index)}
                                            excludeBlock={props.excludeBlock}
                                        />
                                        <EnterButton onClick={() => clickEnterToSubmit(editText, index)}>[Enter]</EnterButton>
                                    </InputItemWrapper>
                                    : (
                                        <span onClick={() => { clickToEdit(index, d) }}>
                                            {d}
                                        </span>
                                    )
                                }

                                <DeleteButton onClick={() => onClickDel(d)}>
                                    <i className="fas fa-times" />
                                </DeleteButton>

                                {index === 0 && props.inputs.length > 1 && (
                                    <ExpandButton onClick={() => setExpand(!expand)}>
                                        <i data-expand={expand} className="fas fa-angle-right" />
                                    </ExpandButton>
                                )}
                            </InputListItemContent>
                        </InputListItem>)
                        .reduce((p, c) => (
                            <>
                                {p}
                                <Separator excludeBlock={props.excludeBlock}>{props.relationship}</Separator>
                                {c}
                            </>
                        ))
                }
                {((props.inputs.length === 0 && !props.excludeGroup && !props.excludeBlock && edit !== props.inputs.length - 1) || !adding) && !props.isLimited && (
                    <AddInputButton
                        excludeBlock={props.excludeBlock}
                        onClick={() => {
                            setAdding(true)
                        }}
                    >
                        <i className="fas fa-plus" />
                    </AddInputButton>
                )}
                {adding && (
                    <InputItemWrapper>
                        <InputBox
                            autoFocus={true}
                            minLength={1}
                            onChange={e => {
                                if(e.target.value !== "*" && e.target.value !== "/"){
                                    setEditText(e.target.value)
                                } else {
                                    toast.error("Characters like '*' and '/' are not allowed as keywords")
                                }
                            }}
                            type="text"
                            onKeyDown={e => handleKeyDown(e)}
                            excludeBlock={props.excludeBlock}
                        />
                        <EnterButton onClick={() => addNewText(addText)}>[Enter]</EnterButton>
                    </InputItemWrapper>
                )}
            </InputList>
        </InputRoot>
    )
})

export default Input