import * as React from "react"
import { IconProp } from "./interface"

const ViralityScoreMedium = ({
  width = "1rem",
  fill = "#29D1D7",
  stroke = 1.5,
}: IconProp) => {
  return (
    <svg
      viewBox="0 0 35 25"
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.148 7.673L12.62 13.32l7.22 5.416a10.755 10.755 0 01-9.076 4.976C4.819 23.712 0 18.892 0 12.948 0 7.004 4.82 2.185 10.764 2.185a10.76 10.76 0 019.384 5.488z"
        fill={fill}
      />
      <path
        d="M22.667 8.481c1.47 1.47 3.526 5.415 0 9.445M26.444 4.703c1.9 2.547 4.562 9.386 0 16.37"
        stroke={fill}
        strokeWidth={stroke}
        strokeLinecap="round"
      />
      <path
        d="M30.223.926c2.85 3.722 6.842 13.717 0 23.926"
        stroke="#F8F8FA"
        strokeWidth={stroke}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ViralityScoreMedium
