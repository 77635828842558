import React from 'react'
import moment from "moment"
import { DateRange } from "../../../../../../../app/interfaces/overview"
import { Interval } from "../../../interface"

export function generateLiveTimeRangeLabel(timestamp: string, dateRange: DateRange) {
  const date = moment(timestamp).format("MMM D, YYYY")

  switch (dateRange) {
    case 0:
      return (
        <>
          <p>{date}</p>
          <p>{`${moment(timestamp).format("HH:mm")}-${moment(timestamp).add(1, 'hours').format("HH:mm")}`}</p>
        </>
      )
    case 1:
      return <p>{date}</p>
    case 2:
      return <p>{date}</p>
  }
}

export function generateAdHocTimeRangeLabel(timestamp: string, interval: Interval | null) {
  const date = moment(timestamp).format("MMM D, YYYY")

  if (!interval) return null

  switch (interval) {
    case "hour":
      return (
        <>
          <p>{date}</p>
          <p>{`${moment(timestamp).format("HH:mm")}-${moment(timestamp).add(1, 'hours').format("HH:mm")}`}</p>
        </>
      )
    case "day":
      return <p>{date}</p>
    case "month":
      return <p>{moment(timestamp).format("MMMM YYYY")}</p>
    case "year":
      return <p>{moment(timestamp).format("YYYY")}</p>
  }
}

export function deduplicateThreads(topThreads: {
  site: string
  threadLink: string
  threadTitle: string
}[]) {
  if (!topThreads || !topThreads.length) return topThreads
  if (topThreads.length === 1 || topThreads[0].threadTitle !== topThreads[1].threadTitle) return topThreads
  return [topThreads[0]]
}