import * as React from "react";

function ThumbsUp(props:any) {
  return (
    <svg
      width={20}
      height={19}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 6h-5.612l1.123-3.367c.202-.608.1-1.282-.275-1.802A2.009 2.009 0 0011.612 0H10c-.297 0-.578.132-.769.36L4.531 6H2C.897 6 0 6.897 0 8v9c0 1.103.897 2 2 2h13.307a2.01 2.01 0 001.873-1.298l2.757-7.351A1 1 0 0020 10V8c0-1.103-.897-2-2-2zM2 8h2v9H2V8zm16 1.819L15.307 17H6V7.362L10.468 2h1.146l-1.562 4.683A.999.999 0 0011 8h7v1.819z"
        fill="#000"
      />
    </svg>
  );
}

export default ThumbsUp
