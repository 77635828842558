import styled from 'styled-components'
import BaseButton from "../../../../../reusable/buttons/BaseButton"


export const Logo = styled.img`
  height: 20px;
  margin-right: 0.5rem;
`

export const AuthorTitle = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Channel = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const GreyText = styled.p`
  color: #bdbdbd
`

export const PostCount = styled.p`

`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`

export const PaginationButton = styled(BaseButton) <{ $disabled: boolean }>`
   cursor: pointer;
   color: ${props => props.$disabled ? "#dbdbdb" : "#4a4a4a"}
`