import styled from "styled-components"

export const SelectRoot = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: fit-content;

	> span {
		color: #bdbdbd;
	}
`

export const SelectHead = styled.header<{ width: number }>`
	color: #516380;
	font-weight: 500;
	background-color: #ffffff;
	padding: 0rem 0.5rem;
	width: ${(props) => props.width}px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;

	i {
		color: #898989;
	}
`

export const DropdownMenu = styled.div<{ width: number }>`
	max-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow: auto;
	padding: 0.5rem 0;
	width: ${(props) => props.width}px;
	position: absolute;
	top: 24px;
	right: 0px;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
		0 0 0 1px rgba(10, 10, 10, 0.02);
	z-index: 11;
`

export const DropdownMenuContent = styled.ul`
	overflow: auto;
	width: 100%;
`

export const SingleSelectItem = styled.li`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	column-gap: 0.5rem;
	color: #424c5a;
	font-size: 14px;
	padding: 0.4rem 0.8rem;
	height: 35px;
	line-height: 1;
	cursor: pointer;
	&:hover {
		background-color: #f5f5f5;
	}
`
