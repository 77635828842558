import React from 'react'
import { IconProp } from "./interface"

function ThreeDotsIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      height={height} 
      width={width}
    >
      <defs>
        <style>{`.Option_svg__cls-1{fill:${fill}}`}</style>
      </defs>
      <g id="Option_svg__updated">
        <path
          className="Option_svg__cls-1"
          d="M70.87.29a14.18 14.18 0 11-5.42 27.27 14.2 14.2 0 01-7.68-7.67A14.18 14.18 0 0170.87.29zM70.87 56.65a14.2 14.2 0 11-10 4.16 14.21 14.21 0 0110-4.16zM70.87 113.06A14.15 14.15 0 0185 127.2a14 14 0 01-1 5.43 14.16 14.16 0 01-13.11 8.77 14.17 14.17 0 01-10-24.19 14.09 14.09 0 0110-4.15z"
        />
      </g>
    </svg>
  )
}

export default ThreeDotsIcon
