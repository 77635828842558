import styled from 'styled-components'
import IconButton from "../../../../../reusable/buttons/IconButton"

export const StyledIconButton = styled(IconButton)`
  &:hover {
    background-color: white;
  }
`

export const ActionMenu = styled.div`
  position: absolute;
  right: 0px;
  z-index: 999;
  background-color: white;
  padding: 0.5rem 0;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
  border-radius: 4px;
`

export const MoreActionsRoot = styled.div`
  position: relative;
`

export const MenuList = styled.ul`
  width: 230px;
`

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0.4rem 1rem;
  height: 35px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`