import React, { useState } from "react";
import IntervalSwitch from "../../../components/IntervalSwitch";
import { IntervalContext } from "../../../context/IntervalContext";
import { GadgetBody, GadgetHeader, GadgetRoot } from "../../../styled";
import ChartTypeSwitch from "./ChartTypeSwitch";
import useInterval from "./hook/useInterval";
import PercentageChart from "./percentage/PercentageChart";
import { LeftContainer } from "./styled";
import VolumeChart from "./volume/VolumeChart";
import {
  selectCompareFeedTaskInfo,
  selectFeedTaskInfo,
} from "../../../../../../../app/store/feedSlice";
import { useSelector } from "react-redux";

export type ChartType = "percentage" | "volume";

function SentimentTimeline() {
  const [chartType, setChartType] = useState<ChartType>("percentage");

  const intervalObject = useInterval();
  const compareTask = useSelector(selectCompareFeedTaskInfo);
  const task = useSelector(selectFeedTaskInfo);

  return (
    <IntervalContext.Provider value={intervalObject}>
      <GadgetRoot>
        <GadgetHeader>
          <LeftContainer>
            <h3>Sentiment Timeline</h3>
            <IntervalSwitch />
          </LeftContainer>
          <ChartTypeSwitch type={chartType} setType={setChartType} />
        </GadgetHeader>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1 1 " }}>
            <div
              style={{
                marginLeft: "40px",
                marginTop: "10px",
                marginBottom: "10px",
                color: "#29d1d7",
                fontWeight: "bold",
              }}
            >
              {task?.task_name}
            </div>
            <GadgetBody>
              {chartType === "percentage" ? (
                <PercentageChart />
              ) : (
                <VolumeChart />
              )}
            </GadgetBody>
          </div>

          <div style={{ flex: "1 1 " }}>
            <div
              style={{
                marginLeft: "40px",
                marginTop: "10px",
                marginBottom: "10px",
                color: "#ffd45d",
                fontWeight: "bold",
              }}
            >
              {compareTask?.task_name}
            </div>
            <GadgetBody>
              {chartType === "percentage" ? (
                <PercentageChart isCompare />
              ) : (
                <VolumeChart isCompare />
              )}
            </GadgetBody>
          </div>
        </div>
      </GadgetRoot>
    </IntervalContext.Provider>
  );
}

export default SentimentTimeline;
