import React, { useEffect } from 'react'
import styled from 'styled-components'
import TabSelector from "./TabSelector"
import History from "./history"
import {
  selectShowHistoryTab,
  fetchValidationData,
  fetchTrainingData,
  fetchTrainingHistory,
  selectCurrent
} from "../../../../../app/store/smartTrainSlice"
import { useDispatch, useSelector } from "react-redux"
import ModelTrainer from "./modelTrainer"

export type SmartTrainContentTab = "relevancy" | "sentiment"

const ContentRoot = styled.div`
  width: 100%;
  height: 570px;
`

const HorizontalContainer = styled.div`
  width: 100%;
  display: flex;
`

function SmartTrainContent() {
  const dispatch = useDispatch()

  const showHistoryTab = useSelector(selectShowHistoryTab)
  const current = useSelector(selectCurrent)

  useEffect(() => {

    if (current.shouldCreateModel) {
      if (current.trainingData === null) {
        dispatch(fetchTrainingData())
        dispatch(fetchTrainingHistory())
      }
    } else {
      if (current.validationData === null) {
        dispatch(fetchValidationData())
      }
    }

  }, [current.shouldCreateModel, current.trainingData, current.validationData, dispatch])

  return (
    <ContentRoot>
      <TabSelector />
      <HorizontalContainer>
        <ModelTrainer />
        {showHistoryTab && <History />}
      </HorizontalContainer>
    </ContentRoot>
  )
}

export default SmartTrainContent
