import React from "react"
// import Button from "../../../reusable/buttons/Button"
// import AddIcon from "../../../reusable/icons/AddIcon"
import styles from "./button.module.scss"
import TaskEditor from "../../task/editor"

const CreateFeedButton = () => {
    const [showEditor, setShowEditor] = React.useState(false)

    return (
        <div>
            <div className="field has-addons">
                <p className="control">
                    {/* <Button icon={<AddIcon fill="#FFFFFF" />} onClick={() => setShowEditor(true)}>
                        <p>New Feed</p>
                    </Button> */}
                    <button className="button" onClick={() => setShowEditor(true)} id={styles.button}>
                        <span className="icon is-small">
                            <i className="fas fa-plus"></i>
                        </span>
                        <span>New Feed</span>
                    </button>
                </p>
            </div>
            {showEditor && <TaskEditor type={0} onClose={() => setShowEditor(false)} />}
        </div>
    )
}

export default CreateFeedButton