import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from "react-redux"
import { selectUserInfo, selectUserName } from "../../../app/store/userSlice"
import BaseButton from "../../reusable/buttons/BaseButton"
import PlanUsageIcon from "../../reusable/icons/PlanUsageIcon"
import LogoutIcon from "../../reusable/icons/LogoutIcon"
import { Link } from "react-router-dom"

const AccountRoot = styled.div`
  position: relative;
`

const Button = styled(BaseButton)`
  height: 35px;
  width: 35px;
  color: white;
  background-color: #FFC44E;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 500;
`

const DropdownMenu = styled.div`
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 0 0.5rem 0;
  min-width: 10rem;
  position: absolute;
  right: -11rem;
  /* top: -8rem; */
  top: -6rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
  0 0 0 1px rgba(10, 10, 10, 0.02);
  z-index: 11;
`

const MenuHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid #F8F8FA;
  padding: 0rem 1rem 0.3rem 1rem;
  p:first-child {
    font-weight: 400;
    color: #283044;
    line-height: 16px;
  }
  p:nth-child(2) {
    font-size: 14px;
    color: #92A5C3;
  }
`

const MenuList = styled.ul`
  width: 100%;

  li {
    color: #516380;
    display: flex;
    align-items: center;
    height: 32px;
    cursor: pointer;
    padding: 1rem;
    font-size: 14px;
    &:hover {
      background-color: #F8FAF8;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
`

function AccountButton() {
  const userName = useSelector(selectUserName)
  const userInfo = useSelector(selectUserInfo)

  const [showDropdown, setShowDropdown] = useState(false)

  const toggleMenu = () => {
    if (!showDropdown) {
      document.addEventListener('click', closeMenu)
      setShowDropdown(true)
    }
  }

  const closeMenu = () => {
    setShowDropdown(false)
    document.removeEventListener('click', closeMenu)
  }

  return (
    <AccountRoot>
      <Button onClick={toggleMenu}>{userName ? userName[0] : ""}</Button>
      {
        showDropdown &&
        <DropdownMenu>
          <MenuHeader>
            <p>Your Account</p>
            <p>{userInfo.display_name}</p>
          </MenuHeader>
          <MenuList>
            {/* <Link to={`/account/profile`}>
              <li>
                <UserIcon fill="#516380" height="14px" width="14px" />
                <p>Profile</p>
              </li>
            </Link> */}
            <Link to={`/account/usage`}>
              <li>
                <PlanUsageIcon fill="#516380" height="14px" width="14px" />
                <p>Plan Usage</p>
              </li>
            </Link>
            <Link to={`/logout`}>
              <li>
                <LogoutIcon fill="#516380" height="14px" width="14px" />
                <p>Logout</p>
              </li>
            </Link>

          </MenuList>
        </DropdownMenu>
      }
    </AccountRoot>
  )
}

export default AccountButton
