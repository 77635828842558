import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SelectAuthors,
  SelectCompareAuthors,
  clearAuthorData,
  fetchTopAuthors,
  refetchCompareTopAuthorsCommentsData,
  refetchTopAuthorsCommentsData,
  selectDateRange,
  showAuthorData,
} from "../../../../../../app/store/overviewSlice";
import {
  DateRange,
  TopAuthors,
} from "../../../../../../app/interfaces/overview";
import styles from "./authors.module.scss";
import { siteicon } from "../../../../../../app/tools/display";
import EmptyStatePlaceholder from "../../components/EmptyStatePlaceholder";
import AuthorsSkeleton from "./Skeleton";
import {
  GadgetBody,
  GadgetHeader,
  GadgetRoot,
  RowGridContainer,
} from "../../styled";
import {
  AuthorTitle,
  Channel,
  GreyText,
  Logo,
  PaginationButton,
  PaginationContainer,
  PostCount,
} from "./styled";
import PostCountComponent from "./postCountComponent";
import {
  selectCompareFeedTaskInfo,
  selectFeedTaskInfo,
  selectFeedType,
  selectTaskId,
  selectTaskUnixEnd,
  selectTaskUnixStart,
} from "../../../../../../app/store/feedSlice";

export type SortOption = "threads" | "comments" | "threads and comments";

const Authors = ({ setIsAuthorLoaded }: any) => {
  const dispatch = useDispatch();
  const topAuthors: TopAuthors | null = useSelector(SelectAuthors);
  const topCompareAuthors: TopAuthors | null =
    useSelector(SelectCompareAuthors);

  const compareTask = useSelector(selectCompareFeedTaskInfo);
  const task = useSelector(selectFeedTaskInfo);

  const dateRange = useSelector(selectDateRange);
  const [dropdownExpanded, setDropdownExpanded] = React.useState(false);

  const [sortOption, setSortOption] = React.useState<SortOption>("threads");
  const sortOptions: SortOption[] = [
    "threads",
    "comments",
    "threads and comments",
  ];

  const sortedAuthors = useMemo(() => {
    if (topAuthors) {
      switch (sortOption) {
        case "comments":
          return topAuthors.byComments;
        case "threads":
          return topAuthors.byThreads;
        default:
          return topAuthors.byTotal;
      }
    }
    return [];
  }, [sortOption, topAuthors]);

  const sortedCompareAuthors = useMemo(() => {
    if (topCompareAuthors) {
      switch (sortOption) {
        case "comments":
          return topCompareAuthors.byComments;
        case "threads":
          return topCompareAuthors.byThreads;
        default:
          return topCompareAuthors.byTotal;
      }
    }
    return [];
  }, [sortOption, topCompareAuthors]);

  const pageShow = 5;
  const [page, setPage] = React.useState(0);
  const pageRange0 = page * pageShow;
  const pageRange1 = page * pageShow + pageShow;
  const pageAuthors = sortedAuthors.slice(pageRange0, pageRange1);

  const [comparePage, setComparePage] = React.useState(0);
  const comparePageRange0 = comparePage * pageShow;
  const comparePageRange1 = comparePage * pageShow + pageShow;
  const comparePageAuthors = sortedCompareAuthors.slice(
    comparePageRange0,
    comparePageRange1
  );

  const onClickAuthor = React.useCallback(
    (authorid: string) => {
      dispatch(showAuthorData(authorid));
    },
    [dispatch]
  );

  const handleClickExpand = () => {
    if (!dropdownExpanded) {
      document.addEventListener("click", collapseDropdown);
    }
    setDropdownExpanded(true);
  };

  const collapseDropdown = () => {
    setDropdownExpanded(false);
    document.removeEventListener("click", collapseDropdown);
  };

  const taskId = useSelector(selectTaskId);
  const taskType = useSelector(selectFeedType);
  const taskUnixStart = useSelector(selectTaskUnixStart);
  const taskUnixEnd = useSelector(selectTaskUnixEnd);

  useEffect(() => {
    if (taskId !== 0) {
      if (taskType === 0) {
        dispatch(
          refetchTopAuthorsCommentsData({
            type: sortOption,
          })
        );
        dispatch(
          refetchCompareTopAuthorsCommentsData({
            type: sortOption,
          })
        );
      }

      if (taskType === 1 && taskUnixStart && taskUnixEnd) {
        dispatch(
          refetchTopAuthorsCommentsData({
            start: taskUnixStart,
            end: taskUnixEnd,
            type: sortOption,
          })
        );
        dispatch(
          refetchCompareTopAuthorsCommentsData({
            start: taskUnixStart,
            end: taskUnixEnd,
            type: sortOption,
          })
        );
      }
    }
    return () => {
      dispatch(clearAuthorData());
    };
  }, [sortOption]);

  useEffect(() => {
    if (topAuthors && topCompareAuthors) {
      setTimeout(() => {
        setIsAuthorLoaded(true);
      }, 500)
     
    }
  }, [topAuthors, topCompareAuthors]);

  // useEffect(() => {
  //     if(sortOption){
  //         dispatch(fetchTopAuthors(null))
  //     }

  // }, [sortOption])

  return (
    <GadgetRoot>
      <GadgetHeader>
        <h3>Top Authors</h3>
        <div>
          <span>View by:</span>
          <div
            className={
              "dropdown is-right" + (dropdownExpanded ? " is-active" : "")
            }
            id={styles.dropdownContainer}
          >
            <div
              className="dropdown-trigger"
              onClick={handleClickExpand}
              id={styles.dropdownTriggerContainer}
            >
              <button
                className="button"
                aria-haspopup="true"
                aria-controls="taskMenu"
                id={styles.dropdownButton}
              >
                <span className={styles.chosen}>{sortOption}</span>
                <span className="icon is-small">
                  {dropdownExpanded ? (
                    <i className="fas fa-angle-up" aria-hidden="true" />
                  ) : (
                    <i className="fas fa-angle-down" aria-hidden="true" />
                  )}
                </span>
              </button>
            </div>
            <div className="dropdown-menu" id="taskMenu" role="menu">
              <div className="dropdown-content">
                {sortOptions.map((sortOption) => {
                  return (
                    <div
                      key={sortOption}
                      className={"dropdown-item " + styles.dropdownOption}
                      onClick={() => {
                        setSortOption(sortOption);
                        setPage(0);
                      }}
                    >
                      No. of {sortOption}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </GadgetHeader>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1 1", marginRight: "10px" }}>
          <div
            style={{
              marginLeft: "40px",
              marginTop: "10px",
              marginBottom: "10px",
              color: "#29d1d7",
              fontWeight: "bold",
            }}
          >
            {task?.task_name}
          </div>
          <GadgetBody>
            <RowGridContainer>
              <div>
                <GreyText>Author</GreyText>
              </div>
              <div>
                <GreyText>Channel</GreyText>
              </div>
              <div>
                <GreyText>Post</GreyText>
              </div>
            </RowGridContainer>

            <div>
              {topAuthors ? (
                pageAuthors.length ? (
                  pageAuthors.map((author) => (
                    <RowGridContainer
                      $isClickable
                      key={author.id}
                      onClick={() => onClickAuthor(author.id)}
                    >
                      <div>
                        <Logo
                          src={siteicon(author.site, "News")}
                          alt={`${author.site} logo`}
                        />
                        <AuthorTitle> {author.name}</AuthorTitle>
                      </div>
                      <div>
                        <Channel>{author.channel}</Channel>
                      </div>
                      <div>
                        <PostCount>{author.postCount}</PostCount>
                      </div>
                    </RowGridContainer>
                  ))
                ) : (
                  <EmptyStatePlaceholder />
                )
              ) : (
                <AuthorsSkeleton />
              )}
            </div>

            <PaginationContainer>
              <PaginationButton
                $disabled={page === 0}
                onClick={() => page > 0 && setPage(page - 1)}
              >
                <i className="fas fa-chevron-left"></i>
              </PaginationButton>
              <p className={styles.txt}>
                Showing {pageRange0 + 1}-{pageRange1} of {sortedAuthors.length}{" "}
                authors
              </p>
              <PaginationButton
                $disabled={pageRange1 >= sortedAuthors.length}
                onClick={() =>
                  pageRange1 < sortedAuthors.length && setPage(page + 1)
                }
              >
                <i className="fas fa-chevron-right"></i>
              </PaginationButton>
            </PaginationContainer>
          </GadgetBody>
        </div>
        <div style={{ flex: "1 1", marginLeft: "10px" }}>
          <div
            style={{
              marginLeft: "40px",
              marginTop: "10px",
              marginBottom: "10px",
              color: "#ffd45d",
              fontWeight: "bold",
            }}
          >
            {compareTask?.task_name}
          </div>
          <GadgetBody>
            <RowGridContainer>
              <div>
                <GreyText>Author</GreyText>
              </div>
              <div>
                <GreyText>Channel</GreyText>
              </div>
              <div>
                <GreyText>Post</GreyText>
              </div>
            </RowGridContainer>

            <div>
              {topCompareAuthors ? (
                comparePageAuthors.length ? (
                  comparePageAuthors.map((author) => (
                    <RowGridContainer
                      $isClickable
                      key={author.id}
                      onClick={() => onClickAuthor(author.id)}
                    >
                      <div>
                        <Logo
                          src={siteicon(author.site, "News")}
                          alt={`${author.site} logo`}
                        />
                        <AuthorTitle> {author.name}</AuthorTitle>
                      </div>
                      <div>
                        <Channel>{author.channel}</Channel>
                      </div>
                      <div>
                        <PostCount>{author.postCount}</PostCount>
                      </div>
                    </RowGridContainer>
                  ))
                ) : (
                  <EmptyStatePlaceholder />
                )
              ) : (
                <AuthorsSkeleton />
              )}
            </div>

            <PaginationContainer>
              <PaginationButton
                $disabled={comparePage === 0}
                onClick={() =>
                  comparePage > 0 && setComparePage(comparePage - 1)
                }
              >
                <i className="fas fa-chevron-left"></i>
              </PaginationButton>
              <p className={styles.txt}>
                Showing {comparePageRange0 + 1}-{comparePageRange1} of{" "}
                {sortedCompareAuthors.length} authors
              </p>
              <PaginationButton
                $disabled={comparePageRange1 >= sortedCompareAuthors.length}
                onClick={() =>
                  comparePageRange1 < sortedCompareAuthors.length &&
                  setComparePage(comparePage + 1)
                }
              >
                <i className="fas fa-chevron-right"></i>
              </PaginationButton>
            </PaginationContainer>
          </GadgetBody>
        </div>
      </div>
    </GadgetRoot>
  );
};

export default Authors;
