import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { FeedData } from "../../../../../../../app/interfaces/feed"
import { addExportFeed, removeExportFeed, selectExportFeed, selectIsSelecting, setIsSelecting } from "../../../../../../../app/store/livefeedpost"
import IconButton from "../../../../../../reusable/buttons/IconButton"
import SelectIcon from "../../../../../../reusable/icons/SelectIcon"

interface SelectButtonProp {
  data: FeedData
}

function SelectButton({ data }: SelectButtonProp) {
  const dispatch = useDispatch()
  const isSelecting = useSelector(selectIsSelecting)
  const exportFeeds = useSelector(selectExportFeed)

  const handleClick = () => {
    if (!isSelecting) {
      dispatch(setIsSelecting(true))
    }

    if (!exportFeeds.every(feed => feed.hash !== data.hash)) {
      dispatch(removeExportFeed(data))
    } else {
      dispatch(addExportFeed(data))
    }
  }


  return (
    <IconButton onClick={handleClick}>
      <SelectIcon />
    </IconButton>
  )
}

export default SelectButton
