import React from 'react'
import styled from 'styled-components'
import { uuidv4 } from "usefuljs"
import Skeleton from '../../utils/Skeleton'

export const RowGridContainer = styled.div`
  display: grid;
  width: 100%;
  padding: 12px 1rem 12px 1.5rem;
  grid-template-columns:10rem 5rem 10fr 1fr;
  column-gap: 1rem;
`

function SiteItemSkeleton() {
  return (
    <RowGridContainer>
      <Skeleton height="1rem" />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </RowGridContainer>
  )
}

function SitesSkeleton() {
  return (
    <>
      {Array.from(Array(7)).map(_ => <SiteItemSkeleton key={uuidv4()} />)}
    </>
  )
}

export default SitesSkeleton
