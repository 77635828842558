import React from 'react'
import styled from 'styled-components'

const PlaceholderContainer = styled.div`
  height: 100%;
  width: 100%;
  /* min-height: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  >i {
    font-size: 1.5rem;
    color: white;
    background-color: #dbdbdb;
    padding: 0.5rem;
    border-radius: 50%;
    margin-bottom: 0.7rem;
  }
`

const MainText = styled.p`
  color: #516380;
  font-weight: 500;
  font-size: 1.2rem;
`

const SubText = styled.p`
  color: #828282;
  font-weight: 400;
  font-size: 1rem;
`

function EmptyStatePlaceholder() {
  return (
    <PlaceholderContainer>
      <i className="fas fa-chart-line"/>
      <MainText>Nothing to show here at the moment</MainText>
      <SubText>Try a longer data period of data or give it some time to reload</SubText>
    </PlaceholderContainer>
  )
}

export default EmptyStatePlaceholder
