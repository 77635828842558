import styled from 'styled-components'

const DropdownMenu = styled.div`
  width: 270px;
  display: flex;
  position: absolute;
  top: 2rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background-color: white;
  padding: 0.5rem 0;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.02);
  z-index: 1;
`

export default DropdownMenu