import React from 'react'
import { IconProp } from "./interface"

function PlanUsageIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
      height={height} 
      width={width}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.578 11.75c.281 0 .422.14.422.422v1.406c0 .281-.14.422-.422.422H.422C.14 14 0 13.86 0 13.578V.922C0 .64.14.5.422.5h1.406c.281 0 .422.14.422.422V11.75h15.328zm-4.465-7.875c.094-.094.2-.117.317-.07.14.023.234.093.28.21l3.165 6.61h-13.5V6.969L6.434 1.87a.345.345 0 01.316-.176c.164-.023.293.024.387.14l2.988 4.009 2.988-1.969z"
        fill={fill}
      />
    </svg>
  )
}

export default PlanUsageIcon
