import React from "react"
import useDropdown from "../../../../../../hooks/useDropdown"
import MehIcon from "../../../../../../reusable/icons/MehIcon"
import Picker from "./Picker"
import { NoSentimentWrapper, SentimentLabel, SentimentRoot } from "./styled"

interface SentimentProp {
    sentiment: number | null
    hash: string
}

const getLabelText = (sentiment: number | null) => {
    switch (sentiment) {
        case 1:
            return "Positive"
        case 0:
            return "Neutral"
        case -1:
            return "Negative"
        default:
            return ""
    }
}

const NoSentiment = () => {
    return (
        <NoSentimentWrapper>
            <MehIcon height="24px" width="24px" fill="#DADADA" />
            <p>Analyzing Sentiment ...</p>
        </NoSentimentWrapper>
    )
}

const Sentiment = ({ sentiment, hash }: SentimentProp) => {
    const { showDropdownMenu, toggleMenu } = useDropdown("sentiment-picker")

    return (
        <SentimentRoot onClick={() => toggleMenu()}>
            {
                sentiment === null ?
                    <NoSentiment /> : (
                        <SentimentLabel score={sentiment}>
                            {getLabelText(sentiment)}
                        </SentimentLabel>
                    )
            }
            {
                showDropdownMenu && <Picker hash={hash} />
            }
        </SentimentRoot>
    )
}

export default Sentiment