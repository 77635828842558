import React, { memo } from 'react'

interface ScrollToTopProp {
  children: JSX.Element
  element: HTMLElement
  yAxis?: number
  threshold?: number
  behavior?: "auto" | "smooth"
  top?: number
  left?: number
}

const ScrollTo = memo<ScrollToTopProp>(({
  children,
  element,
  yAxis,
  threshold,
  behavior = "auto",
  top = 0,
  left = 0
}) => {

  const scroll = () => {
    if (threshold && yAxis) {
      if (yAxis > threshold) {
        element.scroll({ top, left, behavior })
      }
      return
    }

    element.scroll({ top, left, behavior })
  }

  return (
    <div onClick={scroll}>
      {children}
    </div>
  )
})

export default ScrollTo
