import React from 'react'
import { SiteForTopThreads } from '../../../interface'
import SiteOption from './SiteOption'
import { AllSitesOption, PickerRoot } from './styled'

interface SitesPickerProp {
  sites: SiteForTopThreads[]
  setSites: (sites: SiteForTopThreads[]) => void
}

function SitesPicker({ sites, setSites }: SitesPickerProp) {

  const selectAll = () => {
    setSites(sites.map(site => ({ ...site, isActive: true })))
  }

  const selectSingle = (name: string) => {
    setSites(sites.map(site => site.name === name ? (
      { ...site, isActive: !site.isActive }
    ) : site
    ))
  }

  return (
    <PickerRoot>
      <AllSitesOption isActive={false} onClick={selectAll}>
        All
      </AllSitesOption>
      {sites.map((site,i) => (
        <div
          key={`${site.name}${i}`}
          onClick={() => selectSingle(site.name)}
        >
          <SiteOption
            name={site.name}
            count={site.count}
            isActive={site.isActive}
          />
        </div>
      ))}
    </PickerRoot>
  )
}

export default SitesPicker