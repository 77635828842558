import React from 'react'
import { shorttxt } from '../../../../../app/tools/string'
import useDropdown from "../../../../hooks/useDropdown"
import {
  DropdownMenu,
  DropdownMenuContent,
  SelectHead,
  SelectRoot,
  SingleSelectItem,
} from "../styled"

interface SelectProp {
  id: string
  options: { label: string, value: string | number }[]
  value: string | number
  setValue: (value: string | number) => void
  width: number
  label?: string
}

function SingleSelect({
  id,
  options,
  value,
  setValue,
  width,
  label
}: SelectProp) {
  const { showDropdownMenu, toggleMenu } = useDropdown(id, false)

  return (
    <SelectRoot onClick={toggleMenu}>
      <span>{label}</span>
      <SelectHead width={width}>
        <span>{(typeof value === 'string' && (value.length > 16 && shorttxt(value,14))) || value}</span>
        <i className={`fas fa-angle-${showDropdownMenu ? "up" : "down"}`} aria-hidden="true" />
      </SelectHead>
      {
        showDropdownMenu && (
          <DropdownMenu width={width}>
            <DropdownMenuContent id={id}>
              {
                options.map(option => (
                  <SingleSelectItem onClick={() => setValue(option.value)} key={option.value} >
                    <span>{option.label}</span>
                  </SingleSelectItem>
                ))
              }
            </DropdownMenuContent>
          </DropdownMenu>)
      }
    </SelectRoot>
  )
}

export default SingleSelect