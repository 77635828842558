import styled from 'styled-components'

export const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

//SummaryCardsRow
//TODO media query - flex wrap
export const SummaryCardsRowContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content:space-evenly;

`

export const SummaryCardContainer = styled.div`
  width: 25%;
  margin: 1rem 0.5rem;
`

//ChartRow
export const ChartContainer = styled.div`
  width: 100%;
  margin: 0 1rem;
`
export const ChartTitle = styled.h3`
  font-weight: 600;
  margin: 1rem;
  color: rgba(40, 48, 68, 0.97);
`