import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { loadState, saveState } from "./sessionStorage"
import counterReducer from '../../features/counter/counterSlice'
import userReducer from "./userSlice"
import feedReducer from "./feedSlice"
import miscReducer from "./miscSlice"
import livefeedReducer from "./livefeedpost"
import overviewReducer from "./overviewSlice"
import smartTrainReducer from "./smartTrainSlice"


const preloadedState = loadState("user")
export const store = configureStore({
    preloadedState,
    reducer: {
        counter: counterReducer,
        user: userReducer,
        feed: feedReducer,
        misc: miscReducer,
        livefeed: livefeedReducer,
        overview: overviewReducer,
        smartTrain: smartTrainReducer
    },
    devTools: true
})

store.subscribe(() => {
    saveState({
        user: store.getState().user
    }, "user")
})


export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
export type AppDispatch = typeof store.dispatch
