import React from 'react'
import styled, { css } from 'styled-components'

interface ProgressProp {
  value: number
  height?: number
  backgroundColor?: string
  loadingColor?: string
  finishedColor?: string
  hasHover?: boolean
  hoverColor?: string
  disabled?: boolean
  hideLabel?: boolean
}

interface ProgressRootProp {
  height: number
  backgroundColor: string
}

interface ProgressBarProp {
  value: number
  height: number
  loadingColor: string
  finishedColor: string
  hasHover?: boolean
  hoverColor?: string
}

const ProgressRoot = styled.div<ProgressRootProp>`
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: ${props => props.height ? `${props.height / 2}px` : "8px"};
  height: ${props => props.height ? `${props.height}px` : "16px"};
  background-color: ${props => props.backgroundColor ?? "#E8E8E8"};
`

const ProgressBar = styled.div<ProgressBarProp>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: ${props => props.height ? `${props.height / 2}px` : "8px"};
  transform-origin: left;
  transition: transform .4s linear;
  transform: ${props => props.value !== undefined ? `translateX(-${100 - props.value * 100}%)` : "translateX(-1)"};
  background-color: ${props => props.value === 1 ? props.finishedColor : props.loadingColor};
  display: flex;
  justify-content: flex-end;

  ${props => props.hasHover && css`
    &:hover {
      background-color: ${props.hoverColor ?? "#29D1D7"};
    }
  `}

  p {
    color: white;
    font-size: 14px;
    font-weight: 500;
    margin-right: 1rem;
  }
`

function Progress({
  height = 16,
  value,
  backgroundColor = "#E8E8E8",
  loadingColor = "#92A5C3",
  finishedColor = "#29D1D7",
  hasHover = false,
  hoverColor,
  disabled = false,
  hideLabel = false
}: ProgressProp) {
  return (
    <ProgressRoot
      height={height}
      backgroundColor={backgroundColor}

    >
      {
        disabled ? null : (
          <ProgressBar
            value={value}
            height={height}
            loadingColor={loadingColor}
            finishedColor={finishedColor}
            hasHover={hasHover}
            hoverColor={hoverColor}
          >
            {!hideLabel && <p>{Math.round(value * 100)}%</p>}
          </ProgressBar>
        )}

    </ProgressRoot>
  )
}

export default Progress
