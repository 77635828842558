import React from 'react'
import styled from 'styled-components'
import Skeleton from "../../../../../../../reusable/utils/Skeleton"

const TimelineSkeletonRoot = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px 30px 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

const SkeletonContainer = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  border-bottom: 1px solid #D3D2E0;
  border-left: 1px solid #D3D2E0;
`

const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
`

function PercentageSkeleton() {
  return (
    <TimelineSkeletonRoot>
      <SkeletonContainer>
        <Skeleton height="80%" width="16px" />
        <Skeleton height="50%" width="16px" />
        <Skeleton height="60%" width="16px" />
        <Skeleton height="30%" width="16px" />
        <Skeleton height="50%" width="16px" />
        <Skeleton height="60%" width="16px" />
        <Skeleton height="40%" width="16px" />
        <Skeleton height="20%" width="16px" />
      </SkeletonContainer>
      <LegendContainer>
        <Skeleton height="16px" width="40px" margin="0px 10px" />
        <Skeleton height="16px" width="40px" margin="0px 10px" />
        <Skeleton height="16px" width="40px" margin="0px 10px" />
      </LegendContainer>
    </TimelineSkeletonRoot>

  )
}

export default PercentageSkeleton