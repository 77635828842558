import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOverviewData,
  clearOverviewData,
  fetchCompareOverviewData,
} from "../../../../app/store/overviewSlice";
import Head from "./head";
import Stats from "./gadgets/stats";
import LineGraph from "./gadgets/linegraph";
import Authors from "./gadgets/authors";
import TopThreads from "./gadgets/topthreads";
import TopSites from "./gadgets/sites";
import OVdata from "./ovdata";
import WordCloud from "./gadgets/wordcloud";
import Trend from "./gadgets/trend";
// import EmptyOverviewData from "./EmptyOverviewData"
import {
  selectFeedType,
  selectTaskId,
  selectTaskUnixEnd,
  selectTaskUnixStart,
} from "../../../../app/store/feedSlice";
import {
  HeaderContainer,
  HorizontalFlexContainer,
  LineGraphContainer,
  OverviewRoot,
  StatsContainer,
  VerticalLeftContainer,
  VerticalRightContainer,
} from "./styled";
import SentimentOverview from "./gadgets/sentiment/overview";
import SentimentTimeline from "./gadgets/sentiment/timeline";
import Button from "../../../reusable/buttons/Button";

import html2canvas from "html2canvas";

const Compare = () => {
  const dispatch = useDispatch();

  const taskId = useSelector(selectTaskId);
  const taskType = useSelector(selectFeedType);
  const taskUnixStart = useSelector(selectTaskUnixStart);
  const taskUnixEnd = useSelector(selectTaskUnixEnd);
  const [isAuthorLoaded, setIsAuthorLoaded] = useState(false);

  console.log("taskType", taskType, taskUnixStart);

  useEffect(() => {
    if (taskId !== 0) {
      if (taskType === 0) {
        dispatch(fetchOverviewData(null));
        dispatch(fetchCompareOverviewData(null));
      }

      if (taskType === 1 && taskUnixStart && taskUnixEnd) {
        dispatch(
          fetchOverviewData({
            start: taskUnixStart,
            end: taskUnixEnd,
          })
        );
        dispatch(
          fetchCompareOverviewData({
            start: taskUnixStart,
            end: taskUnixEnd,
          })
        );
      }
    }

    return () => {
      dispatch(clearOverviewData());
    };
  }, [dispatch, taskId, taskType, taskUnixEnd, taskUnixStart]);

  return (
    <OverviewRoot>
      <div
        id="capture1"
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
          columnGap: "1rem",
        }}
      >
        <HeaderContainer>
          <Head isAuthorLoaded={isAuthorLoaded} />
        </HeaderContainer>
        <StatsContainer>
          <VerticalLeftContainer>
            {" "}
            <Stats isCompare={false} color="#29d1d7" />
          </VerticalLeftContainer>

          <VerticalLeftContainer>
            {" "}
            <Stats isCompare={true} color="#ffd45d" />
          </VerticalLeftContainer>
        </StatsContainer>
        <LineGraphContainer>
          <LineGraph />
        </LineGraphContainer>

        <LineGraphContainer>
          <SentimentOverview />
        </LineGraphContainer>
        <LineGraphContainer>
          {" "}
          <SentimentTimeline />
        </LineGraphContainer>

        <LineGraphContainer>
          {" "}
          <WordCloud />
        </LineGraphContainer>

        <LineGraphContainer>
          {" "}
          <TopThreads isExpanded={taskType === 1} />
        </LineGraphContainer>

        <LineGraphContainer>
          {" "}
          <Authors setIsAuthorLoaded={setIsAuthorLoaded} />
        </LineGraphContainer>

        <LineGraphContainer>
          {" "}
          <TopSites />
        </LineGraphContainer>

        <OVdata />
      </div>
    </OverviewRoot>
  );
};

export default Compare;
