import styled from 'styled-components'

const DropdownItem = styled.div`
  color:  #232830;
  padding: 0.375rem 1rem;
  width: 100%;
  cursor: pointer;
  &:hover {
      background-color: #f5f5f5;
      transition: 0.1s;
  }
`

export default DropdownItem