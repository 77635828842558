import moment from "moment"
import {
	Interval,
	IntervalStep,
} from "../../components/feed/task/overview/interface"
import { DateRangeFilter } from "../interfaces/misc"
import { DateRange } from "../interfaces/overview"

export const convertDateRangeToUnit = (dateRange: DateRange) => {
	switch (dateRange) {
		case DateRange.Today:
			return "day"
		case DateRange.Week:
			return "week"
		case DateRange.Month:
			return "month"
		default:
			return ""
	}
}

export const convertDateRangeToText = (dateRange: DateRange) => {
	switch (dateRange) {
		case DateRange.Today:
			return "today"
		case DateRange.Week:
			return "this week"
		case DateRange.Month:
			return "this month"
		default:
			return ""
	}
}

export const convertDateRangeToText2 = (dateRange: DateRange) => {
	switch (dateRange) {
		case DateRange.Today:
			return "last 24 hours"
		case DateRange.Week:
			return "last 7 days"
		case DateRange.Month:
			return "last 30 days"
		default:
			return ""
	}
}

export const generateTimeRange = (timestamp: string, dateRange: DateRange) => {
	const from = new Date(timestamp).getTime()
	let interval: number
	switch (dateRange) {
		case DateRange.Today:
			interval = 1000 * 60 * 60
			break
		case DateRange.Week:
		case DateRange.Month:
			interval = 1000 * 60 * 60 * 24
	}

	const to = from + interval
	return [from, to]
}

export const generateTimeRangeUnix = (dateRange: DateRange) => {
	const to = Date.now()
	let interval: number
	switch (dateRange) {
		case DateRange.Today:
			interval = 1000 * 60 * 60 * 24
			break
		case DateRange.Week:
			interval = 1000 * 60 * 60 * 24 * 7
			break
		case DateRange.Month:
			interval = 1000 * 60 * 60 * 24 * 30
	}

	const from = to - interval
	return [from, to]
}

export const calculateDefaultDateRangeCode = (range: DateRangeFilter) => {
	const unixDifference = range.end - range.start
	const unixOneDay = 1000 * 60 * 60 * 24
	if (unixDifference < unixOneDay) {
		return 0
	} else if (unixDifference < unixOneDay * 3) {
		return 0
	} else if (unixDifference < unixOneDay * 60) {
		return 1
	} else if (unixDifference < unixOneDay * 730) {
		return 1
	} else {
		return 2
	}
}

export const generateDateRangeInterval = (
	timestamp: string,
	interval: Interval
) => {
	const from = moment(timestamp)
	let to: moment.Moment

	switch (interval) {
		case "hour":
			to = moment(timestamp).add(1, "hour")
			break
		case "day":
			to = moment(timestamp).add(1, "day")
			break
		case "month":
			to = moment(timestamp).add(1, "month")
			break
		case "year":
			to = moment(timestamp).add(1, "year")
			break
	}

	return [from.unix() * 1000, to.unix() * 1000]
}

export const generateStartOfRangeFromIntervalUnixTimestamp = (
	timestamp: number,
	range: "hour" | "day" | "month" | "year"
) => {
	return moment(new Date(timestamp)).startOf(range).valueOf()
}

export const generateEndOfRangeFromIntervalUnixTimestamp = (
	timestamp: number,
	range: "hour" | "day" | "month" | "year"
) => {
	return moment(new Date(timestamp)).endOf(range).valueOf()
}

export const convertIntervalStepAndIntervalToDateRange = (
	intervalStep: IntervalStep,
	interval: Interval
) => {
	switch (intervalStep) {
		case "hourOnly":
			return "hour"
		case "hourDay":
			switch (interval) {
				case "hour":
					return "hour"
				case "day":
					return "day"
				default:
					return "hour"
			}
		case "dayOnly":
			return "day"
		case "dayMonth":
			switch (interval) {
				case "day":
					return "day"
				case "month":
					return "month"
				default:
					return "day"
			}
		case "monthYear":
			switch (interval) {
				case "month":
					return "month"
				case "year":
					return "year"
				default:
					return "month"
			}
		default:
			return "hour"
	}
}

export const generateStartOfRangeFromDateRangeUnixTimestamp = (
	timestamp: number,
	dateRange: 0 | 1 | 2
) => {
	switch (dateRange) {
		case 0: //interval: hour
			return moment(new Date(timestamp)).startOf("hour").valueOf()
		case 1: //interval: day
		case 2:
			return moment(new Date(timestamp)).startOf("day").valueOf()
		default:
			//use day
			return moment(new Date(timestamp)).startOf("day").valueOf()
	}
}

export const generateEndOfRangeFromDateRangeUnixTimestamp = (
	timestamp: number,
	dateRange: 0 | 1 | 2
) => {
	switch (dateRange) {
		case 0: //interval: hour
			return moment(new Date(timestamp)).endOf("hour").valueOf()
		case 1: //interval: day
		case 2:
			return moment(new Date(timestamp)).endOf("day").valueOf()
		default:
			//use day
			return moment(new Date(timestamp)).endOf("day").valueOf()
	}
}
