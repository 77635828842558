import React, {memo,useState} from "react"
import TaskEditor from "../../../task/editor"
import styles from "./index.module.scss"

const EditFeedSettingButton = memo(() => {
    const [showEditor, setShowEditor] = useState(false)

    return (
        <>
            <span className="icon is-small" onClick={() => setShowEditor(true)}>
                <i className={"fas fa-cog " + styles.icon}></i>
            </span>

            {showEditor && (
                <TaskEditor
                    type={1}
                    onClose={() => setShowEditor(false)}
                />
            )}
        </>
    )
}) 

export default EditFeedSettingButton
