import React from 'react'
// import SingleSelect from "../../../reusable/inputs/select/singleSelect"
import { calculateSentiment } from '../../home/Cards/CardBody/ExpandedCard/Statistics/ChartRow/helperFn'
import { usePostsStore } from '../../postStore'
import { SectionBody, SectionHeader, SentimentChartRoot } from "../styled"
import Chart from "./SentimentChart"

function SentimentChartSection() {
  const {overviewPost} = usePostsStore(state => ({
    overviewPost : state.overviewPost,
  }))

  // const [option, setOption] = useState("Threads")
  const sentimentData = (overviewPost && calculateSentiment(overviewPost.ai_results.sentiment)) || []
  // const sentimentData = (overviewPost && calculateSentiment(overviewPost.ai_results.sentiment, option)) || []

  // const viewLabels = [{ label: "Threads", value: "Threads" }, { label: "Posts", value: "Posts" }]
  
  return (
    <SentimentChartRoot>
      <SectionHeader>
        <h3>Sentiment</h3>
        <div>
          {/* <SingleSelect
            id="post-count-chart-view-by"
            options={viewLabels}
            value={option}
            setValue={(value) => setOption(value as string)}
            width={110}
            label="View by:"
          /> */}
        </div>
      </SectionHeader>
      <SectionBody>
        <Chart data={sentimentData} />
      </SectionBody>
    </SentimentChartRoot>
  )
}

export default React.memo(SentimentChartSection)
