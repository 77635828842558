import React from "react"
import {
    EditorState,
    EditorType,
    EditorView,
    EditorDetail,
    PreviewState,
} from "../interface"

const init_type: EditorType | null = null
const init_view: EditorView = EditorView.Type
const init_detail: EditorDetail = {
    type: null, 
    name: null, 
    indus_id: null, 
    keywords: null,
    lang: "", 
    mediums: [], 
    unix_start: 0, 
    unix_end: 0, 
    frequency: null, 
}

const init_preview: PreviewState = {
    data: null,
    fetching: false,
}

export const init_task_id: number | null = null

export const initState: EditorState = {
    task_id: init_task_id,
    type: init_type,
    view: init_view,
    detail: init_detail,
    preview: init_preview,
    submitting: false,
}

export type Thunk = (dispatch: React.Dispatch<any>, state: EditorState) => void
export const dispatcher = (
    dispatch: React.Dispatch<any>,
    state: EditorState
) => (input: Thunk | any) =>
    input instanceof Function ? input(dispatch, state) : dispatch(input)

export interface Context {
    editor: EditorState // state
    setEditor: React.Dispatch<Thunk | any> //dispatch
}

export default React.createContext({})
