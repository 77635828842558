import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOverviewData,
  clearOverviewData,
  selectStats,
} from "../../../../app/store/overviewSlice";
import Head from "./head";
import Stats from "./gadgets/stats";
import LineGraph from "./gadgets/linegraph";
import Authors from "./gadgets/authors";
import TopThreads from "./gadgets/topthreads";
import TopSites from "./gadgets/sites";
import OVdata from "./ovdata";
import WordCloud from "./gadgets/wordcloud";
import Trend from "./gadgets/trend";
// import EmptyOverviewData from "./EmptyOverviewData"
import {
  selectFeedType,
  selectTaskId,
  selectTaskUnixEnd,
  selectTaskUnixStart,
} from "../../../../app/store/feedSlice";
import {
  HeaderContainer,
  HorizontalFlexContainer,
  LineGraphContainer,
  OverviewRoot,
  StatsContainer,
  VerticalLeftContainer,
  VerticalRightContainer,
} from "./styled";
import SentimentOverview from "./gadgets/sentiment/overview";
import SentimentTimeline from "./gadgets/sentiment/timeline";
import Button from "../../../reusable/buttons/Button";
import EmptyPostData from "../posts/EmptyPostData";
import Loader from "../../../reusable/loader";

const Overview = () => {
  const ref = createRef();
  const dispatch = useDispatch();

  const taskId = useSelector(selectTaskId);
  const taskType = useSelector(selectFeedType);
  const taskUnixStart = useSelector(selectTaskUnixStart);
  const taskUnixEnd = useSelector(selectTaskUnixEnd);
  const stats = useSelector(selectStats);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorLoaded, setIsAuthorLoaded] = useState(false);
  useEffect(() => {
    if (taskId !== 0) {
      if (taskType === 0) {
        dispatch(fetchOverviewData(null));
      }

      if (taskType === 1 && taskUnixStart && taskUnixEnd) {
        dispatch(
          fetchOverviewData({
            start: taskUnixStart,
            end: taskUnixEnd,
          })
        );
      }
    }

    return () => {
      dispatch(clearOverviewData());
    };
  }, [dispatch, taskId, taskType, taskUnixEnd, taskUnixStart]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <OverviewRoot>
          {stats?.postCount?.total === 0 && <EmptyPostData />}
          {(stats?.postCount?.total || !stats?.postCount) && (
              <div
              id="capture"
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
                columnGap: "1rem",
              }}
            >
              <HeaderContainer>
              <Head isAuthorLoaded={isAuthorLoaded} />
              </HeaderContainer>
              <StatsContainer>
                <Stats />
              </StatsContainer>
              <LineGraphContainer>
                <LineGraph />
              </LineGraphContainer>

              <HorizontalFlexContainer>
                <VerticalLeftContainer>
                  <SentimentOverview />
                </VerticalLeftContainer>
                <VerticalRightContainer>
                  <SentimentTimeline />
                </VerticalRightContainer>
              </HorizontalFlexContainer>

              <HorizontalFlexContainer>
                <VerticalLeftContainer>
                  {taskType !== 1 && <Trend />}
                  <WordCloud />
                  <Authors setIsAuthorLoaded={setIsAuthorLoaded} />
                  {taskType === 1 && <TopSites />}
                </VerticalLeftContainer>
                <VerticalRightContainer>
                  <TopThreads isExpanded={taskType === 1} />
                  {taskType !== 1 && <TopSites />}
                </VerticalRightContainer>
              </HorizontalFlexContainer>
            </div>
          )}

          <OVdata />
        </OverviewRoot>
      )}
    </>
  );
};

export default Overview;
