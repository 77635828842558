import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux"
import { useSpring, animated } from 'react-spring'
import { ModalContent } from "../styled"
import {
  selectShowHistoryTab,
  selectShowModelSelector,
  selectShowSmartTrainManager,
  setShowSmartTrainManager
} from "../../../../../app/store/smartTrainSlice"
import ModalHeader from "../../../../reusable/utils/Modal/ModalHeader"
import ModalMain from "../../../../reusable/utils/Modal/ModalMain"
import ModalRoot from "../../../../reusable/utils/Modal/ModalRoot"
import SmartTrainContent from "./SmartTrainContent"
import ModelSelector from "./modelSelector"
import ReactTooltip from "react-tooltip"

const Icon = styled.i`
  font-size: 20px;
  color: #BDBDBD;
`

function SmartTrainManager() {
  const dispatch = useDispatch()
  const showModal = useSelector(selectShowSmartTrainManager)
  const showHistoryTab = useSelector(selectShowHistoryTab)
  const showModelSelector = useSelector(selectShowModelSelector)

  const props = useSpring({ opacity: showModal ? 1 : 0, from: { opacity: 0 }, config: { tension: 350 } })

  const handleClose = () => {
    dispatch(setShowSmartTrainManager(false))
  }

  const getModalWidth = () => {
    if (showModelSelector || showHistoryTab) {
      return 1040
    }

    return 720
  }

  return (
    <div>
      {showModal && (
        <animated.div style={props}>
          <ModalRoot show={true}>
            <ModalMain width={getModalWidth()} height={670}>
              <ModalHeader
                title={showModelSelector ? "Customized AI Tagging" : "Confirm Posts"}
                onClose={handleClose}
                iconRight={
                  showModelSelector ? (
                    <>
                      <ReactTooltip />
                      <Icon
                        className="fas fa-info-circle"
                        data-tip={"<p>Customize your detection models for automatic<br/>tagging on relevancy and sentiment. Model<br/>accuracy based heavily on data labeled from set<br/>up and smart train. We recommend resetting<br/>your model(s) when: <br/><br/>1) Your definition has changed, or <br/>2) Your labeled data is heavily polluted, or<br/>3) You have recently made a change in keywords<br/>query</p>"}
                        data-place="bottom"
                        data-effect="solid"
                        data-html={true}
                      />
                    </>
                  ) : undefined
                }
              />
              <ModalContent>
                {
                  showModelSelector ? <ModelSelector /> : <SmartTrainContent />
                }
              </ModalContent>
            </ModalMain>
          </ModalRoot>
        </animated.div>
      )}
    </div>
  )
}

export default SmartTrainManager
