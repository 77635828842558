import React from "react"
import { useLocation } from "react-router-dom"
import styles from "./vnav.module.scss"
import styled from "styled-components"
import AccountButton from "./AccountButton"

const LogoContainer = styled.div`
    width: 100%;
    padding: 0rem 0.5em;
    transition: all 0.2s ease;
    cursor: pointer;
        
    >img {
        width: 100%;
        border-radius: 5px;
    }

    >p {
        color: #92A5C3;
        font-size: 0.7rem;
        text-align: center;
        font-weight: 500;
    }
`

const Vnav = () => {
    const location = useLocation()
    const page = location.pathname.split("/")[1]

    return (
        <div id={styles.vnav}>
            <div>
                <LogoContainer onClick={() => window.location.href = "/"}>
                    <img src="https://d18dyb0v4b4cx9.cloudfront.net/lenx_logo_white.webp" alt="logo" />
                </LogoContainer>
                <span data-tooltip="Feed" className={"has-tooltip-arrow has-tooltip-right " + styles.sub_items + (page === "feed" ? " active" : "")} onClick={() => window.location.href = "/feed"}>
                    <i className="fas fa-rss" />
                </span>
                <span data-tooltip="Trend" className={"has-tooltip-arrow has-tooltip-right " + styles.sub_items + (page === "trend" ? " active" : "")} onClick={() => window.location.href = "/trend?f=Latest"}>
                    <i className="fas fa-chart-line" />
                </span>
            </div>
            <span className={"has-tooltip-arrow has-tooltip-right " + styles.sub_items + (page === "account" ? " active" : "")}>
                <AccountButton />
            </span>
        </div>
    )
}
export default Vnav