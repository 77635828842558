import styled from 'styled-components'

export const MainContent = styled.div`
  padding: 16px;
`

export const ExportInfoLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #898989;

  i {
    color: #BDBDBD;
    margin-right: 0.5rem;
  }
`