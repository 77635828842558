import React from 'react'
import { useSelector } from "react-redux"
import styled from 'styled-components'
import { selectCurrent, selectSmartTrainMode } from "../../../../../../app/store/smartTrainSlice"
import Progress from "../../../../../reusable/utils/ProgressBar"
import { calculateConfirmed, calculateProgress } from "./calculateProgress"

const ProgressRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 250px;
  row-gap: 4px;
`

const ProgressContainer = styled.div`
  width: 200px;
`

const Label = styled.div`
  color: #7B7B7B;
  font-size: 14px;
`

const SubLabel = styled.div`
  color: #7B7B7B;
  font-size: 12px;
`

function TrainingProgress() {
  const mode = useSelector(selectSmartTrainMode)
  const current = useSelector(selectCurrent)
  const progress = calculateProgress(current.tagHistory, mode)
  const confirmed = calculateConfirmed(current.tagHistory, mode)

  return (
    <ProgressRoot>
      {
        current.shouldCreateModel ? (
          <>
            <Label>{Math.round(progress * 100)}% completed</Label>
            <ProgressContainer>
              <Progress
                value={progress}
                height={8}
                loadingColor="#BDBDBD"
                finishedColor="#BDBDBD"
                backgroundColor="#F2F5FA"
                hideLabel
              />
            </ProgressContainer>
          </>
        ) : (
          <>
            <Label>Total confirmed: {confirmed + (current.counts?.validating ?? 0)}</Label>
            <ProgressContainer>
              <Progress
                value={progress}
                height={8}
                loadingColor="#BDBDBD"
                finishedColor="#BDBDBD"
                backgroundColor="#F2F5FA"
                hideLabel
              />
            </ProgressContainer>
            <SubLabel>Suggested minimum for each category: 50</SubLabel>
          </>
        )
      }

    </ProgressRoot >
  )
}

export default TrainingProgress
