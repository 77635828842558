import React from 'react'
import styled from 'styled-components'
import { getHighestChance, mapTrendForecastText } from '../../../../helperFn/trendForecastText'
import { TrendPeriod } from '../../../../interface'

const TrendForecast = ({trendForecast}: TrendForecastProps) => {
  
  const forecastResults = mapTrendForecastText(trendForecast)

  const highestTrendForecastPeriod = getHighestChance(trendForecast)
  const trendForecastPeriodInWeeks = highestTrendForecastPeriod === '2' ? '2 weeks':(highestTrendForecastPeriod=== '1'? '1 week' : (highestTrendForecastPeriod === '0' ? 'less than 1 week': undefined))
  const tooltipText = trendForecast && highestTrendForecastPeriod && trendForecastPeriodInWeeks !== undefined ? `${Math.round((trendForecast[highestTrendForecastPeriod as keyof TrendPeriod])*100)}% chance this trend will last for ${trendForecastPeriodInWeeks}`: 'Trend Forecast is not available for this topic.'

  return (
    <div
      data-tip={tooltipText}
      data-place="top"
      data-effect="solid"
      data-html={true}
    >
      <CentredParagraph lightText={forecastResults === 'Not Available'}>
        <span>{forecastResults.split('\n')[0]}</span><br />
        <span>{forecastResults.split('\n')[1]}</span>
      </CentredParagraph>
    </div>
  )
}

export default TrendForecast

interface TrendForecastProps {
  trendForecast: TrendPeriod
}

const CentredParagraph = styled.p<{lightText:boolean}>`
  text-align: center;
  color: ${props=> props.lightText? '#bdbdbd' : '#516380'};
  
  >span:first-child{
    font-weight: 600;
  }
  >span:last-child{
    font-size: 0.8rem;
  }
`
