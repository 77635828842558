import React from "react";
import { StatType } from ".";
import {
  DateRange,
  StatCount,
} from "../../../../../../app/interfaces/overview";
import { convertDateRangeToText2 } from "../../../../../../app/tools/dateRangeConverter";
import { percentageChange } from "../../../../../../app/tools/number";
import { prettynum } from "../../../../../../app/tools/string";
import {
  generateAdhocLabel,
  generateStatTooltip,
  generateTypeCountLabel,
} from "./labelGenerators";
import SummaryCard from "../../../../../reusable/cards/summaryCard/SummaryCard";
import SummaryCardSkeleton from "../../../../../reusable/cards/summaryCard/SummaryCardSkeleton";
import ReactTooltip from "react-tooltip";
import { DateRangeFilter } from "../../../../../../app/interfaces/misc";

interface StatCardProp {
  count: StatCount | undefined;
  icon: JSX.Element;
  dateRange: DateRange;
  type: StatType;
  isAdHoc: boolean;
  customDateRange: DateRangeFilter | null;
  color?: string;
}

function StatCard({
  count,
  icon,
  dateRange,
  type,
  isAdHoc,
  customDateRange,
  color,
}: StatCardProp) {
  if (count) {
    const percentChanged = Math.abs(
      Math.ceil(percentageChange(count.total, count.previous))
    );
    const isIncreased = count.total > count.previous;

    const tooltipText = generateStatTooltip(
      type,
      count,
      isIncreased,
      dateRange,
      percentChanged,
      isAdHoc
    );
    console.log(
      "Date Range",
      dateRange,
      DateRange.Today,
      dateRange === DateRange.Week
    );

    return (
      <>
        <ReactTooltip html={true} />
        <SummaryCard
          icon={icon}
          title={prettynum(count.total)}
          percentage={count.previous === 0 ? 99999 : percentChanged}
          subtitle={
            isAdHoc
              ? generateAdhocLabel(type)
              : `${generateTypeCountLabel(type, dateRange, customDateRange)}`
          }
          tooltip={tooltipText}
        />
      </>
    );
  } else {
    return (
      <>
        <SummaryCardSkeleton icon={icon} />
      </>
    );
  }
}

export default StatCard;
