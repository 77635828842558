import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import styled from 'styled-components'
import { clearIrrelevant, fetchIrrelevant, selectIrrelevant } from "../../../../../../app/store/smartTrainSlice"
import Banner from "./Banner"
import { IrrelevantPostsManagerTab, Order, OrderBy } from "./interface"
import PostList from "./PostList"
import Searcher from "./Searcher"
import { getComparator, stableSort } from "./sorting"
import SortingBar from "./SortingBar"
import { ManagerRoot } from "./styled"
import TabSelector from "./TabSelector"

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

function Manager() {

  const dispatch = useDispatch()

  const irrelevantPosts = useSelector(selectIrrelevant)

  const [tab, setTab] = useState<IrrelevantPostsManagerTab>("all")
  const [searchText, setSearchText] = useState("")
  const [order, setOrder] = useState<Order>("desc")
  const [orderBy, setOrderBy] = useState<OrderBy>("spam_at")

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const displayPosts = irrelevantPosts ? (
    irrelevantPosts.filter(post => (
      post.post_message?.toLowerCase().includes(searchText.toLowerCase()) ||
      post.thread_title?.toLowerCase().includes(searchText.toLowerCase()) ||
      post.spam_by?.toLowerCase().includes(searchText.toLowerCase())
    ))
  ) : []

  useEffect(() => {
    dispatch(fetchIrrelevant(tab))
    return () => {
      dispatch(clearIrrelevant())
    }
  }, [dispatch, tab])

  return (
    <ManagerRoot>
      <Banner />
      <ActionsContainer>
        <TabSelector tab={tab} setTab={setTab} />
        <Searcher searchText={searchText} handleSearchInput={handleSearchInput} />
      </ActionsContainer>
      <div>
        <SortingBar setOrderBy={setOrderBy} setOrder={setOrder} order={order} orderBy={orderBy} />
        {displayPosts && <PostList feeds={stableSort(displayPosts, getComparator(order, orderBy))} />}
      </div>
    </ManagerRoot>
  )
}

export default Manager
