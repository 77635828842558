import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux"
import { selectTaskName } from "../../../../../../app/store/feedSlice"
import BaseButton from "../../../../../reusable/buttons/BaseButton"
import CrossIcon from "../../../../../reusable/icons/CrossIcon"
import SkipIcon from "../../../../../reusable/icons/SkipIcon"
import TickIcon from "../../../../../reusable/icons/TickIcon"
import { selectIsLoading, setValidation, selectSmartTrainMode, tagTrainingItem, selectCurrent } from "../../../../../../app/store/smartTrainSlice"
import PositiveFaceIcon from "../../../../../reusable/icons/PositiveFaceIcon"
import NeutralFaceIcon from "../../../../../reusable/icons/NeutralFaceIcon"
import NegativeFaceIcon from "../../../../../reusable/icons/NegativeFaceIcon"
import ReactTooltip from "react-tooltip"

const ClassifierRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
`

const Label = styled.p`
  font-size: 20px;
  color: #7B7B7B;
`

const ButtonPanel = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 60%;
`

const Button = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  box-shadow: 0px 2px 6px rgba(211, 210, 224, 0.6);
  &:hover {
    background-color: #F7F7F9;
  }
`

function Classifier() {
  const dispatch = useDispatch()
  const taskName = useSelector(selectTaskName)
  const mode = useSelector(selectSmartTrainMode)
  const current = useSelector(selectCurrent)
  const isLoading = useSelector(selectIsLoading)


  const tagCurrentPost = (tag: number) => {

    if (!isLoading) {
      // spam 1, ignore -10, not spam 0
      // positive 1, neutral 0, negative -1, skip -10
      if (current.shouldCreateModel) {
        dispatch(tagTrainingItem(tag))
      } else {
        dispatch(setValidation(tag))
      }
    }
  }

  return (
    <ClassifierRoot>
      <ReactTooltip place="bottom" effect="solid" />
      <Label>
        {
          mode === "relevancy" ? (
            `Is this post relevant to "${taskName}"?`
          ) : (
            `What is the sentiment of this post?`
          )
        }
      </Label>
      {mode === "relevancy" ? (
        <ButtonPanel>
          <Button onClick={() => tagCurrentPost(0)} data-tip="Relevant">
            <TickIcon fill="#29D1D7" height="2rem" width="2rem" />
          </Button>
          <Button onClick={() => tagCurrentPost(-10)} data-tip="Skip">
            <SkipIcon fill="#000000" height="1.8rem" width="1.8rem" />
          </Button>
          <Button onClick={() => tagCurrentPost(1)} data-tip="Irrelevant">
            <CrossIcon fill="#FF6E86" height="1.8rem" width="1.8rem" variant="fat" />
          </Button>
        </ButtonPanel>
      ) : (
        <ButtonPanel>
          <Button onClick={() => tagCurrentPost(1)} data-tip="Positive">
            <PositiveFaceIcon height="2rem" width="2rem" />
          </Button>
          <Button onClick={() => tagCurrentPost(0)} data-tip="Neutral">
            <NeutralFaceIcon height="2rem" width="2rem" />
          </Button>
          <Button onClick={() => tagCurrentPost(-1)} data-tip="Negative">
            <NegativeFaceIcon height="2rem" width="2rem" />
          </Button>
          <Button onClick={() => tagCurrentPost(-10)} data-tip="Skip">
            <SkipIcon fill="#000000" height="1.8rem" width="1.8rem" />
          </Button>
        </ButtonPanel>
      )}

    </ClassifierRoot>
  )
}

export default Classifier
