import * as React from "react"
import { IconProp } from "./interface"

const ViralityScoreHigh = ({
  width = "1rem",
  fill = "#29D1D7",
  stroke = 1.5,
}: IconProp) => {
  return (
    <svg
      viewBox="0 0 35 25"
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.148 7.748l-7.528 5.646 7.22 5.416a10.755 10.755 0 01-9.076 4.976C4.819 23.786 0 18.967 0 13.022 0 7.079 4.82 2.26 10.764 2.26a10.76 10.76 0 019.384 5.489z"
        fill={fill}
      />
      <path
        d="M22.667 8.556c1.47 1.469 3.526 5.415 0 9.444M26.444 4.777c1.9 2.547 4.562 9.386 0 16.37M30.223 1c2.85 3.722 6.842 13.717 0 23.926"
        stroke={fill}
        strokeWidth={stroke}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ViralityScoreHigh
