import { FeedData } from "../interfaces/feed";

export const tasklive_dedupe = (data:FeedData[]) =>{
    return data.reduce<FeedData[]>((accumulator, currentFeed)=>{
        if (accumulator.every(item=>item.hash !== currentFeed.hash)) {
            return [...accumulator, currentFeed]
        } else {
            return accumulator
        }
    }, [])
}


// helper functions
export const date_conv = (x: number): number => {
    switch (x) {
        case -1:
            return Date.now();
        case -2:
            const now = new Date();
            now.setDate(now.getDate() - 1);
            return now.getTime();
        case -3: {
            const now = new Date();
            now.setDate(now.getDate() - 7);
            return now.getTime();
        }
        case -4: {
            const now = new Date();
            now.setDate(now.getDate() - 31);
            return now.getTime();
        }
        default:
            return x;
    }
}

export const formatDate = (date: Date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const timeFromNow = (date: Date) => {
    const nowUnix = Date.now()
    const targetUnix = date.getTime()
    const difference = nowUnix - targetUnix

    if (difference < 1000*60) {
        return "less than a minute"
    }

    if (difference < 1000*60*60) {
        const minute = Math.ceil(difference/(1000*60))
        return `${minute} minute${minute > 1 && "s"} ago`
    }

    if (difference < 1000*60*60*24) {
        const hour = Math.ceil(difference/(1000*60*60))
        return `${hour} hour${hour > 1 && "s"} ago`
    }

    if (difference < 1000*60*60*24*30) {
        const day = Math.ceil(difference/(1000*60*60*24))
        return `${day} day${day > 1 && "s"} ago`
    }

    if (difference < 1000*60*60*24*365) {
        const month = Math.ceil(difference/(1000*60*60*24*30))
        return `${month} month${month > 1 && "s"} ago`
    }

    const year = Math.ceil(difference/(1000*60*60*24*365))
    return `${year} year${year > 1 && "s"} ago`
}


export const convertIsComment = (isComment: number) => {
    switch (isComment) {
        case 0:
            return null
        case 1:
            return true
        case 2:
            return false
        default:
            return null
    }
}