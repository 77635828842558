import React from 'react'
import { IconProp } from "./interface"

function SentimentGraphicIcon({
  height = "1rem",
  width = "1rem",
  fill = "#283044"
}: IconProp) {
  return (
    <svg
    height={height} 
    width={width}
      viewBox="0 0 143 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#Sentiment_graphic_svg__clip0)">
        <path
          d="M66.267 122.063c31.627 0 57.267-25.758 57.267-57.532C123.534 32.758 97.894 7 66.267 7 34.639 7 9 32.758 9 64.531c0 31.774 25.64 57.532 57.267 57.532z"
          fill="#E8E9F0"
        />
        <g filter="url(#Sentiment_graphic_svg__filter0_d)">
          <path
            d="M72.102 95.064H18.425c-1.895 0-3.425-1.755-3.425-3.94V21.94C15 19.788 16.53 18 18.425 18h41.572l15.497 16.684v56.44c.034 2.185-1.496 3.94-3.392 3.94z"
            fill="#fff"
          />
        </g>
        <g filter="url(#Sentiment_graphic_svg__filter1_d)">
          <path
            d="M59.664 18.032v13.672c0 1.655 1.164 3.012 2.627 3.012h13.27L59.664 18.032z"
            fill="#fff"
          />
        </g>
        <path
          d="M11.738 23.813h27.16c3.02 0 5.624 2.239 5.913 5.214a5.748 5.748 0 01-4.981 6.239v.032c.064.16.707 1.663.835 1.92 0 0 0 .031-.032.031l-3.278-1.887H11.899c-3.15 0-5.818-2.432-5.946-5.567-.097-3.295 2.539-5.983 5.785-5.983z"
          fill="#D9DBDD"
        />
        <path
          d="M22.184 30.851h17.71c.61 0 1.093-.48 1.093-1.087v-.672c0-.608-.483-1.088-1.093-1.088h-17.71c-.61 0-1.093.48-1.093 1.088v.672c-.032.575.482 1.087 1.093 1.087z"
          fill="#fff"
        />
        <path
          d="M28.773 41.727h27.16c3.02 0 5.624 2.24 5.913 5.215a5.748 5.748 0 01-4.981 6.239v.032c.064.16.707 1.663.835 1.92 0 0 0 .031-.032.031l-3.278-1.887H28.934c-3.15 0-5.818-2.432-5.946-5.567-.096-3.295 2.54-5.983 5.785-5.983z"
          fill="#AFB7BF"
        />
        <path
          d="M39.22 48.766h17.709c.61 0 1.093-.48 1.093-1.087v-.672c0-.608-.482-1.088-1.093-1.088h-17.71c-.61 0-1.093.48-1.093 1.088v.672c-.032.575.483 1.087 1.093 1.087z"
          fill="#fff"
        />
        <path
          d="M15.596 61.563h27.159c3.021 0 5.624 2.24 5.914 5.215a5.748 5.748 0 01-4.982 6.239v.032c.064.16.707 1.663.836 1.92 0 0 0 .031-.033.031l-3.278-1.887H15.756c-3.15 0-5.817-2.432-5.946-5.567-.096-3.295 2.54-5.983 5.786-5.983z"
          fill="#9EA6AD"
        />
        <path
          d="M26.041 68.602h17.71c.61 0 1.093-.48 1.093-1.088v-.671c0-.608-.482-1.088-1.093-1.088h-17.71c-.61 0-1.092.48-1.092 1.088v.671c-.032.576.482 1.088 1.092 1.088z"
          fill="#fff"
        />
        <g filter="url(#Sentiment_graphic_svg__filter2_d)">
          <path
            d="M96.591 53h35.62c3.963 0 7.377 2.787 7.757 6.49.379 3.942-2.614 7.287-6.534 7.765v.04c.084.199.927 2.07 1.096 2.388 0 0 0 .04-.042.04l-4.3-2.349H96.802c-4.131 0-7.63-3.026-7.799-6.928-.126-4.101 3.33-7.446 7.588-7.446z"
            fill="#fff"
          />
        </g>
        <path
          d="M110.291 61.76h23.226c.801 0 1.434-.597 1.434-1.353v-.836c0-.757-.633-1.354-1.434-1.354h-23.226c-.801 0-1.434.597-1.434 1.354v.836c-.042.717.633 1.354 1.434 1.354z"
          fill="#44D86C"
        />
        <g filter="url(#Sentiment_graphic_svg__filter3_d)">
          <path
            d="M96.591 73.683h35.62c3.963 0 7.377 2.787 7.757 6.49.379 3.942-2.614 7.287-6.534 7.764v.04c.084.2.927 2.07 1.096 2.39 0 0 0 .039-.042.039l-4.3-2.35H96.802c-4.131 0-7.63-3.025-7.799-6.927-.126-4.102 3.33-7.446 7.588-7.446z"
            fill="#fff"
          />
        </g>
        <path
          d="M110.291 82.443h23.226c.801 0 1.434-.597 1.434-1.354v-.836c0-.756-.633-1.354-1.434-1.354h-23.226c-.801 0-1.434.598-1.434 1.354v.836c-.042.717.633 1.354 1.434 1.354z"
          fill="#C4C4C4"
        />
        <g filter="url(#Sentiment_graphic_svg__filter4_d)">
          <path
            d="M96.591 93.276h35.62c3.963 0 7.377 2.788 7.757 6.49.379 3.943-2.614 7.287-6.534 7.765v.04c.084.199.927 2.07 1.096 2.389 0 0 0 .04-.042.04l-4.3-2.35H96.802c-4.131 0-7.63-3.026-7.799-6.928-.126-4.101 3.33-7.446 7.588-7.446z"
            fill="#fff"
          />
        </g>
        <path
          d="M110.291 102.037h23.226c.801 0 1.434-.597 1.434-1.354v-.836c0-.757-.633-1.354-1.434-1.354h-23.226c-.801 0-1.434.597-1.434 1.354v.836c-.042.717.633 1.354 1.434 1.354z"
          fill="red"
        />
        <g filter="url(#Sentiment_graphic_svg__filter5_d)">
          <ellipse
            cx={97.455}
            cy={81.463}
            rx={4.484}
            ry={4.463}
            fill="#FFD366"
          />
          <path
            d="M101.689 81.463c0 2.325-1.894 4.213-4.234 4.213-2.339 0-4.233-1.888-4.233-4.213 0-2.326 1.894-4.213 4.233-4.213 2.34 0 4.234 1.887 4.234 4.213z"
            stroke="#FFCC4D"
            strokeWidth={0.5}
          />
        </g>
        <ellipse cx={95.921} cy={80.641} rx={0.59} ry={0.587} fill="#516380" />
        <path
          d="M99.343 80.64a.589.589 0 01-.59.588.589.589 0 01-.59-.587c0-.324.264-.587.59-.587.326 0 .59.263.59.587z"
          fill="#516380"
        />
        <g filter="url(#Sentiment_graphic_svg__filter6_d)">
          <ellipse
            cx={97.455}
            cy={100.463}
            rx={4.484}
            ry={4.463}
            fill="#FFB966"
          />
          <path
            d="M101.689 100.463c0 2.325-1.894 4.213-4.234 4.213-2.339 0-4.233-1.888-4.233-4.213 0-2.326 1.894-4.213 4.233-4.213 2.34 0 4.234 1.887 4.234 4.213z"
            stroke="#ED9C52"
            strokeOpacity={0.5}
            strokeWidth={0.5}
          />
        </g>
        <path
          d="M99.58 102.342c-.119-.256-.927-.94-2.126-.94-1.198 0-2.006.684-2.122.94"
          stroke="#516380"
          strokeWidth={0.6}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx={95.921} cy={99.641} rx={0.59} ry={0.587} fill="#516380" />
        <path
          d="M99.343 99.64a.589.589 0 01-.59.588.588.588 0 01-.59-.587c0-.324.264-.587.59-.587.326 0 .59.263.59.587z"
          fill="#516380"
        />
        <g filter="url(#Sentiment_graphic_svg__filter7_d)">
          <ellipse
            cx={97.527}
            cy={59.658}
            rx={4.484}
            ry={4.463}
            fill="#FFD366"
          />
          <path
            d="M101.76 59.658c0 2.326-1.894 4.213-4.234 4.213-2.339 0-4.233-1.887-4.233-4.213 0-2.325 1.894-4.213 4.233-4.213 2.34 0 4.234 1.888 4.234 4.213z"
            stroke="#FFCC4D"
            strokeWidth={0.5}
          />
        </g>
        <path
          d="M95.403 60.715c.118.352.926 1.292 2.124 1.292s2.006-.94 2.122-1.292"
          stroke="#516380"
          strokeWidth={0.6}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx={95.992} cy={58.836} rx={0.59} ry={0.587} fill="#516380" />
        <path
          d="M99.414 58.836a.589.589 0 01-.59.587.589.589 0 01-.59-.587c0-.324.265-.587.59-.587.326 0 .59.263.59.587z"
          fill="#516380"
        />
        <path
          d="M75.5 77H80a3 3 0 003-3V60a3 3 0 013-3h2.5M73 83h7a3 3 0 013 3v7a3 3 0 003 3h2M78.5 79.5h11"
          stroke="#92A5C3"
          strokeWidth={0.7}
          strokeDasharray="2 2"
        />
        <path
          d="M79.002 75.662h-2.45c-.15-.53-.376-1.023-.602-1.515l1.771-1.78a1.972 1.972 0 000-2.763l-2.186-2.196a1.95 1.95 0 00-2.751 0l-1.734 1.741c-.49-.265-1.017-.454-1.545-.643v-2.537A1.95 1.95 0 0067.545 64h-3.128a1.95 1.95 0 00-1.96 1.969v2.537c-.527.189-1.017.378-1.47.605l-1.809-1.817a1.95 1.95 0 00-2.75 0L54.24 69.49a1.972 1.972 0 000 2.764l1.81 1.817c-.265.493-.453 1.023-.642 1.553h-2.45A1.95 1.95 0 0051 77.593v3.142a1.95 1.95 0 001.96 1.97h2.487c.189.53.377 1.021.64 1.476l-1.733 1.741a1.972 1.972 0 000 2.764l2.186 2.196a1.95 1.95 0 002.752 0l1.77-1.78c.453.228.943.417 1.433.607v2.537a1.95 1.95 0 001.96 1.968h3.128a1.95 1.95 0 001.96-1.968v-2.537c.527-.19 1.017-.379 1.47-.606l1.809 1.817a1.95 1.95 0 002.75 0l2.187-2.196a1.972 1.972 0 000-2.764l-1.81-1.817c.227-.454.415-.947.604-1.439h2.487A1.95 1.95 0 0081 80.735v-3.142a2 2 0 00-1.998-1.931zm-13.04 6.928c-1.884 0-3.43-1.552-3.43-3.445 0-1.893 1.546-3.445 3.43-3.445 1.885 0 3.43 1.552 3.43 3.445 0 1.893-1.545 3.446-3.43 3.446z"
          fill="#283044"
        />
      </g>
      <defs>
        <filter
          id="Sentiment_graphic_svg__filter0_d"
          x={11}
          y={18}
          width={68.495}
          height={85.064}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0.854902 0 0 0 0 0.854902 0 0 0 0 0.854902 0 0 0 0.4 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="Sentiment_graphic_svg__filter1_d"
          x={49.664}
          y={12.032}
          width={35.897}
          height={36.684}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.823529 0 0 0 0 0.823529 0 0 0 0 0.823529 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="Sentiment_graphic_svg__filter2_d"
          x={85}
          y={53}
          width={59}
          height={24.723}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0.572549 0 0 0 0 0.647059 0 0 0 0 0.764706 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="Sentiment_graphic_svg__filter3_d"
          x={85}
          y={73.683}
          width={59}
          height={24.723}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0.572549 0 0 0 0 0.647059 0 0 0 0 0.764706 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="Sentiment_graphic_svg__filter4_d"
          x={85}
          y={93.276}
          width={59}
          height={24.723}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0.572549 0 0 0 0 0.647059 0 0 0 0 0.764706 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="Sentiment_graphic_svg__filter5_d"
          x={82.972}
          y={69}
          width={28.967}
          height={28.926}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="Sentiment_graphic_svg__filter6_d"
          x={82.972}
          y={88}
          width={28.967}
          height={28.926}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="Sentiment_graphic_svg__filter7_d"
          x={83.043}
          y={47.195}
          width={28.967}
          height={28.926}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.828615 0 0 0 0 0.824219 0 0 0 0 0.879167 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <clipPath id="Sentiment_graphic_svg__clip0">
          <path fill="#fff" d="M0 0h143v124H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SentimentGraphicIcon
