import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { RouteComponentProps } from "react-router-dom"
import PreviousResultTimeframeSelector from './PreviousResultTimeframeSelector'
import { timeFrameSelections } from '../components/menuSelections'
// import { FeedData } from "../../../app/interfaces/feed"
import { usePostsStore } from '../postStore'
import { fetchTrendOvData } from '../queryFn'
// import AIDashboard from "./aiDashboard"
import TopicSummary from "./topicSummary"
import PostCountChart from "./postCountChart"
import SentimentChart from "./sentimentChart"
import StatCards from "./statCards"
import { RowContainer, LayoutGrid, OverviewLayoutContainer } from "./styled"
import TopAuthors from './topAuthors'
import TopicPicker from "./topicPicker"
import TopChannels from './topChannels'
import TopThreads from './topThreads'
// import RelatedCategory from './relatedCategory'
import RelatedTopics from './relatedTopics'
import { Frequency, TrendAccess } from '../interface'
import AngleLeft from '../../reusable/icons/AngleLeft'
import CurrentResultTimeframeSelectRow from './currentResultTimeframeSelectRow'
import ReactTooltip from 'react-tooltip'
import { parseQueryString } from '../helperFn/querystring'
import { useHistory } from 'react-router'
import { destructuredQueryFn } from '../../../app/query/client'

interface TrendOverviewProp extends RouteComponentProps<{ topic: string }> { }

function TrendOverview(props: TrendOverviewProp) {
  const topicId = props.match.params.topic
  const reqFrequency = parseQueryString(props.location.search).f
  const reqIndustry = parseQueryString(props.location.search).label

  const { 
    frequency,
    setFrequency,
    setFrequencyLabel,
    setIsLoadingOverview,
    setOverviewPost,
    labelId,
    setLabelId,
    // isOpen,
    // toggleOpen,
  } = usePostsStore(state => ({
    frequency: state.frequency,
    setFrequency: state.setFrequency,
    setFrequencyLabel: state.setFrequencyLabel,
    setIsLoadingOverview: state.setIsLoadingOverview, 
    setOverviewPost: state.setOverviewPost,
    labelId: state.labelId,
    setLabelId: state.setLabelId,
    // isOpen: state.isOpen,
    // toggleOpen: state.toggleOpen,
  }))

  const {isFetching, isLoading, data: ovData} = useQuery(['fetchTrendOvData', topicId, frequency], fetchTrendOvData, {
    refetchOnWindowFocus: false
  })

  const {data: accessRightsWithFreqDetails} = useQuery<TrendAccess | null>(['/api/users/trendaccess'], destructuredQueryFn)

  const frequencyLabels = labelId && accessRightsWithFreqDetails && (
    timeFrameSelections.map(item => (
      {
        ...item,
        disabled: !accessRightsWithFreqDetails[labelId.toString()]?.freq.includes(item.value) ?? true
      }
    )).filter(item => item.show)
  )

  useEffect(()=>{
    (isFetching || isLoading) ? 
      setIsLoadingOverview(isFetching || isLoading) : setIsLoadingOverview(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isFetching, isLoading])


  useEffect(() => {
    setOverviewPost(ovData?.data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ovData])

  useEffect(() => {
    const mainDiv = document.getElementById('main-content')
    mainDiv && (mainDiv.scrollTop = 0)
    setFrequency(reqFrequency as Frequency)
    setFrequencyLabel(timeFrameSelections.find((selection)=>(selection.value === reqFrequency))!.title)
    setLabelId(+reqIndustry)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory()

  return (
    <OverviewLayoutContainer>
      <ReactTooltip html={true} />
      {/* <button onClick={toggleOpen}>open</button> */}
      <RowContainer>
        <span>
          <span style={{cursor:'pointer'}} onClick={()=>history.goBack()}>
            <AngleLeft marginRight='10px' paddingTop='2px' />
            <span style={{textDecoration:'underline'}}>Back</span>
          </span>
           {` `} to Trend Results of 
        </span>
        {/* for previous page */}
        {frequencyLabels && (
          <PreviousResultTimeframeSelector
            selections={frequencyLabels}
            selected={reqFrequency as Frequency}
            selectLabelAction={setFrequencyLabel}
            selectValueAction={setFrequency}
            id="trend-overview-timeframe-filter" 
            labelId={labelId!}
          />
        )}
      </RowContainer>
      <RowContainer>
        <span>View analysis for </span>
        <CurrentResultTimeframeSelectRow 
          selections={timeFrameSelections.filter(item => item.show)} 
          frequency={frequency} 
          selectLabelAction={setFrequencyLabel}
          selectValueAction={setFrequency}
          fromFrequency={reqFrequency}
        />
      </RowContainer>
      <TopicPicker />
      {/* <AIDashboard /> */}
      <StatCards />
      <RowContainer>
        <PostCountChart />
        <SentimentChart />
      </RowContainer>
      <RowContainer>
        {/* <RelatedCategory /> */}
        <TopicSummary />
      </RowContainer>
      <LayoutGrid>
        <div>
          <RelatedTopics />
          <TopChannels />
          <TopAuthors />
        </div>
        <div>
          <TopThreads />
        </div>
      </LayoutGrid>
    </OverviewLayoutContainer>
  )
}

export default TrendOverview
