import { ModelType, SpamRecord } from "../../../../../../app/interfaces/smartTrain"

export function calculateRelevancyProgress(records: SpamRecord[]) {
  let relevant = 0
  let irrelevant = 0
  records.forEach(record => {
    if (record.tag === 1) {
      relevant++
    }

    if (record.tag === 0) {
      irrelevant++
    }
  })

  const count = (relevant > 50 ? 50 : relevant) + (irrelevant > 50 ? 50 : irrelevant)
  return count > 100 ? 1 : count / 100
}


export function calculateSentimentProgress(records: SpamRecord[]) {
  let positive = 0
  let neutral = 0
  let negative = 0
  records.forEach(record => {
    if (record.tag === 1) {
      positive++
    }

    if (record.tag === 0) {
      neutral++
    }

    if (record.tag === -1) {
      negative++
    }
  })

  const count = (positive > 50 ? 50 : positive) + (neutral > 50 ? 50 : neutral) + (negative > 50 ? 50 : negative)
  return count > 150 ? 1 : Math.round(count / 150 * 100) / 100
}

export function calculateProgress(records: SpamRecord[], mode: ModelType) {
  switch (mode) {
    case "relevancy":
      return calculateRelevancyProgress(records)
    case "sentiment":
      return calculateSentimentProgress(records)
  }
}

export function calculateRelevancyConfirmed(records: SpamRecord[]) {
  let relevant = 0
  let irrelevant = 0
  records.forEach(record => {
    if (record.tag === 0) {
      relevant++
    }

    if (record.tag === 1) {
      irrelevant++
    }
  })

  const count = (relevant > 50 ? 50 : relevant) + (irrelevant > 50 ? 50 : irrelevant)
  return count 
}


export function calculateSentimentConfirmed(records: SpamRecord[]) {
  let positive = 0
  let neutral = 0
  let negative = 0
  records.forEach(record => {
    if (record.tag === 1) {
      positive++
    }

    if (record.tag === 0) {
      neutral++
    }

    if (record.tag === -1) {
      negative++
    }
  })
  
  return (positive > 50 ? 50 : positive) + (neutral > 50 ? 50 : neutral) + (negative > 50 ? 50 : negative)
}

export function calculateConfirmed(records: SpamRecord[], mode: ModelType) {
  switch (mode) {
    case "relevancy":
      return calculateRelevancyConfirmed(records)
    case "sentiment":
      return calculateSentimentConfirmed(records)
  }
}