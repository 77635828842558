import React, { useState } from 'react'
import styled from 'styled-components'
import TaskEditor from "../editor"
import Lottie from "lottie-react";
import emptyPostData from "../../../../assets/dataLoading.json";

import styles from './posts.module.scss'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-top: 4rem;
  margin-top: -80px;
`

const Heading = styled.h1`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const SubHeading = styled.p`
  font-weight: 500;
  color: #828282;
  .keywords {
    color: #3a89ff;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
`

function EmptyPostData() {

  const [showEditor, setShowEditor] = useState(false)

  return (
    <>
      <Container >
      <Lottie animationData={emptyPostData} loop={true} />
        {/* <img src="https://lenx-public-assets.s3.amazonaws.com/EmptyPostImg.webp" alt="empty post data" /> */}
        <div className={styles["empty-post-container"]}>
        <Heading>Seems like we need more time<br />collecting data for this feed..</Heading>
        <SubHeading>Come back later or <span className="keywords" onClick={() => setShowEditor(true)}>refine your keywords</span> to collect more posts</SubHeading>
        </div>
      
      </Container>

      {showEditor && (
        <TaskEditor
          type={1}
          onClose={() => setShowEditor(false)}
        />
      )}
    </>
  )
}

export default EmptyPostData

