import styled from 'styled-components'

export const ModalRoot = styled.div<{ show: boolean }>`
  display: ${props => props.show ? "block" : "none"};
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6) !important;
  cursor: default;
`

export const ModalBody = styled.main`
  padding: 0.5rem 3rem 1.5rem 3rem;
  max-height: 80vh;
  min-height: 250px;
  overflow-y: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

`

export const TitleText = styled.p`
  font-weight: 500;
  color: #283044;
  font-size: 1.5rem;
  width: 80%;
`

export const StyledForm = styled.form`
  width: 80%;
  > p {
    text-align: center;
    color: #29D1D7;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
`

export const CheckboxesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 10px;
  margin: 1.5rem 0;

  > textarea {
    border: 1px solid #D2D2D2;
    border-radius: 3px;
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;
    height: 100px;
    resize: none;
  }
`

export const CheckboxRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
`