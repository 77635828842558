import React from 'react'
import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  Legend
} from "recharts"
import { FinalDataMedium } from '../../home/Cards/CardBody/ExpandedCard/Statistics/ChartRow/helperFn'
import { MediumKey, MediumKeyColorMap } from "../models"

interface ChartDataItem extends FinalDataMedium {
}

interface ChartData {
  data: ChartDataItem[]
}

function Chart({ data }: ChartData) {

  return (
    <div>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={data}>
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
          />
          <XAxis
            dataKey="dateTime"
            axisLine={{ stroke: '#D3D2E0' }}
            tick={{ fontSize: '14px' }}
            tickLine={{ stroke: '#D3D2E0' }}
            tickSize={12}
          />
          <YAxis
            axisLine={{ stroke: '#D3D2E0' }}
            tick={{ fontSize: '14px' }}
            tickLine={{ stroke: '#D3D2E0' }}
            tickSize={12}
          />
          <Legend
            iconType="circle"
            wrapperStyle={{ bottom: -10 }}
          />
          {
            data.length && Object.keys(data[0]).map(key => key in MediumKeyColorMap && (
              <Line
                key={key}
                type="monotone"
                dataKey={key}
                stroke={MediumKeyColorMap[key as MediumKey]}
                strokeWidth={3}
                dot={false}
              />
            ))
          }
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart
