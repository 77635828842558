import React, { memo } from 'react'
import styled from "styled-components"
import { useSelector } from "react-redux"
import { selectPage } from "../../../../../app/store/livefeedpost"

interface PageIndicatorProp {
  count: number
}

const LabelText = styled.p<{ margin?: string }>`
  display: block;
  font-size: 1rem;
  color: #868686;
  margin:${props => props.margin ?? "0px"};
  min-width: 80px;
  @media (max-width: 1092px) {
    display: none;
  }
`

const PageIndicator = memo<PageIndicatorProp>(({ count }) => {

  const page = useSelector(selectPage)

  return (
    <LabelText>
      {`${page} of ${count}`}
    </LabelText>
  )
})

export default PageIndicator
