import React from 'react'
import styled, {css} from 'styled-components'
import BaseButton from '../../../reusable/buttons/BaseButton'
import { Frequency } from '../../interface'

const TimeframeSelection = ({isActive, isDisabled, selection, onClick}: TimeframeSelectionButtonProps) => {
  return (
      <TimeframeSelectionButton 
        isActive={isActive} 
        isDisabled={isDisabled}
        {...!isDisabled && {onClick: (e)=>onClick(e,selection.title, selection.value)}}
      >
        <span>{selection.title}</span>
      </TimeframeSelectionButton>
  )
}

export default TimeframeSelection

interface TimeframeSelectionButtonProps{
  isActive: boolean
  isDisabled: boolean
  selection: Selection
  onClick: (e: React.MouseEvent<HTMLButtonElement>, title: string, value: Frequency) => void
}

interface Selection {
  title: string
  disabled?: boolean
  value: Frequency
}


export const TimeframeSelectionButton = styled(BaseButton)<{isDisabled?: boolean, isActive?: boolean}>`
  height: 36px;
  font-size: 1rem;
  background-color: #fff;
  padding: 0 15px;
  margin: 0;
  border-top: 1px solid #F2F2F2;
  border-bottom: 1px solid #F2F2F2;
  border-right: 1px solid #F2F2F2;

  > span{
    color: #283044;
  }

  ${props => props.isActive && css`
  font-weight: 500;
  background-color: #D5FEFF;
    >span {
      color: #29D1D7;
    }
  `}
  ${props => props.isDisabled && css`
    background-color: #FAFAFA;
    cursor: auto;
    span{
      color: #D3D2E0;
    }
  `}
`