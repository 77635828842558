import React from "react";
import CreateFeedButton from "../../create/button";
import styles from "./empty.module.scss";

const EmptyFeed = ()=>{
    return (
        <div id={styles.empty}>
            <span id={styles.icon}><i className="far fa-newspaper"></i></span>
            <span><b>You have no feeds yet</b></span>
            <span><p>Create a new feed to get started</p></span>
            <CreateFeedButton />
        </div>
    )
}


export default EmptyFeed;