import React from 'react'
import { useSelector } from "react-redux"
import { selectSentiments } from "../../../../../../../app/store/overviewSlice"
import EmptyStatePlaceholder from "../../../components/EmptyStatePlaceholder"
import { GadgetBody, GadgetHeader, GadgetRoot } from "../../../styled"
import SentimentIndicator from "./SentimentIndicator"
import OverviewSkeleton from "./Skeleton"
import { SentimentIndicatorContainer } from "./styled"

function SentimentOverview() {
  const sentiments = useSelector(selectSentiments)

  if (sentiments) {
    const positive = sentiments.reduce((sum, item) => sum + item.positive, 0)
    const neutral = sentiments.reduce((sum, item) => sum + item.neutral, 0)
    const negative = sentiments.reduce((sum, item) => sum + item.negative, 0)
    const sum = positive + neutral + negative

    return (
      <GadgetRoot>
        <GadgetHeader>
          <h3>Sentiment Overview</h3>
        </GadgetHeader>
        <GadgetBody>
          {
            sentiments.length ? (
              <SentimentIndicatorContainer>
                <SentimentIndicator type="positive" value={positive / sum} />
                <SentimentIndicator type="neutral" value={neutral / sum} />
                <SentimentIndicator type="negative" value={negative / sum} />
              </SentimentIndicatorContainer>
            ) : <EmptyStatePlaceholder />
          }
        </GadgetBody>
      </GadgetRoot>
    )


  }

  return (
    <OverviewSkeleton />
  )
}

export default SentimentOverview
