import React, { useMemo, useCallback, memo } from "react"
import Input from "./input"
import styled from 'styled-components'

type prop = {
    in: (string | string[])[],
    onChange: (data: (string | string[])[]) => void,
    relationship: "AND" | "OR",
    selectedGroup: boolean,
    sanitize: () => void,
    excludeOnly: boolean,
    excludeBlock: boolean,
    isLimited: boolean
}

const InRoot = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
`

const Separator = styled.p<{ excludeGroup: boolean }>`
    font-weight: 500;
    align-self: center;
    justify-self: center;
    color: #3a89ff;
    background-color: ${props => props.excludeGroup ? "white" : "transparent"};
`

const In = memo<prop>((props) => {
    const dimensions: number = useMemo(() => {
        if (!props.in) return 1
        if (!props.in.length) return 1
        if (props.in.length && typeof props.in[0] === "string") return 1
        return 2
    }, [props.in])

    const onChangeData = useCallback((changedData: string[], index: number) => {
        let currd: string[][] = dimensions === 1 ? [[...props.in as string[]]] : props.in as string[][]
        currd[index] = changedData

        if (!changedData.length && currd.length === 1) {
            currd = []
        }

        props.onChange(currd.filter(item => item.length !== 0))
    }, [dimensions, props])


    return (
        <InRoot>
            {
                dimensions === 1 ?
                    <Input index={0}
                        onChange={onChangeData}
                        inputs={props.in as string[]}
                        relationship={"OR"}
                        selectedGroup={props.selectedGroup}
                        sanitize={props.sanitize}
                        excludeGroup={props.excludeOnly}
                        excludeBlock={props.excludeBlock}
                        isLimited={props.isLimited}
                    /> :
                    props.in
                        .map((inputStrings, index) => (
                            <Input
                                onChange={onChangeData}
                                key={index}
                                index={index}
                                inputs={inputStrings as string[]}
                                relationship={"OR"}
                                selectedGroup={props.selectedGroup}
                                sanitize={props.sanitize}
                                excludeGroup={props.excludeOnly}
                                excludeBlock={props.excludeBlock}
                                isLimited={props.isLimited}
                            />))
                        .reduce((p, c) => (
                            <>
                                {p}
                                <Separator excludeGroup={props.excludeOnly}>{props.relationship}</Separator>
                                {c}
                            </>
                        ))
            }

        </InRoot>
    )
}
)
export default In