import React from 'react'
import styled, { css } from 'styled-components'
import { ChartType } from "."
import BaseButton from "../../../../../../reusable/buttons/BaseButton"

interface ChartTypeSwitchProp {
  type: ChartType
  setType: React.Dispatch<React.SetStateAction<ChartType>>
}

interface ButtonProp {
  isActive: boolean
}

const SwitchRoot = styled.div`
  border-radius: 4px;
  border: 1px solid #F8F8FA;
  min-width: fit-content;
`

const Button = styled(BaseButton) <ButtonProp>`
  font-size: 14px;
  font-weight: 400;
  height: 28px;

  ${props => props.isActive ? css`
    color: #29D1D7;
    background-color: #DBF7F8;
  ` : css`
    color: #516380;
    background-color: #F8F8FA;
  `}
  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }
  &:nth-child(2) {
    border-radius: 0px 4px 4px 0px;
  }
`

function ChartTypeSwitch({ type, setType }: ChartTypeSwitchProp) {
  return (
    <SwitchRoot>
      <Button
        isActive={type === "percentage"}
        onClick={() => setType("percentage")}
      >
        Percentage
      </Button>
      <Button
        isActive={type === "volume"}
        onClick={() => setType("volume")}
      >
        Volume
      </Button>
    </SwitchRoot>
  )
}

export default ChartTypeSwitch
