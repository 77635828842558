import React from 'react'
import CrossIcon from "../../icons/CrossIcon"
import styled from 'styled-components'
import BaseButton from "../../buttons/BaseButton"

interface ModalHeaderProp {
  icon?: JSX.Element
  iconRight?: JSX.Element
  title?: string
  onClose?: () => void
}

const HeaderRoot = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
`

const CrossWrapper = styled.div`
  &:hover {
    svg g path {
          fill: #bdbdbd;
    }
  }
`

function ModalHeader({ icon, iconRight, title = "", onClose }: ModalHeaderProp) {
  return (
    <HeaderRoot>
      <LeftWrapper>
        {icon}
        <HeaderTitle>{title}</HeaderTitle>
        {iconRight}
      </LeftWrapper>
      {onClose && (
        <BaseButton onClick={() => { onClose() }}>
          <CrossWrapper>
            <CrossIcon />
          </CrossWrapper>
        </BaseButton>
      )}
    </HeaderRoot>
  )
}

export default ModalHeader
